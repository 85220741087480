import request from './request';

function getKPI(data) {
	return request({
		url: '/kpi',
		method: 'GET',
		params: data,
	});
}


function updateKPI(data) {
	return request({
		url: '/kpi',
		method: 'PUT',
		data,
	});
}

export default {
	getKPI,
	updateKPI,
};
