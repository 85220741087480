import React, { useContext } from 'react';
import { Snackbar, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { snackbarContext } from '../../contexts/snackbarContext';

const useStyles = makeStyles(theme => ({
	closeIcon: {
		padding: 7,
	},
	default: {

	},
	error: {
		backgroundColor: theme.palette.error.main,
	},
	success: {
		backgroundColor: theme.palette.success.main,
	},
}));

function CustomSnackbar() {
	const classes = useStyles();
	const { 
		text,
		variant, 
		vertical, 
		horizontal, 
		handleExited,
		handleClose,
	} = useContext(snackbarContext);

	return (
		<Snackbar
			anchorOrigin={{ vertical, horizontal }}
			open={Boolean(text)}
			autoHideDuration={3000}
			onClose={handleClose}
			onExited={handleExited}
			ContentProps={{ 
				'aria-describedby': 'snackbar-id',
				className: classes[variant],
			}}
			message={text}
			ClickAwayListenerProps={{ onClickAway: () => null }}
			action={[
				<IconButton 
					className={classes.closeIcon}
					key="close" 
					aria-label="close" 
					color="inherit" 
					onClick={handleClose}
				>
					<CloseIcon />
				</IconButton>,
			]}
		/>
	);
}

export default CustomSnackbar;
