import React from 'react';
import { Link, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  item: { padding: theme.spacing(1) },
  link: { paddingLeft: `15px` },
}));

export default function FileList(props) {
  const { files } = props;
  const classes = useStyles();

  return (
    <div>
      {files.map((file, index) => (
        <div key={file.url} className={classes.item}>
          <Typography variant="body2">
            {`${index + 1}. ${file.name}`}
          </Typography>

          <div className={classes.link}>
            <Link href={file.url} target="_blank" underline="always">
              {file.originalName}
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
}
