import moment from 'moment';

const dateFormat = 'DD/MM/YYYY';

const getSubheaders = (_headers) => {
	let headers = [];

	_headers.forEach((header) => {
		if(header.subheaders && header.subheaders.length) {
			header.subheaders.forEach((subheader) => {
				headers.push({ ...subheader });
			});
		} else {
			headers.push({ ...header });
		}
	});
	return headers;
};

const generateNumber = (raw_number, digit = 2) => {
	let number = Number(raw_number);

	// invalid number
	if(isNaN(number)) return '-';

	// setup option
	let option = {
		minimumFractionDigits: digit, 
		maximumFractionDigits: digit
	};

	number = number.toLocaleString('en', option);
	return number;
}

const reformatData = (subheaders, _data) => {
	let data = [];
	try {
		_data.forEach((e) => {
			let row = [];
			let disableParseData = Boolean(e.disableParseData);

			subheaders.forEach((el) => {
				let tmp = e[el.id];
				if(disableParseData) return row.push(tmp);

				switch (el.type) {
					case 'string':
						tmp = e[el.id];
						break;
					case 'dom':
						tmp = e[el.id];
						break;
					case 'int':
						tmp = generateNumber(e[el.id], 0);
						break;
					case 'float':
						tmp = generateNumber(e[el.id], 2);
						break;
					case 'date':
						tmp = e[el.id] && moment(e[el.id]).format(dateFormat);
						break;
					default:
						tmp = '';
						break;
				}
				row.push(tmp);
			});
			data.push(row);
		});
	} catch(err) {
		console.error(err);
	}

	return data;
};

const getObjectByKey = (_array, key, value) => {
	const array = _array.filter(e => e[key] === value);
	if(!array.length) return {};
	return array[0];
};

const generateSortable = (_headers) => (id) => {
	const header = getObjectByKey(_headers, 'id', id);
	if(header.type === 'dom') return false;
	return Boolean(!header.disableSort);
};

export {
	getSubheaders,
	getObjectByKey,
	generateNumber,
	reformatData,
	generateSortable,
};
