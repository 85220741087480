import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

// eslint-disable-next-line
import auth from '../api/auth';
// eslint-disable-next-line
import configs from '../configs';

function ScrollToTop() {
	const routerHistory = useHistory();
	const { pathname } = useLocation();

	useEffect(() => {
		window.__history = routerHistory;
	}, [routerHistory]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	/*
	useEffect(() => {
		(async () => {
			const { success} = await auth.authCheck();
			console.log(success);
			if(!success) {
				window.localStorage.removeItem('user');
				const redirectURL = window.location.pathname + window.location.search;
				const loginURL = `${configs.loginPath}?reason=SESSION_EXPIRED&redirect=${window.encodeURIComponent(redirectURL)}`;
				if(window.__history) {
					window.__history.push(loginURL);
				} else {
					window.location.href = window.location.origin + loginURL;
				}
			}
		})();
	}, [])
	*/

	return null;
}

export default ScrollToTop;
