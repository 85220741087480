import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';

import Personal from './Personal';
import DirectGroup from './DirectGroup';
import IndirectGroup from './IndirectGroup';
import WholeGroup from './WholeGroup';
import DirectAgents from './DirectAgents';
import IndirectAgents from './IndirectAgents';
import ActivityPanel from './ActivityPanel';
import GoalProgressSection from './GoalProgressSection';

import useFetch from '../../../hooks/useFetch';
import useDirectGroupLeaders from '../../../hooks/useDirectGroupLeaders';

import goals from '../../../api/goals';
import { 
	getSitemapData, 
	getObjectFromArray
} from '../../../utils';

const defaultTabValue = 'PS';
const defaultGoalTypeValue = 'GROUP';
const sitemapValue = 'PERSONAL_GOAL';
const { label } = getSitemapData(sitemapValue);

const generateGoalTypeOptions = (ranking) => {
	const goalTypeOptions = [{ label: 'Overview', value: 'GROUP' }];

	if(['GSM', 'USM'].includes(ranking)) {
		goalTypeOptions.push({ label: 'Direct Agents', value: 'DG_AGENT' });
	}

	if(['GSM'].includes(ranking)) {
		goalTypeOptions.push({ label: 'Indirect Agents', value: 'IG_AGENT' });
	}
	return goalTypeOptions;
};

const generateTabOptions = (ranking, goalType, USMLeaders) => {
	let tabOptions;

	switch (goalType) {
		case 'GROUP':
			if(ranking === 'GSM') {
				tabOptions = [
					{ label: 'Whole Group', value: 'WG' },
					{ label: 'Direct Group', value: 'DG' },
					{ label: 'Personal', value: 'PS' },
					...USMLeaders.map(e => ({ 
						label: `USM: ${e.name}`, 
						value: `IG-${e.agentID}` 
					})),
				];
			} else if (ranking === 'USM') {
				tabOptions = [
					{ label: 'Direct Group', value: 'DG' },
					{ label: 'Personal', value: 'PS' },
				];
			} else { // normal agents
				tabOptions = [
					{ label: 'Personal', value: 'PS' },
				];
			}
			break;
		case 'DG_AGENT':
			if(ranking === 'GSM') {
				tabOptions = [
					{ label: 'Direct Agents', value: 'DG_AGENT' }
				];
			} else if (ranking === 'USM') {
				tabOptions = [
					{ label: 'Direct Agents', value: 'DG_AGENT' }
				];
			}
			break;
		case 'IG_AGENT':
			if(ranking === 'GSM') {
				tabOptions = [
					...USMLeaders.map(e => ({ 
						label: e.name, 
						value: `IG_AGENT-${e.agentID}` 
					})),
				];
			}
			break;
		default:
			tabOptions = [];
			break;
	}

	return tabOptions;
};

const generateTabDefaultValue = (ranking) => {
	let tab;
	switch (ranking) {
		case 'GSM':
			tab = 'WG';
			break;
		case 'USM':
			tab = 'DG';
			break;
		default:
			tab = defaultTabValue;
			break;
	}
	return tab;
};

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	},
	summaryButton: {
		marginTop: 14,
		marginLeft: 5,
	},
	wrapper: {
		borderRadius: theme.shape.borderRadius,
		backgroundColor: '#fff'
	},
	tabs: {
		//backgroundColor: '#fff',
		marginBottom: -10,
	},
	tab: {
	},
	selected: {
		color: theme.palette.primary.main,
		fontWeight: 600,
	},
}));

function Goal() {
	const classes = useStyles();
	const [year, setYear] = useState('');
	const [goalProgress, setGoalProgress] = useState([]);
	const [yearList, setYearList] = useState([]);
	const [res, status, handleFetch] = useFetch(goals.getGoalStates);
	const [tab, setTab] = useState(defaultTabValue);
	const [goalType, setGoalType] = useState(defaultGoalTypeValue);
	const leaders = useDirectGroupLeaders(year);

	const handleChange = (event) => {
		const year = event.target.value;
		setYear(year);
		setGoalType(defaultGoalTypeValue);

		// set tab
		let ranking = '';
		if(yearList.length && year) {
			ranking = getObjectFromArray(yearList, year).ranking;
		}

		setTab(generateTabDefaultValue(ranking));
	};

	const handleTabChange = (event, newValue) => {
		setTab(newValue);
	};

	const handleGoalTypeChange = (event) => {
		setGoalType(event.target.value);

		// set tab
		let ranking = '';
		if(yearList.length && year) {
			ranking = getObjectFromArray(yearList, year).ranking;
		}
		const USMLeaders = leaders.filter(e => e.ranking === 'USM');
		const tabOptions = generateTabOptions(ranking, event.target.value, USMLeaders);
		setTab(tabOptions[0].value);
	};

	const handleUpdateGoalProgress = useCallback((goals) => {
		setGoalProgress(goals);
	}, []);

	useEffect(() => {
		handleFetch({});
	}, [handleFetch]);

	useEffect(() => {
		if(status.loaded) {
			if(!status.error) {
				const formattedData = res.states.map(e => ({
					...e,
					label: String(e.year),
					value: String(e.year),
				}));
				setYearList(formattedData);
				if(formattedData.length) {
					const selectedYear = formattedData[formattedData.length - 1];
					setYear(selectedYear.value);
					setTab(generateTabDefaultValue(selectedYear.ranking));
				}
			} else {
				setYearList([]);
			}
		}
	}, [res, status.loaded, status.error]);

	let ranking = '';
	if(yearList.length && year) {
		ranking = getObjectFromArray(yearList, year).ranking;
	}

	const USMLeaders = leaders.filter(e => e.ranking === 'USM');

	const tabOptions = generateTabOptions(ranking, goalType, USMLeaders);
	const goalTypeOptions = generateGoalTypeOptions(ranking);

	return (
		<React.Fragment>

		<Layout title={label}>
			<Select 
				className={classes.select}
				maxWidth={110}
				label="Year"
				options={yearList}
				value={year}
				onChange={handleChange}
			/>

			<Select 
				className={classes.select}
				maxWidth={150}
				label="Type"
				options={goalTypeOptions}
				value={goalType}
				onChange={handleGoalTypeChange}
			/>

			<div className={classes.wrapper}>
				<Tabs 
					className={classes.tabs}
					value={tab} 
					onChange={handleTabChange} 
					indicatorColor="primary" 
					variant="scrollable"
				>
					{tabOptions.map(e => (
						<Tab key={e.value} classes={{ 
							root: classes.tab,
							selected: classes.selected 
						}} label={e.label} value={e.value} />
					))}
				</Tabs>

				{tab === 'PS' && <Personal year={year} onUpdateProgress={handleUpdateGoalProgress} />}
				{tab === 'DG' && <DirectGroup year={year} onUpdateProgress={handleUpdateGoalProgress} />}
				{tab.indexOf('IG-') === 0 && <IndirectGroup year={year} onUpdateProgress={handleUpdateGoalProgress} tabValue={tab} />}
				{tab === 'WG' && <WholeGroup year={year} onUpdateProgress={handleUpdateGoalProgress} />}
				{tab === 'DG_AGENT' && <DirectAgents year={year} onUpdateProgress={handleUpdateGoalProgress} />}
				{tab.indexOf('IG_AGENT-') === 0 && <IndirectAgents year={year} onUpdateProgress={handleUpdateGoalProgress} tabValue={tab} />}
			</div>

		</Layout>

		<GoalProgressSection goals={goalProgress} />
		
		<ActivityPanel />

		</React.Fragment>
	);
}

export default Goal;
