import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import companyEventApi from '../../../api/companyEvents';
import useSnackbarText from '../../../hooks/useSnackbarText';

const DATE_FORMAT = 'YYYY-MM-DD';

const useStyles = makeStyles(theme => ({
  section: {
    borderRadius: 10,
    backgroundColor: `#ffffff`,
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2.5),
  },
  eventSection: {
    position: 'relative',
    borderRadius: 10,
    backgroundColor: `#fbfbfb`,
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2.5),
    marginBottom: theme.spacing(1),
  },
  header: { fontWeight: 'bold' },
  row: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  createButton: {
    color: '#ffffff',
    fontWeight: 600,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  eventName: {
    fontSize: `1rem`,
    fontWeight: 'bold',
  },
  deleteButton: {
    color: '#ffffff',
    fontWeight: 600,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

export default function EventListPage() {
  const classes = useStyles();
  const [events, setEvents] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedEventID, setSelectedEventID] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [timestamp, setTimestamp] = useState(new Date().valueOf());
  const handleSnackbarChange = useSnackbarText();

  const handleMoreClick = eventID => event => {
    setAnchorEl(event.currentTarget);
    setSelectedEventID(eventID);
  };

  const handleMoreDismiss = () => {
    setAnchorEl(null);
    setSelectedEventID('');
  };

  const handleDelete = async () => {
    if (!selectedEventID) return;

    setIsDeleting(true);
    const result = await companyEventApi.deleteCompanyEvent(selectedEventID);
    setIsDeleting(false);
    if (result.success) {
      setShowDeleteDialog(false);
      setTimestamp(new Date().valueOf());
      handleSnackbarChange({
        text: `Event has been deleted`,
        variant: 'success',
      });
    } else {
      handleSnackbarChange({ text: result.data.message, variant: 'error' });
    }
  };

  const handleShowDeleteDialog = () => {
    setAnchorEl(false);
    setShowDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setAnchorEl(false);
    setSelectedEventID('');
    setShowDeleteDialog(false);
  };

  useEffect(() => {
    async function main() {
      const result = await companyEventApi.getCompanyEvents();

      if (result.success) {
        setEvents(result.data.events);
      } else {
        handleSnackbarChange({ text: result.data.message, variant: 'error' });
      }
    }

    main();
  }, [timestamp]);

  const eventToBeDeleted = events.find(
    event => event.eventID === selectedEventID
  );

  return (
    <div>
      <div className={classes.section}>
        <div
          className={classes.row}
          style={{ justifyContent: 'space-between' }}
        >
          <Typography className={classes.header} variant="h6">
            Create Event
          </Typography>

          <Button
            className={classes.createButton}
            component={NavLink}
            to="/admin/company-info/events/create"
            startIcon={<AddIcon />}
          >
            Create
          </Button>
        </div>

        <div style={{ padding: 10 }} />

        {events.map(event => {
          const isSameDate =
            moment(event.startAt).format(DATE_FORMAT) ===
            moment(event.endAt).format(DATE_FORMAT);

          return (
            <div key={event.eventID} className={classes.eventSection}>
              <div style={{ position: 'absolute', right: 5, top: 5 }}>
                <IconButton onClick={handleMoreClick(event.eventID)}>
                  <MoreVertIcon />
                </IconButton>
              </div>
              <Typography
                color="primary"
                variant="h6"
                className={classes.eventName}
              >
                {event.name}
              </Typography>

              <Typography variant="body2">
                {isSameDate
                  ? moment(event.startAt).format('D MMM YYYY')
                  : `${moment(event.startAt).format('D MMM YYYY')} - ${moment(
                      event.endAt
                    ).format('D MMM YYYY')}`}
              </Typography>

              {event.description && (
                <Typography variant="body2" style={{ marginTop: 10 }} noWrap>
                  {event.description}
                </Typography>
              )}
            </div>
          );
        })}

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMoreDismiss}
          anchorOrigin={{ horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          keepMounted
        >
          <MenuItem
            component={NavLink}
            to={`/admin/company-info/events/${selectedEventID}/edit`}
            button
          >
            Edit
          </MenuItem>
          <MenuItem onClick={handleShowDeleteDialog}>Delete</MenuItem>
        </Menu>
      </div>

      <Dialog
        open={showDeleteDialog}
        onClose={handleCloseDeleteDialog}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>{`Delete "${eventToBeDeleted?.name || ''}"`}</DialogTitle>
        <DialogContent>
          <DialogContentText>It cannot be undone</DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button
            className={classes.deleteButton}
            onClick={handleDelete}
            disabled={isDeleting}
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
