import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import WithNavigation from './components/WithNavigation';
import ScrollToTop from './components/ScrollToTop';

import Components from './pages/Components';
import SignIn from './pages/SignIn';
import Authentication from './pages/Authentication';

import Personal from './pages/personal';
import Group from './pages/group';
import Admin from './pages/admin';
import CompanyUpdate from './pages/company-update';

import sitemaps from './configs/sitemaps';

function WithNavRoutes() {
  return (
    <WithNavigation>
      <Switch>
        {/* Landing page */}
        <Route path="/" exact>
          <Authentication />
        </Route>

        {/* Personal */}
        <Route path={sitemaps.personal.pathname}>
          <Personal />
        </Route>

        {/* CompanyUpdate */}
        <Route path={sitemaps.company_update.pathname}>
          <CompanyUpdate />
        </Route>

        {/* Group */}
        <Route path={sitemaps.group.pathname}>
          <Group />
        </Route>

        {/* Admin */}
        <Route path={sitemaps.admin.pathname}>
          <Admin />
        </Route>

        {/* Components */}
        <Route path="/components">
          <Components />
        </Route>
      </Switch>
    </WithNavigation>
  );
}

function Router() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route path="/sign-in" exact>
          <SignIn />
        </Route>

        <Route path="/">
          <WithNavRoutes />
        </Route>

        {/* Error handling */}
        <Route>
          <div> 404 Not found</div>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
