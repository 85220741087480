import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';
import Dialog from '../../../components/Dialog';

import Summary from './Summary';

import useQueryParams from '../../../hooks/useQueryParams';
import useDirectGroupLeaders from '../../../hooks/useDirectGroupLeaders';
import useFetch from '../../../hooks/useFetch';

import group from '../../../api/group';
import { 
	getYearList, 
	getSitemapData, 
	getFooterTotal,
} from '../../../utils';

const adminAgentID = 1;
const dateFormat = 'YYYY-MM-DD';
const sitemapValue = 'GROUP_INFORCEPERFORMANCE';
const { label } = getSitemapData(sitemapValue);

const modeOptions = [
	{ label: 'Monthly', value: '0' },
	{ label: 'Quarterly', value: '1' },
	{ label: 'Half Yearly', value: '2' },
	{ label: 'Yearly', value: '3' },
];

const halfYearOptions = [
	{ label: '1st Half Year', value: '1' },
	{ label: '2nd Half Year', value: '2' },
];

const quarterOptions = [
	{ label: 'Quarter 1', value: '1' },
	{ label: 'Quarter 2', value: '2' },
	{ label: 'Quarter 3', value: '3' },
	{ label: 'Quarter 4', value: '4' },
];

const monthOptions = moment.monthsShort().map((e, i) => ({
	label: e,
	value: String(i),
}));

const headers = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
	},
	{
		id: 'BSA',
		label: 'BSA',
		type: 'float',
		errorCheck: true,
	},
	{
		id: 'ANP',
		label: 'ANP',
		type: 'float',
		errorCheck: true,
	},
	{
		id: 'solidANP',
		label: 'Solid ANP',
		type: 'float',
		errorCheck: true,
	},
	{
		id: 'NOC',
		label: 'NOC',
		type: 'int',
		errorCheck: true,
	},
	{
		id: 'client',
		label: 'Client',
		type: 'string',
		subheaders: [
			{
				id: 'newClient',
				label: 'New',
				type: 'int',
				errorCheck: true,
			},
			{
				id: 'existingClient',
				label: 'Existing',
				type: 'int',
				errorCheck: true,
			},
		]
	},
	{
		id: 'nominee',
		label: 'Nominee',
		type: 'string',
		subheaders: [
			{
				id: 'withNominee',
				label: 'Yes',
				type: 'int',
				errorCheck: true,
			},
			{
				id: 'withoutNominee',
				label: 'No',
				type: 'int',
				errorCheck: true,
			},
		]
	},
];

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	},
	summaryButton: {
		marginTop: 14,
		marginLeft: 5,
	}
}));

function InforcedPerformance() {
	const classes = useStyles();
	const [queryParams, setQueryParams] = useQueryParams();
	const leaders = useDirectGroupLeaders(moment(queryParams.startDate).year());
	const [data, setData] = useState([]);
	const [footers, setFooters] = useState([]);
	const [res, status, handleFetch] = useFetch(group.getInforcedPerformance);
	const [state, _setState] = useState({
		mode: modeOptions[0].value,
		year: String(moment().year()),
		halfYear: moment().month() < 6 ? '1' : '2',
		quarter: String(moment().quarter()),
		month: String(moment().month()),
	});

	const setState = useCallback((newState) => {
		_setState(prevState => ({
			...prevState,
			...newState,
		}));
	}, []);

	const handleChange = (type) => (event) => {
		setState({ [type]: event.target.value });
		if(type === 'year') setQueryParams({ leaderID: adminAgentID });
		if(type === 'leaderID') setQueryParams({ leaderID: event.target.value });
	};

	const handleShowSummary = () => {
		setQueryParams({ summary: 'true' });
	};

	useEffect(() => {
		let startDate, endDate;
		let { mode, year, halfYear, quarter, month } = state;

		switch(mode) {
			case '0': // monthly
				startDate = moment(`${year}-01-01`).month(month).startOf('month').format(dateFormat);
				endDate = moment(`${year}-01-01`).month(month).endOf('month').format(dateFormat);
				break;
			case '1': // quarterly
				startDate = moment(`${year}-01-01`).quarter(quarter).startOf('quarter').format(dateFormat);
				endDate = moment(`${year}-01-01`).quarter(quarter).endOf('quarter').format(dateFormat);
				break;
			case '2': // half yearly
				if(halfYear === '1') {
					startDate = moment(`${year}-01-01`).format(dateFormat);
					endDate = moment(`${year}-06-30`).format(dateFormat);
				} else {
					startDate = moment(`${year}-07-01`).format(dateFormat);
					endDate = moment(`${year}-12-31`).format(dateFormat);
				}
				break;
			case '3': // yearly
				startDate = moment(`${year}-01-01`).format(dateFormat);
				endDate = moment(`${year}-12-31`).format(dateFormat);
				break;
			default:
				startDate = null;
				endDate = null;
		}
		setQueryParams({ startDate, endDate });
	}, [state, setQueryParams]);

	useEffect(() => {
		let tmp = {};
		if(!queryParams.startDate) tmp.startDate = moment().startOf('month').format(dateFormat);
		if(!queryParams.endDate) tmp.endDate = moment().endOf('month').format(dateFormat);
		if(!queryParams.leaderID) tmp.leaderID = adminAgentID;
		setQueryParams(tmp);
	}, [queryParams.startDate, queryParams.endDate, queryParams.leaderID, setQueryParams]);

	useEffect(() => {
		if(queryParams.startDate && queryParams.endDate && queryParams.leaderID) {
			handleFetch({
				startDate: queryParams.startDate,
				endDate: queryParams.endDate,
				leaderID: queryParams.leaderID,
			});
		}
	}, [handleFetch, queryParams.startDate, queryParams.endDate, queryParams.leaderID]);

	useEffect(() => {
		if(status.loaded) {
			if(!status.error) {
				const formattedData = res.performance;
				setData(formattedData);
				const footer = getFooterTotal(headers, formattedData);
				setFooters([{
					name: 'Total',
					...footer,
				}]);
			} else {
				setData([]);
				setFooters([]);
			}
		}
	}, [res, status.loaded, status.error]);

	return (
		<Layout title={label}>
			<Select 
				className={classes.select}
				maxWidth={150}
				label="Mode"
				options={modeOptions}
				value={state.mode}
				onChange={handleChange('mode')}
			/>

			<Select 
				className={classes.select}
				maxWidth={110}
				label="Year"
				options={getYearList()}
				value={state.year}
				onChange={handleChange('year')}
			/>

			{state.mode === '2' && (
				<Select 
					className={classes.select}
					maxWidth={150}
					label="Half Year"
					options={halfYearOptions}
					value={state.halfYear}
					onChange={handleChange('halfYear')}
				/>
			)}

			{state.mode === '1' && (
				<Select 
					className={classes.select}
					maxWidth={150}
					label="Quarter"
					options={quarterOptions}
					value={state.quarter}
					onChange={handleChange('quarter')}
				/>
			)}

			{state.mode === '0' && (
				<Select 
					className={classes.select}
					maxWidth={110}
					label="Month"
					options={monthOptions}
					value={state.month}
					onChange={handleChange('month')}
				/>
			)}

			<Select 
				maxWidth={200}
				label="Group Leader"
				options={leaders}
				value={queryParams.leaderID || ''}
				onChange={handleChange('leaderID')}
			/>

			<Button 
				className={classes.summaryButton} 
				onClick={handleShowSummary} 
				color="primary"
				startIcon={<DescriptionIcon />}
				variant="outlined"
			>
				Summary
			</Button>

			<Table
				headers={headers}
				data={data}
				footers={footers}
				isLoading={status.loading}
			/>

			<Dialog queryParamKey="summary" title="Summary" maxWidth="100%">
				<Summary />
			</Dialog>

		</Layout>
	);
}

export default InforcedPerformance;
