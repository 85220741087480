import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Button, IconButton } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import PieChartIcon from '@material-ui/icons/PieChart';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';
import Dialog from '../../../components/Dialog';
// import PieChart from '../../../components/PieChart';
import DiaryPieChart from './DiaryPieChart';

import Summary from './Summary';

import useQueryParams from '../../../hooks/useQueryParams';
import useDirectGroupLeaders from '../../../hooks/useDirectGroupLeaders';
import useFetch from '../../../hooks/useFetch';

import group from '../../../api/group';
import { 
	getYearList, 
	getSitemapData, 
	getFooterTotal,
} from '../../../utils';

const adminAgentID = 1;
const dateFormat = 'YYYY-MM-DD';
const sitemapValue = 'GROUP_ACTIVITYPOINT';
const { label } = getSitemapData(sitemapValue);
const firstDayOfWeek = 1;

const generateWeekOptions = (year) => {
	return Array(53).fill('').map((e, i) => ({
		label: `Week ${i + 1} (${
			moment(`${year}-01-01`).week(i + 1).startOf('week').add(firstDayOfWeek, 'day').format(dateFormat)
		} - ${
			moment(`${year}-01-01`).week(i + 1).endOf('week').add(firstDayOfWeek, 'day').format(dateFormat)
		})`,
		value: String(i + 1),
	}));
};

const modeOptions = [
	{ label: 'Weekly', value: '1' },
	{ label: 'Monthly', value: '2' },
	{ label: 'Quarterly', value: '3' },
	{ label: 'Half Yearly', value: '4' },
	{ label: 'Yearly', value: '5' },
];

const halfYearOptions = [
	{ label: '1st Half Year', value: '1' },
	{ label: '2nd Half Year', value: '2' },
];

const quarterOptions = [
	{ label: 'Quarter 1', value: '1' },
	{ label: 'Quarter 2', value: '2' },
	{ label: 'Quarter 3', value: '3' },
	{ label: 'Quarter 4', value: '4' },
];

const monthOptions = moment.monthsShort().map((e, i) => ({
	label: e,
	value: String(i),
}));

const headers = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
	},
	{
		id: 'pieChart',
		label: 'Pie', 
		type: 'dom',
	},
	{
		id: 'statusOpen',
		label: 'Open',
		type: 'int',
		errorCheck: true,
	},
	{
		id: 'statusClose',
		label: 'Close',
		type: 'int',
		errorCheck: true,
	},
	{
		id: 'statusFactFinding',
		label: 'Fact Finding',
		type: 'int',
		errorCheck: true,
	},
	{
		id: 'statusPolicyReview',
		label: 'Policy Review',
		type: 'int',
		errorCheck: true,
	},
	{
		id: 'statusMealAppointment',
		label: 'Meal Appointment',
		type: 'int',
		errorCheck: true,
	},
	{
		id: 'statusRecruitment',
		label: 'Recruitment',
		type: 'int',
		errorCheck: true,
	},
	{
		id: 'statusFollowUp',
		label: 'Follow Up',
		type: 'int',
		errorCheck: true,
	},
	{
		id: 'statusNone',
		label: 'None/Service',
		type: 'int',
		errorCheck: true,
	},
	{
		id: 'prospects',
		label: 'Prospects',
		type: 'float',
		errorCheck: true,
	},
	{
		id: 'submissions',
		label: 'Submissions',
		type: 'int',
		errorCheck: true,
	},
	{
		id: 'events',
		label: 'Events',
		type: 'int',
		errorCheck: true,
	},
	{
		id: 'statusNonFaceToFace',
		label: 'Non Face To Face',
		type: 'int',
		errorCheck: true,
	},
	{
		id: 'total',
		label: 'Total',
		type: 'float',
		errorCheck: true,
	},
];

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	},
	summaryButton: {
		marginTop: 14,
		marginLeft: 5,
	}
}));

function DiaryPerformance() {
	const classes = useStyles();
	const [queryParams, setQueryParams] = useQueryParams();
	const leaders = useDirectGroupLeaders(moment(queryParams.startDate).year());
	const [data, setData] = useState([]);
	const [footers, setFooters] = useState([]);
	const [res, status, handleFetch] = useFetch(group.getDiaryPerformance);
	const [state, _setState] = useState({
		mode: modeOptions[1].value,
		week: String(moment().week()),
		year: String(moment().year()),
		halfYear: moment().month() < 6 ? '1' : '2',
		quarter: String(moment().quarter()),
		month: String(moment().month()),
	});

	const setState = useCallback((newState) => {
		_setState(prevState => ({
			...prevState,
			...newState,
		}));
	}, []);

	const handleChange = (type) => (event) => {
		setState({ [type]: event.target.value });
		if(type === 'year') setQueryParams({ leaderID: adminAgentID });
		if(type === 'leaderID') setQueryParams({ leaderID: event.target.value });
	};

	const handleShowSummary = () => {
		setQueryParams({ summary: 'true' });
	};

	useEffect(() => {
		let startDate, endDate;
		let { mode, week, year, halfYear, quarter, month } = state;

		switch(mode) {
			case '1': // weekly
				startDate = moment(`${year}-01-01`).week(week).startOf('week').add(firstDayOfWeek, 'day').format(dateFormat);
				endDate = moment(`${year}-01-01`).week(week).endOf('week').add(firstDayOfWeek, 'day').format(dateFormat);
				break;
			case '2': // monthly
				startDate = moment(`${year}-01-01`).month(month).startOf('month').format(dateFormat);
				endDate = moment(`${year}-01-01`).month(month).endOf('month').format(dateFormat);
				break;
			case '3': // quarterly
				startDate = moment(`${year}-01-01`).quarter(quarter).startOf('quarter').format(dateFormat);
				endDate = moment(`${year}-01-01`).quarter(quarter).endOf('quarter').format(dateFormat);
				break;
			case '4': // half yearly
				if(halfYear === '1') {
					startDate = moment(`${year}-01-01`).format(dateFormat);
					endDate = moment(`${year}-06-30`).format(dateFormat);
				} else {
					startDate = moment(`${year}-07-01`).format(dateFormat);
					endDate = moment(`${year}-12-31`).format(dateFormat);
				}
				break;
			case '5': // yearly
				startDate = moment(`${year}-01-01`).format(dateFormat);
				endDate = moment(`${year}-12-31`).format(dateFormat);
				break;
			default:
				startDate = null;
				endDate = null;
		}
		setQueryParams({ startDate, endDate });
	}, [state, setQueryParams]);

	useEffect(() => {
		let tmp = {};
		if(!queryParams.startDate) tmp.startDate = moment().startOf('month').format(dateFormat);
		if(!queryParams.endDate) tmp.endDate = moment().endOf('month').format(dateFormat);
		if(!queryParams.leaderID) tmp.leaderID = adminAgentID;
		setQueryParams(tmp);
	}, [queryParams.startDate, queryParams.endDate, queryParams.leaderID, setQueryParams]);

	useEffect(() => {
		if(queryParams.startDate && queryParams.endDate && queryParams.leaderID) {
			handleFetch({
				startDate: queryParams.startDate,
				endDate: queryParams.endDate,
				leaderID: queryParams.leaderID,
			});
		}
	}, [handleFetch, queryParams.startDate, queryParams.endDate, queryParams.leaderID]);

	useEffect(() => {
		if(status.loaded) {
			if(!status.error) {
				const formattedData = res.performance.map(e => { 
					const total = e.statusOpen 
						+ Math.max(e.statusClose, (e.submissions || 0))
						+ e.statusFactFinding
						+ e.statusPolicyReview
						+ e.statusMealAppointment
						+ e.statusRecruitment
						+ (e.prospects * 0.5);

					return {
						agentID: e.agentID,
						name: e.name,
						prospects: e.prospects * 0.5,
						submissions: e.submissions || 0,
						events: e.events,
						statusOpen: e.statusOpen,
						statusClose: e.statusClose,
						statusFactFinding: e.statusFactFinding,
						statusPolicyReview: e.statusPolicyReview,
						statusMealAppointment: e.statusMealAppointment,
						statusRecruitment: e.statusRecruitment,
						statusFollowUp: e.statusFollowUp,
						statusNone: e.statusNone,
						statusNonFaceToFace: e.statusNonFaceToFace,
						total: total,
						pieChart: 
							<IconButton onClick={() => setQueryParams({ pieChartID: e.agentID })} disabled={!Boolean(total)}>
								<PieChartIcon />
							</IconButton>,
					}
				}
				);
				setData(formattedData);
				const footer = getFooterTotal(headers, formattedData);
				setFooters([{
					name: 'Total',
					...footer,
				}]);
			} else {
				setData([]);
				setFooters([]);
			}
		}
	}, [res, status.loaded, status.error, setQueryParams]);

	const weekOptions = generateWeekOptions(state.year);

	return (
		<Layout title={label}>
			<Select 
				className={classes.select}
				maxWidth={150}
				label="Mode"
				options={modeOptions}
				value={state.mode}
				onChange={handleChange('mode')}
			/>

			<Select 
				className={classes.select}
				maxWidth={110}
				label="Year"
				options={getYearList()}
				value={state.year}
				onChange={handleChange('year')}
			/>

			{state.mode === '4' && (
				<Select 
					className={classes.select}
					maxWidth={150}
					label="Half Year"
					options={halfYearOptions}
					value={state.halfYear}
					onChange={handleChange('halfYear')}
				/>
			)}

			{state.mode === '3' && (
				<Select 
					className={classes.select}
					maxWidth={150}
					label="Quarter"
					options={quarterOptions}
					value={state.quarter}
					onChange={handleChange('quarter')}
				/>
			)}

			{state.mode === '2' && (
				<Select 
					className={classes.select}
					maxWidth={110}
					label="Month"
					options={monthOptions}
					value={state.month}
					onChange={handleChange('month')}
				/>
			)}

			{state.mode === '1' && (
				<Select 
					className={classes.select}
					maxWidth={150}
					label="Week"
					options={weekOptions}
					value={state.week}
					onChange={handleChange('week')}
				/>
			)}

			<Select 
				maxWidth={200}
				label="Group Leader"
				options={leaders}
				value={queryParams.leaderID || ''}
				onChange={handleChange('leaderID')}
			/>

			<Button 
				className={classes.summaryButton} 
				onClick={handleShowSummary} 
				color="primary"
				startIcon={<DescriptionIcon />}
				variant="outlined"
			>
				Summary
			</Button>

			<Table
				headers={headers}
				data={data}
				footers={footers}
				isLoading={status.loading}
			/>

			<Dialog queryParamKey="summary" title="Summary" maxWidth="100%">
				<Summary />
			</Dialog>

			<DiaryPieChart diaryData={data} />

		</Layout>
	);
}

export default DiaryPerformance;
