import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Layout from './Layout';
import routes, { fullPathname } from './routes';

function CompanyUpdate() {
  return (
    <Layout>
      <Switch>
        <Route path={fullPathname} exact>
          <Redirect to={fullPathname + '/sales-report'} />
        </Route>

        {routes.map(route => (
          <Route key={route.path} path={route.path}>
            <route.Page />
          </Route>
        ))}
      </Switch>
    </Layout>
  );
}

export default CompanyUpdate;
