import configs from '../configs';
import sitemaps from '../configs/sitemaps';
import { agentRankingOptions } from '../configs/agents';
import { getSubheaders } from '../components/Table/utils';

const adminAgentID = 1;

const allSitemaps = (sitemaps => {
  // append parent pathname to child
  const { personal, group, company_update, admin } = sitemaps;
  let personalSitemap = personal.sitemaps.map(e => ({
    ...e,
    fullPathname: personal.pathname + e.pathname,
  }));
  let companySitemap = company_update.sitemaps.map(e => ({
    ...e,
    fullPathname: company_update.pathname + e.pathname,
  }));
  let groupSitemap = group.sitemaps.map(e => ({
    ...e,
    fullPathname: group.pathname + e.pathname,
  }));
  let adminSitemap = admin.sitemaps.map(e => ({
    ...e,
    fullPathname: admin.pathname + e.pathname,
  }));
  return [
    ...personalSitemap,
    ...companySitemap,
    ...groupSitemap,
    ...adminSitemap,
  ];
})(sitemaps);

const getSitemapData = sitemapValue => {
  const filteredSitemaps = allSitemaps.filter(e => e.value === sitemapValue);
  if (!filteredSitemaps.length) return null;
  return filteredSitemaps[0];
};

const getYearList = () => {
  let years = [];
  for (
    let i = parseInt(configs.startingYear);
    i <= new Date().getFullYear();
    i++
  ) {
    years.push({ label: String(i), value: String(i) });
  }
  return years;
};

const getObjectFromArray = (
  array,
  value,
  key = 'value',
  parseString = true
) => {
  const index = array
    .map(e => e[key])
    .indexOf(parseString ? String(value) : value);
  return index !== -1 ? array[index] : null;
};

const getAgentRankingLabel = value => {
  const ranking = getObjectFromArray(agentRankingOptions, value);
  return ranking ? ranking.label : '';
};

const capitalizeFirstLetter = (input = '', separator = ' ') => {
  return input
    .toLowerCase()
    .split(separator)
    .map(e => e.charAt(0).toUpperCase() + e.slice(1))
    .join(separator);
};

const getIntroducerID = introducer => {
  const tmp = introducer.split('-');
  return parseInt(tmp[tmp.length - 1]) || adminAgentID;
};

const flattenArray = (...arrays) => {
  let array = [];
  if (arrays.length) {
    arrays.forEach(e => {
      array = [...array, ...e];
    });
  }
  return array;
};

const getTotal = (array = [], key = 'value') => {
  return array.reduce((a, v) => a + v[key], 0);
};

const getFooterTotal = (headers, data) => {
  let tmp = {};
  let subheaders = getSubheaders(headers);
  subheaders = subheaders.filter(e => e.type === 'float' || e.type === 'int');

  subheaders.forEach(e => {
    tmp[e.id] = getTotal(data, e.id);
  });
  return tmp;
};

const generateNumber = (raw_number, digit = 2) => {
  let number = Number(raw_number);

  // invalid number
  if (isNaN(number)) return '-';

  // setup option
  let option = {
    minimumFractionDigits: digit,
    maximumFractionDigits: digit,
  };

  number = number.toLocaleString('en', option);
  return number;
};

const isEmail = input => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(input);
};

export {
  getSitemapData,
  getAgentRankingLabel,
  getYearList,
  getObjectFromArray,
  capitalizeFirstLetter,
  getIntroducerID,
  flattenArray,
  getTotal,
  getFooterTotal,
  generateNumber,
  isEmail,
};
