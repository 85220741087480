import configs from '../configs';
import request from './request';

import admin from './admin';
import { updateAccessToken } from './tokens';

const agentListPath = '/admin/agent';

function signIn(data) {
  return request({
    url: '/agents/sign-in',
    method: 'POST',
    data,
  });
}

function signOut() {
  window.localStorage.removeItem('at');
  window.localStorage.removeItem('rt');
  window.localStorage.removeItem('tt');
  window.localStorage.removeItem(configs.context.main);
  window.localStorage.removeItem(configs.context.user);
  window.localStorage.removeItem('originUserInfo'); // created on view as
  window.__history.push(configs.loginPath);
}

function updatePassword(data) {
  return request({
    url: '/agents/password',
    method: 'PUT',
    data,
  });
}

function updateProfile(data) {
  return request({
    url: '/agents/profile',
    method: 'PUT',
    data,
  });
}

function getProfile() {
  return request({
    url: '/agents/profile',
    method: 'GET',
  });
}

async function handleSetViewAs(agentID) {
  const { success, data } = await admin.getViewAsToken({ agentID });
  if (!success) {
    return window.__setSnackbar({ text: data.message, variant: 'error' });
  }

  window.__setUserInfo({
    name: data.name,
    agentID: data.agentID,
    isAdmin: data.isAdmin,
    isViewAs: true,
  });

  // tt means temporary refresh token
  window.localStorage.tt = window.localStorage.rt;
  window.localStorage.at = data.at;
  window.__history.push(configs.loginRedirectPath);
}

async function handleCancelViewAs() {
  if (!window.localStorage.tt) signOut();

  const refreshToken = window.localStorage.tt;
  window.localStorage.setItem('rt', refreshToken);
  window.localStorage.removeItem('tt');

  try {
    const { data } = await updateAccessToken(refreshToken, true);

    window.localStorage.setItem('at', data.at);
    if (data.rt) window.localStorage.setItem('rt', data.rt);

    const profileResult = await getProfile();
    if (!profileResult.success) throw new Error('Get profile error');

    window.__setUserInfo({
      name: profileResult.data.name,
      agentID: profileResult.data.agentID,
      isAdmin: profileResult.data.isAdmin,
      isViewAs: false,
    });

    window.__history.push(agentListPath);
  } catch (err) {
    window.__setSnackbar({
      text: 'Session expired, please sign in.',
      variant: 'error',
    });
    signOut();
  }
}

export default {
  signIn,
  signOut,
  getProfile,
  updateProfile,
  updatePassword,
  handleSetViewAs,
  handleCancelViewAs,
};
