import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';

import CreateDialog from './CreateDialog';
import UpdateDialog from './UpdateDialog';

import useSnackbarText from '../../../hooks/useSnackbarText';
import useQueryParams from '../../../hooks/useQueryParams';

import { maxWidth } from '../../../components/configs';
import { kpiRankingOptions } from '../../../configs/kpi';
import kpi from '../../../api/kpi';
import { 
	getYearList, 
	getSitemapData, 
	getObjectFromArray,
	flattenArray,
} from '../../../utils';

const sitemapValue = 'ADMIN_KPI';
const { label } = getSitemapData(sitemapValue);

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	},
	textField: {
		margin: 8,
		'& input': {
			textAlign: 'right'
		}
	},
	container: {
		backgroundColor: '#fff',
		maxWidth
	},
	actionPanel: {
		display: 'flex',
		//alignItems: 'flex-end',
		justifyContent: 'flex-start'
	},
	submitButton: {
		marginTop: 8,
		marginBottom: 8,
	}
}));

function KPI() {
	const classes = useStyles();
	const [queryParams, setQueryParams] = useQueryParams();
	const handleSnackbarChange = useSnackbarText();
	const [standards, setStandards] = useState([]);
	const [disabled, setDisable] = useState(false);

	const handleYearChange = (event) => {
		setQueryParams({ year: event.target.value });
	};

	const handleSubmit = async () => {
		let allRankings = flattenArray(...standards.map(e => e.rankings));
		if(allRankings.length !== kpiRankingOptions.length) {
			let tmp = kpiRankingOptions.map(e => e.value);
			allRankings.forEach(e => {
				const index = tmp.indexOf(e);
				if(index !== -1) tmp.splice(index, 1);
			});

			tmp = tmp.map(e => getObjectFromArray(kpiRankingOptions, e).label);

			return handleSnackbarChange({ 
				text: `Some rankings (${tmp.join(', ')}) are missing`, 
				variant: 'error' 
			});
		}
		setDisable(true);

		const { success, data } = await kpi.updateKPI({
			standards: standards,
			year: queryParams.year,
		});

		setDisable(false);
		if(success) {
			handleSnackbarChange({ text: 'KPI updated', variant: 'success' });
		} else {
			handleSnackbarChange({ text: data.message, variant: 'error' });
		}
	};

	const handleToggleDialog = () => {
		setQueryParams({ 
			createDialog: (queryParams.createDialog !== 'true') || null,
		});
	};

	const handleCreateStandard = (newStandard) => {
		setStandards(preStandard => ([...preStandard, newStandard]));
	};

	const handleSetIndex = (index) => () => {
		setQueryParams({ updateDialog: index });
	};

	// get kpi awards
	useEffect(() => {
		const getData = async () => {
			let { data, success } = await kpi.getKPI({
				year: queryParams.year,
			});

			if(success) {
				setStandards(data.standards);
			} else {
				return handleSnackbarChange({ text: data.message, variant: 'error' });
			}
		};

		if(queryParams.year) getData();
	}, [queryParams.year, handleSnackbarChange]);
	
	// set default params
	useEffect(() => {
		if(queryParams.year) return;
		else setQueryParams({ year: moment().year() });
	}, [queryParams.year, setQueryParams]);

	return (
		<Layout title={label} onButtonClick={handleToggleDialog}>
			<Select 
				className={classes.select}
				maxWidth={110}
				label="Year"
				options={getYearList()}
				value={queryParams.year}
				onChange={handleYearChange}
			/>

			{Boolean(standards.length) && (
				<List className={classes.container} dense>
					{standards.map((e, i) => (
						<ListItem key={i} onClick={handleSetIndex(i)} button>
							<ListItemText
								primary={e.label}
								secondary={e.target}
							/>
						</ListItem>
					))}
				</List>
			)}

			<div className={classes.actionPanel}>
				<Button 
					disabled={disabled}
					className={classes.submitButton}
					color="primary" 
					variant="contained" 
					onClick={handleSubmit}
				>
					Update
				</Button>
			</div>

			<CreateDialog standards={standards} onCreateStandard={handleCreateStandard} />
			<UpdateDialog standards={standards} onSetStandards={setStandards} />
		</Layout>
	);
}

export default KPI;
