const kpiRankingOptions = [
	{ label: 'GSM', value: 'GSM' },
	{ label: 'USM', value: 'USM' },
	{ label: 'CA', value: 'CA' },
	{ label: 'LIA', value: 'LIA' },
	{ label: 'SM', value: 'SM' },
	{ label: 'EA (Above 6 years)', value: 'EA' },
	{ label: 'EA 6', value: 'EA_6' },
	{ label: 'EA 5', value: 'EA_5' },
	{ label: 'EA 4', value: 'EA_4' },
	{ label: 'EA 3', value: 'EA_3' },
	{ label: 'EA 2', value: 'EA_2' },
	{ label: 'ROOKIE', value: 'ROOKIE' },
];

export {
	kpiRankingOptions,
}
