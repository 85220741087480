import React, { useCallback } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import DateItem from './DateItem';

import utils from './utils';
import { cellSize } from './configs';

const periodFormat = 'YYYY-MM';
const dateFormat = 'YYYY-MM-DD';
const firstDayOfWeek = 1;

const useStyles = makeStyles(theme => ({
	root: {
		marginLeft: 10,
		marginRight: 10,
	},
	headerWrapper: {
		display: 'flex',
	},
	header: {
		textAlign: 'center',
		width: cellSize,
		height: cellSize,
		padding: 2,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.primary.main,
	},
	row: {
		display: 'flex',
	},
}));

function Calendar(props) {
	const {
		period,
		onDateClick,
		isDayBlocked,
		isDayHighlighted,
		blockPassedDay,
		highlightClassName,
		date: selectedDate,
	} = props;
	const classes = useStyles();

	const periodMoment = moment(period, periodFormat);
	const datesOfMonth = utils.generateDatesOfMonth(periodMoment.year(), periodMoment.month(), firstDayOfWeek);

	const handleDateClick = useCallback((date) => () => {
		onDateClick(date);
	}, [onDateClick]);

	const isBlocked = useCallback((date) => {
		if(blockPassedDay) {
			if(
				moment().format(dateFormat) !== date // same date
				&& moment().diff(moment(date)) > 0 // passed date
			) return true;
		}
		if(isDayBlocked) return isDayBlocked(date) === true;
		else return false;
	}, [isDayBlocked, blockPassedDay]);

	const isHighlighted = useCallback((date) => {
		if(isDayHighlighted) return isDayHighlighted(date) === true;
		else return false;
	}, [isDayHighlighted]);

	const isSelected = useCallback((selectedDate, date) => {
		return Boolean(selectedDate && selectedDate === date);
	}, []);

	// create day title
	let weekdaysShotName = moment.weekdaysShort().map(e => e.substr(0, 1));
	let weekSplice = weekdaysShotName.splice(0, firstDayOfWeek); 
	weekdaysShotName = [...weekdaysShotName, ...weekSplice];

	const headerDivs = weekdaysShotName.map((e, i) => (
		<div key={i} className={classes.header}>{e}</div>
	));

	return (
		<div className={classes.root}>
			<div className={classes.headerWrapper}>
				{headerDivs}
			</div>
			{datesOfMonth.map((row, i) => (
				<div key={i} className={classes.row}>
					{row.map((date, j) => (
						<DateItem 
							key={j}
							date={date}
							onClick={handleDateClick(date)}
							isSelected={isSelected(selectedDate, date)}
							isBlocked={isBlocked(date)}
							isHighlighted={isHighlighted(date)}
							highlightClassName={highlightClassName}
						/>
					))}
				</div>
			))}
		</div>
	);
}

export default Calendar;
