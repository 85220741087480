import { useEffect, useState } from 'react';
import moment from 'moment';

import useSnackbarText from './useSnackbarText';
import admin from '../api/admin';
import { capitalizeFirstLetter } from '../utils'

function useDirectGroupLeaders(year, _hasWholeGroup) {
	const [leaders, setLeaders] = useState([]);
	const handleSnackbarChange = useSnackbarText();
	const hasWholeGroup = Boolean(_hasWholeGroup);
	
	useEffect(() => {
		const getLeaders = async () => {
			let { data, success } = await admin.getDirectGroupLeaders({
				year: year || moment().year()
			});
			if(success) {
				let leaders = data.leaders.map(e => ({
					...e,
					value: String(e.agentID),
					label: capitalizeFirstLetter(e.name),
				}));
				if(hasWholeGroup) {
					leaders = [
						{ label: 'Whole Group', value: '0' },
						...leaders,
					];
				}
				setLeaders(leaders);
			} else {
				return handleSnackbarChange({ text: data.message, variant: 'error' });
			}
		}
		getLeaders();
	}, [hasWholeGroup, year, handleSnackbarChange]);
	return leaders;
}

export default useDirectGroupLeaders;
