const prospectResourceOptions = [
	{ label: 'Referral', value: '0' },
	{ label: 'Road Show', value: '1' },
	{ label: 'Bank Attack', value: '2' },
	{ label: 'Door to Door', value: '3' },
	{ label: 'Social Media', value: '4' },
	{ label: 'Others', value: '5' },
];

const prospectOutcomeOptions = [
	{ label: 'Approach', value: '0' },
	{ label: 'Follow Up', value: '1' },
	{ label: 'No Respond', value: '2' },
	{ label: 'Reject', value: '3' },
	{ label: 'Closing', value: '4' },
	{ label: 'Pending', value: '5' },
];

export {
	prospectResourceOptions,
	prospectOutcomeOptions,
}
