import { useContext, useCallback } from 'react';
import { snackbarContext } from '../contexts/snackbarContext';

function useSnackbarText() {
	const { handleChange } = useContext(snackbarContext);

	const handleTextChange = useCallback((text, options = {}) => {
		if(typeof text === 'string') {
			handleChange({ 
				...options,
				text,
			});
		} else {
			handleChange(text);
		}
	}, [handleChange]);
	return handleTextChange;
}

export default useSnackbarText;
