import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
	Radio as MuiRadio,
	RadioGroup,
	FormHelperText,
	FormControlLabel,
	FormControl,
	FormLabel,
} from '@material-ui/core';

import { maxWidth } from '../configs';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		maxWidth,
	},
	radio: {
		padding: 6,
		paddingLeft: 9,
	},
	controlLabel: {
		minWidth: 100,
	}
}));

function Radio(props) {
	const classes = useStyles();

	const { 
		label, 
		value, 
		options = [], 
		onChange, 
		helperText,
		row,
		...others 
	} = props;

	const handleChange = useCallback((event) => {
		onChange(event, event.target.value);
	}, [onChange]);

	return (
		<FormControl className={classes.root} component="fieldset" {...others}>
			<FormLabel component="legend">{label}</FormLabel>
			<RadioGroup aria-label={label} name={label} value={value} onChange={handleChange} row={row}>
				{options.map(option => (
					<FormControlLabel className={classes.controlLabel} key={option.value} value={option.value} control={
						<MuiRadio className={classes.radio} color="primary" />
					} label={option.label} />
				))}
			</RadioGroup>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
}

export default Radio;
