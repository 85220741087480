import React, { useState, useEffect } from 'react';

import Table from '../../../components/Table';

import useQueryParams from '../../../hooks/useQueryParams';
import useFetch from '../../../hooks/useFetch';

import group from '../../../api/group';
import { getFooterTotal } from '../../../utils';

const headers = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
		disableSort: true,
	},
	{
		id: 'referral',
		label: 'Referral',
		type: 'int',
	},
	{
		id: 'roadShow',
		label: 'Road Show',
		type: 'int',
	},
	{
		id: 'bankAttack',
		label: 'Bank Attack',
		type: 'int',
	},
	{
		id: 'doorToDoor',
		label: 'Door To Door',
		type: 'int',
	},
	{
		id: 'socialMedia',
		label: 'Social Media',
		type: 'int',
	},
	{
		id: 'others',
		label: 'Others',
		type: 'int',
	},
	{
		id: 'total',
		label: 'Total',
		type: 'int',
	},
];

function Summary() {
	const [queryParams] = useQueryParams();
	const [data, setData] = useState([]);
	const [footers, setFooters] = useState([]);
	const [res, status, handleFetch] = useFetch(group.getProspectSummary);

	useEffect(() => {
		if(queryParams.startDate && queryParams.endDate) {
			handleFetch({
				startDate: queryParams.startDate,
				endDate: queryParams.endDate,
			});
		}
	}, [handleFetch, queryParams.startDate, queryParams.endDate]);

	useEffect(() => {
		if(status.loaded) {
			if(!status.error) {
				const formattedData = res.summary;
				setData(formattedData);
				setFooters([{
					name: 'Total',
					...getFooterTotal(headers, formattedData),
				}]);
			} else {
				setData([]);
				setFooters([]);
			}
		}
	}, [res, status.loaded, status.error]);

	return (
		<Table
			headers={headers}
			data={data}
			footers={footers}
			isLoading={status.loading}
		/>
	);
}

export default Summary;
