import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';

import useSnackbarText from '../../../hooks/useSnackbarText';
import useQueryParams from '../../../hooks/useQueryParams';

import group from '../../../api/group';
import { 
	getObjectFromArray,
	getYearList, 
	getSitemapData, 
	getFooterTotal,
} from '../../../utils';

const sitemapValue = 'GROUP_MONTHLYSUBMISSION';
const { label } = getSitemapData(sitemapValue);

const monthHeaders = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
	},
	...moment.monthsShort().map(e => ({
		id: e.toUpperCase(),
		label: e,
		type: 'string',
		subheaders: [
			{
				id: `${e.toUpperCase()}_ANP`,
				label: 'ANP',
				type: 'float',
			},
			{
				id: `${e.toUpperCase()}_NOC`,
				label: 'NOC',
				type: 'int',
			},
		]
	})),
	{
		id: 'WY',
		label: 'Whole Year',
		type: 'string',
		subheaders: [
			{
				id: 'WY_ANP',
				label: 'ANP',
				type: 'float',
			},
			{
				id: 'WY_NOC',
				label: 'NOC',
				type: 'int',
			},
		]
	},
];

const quarterHeaders = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
		disableSort: true,
	},
	{
		id: 'Q1',
		label: 'Quarter 1',
		type: 'string',
		subheaders: [
			{
				id: 'Q1_ANP',
				label: 'ANP',
				type: 'float',
			},
			{
				id: 'Q1_NOC',
				label: 'NOC',
				type: 'int',
			},
		]
	},
	{
		id: 'Q2',
		label: 'Quarter 2',
		type: 'string',
		subheaders: [
			{
				id: 'Q2_ANP',
				label: 'ANP',
				type: 'float',
			},
			{
				id: 'Q2_NOC',
				label: 'NOC',
				type: 'int',
			},
		]
	},
	{
		id: 'Q3',
		label: 'Quarter 3',
		type: 'string',
		subheaders: [
			{
				id: 'Q3_ANP',
				label: 'ANP',
				type: 'float',
			},
			{
				id: 'Q3_NOC',
				label: 'NOC',
				type: 'int',
			},
		]
	},
	{
		id: 'Q4',
		label: 'Quarter 4',
		type: 'string',
		subheaders: [
			{
				id: 'Q4_ANP',
				label: 'ANP',
				type: 'float',
			},
			{
				id: 'Q4_NOC',
				label: 'NOC',
				type: 'int',
			},
		]
	},
	{
		id: 'WY',
		label: 'Whole Year',
		type: 'string',
		subheaders: [
			{
				id: 'WY_ANP',
				label: 'ANP',
				type: 'float',
			},
			{
				id: 'WY_NOC',
				label: 'NOC',
				type: 'int',
			},
		]
	},
];

const halfYearHeaders = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
		disableSort: true,
	},
	{
		id: 'HY1',
		label: '1st Half Year',
		type: 'string',
		subheaders: [
			{
				id: 'HY1_ANP',
				label: 'ANP',
				type: 'float',
			},
			{
				id: 'HY1_NOC',
				label: 'NOC',
				type: 'int',
			},
		]
	},
	{
		id: 'HY2',
		label: '2nd Half Year',
		type: 'string',
		subheaders: [
			{
				id: 'HY2_ANP',
				label: 'ANP',
				type: 'float',
			},
			{
				id: 'HY2_NOC',
				label: 'NOC',
				type: 'int',
			},
		]
	},
	{
		id: 'WY',
		label: 'Whole Year',
		type: 'string',
		subheaders: [
			{
				id: 'WY_ANP',
				label: 'ANP',
				type: 'float',
			},
			{
				id: 'WY_NOC',
				label: 'NOC',
				type: 'int',
			},
		]
	},
];

const periodOptions = [
	{ 
		label: 'Month', 
		value: 'MONTH', 
		headers: monthHeaders 
	},
	{ 
		label: 'Quarter', 
		value: 'QUARTER', 
		headers: quarterHeaders 
	},
	{ 
		label: 'Half Year', 
		value: 'HALF_YEAR', 
		headers: halfYearHeaders 
	},
];

const getHeaders = (value) => {
	let headers = [];
	try {
		return getObjectFromArray(periodOptions, value).headers;
	} catch(err) {
		return headers;
	}
};

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	}
}));

function MonthlySubmission() {
	const classes = useStyles();
	const handleSnackbarChange = useSnackbarText();
	const [queryParams, setQueryParams] = useQueryParams();
	const [data, setData] = useState([]);
	const [footers, setFooters] = useState([]);
	const [loading, setLoading] = useState(true);

	const handleChange = (type) => (event) => {
		setQueryParams({ [type]: event.target.value });
	};

	useEffect(() => {
		let tmp = {};
		if(!queryParams.year) tmp.year = moment().year();
		if(!queryParams.period) tmp.period = periodOptions[0].value;
		setQueryParams(tmp);
	}, [queryParams.year, queryParams.period, setQueryParams]);

	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			let { data, success } = await group.getMonthlySubmission({ 
				year: queryParams.year,
			});
			setLoading(false);

			if(success) {
				const formattedData = data.submissions.map(e => {
					let tmp = {
						agentID: e.agentID,
						name: e.name,
					};
					let months = moment.monthsShort().map(el => el.toUpperCase());

					// set monthly submissions
					e.submissions.forEach((el, j) => {
						tmp[`${months[j]}_ANP`] = el.ANP; 
						tmp[`${months[j]}_NOC`] = el.NOC;
					});

					// set quarterly submissions
					tmp.Q1_ANP = e.submissions.slice(0, 3).reduce((a, v) => a + v.ANP, 0);
					tmp.Q1_NOC = e.submissions.slice(0, 3).reduce((a, v) => a + v.NOC, 0);
					tmp.Q2_ANP = e.submissions.slice(3, 6).reduce((a, v) => a + v.ANP, 0);
					tmp.Q2_NOC = e.submissions.slice(3, 6).reduce((a, v) => a + v.NOC, 0);
					tmp.Q3_ANP = e.submissions.slice(6, 9).reduce((a, v) => a + v.ANP, 0);
					tmp.Q3_NOC = e.submissions.slice(6, 9).reduce((a, v) => a + v.NOC, 0);
					tmp.Q4_ANP = e.submissions.slice(9, 12).reduce((a, v) => a + v.ANP, 0);
					tmp.Q4_NOC = e.submissions.slice(9, 12).reduce((a, v) => a + v.NOC, 0);

					// set half yearly submissions
					tmp.HY1_ANP = e.submissions.slice(0, 6).reduce((a, v) => a + v.ANP, 0);
					tmp.HY1_NOC = e.submissions.slice(0, 6).reduce((a, v) => a + v.NOC, 0);
					tmp.HY2_ANP = e.submissions.slice(6, 12).reduce((a, v) => a + v.ANP, 0);
					tmp.HY2_NOC = e.submissions.slice(6, 12).reduce((a, v) => a + v.NOC, 0);

					// set whole year submissions
					tmp.WY_ANP = e.submissions.reduce((a, v) => a + v.ANP, 0);
					tmp.WY_NOC = e.submissions.reduce((a, v) => a + v.NOC, 0);

					return tmp;
				});
				setData(formattedData);
				setFooters([{
					name: 'Total',
					...getFooterTotal([
						...monthHeaders, 
						...quarterHeaders, 
						...halfYearHeaders
					], formattedData),
				}]);
			} else {
				setData([]);
				setFooters([]);
				return handleSnackbarChange({ text: data.message, variant: 'error' });
			}
		};

		if(queryParams.year) getData();
	}, [queryParams.year, handleSnackbarChange]);

	return (
		<Layout title={label}>
			<Select 
				className={classes.select}
				maxWidth={150}
				label="Year"
				options={getYearList()}
				value={queryParams.year}
				onChange={handleChange('year')}
			/>

			<Select 
				className={classes.select}
				maxWidth={150}
				label="Period"
				options={periodOptions}
				value={queryParams.period}
				onChange={handleChange('period')}
			/>
			
			<Table
				headers={getHeaders(queryParams.period)}
				data={data}
				footers={footers}
				isLoading={loading}
			/>

		</Layout>
	);
}

export default MonthlySubmission;
