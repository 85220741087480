import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Button, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';
import TextField from '../../../components/TextField';

import useSnackbarText from '../../../hooks/useSnackbarText';
import useQueryParams from '../../../hooks/useQueryParams';

import { SSAAwardOptions } from '../../../configs/ssa';
import ssa from '../../../api/ssa';
import { 
	getYearList, 
	getSitemapData, 
	getAgentRankingLabel,
} from '../../../utils';

const sitemapValue = 'ADMIN_SSA';
const { label } = getSitemapData(sitemapValue);

const monthOptions = ['none', ...moment.monthsShort()].map((e, i) => ({
	label: e,
	value: String(i),
}));

const headers = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
	},
	{ 
		id: 'ranking',
		label: 'Ranking', 
		type: 'string',
		disableSort: true,
	},
	{
		id: 'SANPGoal',
		label: 'Goal S-ANP',
		type: 'dom',
	},
	{ 
		id: 'isFirstTimer',
		label: 'First Timer', 
		type: 'dom',
	},
	{ 
		id: 'pr',
		label: 'PR %', 
		type: 'dom',
	},
	{ 
		id: 'award',
		label: 'Award', 
		type: 'dom',
	},
	{ 
		id: 'target',
		label: 'Target', 
		type: 'dom',
	},
	{ 
		id: 'achieveMonth',
		label: 'Month Achieve', 
		type: 'dom',
	},
];

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	},
	textField: {
		margin: 8,
		'& input': {
			textAlign: 'right'
		},
		'& select': {
			paddingTop: 10,
		},		
	},
	actionPanel: {
		display: 'flex',
		//alignItems: 'flex-end',
		justifyContent: 'flex-start'
	},
	submitButton: {
		marginTop: 8,
		marginBottom: 8,
		marginLeft: 10
	}
}));

function SSA() {
	const classes = useStyles();
	const [queryParams, setQueryParams] = useQueryParams();
	const handleSnackbarChange = useSnackbarText();
	const [data, setData] = useState([]);
	const [disabled, setDisable] = useState(false);
	const [NOCTarget, setNOCTarget] = useState('');
	const [lastFetch, setLastFetch] = useState(moment().format('x'));

	const handleYearChange = (event) => {
		setQueryParams({ year: event.target.value });
	};

	const handleChange = useCallback((type, index) => (event) => {
		const { value } = event.target;
		setData(prevData => {
			let newData = [...prevData];
			newData[index][type] = value;
			return newData;
		});
	}, []);

	const handleCheckboxChange = useCallback((type, index) => (event) => {
		const { checked } = event.target;
		setData(prevData => {
			let newData = [...prevData];
			newData[index][type] = checked;
			return newData;
		});
	}, []);

	const handleSubmit = async () => {
		setDisable(true);
		let result = await ssa.updateSSA({
			SSA: data.map(e => ({
				...e,
				NOCTarget,
				year: queryParams.year,
				isFirstTimer: e.isFirstTimer ? 1 : 0,
			}))
		});

		if(result.success) {
			handleSnackbarChange({ text: 'SSA updated!', variant: 'success' });
		} else {
			handleSnackbarChange({ text: result.data.message, variant: 'error' });
		}
		setLastFetch(moment().format('x'));
		setDisable(false);
	};

	useEffect(() => {
		const getData = async () => {
			let { data, success } = await ssa.getSSA({
				year: queryParams.year,
			});

			if(success) {
				setData(data.SSA.map(e => ({
					SSAID: e.SSAID || '',
					agentID: e.agentID,
					name: e.name,
					ranking: getAgentRankingLabel(e.ranking),
					SANPGoal: e.SANPGoal !== null ? String(e.SANPGoal) : '0',
					achieveMonth: e.achieveMonth !== null ? String(e.achieveMonth) : '0',
					award: e.award !== null ? String(e.award) : '0',
					target: e.target !== null ? String(e.target) : '0',
					pr: e.pr !== null ? String(e.pr) : '0',
					isFirstTimer: Boolean(parseInt(e.isFirstTimer)),
				})));

				if(data.SSA.length) {
					setNOCTarget(data.SSA[0].NOCTarget);
				}
			} else {
				return handleSnackbarChange({ text: data.message, variant: 'error' });
			}
		};

		if(queryParams.year) getData();
	}, [queryParams.year, handleSnackbarChange, lastFetch]);

	useEffect(() => {
		if(!queryParams.year) setQueryParams({ year: moment().year() });
	}, [queryParams.year, setQueryParams]);

	const formattedData = data.map((e, i) => ({
		name: e.name,
		ranking: e.ranking,
		award: 
			<Select
				className={classes.textField}
				value={e.award} 
				options={SSAAwardOptions}
				onChange={handleChange('award', i)} 
			/>,
		target: 
			<Select
				className={classes.textField}
				value={e.target} 
				options={SSAAwardOptions}
				onChange={handleChange('target', i)} 
			/>,
		achieveMonth: 
			<Select
				className={classes.textField}
				value={e.achieveMonth} 
				options={monthOptions}
				onChange={handleChange('achieveMonth', i)} 
			/>,
		SANPGoal: 
			<TextField 
				className={classes.textField}
				value={e.SANPGoal} 
				type="number"
				onChange={handleChange('SANPGoal', i)}
			/>,
		isFirstTimer:
			<Checkbox 
				color="primary" 
				checked={e.isFirstTimer} 
				onChange={handleCheckboxChange('isFirstTimer', i)} 
			/>,
		pr: 
			<TextField 
				className={classes.textField}
				value={e.pr} 
				type="number"
				onChange={handleChange('pr', i)}
			/>,
	}));

	return (
		<Layout title={label}>
			<Select 
				className={classes.select}
				maxWidth={110}
				label="Year"
				options={getYearList()}
				value={queryParams.year}
				onChange={handleYearChange}
			/>
			
			<Table
				headers={headers}
				data={formattedData}
				tableMarginBottom={100}
			/>

			<div className={classes.actionPanel}>
				<TextField
					label="Minimum ANP"
					value={NOCTarget}
					onChange={event => setNOCTarget(event.target.value)}
					maxWidth={150}
				/>
				<Button 
					disabled={disabled}
					className={classes.submitButton}
					color="primary" 
					variant="contained" 
					onClick={handleSubmit}
				>
					Update
				</Button>
			</div>

		</Layout>
	);
}

export default SSA;
