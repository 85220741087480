import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';

import useSnackbarText from '../../../hooks/useSnackbarText';
import useQueryParams from '../../../hooks/useQueryParams';

import submissions from '../../../api/submissions';
import { 
	getYearList, 
	getSitemapData,
	getFooterTotal, 
} from '../../../utils';

const dateFormat = 'YYYY-MM-DD';
const sitemapValue = 'PERSONAL_PERFORMANCE';
const { label } = getSitemapData(sitemapValue);

const headers = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
	},
	{
		id: 'BSA',
		label: 'BSA',
		type: 'string',
		subheaders: [
			{
				id: 'BSAUW',
				label: 'Underwriting',
				type: 'float',
			},
			{
				id: 'BSAInforced',
				label: 'Inforced',
				type: 'float',
			},
		],
	},
	{
		id: 'ANP',
		label: 'ANP',
		type: 'string',
		subheaders: [
			{
				id: 'ANPUW',
				label: 'Underwriting',
				type: 'float',
			},
			{
				id: 'ANPInforced',
				label: 'Inforced',
				type: 'float',
			},
		],
	},
	{
		id: 'sANP',
		label: 'SANP',
		type: 'string',
		subheaders: [
			{
				id: 'sANPUW',
				label: 'Underwriting',
				type: 'float',
			},
			{
				id: 'sANPInforced',
				label: 'Inforced',
				type: 'float',
			},
		],
	},
	{
		id: 'solidANP',
		label: 'Solid ANP',
		type: 'string',
		subheaders: [
			{
				id: 'solidANPUW',
				label: 'Underwriting',
				type: 'float',
			},
			{
				id: 'solidANPInforced',
				label: 'Inforced',
				type: 'float',
			},
		],
	},
	{ 
		id: 'issuedDate',
		label: 'Issued Date', 
		type: 'date',
	},
];

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	},
	textField: {
		margin: 8,
		'& input': {
			textAlign: 'right'
		}
	},
	actionPanel: {
		display: 'flex',
		//alignItems: 'flex-end',
		justifyContent: 'flex-start'
	},
	submitButton: {
		marginTop: 8,
		marginBottom: 8,
	}
}));

function Performance() {
	const classes = useStyles();
	const handleSnackbarChange = useSnackbarText();
	const [queryParams, setQueryParams] = useQueryParams();
	const [data, setData] = useState([]);
	const [footers, setFooters] = useState([]);
	const [year, setYear] = useState(
		(queryParams.startDate && moment(queryParams.startDate).year())
		|| moment().year()
	);

	const handleChange = (event) => {
		setYear(event.target.value);
	};

	useEffect(() => {
		let startDate, endDate;
		startDate = moment(`${year}-01-01`).format(dateFormat);
		endDate = moment(`${year}-12-31`).format(dateFormat);
		setQueryParams({ startDate, endDate });
	}, [year, setQueryParams]);

	useEffect(() => {
		const getData = async () => {
			let { data, success } = await submissions.getPerformance({
				startDate: queryParams.startDate,
				endDate: queryParams.endDate,
			});

			if(success) {
				const formattedData = data.performance;
				setData(formattedData);
				setFooters([{
					name: 'Total',
					...getFooterTotal(headers, formattedData),
				}]);
			} else {
				return handleSnackbarChange({ text: data.message, variant: 'error' });
			}
		};

		if(queryParams.startDate && queryParams.endDate) getData();
	}, [queryParams.startDate, queryParams.endDate, handleSnackbarChange]);

	return (
		<Layout title={label}>
			<Select 
				className={classes.select}
				maxWidth={150}
				label="Year"
				options={getYearList()}
				value={year}
				onChange={handleChange}
			/>
			
			<Table
				headers={headers}
				data={data}
				footers={footers}
			/>

		</Layout>
	);
}

export default Performance;
