import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';
import Dialog from '../../../components/Dialog';

import Summary from './Summary';

import useQueryParams from '../../../hooks/useQueryParams';
import useDirectGroupLeaders from '../../../hooks/useDirectGroupLeaders';
import useFetch from '../../../hooks/useFetch';

import group from '../../../api/group';
import { 
	getYearList, 
	getSitemapData, 
	getFooterTotal,
} from '../../../utils';

const adminAgentID = 1;
const sitemapValue = 'GROUP_MSA';
const { label } = getSitemapData(sitemapValue);

const quarterOptions = [
	{ label: 'Quarter 1', value: '1' },
	{ label: 'Quarter 2', value: '2' },
	{ label: 'Quarter 3', value: '3' },
	{ label: 'Quarter 4', value: '4' },
];

const headers = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
	},
	{
		id: 'ranking',
		label: 'Ranking',
		type: 'string',
	},
	{
		id: 'award',
		label: 'Award',
		type: 'string',
	},
	{
		id: 'target',
		label: 'Target',
		type: 'int',
	},
	{
		id: 'm1',
		label: 'M1',
		type: 'int',
	},
	{
		id: 'm2',
		label: 'M2',
		type: 'int',
	},
	{
		id: 'm3',
		label: 'M3',
		type: 'int',
	},
	{
		id: 'balance',
		label: 'Balance',
		type: 'int',
	},
	{
		id: 'achieved',
		label: 'Achieved',
		type: 'string',
	},
];

const generateHeaders = (quarter) => {
	let _headers = JSON.parse(JSON.stringify(headers));
	switch(String(quarter)) {
		case '1':
			_headers[4].label = 'Jan';
			_headers[5].label = 'Feb';
			_headers[6].label = 'Mar';
			break;
		case '2':
			_headers[4].label = 'Apr';
			_headers[5].label = 'May';
			_headers[6].label = 'Jun';
			break;
		case '3':
			_headers[4].label = 'Jul';
			_headers[5].label = 'Aug';
			_headers[6].label = 'Sep';
			break;
		case '4':
			_headers[4].label = 'Oct';
			_headers[5].label = 'Nov';
			_headers[6].label = 'Dec';
			break;
		default:
			_headers[4].label = '';
			_headers[5].label = '';
			_headers[6].label = '';
	}
	return _headers;
}

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	},
	summaryButton: {
		marginTop: 14,
		marginLeft: 5,
	}
}));

function MSA() {
	const classes = useStyles();
	const [queryParams, setQueryParams] = useQueryParams();
	const leaders = useDirectGroupLeaders(queryParams.year);
	const [data, setData] = useState([]);
	const [footers, setFooters] = useState([]);
	const [res, status, handleFetch] = useFetch(group.getMSA);

	const handleChange = (type) => (event) => {
		if(type === 'year') {
			setQueryParams({ 
				[type]: event.target.value,
				leaderID: adminAgentID,
			});
		} else 
			setQueryParams({ [type]: event.target.value });
	};

	const handleShowSummary = () => {
		setQueryParams({ summary: 'true' });
	};

	useEffect(() => {
		let tmp = {};
		if(!queryParams.year) tmp.year = moment().year();
		if(!queryParams.quarter) tmp.quarter = moment().quarter();
		if(!queryParams.leaderID && leaders.length) tmp.leaderID = leaders[0].value;
		setQueryParams(tmp);
	}, [queryParams.year, queryParams.quarter, queryParams.leaderID, leaders, setQueryParams]);

	useEffect(() => {
		if(queryParams.year && queryParams.quarter && queryParams.leaderID) {
			handleFetch({
				year: queryParams.year,
				quarter: queryParams.quarter,
				leaderID: queryParams.leaderID,
			});
		}
	}, [handleFetch, queryParams.year, queryParams.quarter, queryParams.leaderID]);

	useEffect(() => {
		if(status.loaded) {
			if(!status.error) {
				const formattedData = res.MSA;
				setData(formattedData);
				setFooters([{
					name: 'Total',
					...getFooterTotal(headers, formattedData),
					achieved: formattedData.filter(e => e.achieved === 'Y').length,
				}]);
			} else {
				setData([]);
				setFooters([]);
			}
		}
	}, [res, status.loaded, status.error]);

	return (
		<Layout title={label}>
			<Select 
				className={classes.select}
				maxWidth={110}
				label="Year"
				options={getYearList()}
				value={queryParams.year}
				onChange={handleChange('year')}
			/>

			<Select 
				className={classes.select}
				maxWidth={150}
				label="Quarter"
				options={quarterOptions}
				value={queryParams.quarter}
				onChange={handleChange('quarter')}
			/>

			<Select 
				maxWidth={200}
				label="Group Leader"
				options={leaders}
				value={queryParams.leaderID || ''}
				onChange={handleChange('leaderID')}
			/>

			<Button 
				className={classes.summaryButton} 
				onClick={handleShowSummary} 
				color="primary"
				startIcon={<DescriptionIcon />}
				variant="outlined"
			>
				Summary
			</Button>

			<Table
				headers={generateHeaders(queryParams.quarter)}
				data={data}
				footers={footers}
				isLoading={status.loading}
			/>

			<Dialog queryParamKey="summary" title="Summary" maxWidth="100%">
				<Summary />
			</Dialog>

		</Layout>
	);
}

export default MSA;
