import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Checkbox from '../../../components/Checkbox';
import TextField from '../../../components/TextField';

import useSnackbarText from '../../../hooks/useSnackbarText';

import { planTypeOptions } from '../../../configs/plans';
import { getSitemapData } from '../../../utils';
import plans from '../../../api/plans';
import { maxWidth } from '../../../components/configs';

const sitemapValue = 'ADMIN_PLAN';
const { label, fullPathname } = getSitemapData(sitemapValue);

const useStyles = makeStyles(theme => ({
	content: {
		backgroundColor: '#fff',
		padding: 10,
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column'
	},
	submitButton: {
		maxWidth,
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: 20,
	}
}));

function Create() {
	const classes = useStyles();
	const handleSnackbarChange = useSnackbarText();
	const routerHistory = useHistory();
	const [state, _setState] = useState({
		name: '',
		type: planTypeOptions[0].value,
		sANP: '',
		solidANP: '',
		FYC: '',
		remark: '',
		status: true,
	});
	const [error, setError] = useState({});
	const [disabled, setDisable] = useState(false);

	const setState = useCallback((newState = {}) => {
		_setState((prevState) => ({
			...prevState,
			...newState,
		}));
	}, []);

	const handleValueCheck = () => {
		let noError = true;
		let error = {};
		if(!state.name) {
			noError = false;
			error.name = 'Invalid value.';
		}
		if(!state.sANP) {
			noError = false;
			error.sANP = 'Invalid value.';
		}
		if(!state.solidANP) {
			noError = false;
			error.solidANP = 'Invalid value.';
		}
		if(!state.FYC) {
			noError = false;
			error.FYC = 'Invalid value.';
		}
		setError(error);
		return noError;
	};

	const handleChange = (type) => (event, value) => {
		if(value !== undefined) setState({ [type]: value });
		else setState({ [type]: event.target.value });
	};

	const handleSubmit = async () => {
		if(!handleValueCheck()) {
			handleSnackbarChange({ text: 'Invalid inputs', variant: 'error' });
			return window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		};

		setDisable(true);
		let { data, success } = await plans.createPlan({
			...state,
			status: state.status ? '1' : '0',
		});
		setDisable(false);
		if(success) {
			handleSnackbarChange({ text: 'New plan created', variant: 'success' });
		} else {
			return handleSnackbarChange({ text: data.message, variant: 'error' });
		}
		routerHistory.push(fullPathname);
	};

	return (
		<Layout title={label}>
			<div className={classes.content}>

				<TextField 
					label="Plan Name"
					value={state.name}
					onChange={handleChange('name')}
					error={Boolean(error.name)}
					helperText={error.name}
				/>

				<Select 
					label="Type"
					options={planTypeOptions}
					value={state.type}
					onChange={handleChange('type')}
				/>

				<TextField 
					label="S-ANP"
					value={state.sANP}
					onChange={handleChange('sANP')}
					type="number"
					placeholder="1.5"
					error={Boolean(error.sANP)}
					helperText={error.sANP}
				/>

				<TextField 
					label="Solid ANP"
					value={state.solidANP}
					onChange={handleChange('solidANP')}
					type="number"
					placeholder="0.5"
					error={Boolean(error.solidANP)}
					helperText={error.solidANP}
				/>

				<TextField 
					label="FYC (%)"
					value={state.FYC}
					onChange={handleChange('FYC')}
					type="number"
					placeholder="17.5"
					error={Boolean(error.FYC)}
					helperText={error.FYC}
				/>

				<TextField 
					label="Remarks"
					value={state.remark}
					onChange={handleChange('remark')}
				/>

				<Checkbox
					label="Active Plan"
					value={state.status}
					onChange={handleChange('status')}
				/>

				<div className={classes.submitButton}>
					<Button
						disabled={disabled}
						variant="contained"
						color="primary"
						onClick={handleSubmit}
					>
						Submit
					</Button>
				</div>

			</div>
		</Layout>
	);
}

export default Create;
