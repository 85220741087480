import React from 'react';
import { context } from '../configs';

const defaultState = {
	theme: '', 
	language: 'en', 
	configParams: {}, 
};

let defaultMainContext;
try {
	defaultMainContext = JSON.parse(window.localStorage.getItem(context.main));
} catch(err) {
	console.log('No main context found');
	defaultMainContext = defaultState;
}

const mainContext = React.createContext(defaultMainContext);

export {
	defaultMainContext,
	mainContext,
};
