const SSAAwardOptions = [
	{ label: 'none', value: '0' },
	{ label: 'Elite', value: '1' },
	{ label: 'Super Elite', value: '2' },
	{ label: 'Premier', value: '3' },
	{ label: 'Excellent', value: '4' },
];

export {
	SSAAwardOptions,
}
