import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Grid, SvgIcon, Typography, makeStyles } from '@material-ui/core';
import Layout from '../../components/Layout';
import fileApi from '../../api/files';
import { trainingCategories } from '../../configs/companyUpdate';
import { ReactComponent as TrainingIcon } from '../../assets/icons/icon_training.svg';

import FileList from './FileList';

const useStyles = makeStyles(theme => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  title: {
    marginLeft: 10,
    fontWeight: 'bold',
    fontSize: `1.25rem`,
  },
  spacer: { width: `100%`, height: theme.spacing(3) },
  section: {
    borderRadius: 10,
    padding: theme.spacing(1),
    backgroundColor: `#ffffff`,
    border: `1px solid ${theme.palette.divider}`,
  },
  innerScroll: {
    flex: 1,
    maxHeight: `300px`,
    overflow: 'auto',
  },
}));

export default function TrainingPage() {
  const classes = useStyles();
  const [trainings, setTrainings] = useState(null);

  useEffect(() => {
    async function getTrainings() {
      const result = await fileApi.getFiles({ taxonomy: 'training' });
      if (result.success) {
        setTrainings(result.data.files);
      }
    }

    getTrainings();
  }, []);

  return (
    <Layout
      title={
        <div className={classes.titleWrapper}>
          <SvgIcon
            color="primary"
            component={TrainingIcon}
            viewBox="0 0 48 48"
          />
          <Typography className={classes.title} variant="subtitle1">
            Training & Development
          </Typography>
        </div>
      }
    >
      {trainings && (
        <Grid container spacing={1}>
          {trainingCategories.map(category => {
            const data = trainings.filter(
              training => training.value === category.value
            );
            return (
              <Grid key={category.value} xs={12} sm={6} md={3} item>
                <div
                  style={{
                    height: `100%`,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    className={classes.title}
                    variant="subtitle1"
                    style={{ marginLeft: 0 }}
                  >
                    {category.label}
                  </Typography>

                  {data.length === 0 ? (
                    <Typography variant="caption">
                      No {category.label}
                    </Typography>
                  ) : (
                    <div className={clsx(classes.section, classes.innerScroll)}>
                      <FileList files={data} />
                    </div>
                  )}
                </div>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Layout>
  );
}
