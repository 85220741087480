import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Layout';

import ProfileSection from './ProfileSection';
import StateSection from './StateSection';
import PasswordSection from './PasswordSection';

import { getSitemapData } from '../../../utils';

const sitemapValue = 'ADMIN_AGENT';
const { label } = getSitemapData(sitemapValue);

const useStyles = makeStyles(theme => ({
  content: {},
  wrapper: {
    backgroundColor: '#fff',
    padding: 10,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
}));

function Update() {
  const classes = useStyles();

  return (
    <Layout title={label}>
      <div className={classes.content}>
        <div className={classes.wrapper}>
          <ProfileSection />
        </div>
        <div className={classes.wrapper}>
          <StateSection />
        </div>
        <div className={classes.wrapper}>
          <PasswordSection />
        </div>
      </div>
    </Layout>
  );
}

export default Update;
