import React from 'react';

const defaultSnackbarContext = {
	text: '',
	vertical: 'top',
	horizontal: 'center',
	variant: 'default',
};

const snackbarContext = React.createContext(defaultSnackbarContext);

export {
	defaultSnackbarContext,
	snackbarContext,
};
