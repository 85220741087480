const agentRankingOptions = [
	{ label: 'GSM', value: 'GSM' },
	{ label: 'USM', value: 'USM' },
	{ label: 'CA', value: 'CA' },
	{ label: 'LIA', value: 'LIA' },
	{ label: 'SM', value: 'SM' },
	{ label: 'Agent', value: 'EA' },
	{ label: 'ROOKIE', value: 'ROOKIE' },
];

export {
	agentRankingOptions,
}
