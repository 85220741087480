// pass empty object instead of undefined
// if no payload

import { useState, useEffect, useCallback } from 'react';
import moment from 'moment';

import useSnackbarText from './useSnackbarText';

const useFetch = (request) => {
	const [payload, setPayload] = useState(null);
	const [timestamp, setTimestamp] = useState(moment().unix());
	const handleSnackbarChange = useSnackbarText();

	const [state, _setState] = useState({
		data: {},
		error: false,
		loading: false,
		loaded: false,
	});

	const setState = useCallback((newState) => {
		_setState(prevState => ({
			...prevState,
			...newState,
		}));
	}, []);

	const handleFetch = useCallback((newPayload) => {
		if(newPayload === undefined) setTimestamp(moment().unix());
		else setPayload(newPayload);
	}, []);

	useEffect(() => {
		// cancel setData if component already unmount
		let didCancel = false;

		const fetchData = async () => {
			setState({ 
				loading: true,
			});

			const { data, success } = await request(payload);

			if(didCancel) return;

			if(success) {
				setState({ 
					data,
					error: false, 
					loading: false, 
					loaded: true,
				});
			} else {
				setState({ 
					data,
					error: true, 
					loading: false, 
					loaded: true 
				});
				handleSnackbarChange({ text: data.message, variant: 'error' });
			}
		};

		if(payload) fetchData();

		return () => { didCancel = true };
	}, [request, payload, timestamp, setState, handleSnackbarChange]);

	const { data, ...requestState } = state;

	return [data, requestState, handleFetch];
};

export default useFetch;

/*
const [data, state, handleFetch] = useFetch(admin.getAgent);
*/
