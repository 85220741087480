import request from './request';

function getProspect(data) {
	return request({
		url: `/prospects/${data.prospectID}`,
		method: 'GET',
	});
}

function createProspect(data) {
	return request({
		url: '/prospects',
		method: 'POST',
		data,
	});
}

function updateProspect(data) {
	const { prospectID, ...others } = data;
	return request({
		url: `/prospects/${data.prospectID}`,
		method: 'PUT',
		data: others,
	});
}

function deleteProspect(data) {
	return request({
		url: `/prospects/${data.prospectID}`,
		method: 'DELETE',
	});
}

function getProspects(data) {
	return request({
		url: '/prospects',
		method: 'GET',
		params: data,
	});
}

function getProspectIntroducers(data) {
	return request({
		url: '/prospects/introducers',
		method: 'GET',
		params: data,
	});
}

export default {
	getProspect,
	getProspects,
	createProspect,
	updateProspect,
	deleteProspect,
	getProspectIntroducers,
};
