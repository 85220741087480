import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';

import useQueryParams from '../../../hooks/useQueryParams';
import useFetch from '../../../hooks/useFetch';

import group from '../../../api/group';
import { 
	getYearList, 
	getSitemapData, 
	getFooterTotal,
} from '../../../utils';

const sitemapValue = 'GROUP_CASESSTATUS';
const { label } = getSitemapData(sitemapValue);

const headers = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
	},
	{
		id: 'investment',
		label: 'Investment',
		type: 'string',
		subheaders: [
			{
				id: 'investmentANP',
				label: 'ANP',
				type: 'float',
				errorCheck: true,
			},
			{
				id: 'investmentNOC',
				label: 'NOC',
				type: 'int',
				errorCheck: true,
			},
		],
	},
	{
		id: 'traditional',
		label: 'Traditional',
		type: 'string',
		subheaders: [
			{
				id: 'traditionalANP',
				label: 'ANP',
				type: 'float',
				errorCheck: true,
			},
			{
				id: 'traditionalNOC',
				label: 'NOC',
				type: 'int',
				errorCheck: true,
			},
		],
	},
	{
		id: 'totalInforced',
		label: 'Total Inforced',
		type: 'string',
		subheaders: [
			{
				id: 'inforcedANP',
				label: 'ANP',
				type: 'float',
				errorCheck: true,
			},
			{
				id: 'inforcedNOC',
				label: 'NOC',
				type: 'int',
				errorCheck: true,
			},
			{
				id: 'inforcedAVG',
				label: 'AVG',
				type: 'float',
				errorCheck: true,
			},
		],
	},
	{
		id: 'postponed',
		label: 'Postponed',
		type: 'string',
		subheaders: [
			{
				id: 'postponedANP',
				label: 'ANP',
				type: 'float',
				errorCheck: true,
			},
			{
				id: 'postponedNOC',
				label: 'NOC',
				type: 'int',
				errorCheck: true,
			},
		],
	},
	{
		id: 'declined',
		label: 'Declined',
		type: 'string',
		subheaders: [
			{
				id: 'declinedANP',
				label: 'ANP',
				type: 'float',
				errorCheck: true,
			},
			{
				id: 'declinedNOC',
				label: 'NOC',
				type: 'int',
				errorCheck: true,
			},
		],
	},
	{
		id: 'withdrawn',
		label: 'Withdrawn',
		type: 'string',
		subheaders: [
			{
				id: 'withdrawnANP',
				label: 'ANP',
				type: 'float',
				errorCheck: true,
			},
			{
				id: 'withdrawnNOC',
				label: 'NOC',
				type: 'int',
				errorCheck: true,
			},
		],
	},
	{
		id: 'underwriting',
		label: 'Underwriting',
		type: 'string',
		subheaders: [
			{
				id: 'underwritingANP',
				label: 'ANP',
				type: 'float',
				errorCheck: true,
			},
			{
				id: 'underwritingNOC',
				label: 'NOC',
				type: 'int',
				errorCheck: true,
			},
		],
	},
	{
		id: 'total',
		label: 'Grand Total',
		type: 'string',
		subheaders: [
			{
				id: 'totalANP',
				label: 'ANP',
				type: 'float',
				errorCheck: true,
			},
			{
				id: 'totalNOC',
				label: 'NOC',
				type: 'int',
				errorCheck: true,
			},
		],
	},
	{
		id: 'paymentMode',
		label: 'Payment Mode',
		type: 'string',
		subheaders: [
			{
				id: 'month',
				label: 'Monthly',
				type: 'int',
				errorCheck: true,
			},
			{
				id: 'quarter',
				label: 'Quarterly',
				type: 'int',
				errorCheck: true,
			},
			{
				id: 'halfYear',
				label: 'Half Yearly',
				type: 'int',
				errorCheck: true,
			},
			{
				id: 'year',
				label: 'Yearly',
				type: 'int',
				errorCheck: true,
			},
		],
	},
];

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	}
}));

function CasesStatus() {
	const classes = useStyles();
	const [queryParams, setQueryParams] = useQueryParams();
	const [data, setData] = useState([]);
	const [footers, setFooters] = useState([]);
	const [res, status, handleFetch] = useFetch(group.getCasesStatus);

	const handleChange = (type) => (event) => {
		setQueryParams({ [type]: event.target.value });
	};

	useEffect(() => {
		let tmp = {};
		if(!queryParams.year) tmp.year = moment().year();
		setQueryParams(tmp);
	}, [queryParams.year, setQueryParams]);

	useEffect(() => {
		if(queryParams.year) {
			handleFetch({ year: queryParams.year });
		}
	}, [handleFetch, queryParams.year]);

	useEffect(() => {
		if(status.loaded) {
			if(!status.error) {
				const formattedData = res.casesStatus.map(e => ({
					...e,
					inforcedAVG: e.inforcedANP / e.inforcedNOC,
				}));
				setData(formattedData);
				const footer = getFooterTotal(headers, formattedData);
				setFooters([{
					name: 'Total',
					...footer,
					inforcedAVG: footer.inforcedANP / footer.inforcedNOC,
				}]);
			} else {
				setData([]);
				setFooters([]);
			}
		}
	}, [res, status.loaded, status.error]);

	return (
		<Layout title={label}>
			<Select 
				className={classes.select}
				maxWidth={150}
				label="Year"
				options={getYearList()}
				value={queryParams.year}
				onChange={handleChange('year')}
			/>
			
			<Table
				headers={headers}
				data={data}
				footers={footers}
				isLoading={status.loading}
			/>
		</Layout>
	);
}

export default CasesStatus;
