import request from './request';

function authCheck() {
	return request({
		url: '/auth',
		method: 'POST',
	});
}

export default {
	authCheck,
};
