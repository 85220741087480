import React from 'react';
import moment from 'moment';
import { Link, Typography, makeStyles } from '@material-ui/core';

import companyLogo from '../../assets/images/company_logo.png';

const DATE_FORMAT = 'YYYY-MM-DD';
const IMAGE_HEIGHT = 150;

const useStyles = makeStyles(theme => ({
  root: {
    width: `100%`,
    height: `100%`,
    borderRadius: 10,
    padding: theme.spacing(2),
    backgroundColor: `#ffffff`,
    border: `1px solid ${theme.palette.divider}`,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
  },
  title: { fontWeight: 'bold' },
  image: {
    marginTop: theme.spacing(2),
    width: `100%`,
    height: `${IMAGE_HEIGHT}px`,
    objectFit: 'cover',
    borderRadius: 10,
  },
  companyLogoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: `100%`,
    height: `${IMAGE_HEIGHT}px`,
    backgroundColor: `#fbfbfb`,
    borderRadius: 10,
  },
  companyLogo: { width: `50px` },
  viewMoreLink: { marginTop: theme.spacing(1) },
}));

export default function EventCard(props) {
  const { event, onClick } = props;
  const classes = useStyles();

  const isSameDate =
    moment(event.startAt).format(DATE_FORMAT) ===
    moment(event.endAt).format(DATE_FORMAT);

  return (
    <div className={classes.root} onClick={onClick}>
      <Typography className={classes.title} color="primary">
        {event.name}
      </Typography>

      <Typography variant="body2">
        {isSameDate
          ? moment(event.startAt).format('D MMM YYYY')
          : `${moment(event.startAt).format('D MMM YYYY')} - ${moment(
              event.endAt
            ).format('D MMM YYYY')}`}
      </Typography>

      {event.featureImageUrl ? (
        <img className={classes.image} src={event.featureImageUrl} alt="" />
      ) : (
        <div className={classes.companyLogoContainer}>
          <img
            className={classes.companyLogo}
            src={companyLogo}
            alt="company_logo"
          />
        </div>
      )}

      <Link className={classes.viewMoreLink} align="center" underline="always">
        View More
      </Link>
    </div>
  );
}
