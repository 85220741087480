import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';
import Dialog from '../../../components/Dialog';

import PerformanceSummary from './PerformanceSummary';

import useSnackbarText from '../../../hooks/useSnackbarText';
import useQueryParams from '../../../hooks/useQueryParams';
import useDirectGroupLeaders from '../../../hooks/useDirectGroupLeaders';

import group from '../../../api/group';
import { 
	getYearList, 
	getSitemapData, 
	getFooterTotal,
} from '../../../utils';

const adminAgentID = 1;
const sitemapValue = 'GROUP_PERFORMANCE';
const { label } = getSitemapData(sitemapValue);

const headers = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
	},
	{
		id: 'BSA',
		label: 'BSA',
		type: 'string',
		subheaders: [
			{
				id: 'BSAUW',
				label: 'Underwriting',
				type: 'float',
				errorCheck: true,
			},
			{
				id: 'BSAInforced',
				label: 'Inforced',
				type: 'float',
				errorCheck: true,
			},
			{
				id: 'BSATotal',
				label: 'Total',
				type: 'float',
				errorCheck: true,
			},
		],
	},
	{
		id: 'ANP',
		label: 'ANP',
		type: 'string',
		subheaders: [
			{
				id: 'ANPUW',
				label: 'Underwriting',
				type: 'float',
				errorCheck: true,
			},
			{
				id: 'ANPInforced',
				label: 'Inforced',
				type: 'float',
				errorCheck: true,
			},
			{
				id: 'ANPTotal',
				label: 'Total',
				type: 'float',
				errorCheck: true,
			},
		],
	},
	{
		id: 'solidANP',
		label: 'Solid ANP',
		type: 'string',
		subheaders: [
			{
				id: 'solidANPUW',
				label: 'Underwriting',
				type: 'float',
				errorCheck: true,
			},
			{
				id: 'solidANPInforced',
				label: 'Inforced',
				type: 'float',
				errorCheck: true,
			},
			{
				id: 'solidANPTotal',
				label: 'Total',
				type: 'float',
				errorCheck: true,
			},
		],
	},
	{
		id: 'NOC',
		label: 'NOC',
		type: 'string',
		subheaders: [
			{
				id: 'NOCUW',
				label: 'Underwriting',
				type: 'int',
				errorCheck: true,
			},
			{
				id: 'NOCInforced',
				label: 'Inforced',
				type: 'int',
				errorCheck: true,
			},
			{
				id: 'NOCTotal',
				label: 'Total',
				type: 'int',
				errorCheck: true,
			},
		],
	},
];

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	},
	summaryButton: {
		marginTop: 14,
		marginLeft: 5,
	}
}));

function Performance() {
	const classes = useStyles();
	const handleSnackbarChange = useSnackbarText();
	const [queryParams, setQueryParams] = useQueryParams();
	const leaders = useDirectGroupLeaders(queryParams.year);
	const [data, setData] = useState([]);
	const [footers, setFooters] = useState([]);
	const [loading, setLoading] = useState(true);

	const handleChange = (type) => (event) => {
		if(type === 'year') {
			setQueryParams({ 
				[type]: event.target.value,
				leaderID: adminAgentID,
			});
		} else 
			setQueryParams({ [type]: event.target.value });
	};

	const handleShowSummary = () => {
		setQueryParams({ summary: 'true' });
	};

	useEffect(() => {
		let tmp = {};
		if(!queryParams.year) tmp.year = moment().year();
		if(!queryParams.leaderID && leaders.length) {
			tmp.leaderID = leaders[0].value;
		}
		setQueryParams(tmp);
	}, [queryParams.year, queryParams.leaderID, leaders, setQueryParams]);

	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			let { data, success } = await group.getPerformance({ 
				year: queryParams.year,
				leaderID: queryParams.leaderID,
			});
			setLoading(false);

			if(success) {
				const formattedData = data.performance.map(e => ({
					...e,
					BSATotal: (e.BSAUW + e.BSAInforced) || 0,
					ANPTotal: (e.ANPUW + e.ANPInforced) || 0,
					solidANPTotal: (e.solidANPUW + e.solidANPInforced) || 0,
					NOCTotal: (e.NOCUW + e.NOCInforced) || 0,
				}));

				setData(formattedData);
				setFooters([{
					name: 'Total',
					...getFooterTotal(headers, formattedData),
				}]);
			} else {
				return handleSnackbarChange({ text: data.message, variant: 'error' });
			}
		};

		if(queryParams.year && queryParams.leaderID) getData();
	}, [queryParams.year, queryParams.leaderID, handleSnackbarChange]);

	return (
		<Layout title={label}>
			<Select 
				className={classes.select}
				maxWidth={110}
				label="Year"
				options={getYearList()}
				value={queryParams.year}
				onChange={handleChange('year')}
			/>

			<Select 
				maxWidth={200}
				label="Group Leader"
				options={leaders}
				value={queryParams.leaderID || ''}
				onChange={handleChange('leaderID')}
			/>

			<Button 
				className={classes.summaryButton} 
				onClick={handleShowSummary} 
				color="primary"
				startIcon={<DescriptionIcon />}
				variant="outlined"
			>
				Summary
			</Button>

			<Table
				headers={headers}
				data={data}
				footers={footers}
				isLoading={loading}
			/>

			<Dialog queryParamKey="summary" title="Summary" maxWidth="100%">
				<PerformanceSummary />
			</Dialog>

		</Layout>
	);
}

export default Performance;
