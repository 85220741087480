import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';
import TextField from '../../../components/TextField';

import useSnackbarText from '../../../hooks/useSnackbarText';
import useQueryParams from '../../../hooks/useQueryParams';

import { goalTypeOptions } from '../../../configs/goals';
import goals from '../../../api/goals';
import { getYearList, getSitemapData } from '../../../utils';

const sitemapValue = 'ADMIN_GOAL';
const { label } = getSitemapData(sitemapValue);

const headers = [
  {
    id: 'name',
    label: 'Name',
    type: 'string',
    sticky: true,
  },
  {
    id: 'ANP',
    label: 'ANP',
    type: 'dom',
  },
  {
    id: 'sANP',
    label: 'S-ANP',
    type: 'dom',
  },
  {
    id: 'QFYLP',
    label: 'QFYLP',
    type: 'dom',
  },
  {
    id: 'recruitment',
    label: 'Recruitment',
    type: 'dom',
  },
];

const useStyles = makeStyles(theme => ({
  select: {
    marginRight: 10,
  },
  textField: {
    margin: 8,
    '& input': {
      textAlign: 'right',
    },
  },
  actionPanel: {
    display: 'flex',
    //alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },
  submitButton: {
    marginTop: 8,
    marginBottom: 8,
  },
}));

function Goal() {
  const classes = useStyles();
  const [queryParams, setQueryParams] = useQueryParams();
  const handleSnackbarChange = useSnackbarText();
  const [data, setData] = useState([]);
  const [disabled, setDisable] = useState(false);
  const [timestamp, setTimestamp] = useState(new Date().getTime());

  const handleYearChange = event => {
    setQueryParams({ year: event.target.value });
  };

  const handleGoalTypeChange = event => {
    setQueryParams({ type: event.target.value });
  };

  const handleChange = useCallback(
    (type, index) => event => {
      const { value } = event.target;
      setData(prevData => {
        let newData = [...prevData];
        newData[index][type] = value;
        return newData;
      });
    },
    []
  );

  const handleSubmit = async () => {
    setDisable(true);

    const updateResult = await goals.updateGoals({
      goals: data.map(e => ({
        goalID: e.goalID,
        year: queryParams.year,
        agentID: e.agentID,
        targetType: queryParams.type,
        ANP: e.ANP || 0,
        sANP: e.sANP || 0,
        QFYLP: e.QFYLP || 0,
        recruitment: e.recruitment || 0,
      })),
    });

    setDisable(false);
    if (updateResult.success) {
      setTimestamp(new Date().getTime());
      handleSnackbarChange({
        text: 'Goal has been updated',
        variant: 'success',
      });
    } else {
      handleSnackbarChange({
        text: updateResult.data.message,
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    const getData = async () => {
      let { data, success } = await goals.getGoals({
        year: queryParams.year,
        type: queryParams.type,
      });

      if (success) {
        setData(
          data.goals.map(e => ({
            goalID: e.goalID,
            agentID: e.agentID,
            name: e.name,
            ANP: e.ANP !== null ? String(e.ANP) : '',
            sANP: e.sANP !== null ? String(e.sANP) : '',
            QFYLP: e.QFYLP !== null ? String(e.QFYLP) : '',
            recruitment: e.recruitment !== null ? String(e.recruitment) : '',
          }))
        );
      } else {
        return handleSnackbarChange({ text: data.message, variant: 'error' });
      }
    };

    if (queryParams.year && queryParams.type) getData();
  }, [timestamp, queryParams.year, queryParams.type, handleSnackbarChange]);

  useEffect(() => {
    if (queryParams.year && queryParams.type) return;

    let newQueryParams = {
      year: queryParams.year,
      type: queryParams.type,
    };

    if (!queryParams.year) newQueryParams.year = moment().year();
    if (!queryParams.type) newQueryParams.type = goalTypeOptions[0].value;
    setQueryParams(newQueryParams);
  }, [queryParams.year, queryParams.type, setQueryParams]);

  const formattedData = data.map((e, i) => ({
    name: e.name,
    ANP: (
      <TextField
        className={classes.textField}
        value={e.ANP}
        type="number"
        onChange={handleChange('ANP', i)}
      />
    ),
    sANP: (
      <TextField
        className={classes.textField}
        value={e.sANP}
        type="number"
        onChange={handleChange('sANP', i)}
      />
    ),
    QFYLP: (
      <TextField
        className={classes.textField}
        value={e.QFYLP}
        type="number"
        onChange={handleChange('QFYLP', i)}
      />
    ),
    recruitment: (
      <TextField
        className={classes.textField}
        value={e.recruitment}
        type="number"
        onChange={handleChange('recruitment', i)}
      />
    ),
  }));

  return (
    <Layout title={label}>
      <Select
        className={classes.select}
        maxWidth={110}
        label="Year"
        options={getYearList()}
        value={queryParams.year}
        onChange={handleYearChange}
      />

      <Select
        className={classes.select}
        maxWidth={150}
        label="Type"
        options={goalTypeOptions}
        value={queryParams.type}
        onChange={handleGoalTypeChange}
      />

      <Table headers={headers} data={formattedData} />

      <div className={classes.actionPanel}>
        <Button
          className={classes.submitButton}
          disabled={disabled}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Update
        </Button>
      </div>
    </Layout>
  );
}

export default Goal;
