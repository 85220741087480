import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';
import MultiSelect from '../../../components/MultiSelect';

import useQueryParams from '../../../hooks/useQueryParams';
import usePlanList from '../../../hooks/usePlanList';
import useFetch from '../../../hooks/useFetch';

import group from '../../../api/group';
import { 
	getYearList, 
	getSitemapData, 
	getFooterTotal,
} from '../../../utils';

const dateFormat = 'YYYY-MM-DD';
const sitemapValue = 'GROUP_PLANPERFORMANCE';
const { label } = getSitemapData(sitemapValue);

const startHeaders = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
	}
];

const endHeaders = [
	{
		id: 'investment',
		label: 'Investment',
		type: 'string',
		subheaders: [
			{ 
				id: `investmentANP`,
				label: 'ANP',
				type: 'float',
				errorCheck: true,
			},
			{ 
				id: `investmentNOC`,
				label: 'NOC',
				type: 'int',
				errorCheck: true,
			},
		]
	},
	{
		id: 'traditional',
		label: 'Traditional',
		type: 'string',
		subheaders: [
			{ 
				id: `traditionalANP`,
				label: 'ANP',
				type: 'float',
				errorCheck: true,
			},
			{ 
				id: `traditionalNOC`,
				label: 'NOC',
				type: 'int',
				errorCheck: true,
			},
		]
	}
];

const generateHeaders = (rawPlanList, selectedPlans = []) => {
	let planList = [];
	for(let i = 0; i < rawPlanList.length; i++) {
		for(let j = 0; j < rawPlanList[i].options.length; j++) {
			planList.push({ ...rawPlanList[i].options[j] });
		}
	}

	const planIDs = planList.map(e => e.value);

	let plans = [];
	for(let i = 0; i < selectedPlans.length; i++) {
		const index = planIDs.indexOf(selectedPlans[i]);
		if(index === -1) continue;
		plans.push({
			id: `plan_${planList[index].value}`,
			label: planList[index].label,
			type: 'string',
			subheaders: [
				{ 
					id: `plan_${planList[index].value}_anp`,
					label: 'ANP',
					type: 'float',
					errorCheck: true,
				},
				{ 
					id: `plan_${planList[index].value}_noc`,
					label: 'NOC',
					type: 'int',
					errorCheck: true,
				},
			]
		})
	}

	return plans;
};

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	}
}));

function PlanPerformance() {
	const classes = useStyles();
	const [queryParams, setQueryParams] = useQueryParams();
	const planList = usePlanList();
	const [plans, setPlans] = useState([]);
	const [data, setData] = useState([]);
	const [footers, setFooters] = useState([]);
	const [res, status, handleFetch] = useFetch(group.getPlanPerformance);

	const handleChange = (type) => (event) => {
		setQueryParams({ [type]: event.target.value });
	};

	const handlePlanChange = (event) => {
		setPlans(event.target.value);
	};

	useEffect(() => {
		let tmp = {};
		if(!queryParams.year) tmp.year = moment().year();
		setQueryParams(tmp);
	}, [queryParams.year, setQueryParams]);

	useEffect(() => {
		if(queryParams.year) {
			handleFetch({ 
				startDate: moment(`${queryParams.year}-01-01`).format(dateFormat),
				endDate: moment(`${queryParams.year}-12-31`).format(dateFormat),
			});
		}
	}, [handleFetch, queryParams.year]);

	useEffect(() => {
		if(status.loaded) {
			if(!status.error) {
				const formattedData = res.performance.map(e => {
					let { performance, ...others } = e;
					let tmp = { 
						...others,
						investmentANP: 0,
						investmentNOC: 0,
						traditionalANP: 0,
						traditionalNOC: 0,
					};

					for(let i in performance) {
						tmp[`${i}_anp`] = performance[i].anp;
						tmp[`${i}_noc`] = performance[i].noc;
						if(performance[i].type === '1') {
							tmp.traditionalANP += performance[i].anp;
							tmp.traditionalNOC += performance[i].noc;
						} else {
							tmp.investmentANP += performance[i].anp;
							tmp.investmentNOC += performance[i].noc;
						}
					}
					return tmp;
				});
				setData(formattedData);
				setFooters([{
					name: 'Total',
					...getFooterTotal([
						...generateHeaders(planList, plans),
						...endHeaders,
					], formattedData),
				}]);
			} else {
				setData([]);
				setFooters([]);
			}
		}
	}, [res, status.loaded, status.error, planList, plans]);

	const dynamicHeaders = [
		...startHeaders,
		...generateHeaders(planList, plans),
		...endHeaders,
	];

	return (
		<Layout title={label}>
			<Select 
				className={classes.select}
				maxWidth={150}
				label="Year"
				options={getYearList()}
				value={queryParams.year}
				onChange={handleChange('year')}
			/>

			<MultiSelect
				label="Plans"
				options={planList}
				value={plans}
				onChange={handlePlanChange}
				group
				maxWidth={200}
			/>
			
			<Table
				headers={dynamicHeaders}
				data={data}
				footers={footers}
				isLoading={status.loading}
				dense
			/>

		</Layout>
	);
}

export default PlanPerformance;
