import request from './request';

function getDiary(data) {
	return request({
		url: '/diary',
		method: 'GET',
		params: data,
	});
}

function getDiaryByKeyword(data) {
	return request({
		url: '/diary/search',
		method: 'GET',
		params: data,
	});
}

export default {
	getDiary,
	getDiaryByKeyword
};
