import React, { useState, useEffect } from 'react';

import Table from '../../../components/Table';

import useQueryParams from '../../../hooks/useQueryParams';
import useFetch from '../../../hooks/useFetch';

import group from '../../../api/group';
import { getFooterTotal } from '../../../utils';

const adminAgentID = 1;

const headers = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
		disableSort: true,
	},
	{
		id: 'statusOpen',
		label: 'Open',
		type: 'int',
		errorCheck: true,
	},
	{
		id: 'statusClose',
		label: 'Close',
		type: 'int',
		errorCheck: true,
	},
	{
		id: 'statusFactFinding',
		label: 'Fact Finding',
		type: 'int',
		errorCheck: true,
	},
	{
		id: 'statusPolicyReview',
		label: 'Policy Review',
		type: 'int',
		errorCheck: true,
	},
	{
		id: 'statusMealAppointment',
		label: 'Meal Appointment',
		type: 'int',
		errorCheck: true,
	},
	{
		id: 'statusRecruitment',
		label: 'Recuirment',
		type: 'int',
		errorCheck: true,
	},
	{
		id: 'statusFollowUp',
		label: 'Follow Up',
		type: 'int',
		errorCheck: true,
	},
	{
		id: 'statusNone',
		label: 'None/Service',
		type: 'int',
		errorCheck: true,
	},
	{
		id: 'prospects',
		label: 'Prospects',
		type: 'float',
		errorCheck: true,
	},
	{
		id: 'submissions',
		label: 'Submissions',
		type: 'int',
		errorCheck: true,
	},
	{
		id: 'events',
		label: 'Events',
		type: 'int',
		errorCheck: true,
	},
	{
		id: 'statusNonFaceToFace',
		label: 'Non Face To Face',
		type: 'int',
		errorCheck: true,
	},
	{
		id: 'total',
		label: 'Total',
		type: 'float',
		errorCheck: true,
	},
];


function Summary() {
	const [queryParams] = useQueryParams();
	const [data, setData] = useState([]);
	const [footers, setFooters] = useState([]);
	const [res, status, handleFetch] = useFetch(group.getDiaryPerformanceSummary);

	useEffect(() => {
		if(queryParams.startDate && queryParams.endDate) {
			handleFetch({
				startDate: queryParams.startDate,
				endDate: queryParams.endDate,
			});
		}
	}, [handleFetch, queryParams.startDate, queryParams.endDate]);

	useEffect(() => {
		if(status.loaded) {
			if(!status.error) {
				const formattedData = res.summary.map(e => {
					const total = e.statusOpen 
						+ Math.max(e.statusClose, (e.submissions || 0))
						+ e.statusFactFinding
						+ e.statusPolicyReview
						+ e.statusMealAppointment
						+ e.statusRecruitment
						+ (e.prospects * 0.5);

					return {
					name: e.name,
					agentID: e.agentID,
					prospects: e.prospects * 0.5,
					submissions: e.submissions || 0,
					events: e.events,
					statusOpen: e.statusOpen,
					statusClose: e.statusClose,
					statusFactFinding: e.statusFactFinding,
					statusPolicyReview: e.statusPolicyReview,
					statusMealAppointment: e.statusMealAppointment,
					statusRecruitment: e.statusRecruitment,
					statusFollowUp: e.statusFollowUp,
					statusNone: e.statusNone,
					statusNonFaceToFace: e.statusNonFaceToFace,
					total: total,
				}});
				setData(formattedData);
				setFooters([
					{
						name: 'Direct Total',
						...getFooterTotal(headers, formattedData.filter(e => e.agentID === adminAgentID)),
					},
					{
						name: 'Indirect Total',
						...getFooterTotal(headers, formattedData.filter(e => e.agentID !== adminAgentID)),
					},
					{
						name: 'Grand Total',
						...getFooterTotal(headers, formattedData),
					}
				]);
			} else {
				setData([]);
				setFooters([]);
			}
		}
	}, [res, status.loaded, status.error]);

	return (
		<Table
			headers={headers}
			data={data}
			footers={footers}
			isLoading={status.loading}
		/>
	);
}

export default Summary;
