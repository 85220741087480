/*
	For table less than 3 cols
*/

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const columnWidth = 150;

const useStyles = makeStyles(theme => ({
	root: {
		marginTop: 20,
		marginBottom: 30,
	},
	title: {
		color: theme.palette.primary.dark
	},
	table: {
		border: `1px solid ${theme.palette.grey.primary}`,
	},
	headerWrapper: {
		display: 'flex',
		alignItems: 'center',
		borderBottom: `1px solid ${theme.palette.grey.primary}`,
	},
	header: {
		width: columnWidth,
		minHeight: 30,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	bodyRow: {
		backgroundColor: '#fbfbfb',
		borderBottom: `1px solid ${theme.palette.grey.primary}`,
		display: 'flex',
		alignItems: 'center'
	},
	bodyCol: {
		width: columnWidth,
		minHeight: 30,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	footerRow: {
		backgroundColor: '#efefef',
		display: 'flex',
		alignItems: 'center'
	},
	footerCol: {
		width: columnWidth,
		minHeight: 30,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

function SimpleTable(props) {
	const classes = useStyles();
	const { 
		title = '', 
		headers = [], 
		data = [], 
		footers = [] 
	} = props;
	return (
		<div className={classes.root}>
			<Typography className={classes.title}>{title}</Typography>
			<div className={classes.table}>
				<div className={classes.headerWrapper}>
					{headers.map(e => (
						<div key={e.value} className={classes.header}>{e.label}</div>
					))}
				</div>
				<div className={classes.bodyRoot}>
					{data.map((e, i) => (<div key={i} className={classes.bodyRow}>
						{headers.map(el => (
							<div key={el.value} className={classes.bodyCol}>{e[el.value]}</div>
						))}
						</div>
					))}
				</div>
				<div className={classes.footerWrapper}>
					{footers.map((e, i) => (<div key={i} className={classes.footerRow}>
						{headers.map(el => (
							<div key={el.value} className={classes.footerCol}>{e[el.value]}</div>
						))}
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export default SimpleTable;
