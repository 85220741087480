import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { generateNumber } from '../../../utils';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		maxWidth: 800,
	},
	gridContainer: {
		backgroundColor: 'white',
		padding: 10,
		borderRadius: theme.shape.borderRadius
	},
	gridItem: {
		textAlign: 'center',
		[theme.breakpoints.down('xs')]: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-evenly',
			'& p': {
				width: 100
			}
		}
	},
	label: {
		fontWeight: 500,
		textAlign: 'center'
	},
	firstChar: {
		fontWeight: 600,
		color: theme.palette.primary.main,
	},
	value: {
		fontSize: 14,
	}
}));

function MEAA(props) {
	const classes = useStyles();
	const { 
		market = 0,
		effective = 0,
		activity = 0,
		ANP = 0
	} = props;

	return (
		<div className={classes.root}>
			<Grid className={classes.gridContainer} container>
				<Grid className={classes.gridItem} item xs={12} sm={3}>
					<Typography className={classes.label}>
						<span className={classes.firstChar}>M</span>arket
					</Typography>
					<Typography className={classes.value}>{generateNumber(market)}</Typography>
				</Grid>
				<Grid className={classes.gridItem} item xs={12} sm={3}>
					<Typography className={classes.label}>
						<span className={classes.firstChar}>E</span>ffective
					</Typography>
					<Typography className={classes.value}>{generateNumber(effective)} %</Typography>
				</Grid>
				<Grid className={classes.gridItem} item xs={12} sm={3}>
					<Typography className={classes.label}>
						<span className={classes.firstChar}>A</span>ctivity
					</Typography>
					<Typography className={classes.value}>{generateNumber(activity, 0)}</Typography>
				</Grid>
				<Grid className={classes.gridItem} item xs={12} sm={3}>
					<Typography className={classes.label}>
						<span className={classes.firstChar}>A</span>NP
					</Typography>
					<Typography className={classes.value}>{generateNumber(ANP)}</Typography>
				</Grid>
			</Grid>
		</div>
	);
}

export default MEAA;
