import React, { useState, useEffect, useCallback, useRef } from 'react';
import clsx from 'clsx';
import { Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  getSubheaders,
  getObjectByKey,
  reformatData,
  generateSortable,
} from './utils';

import TextField from '../TextField';

// configs
const rootPadding = 10;
const indexNumberWidth = 40;
const cellWidth = 150;
const denseCellWidth = 120;
const cellHeight = 33;
const tableMinHeight = 250;
const defaultTableMarginBottom = 40; // control table height
const selectedBorderWidth = 3;
const hoverColor = '#fff9dd';
const bodyStickColor = '#ffeaea';
const footerStickColor = '#f1f1f1';

const orderOptions = ['asc', 'desc'];
const getInvertOrder = order => {
  return order === orderOptions[0] ? orderOptions[1] : orderOptions[0];
};
const emptyFunc = () => {};

const defaultState = {
  orderBy: '',
  order: orderOptions[0],
  keyword: '',
};

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 10,
    padding: rootPadding,
    borderRadius: theme.shape.borderRadius,
    //border: `1px solid ${theme.palette.grey.primary}`,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
  },
  titleWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontWeight: 600,
  },
  keywordWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  tableRoot: {
    maxWidth: '100%',
    minHeight: tableMinHeight,
    overflow: 'auto',
    scrollSnapType: 'both mandatory',
    WebkitOverflowScrolling: 'touch',
    transition: 'all 0.3s',
  },
  removeMinHeight: {
    minHeight: 'auto',
  },
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerWrapper: {
    zIndex: 2, // prevent sticky div in body
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    borderBottom: '1px solid #cacaca',
  },
  headerRow: {
    display: 'flex',
  },
  headerCol: {
    paddingLeft: 2,
    paddingRight: 2,
    display: 'flex',
    flexDirection: 'column',
    scrollMargin: 0,
    scrollSnapStop: 'normal',
    scrollSnapAlign: 'start',
  },
  headerColItemWrapper: {
    display: 'flex',
    flex: 1,
  },
  headerColItemWrapperDivider: {
    borderTop: '1px solid #cacaca',
  },
  headerColItem: {
    transition: 'all 0.15s',
    minHeight: cellHeight - 1,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
  },
  bodyWrapper: {
    //backgroundColor: '#f9f9f9',
    backgroundColor: '#fff',
  },
  bodyRow: {
    display: 'flex',
    minHeight: cellHeight,
    '&:hover': {
      '& $bodyCol': {
        backgroundColor: hoverColor,
      },
    },
  },
  bodyColIndex: {
    textAlign: 'right',
  },
  bodyCol: {
    transition: 'all 0.3s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: cellWidth,
    scrollMargin: 0,
    scrollSnapStop: 'normal',
    scrollSnapAlign: 'start',
    borderBottom: `1px solid ${theme.palette.grey.primary}`,
  },
  bodyStickLeft: {
    justifyContent: 'flex-start',
    paddingLeft: 10,
    backgroundColor: bodyStickColor,
    position: 'sticky',
    width: `${cellWidth}px!important`,
    left: 0,
    zIndex: 1,
  },
  footerWrapper: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: '#f1f1f1',
    borderTop: '1px solid #cacaca',
    zIndex: 1,
  },
  footerRow: {
    display: 'flex',
    minHeight: cellHeight,
  },
  footerColIndex: {
    textAlign: 'right',
  },
  footerCol: {
    fontWeight: 600,
    transition: 'all 0.3s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: cellWidth,
    scrollMargin: 0,
    scrollSnapStop: 'normal',
    scrollSnapAlign: 'start',
    borderBottom: `1px solid ${theme.palette.grey.primary}`,
  },
  footerStickLeft: {
    justifyContent: 'flex-start',
    paddingLeft: 10,
    backgroundColor: footerStickColor,
    position: 'sticky',
    width: `${cellWidth}px!important`,
    left: 0,
  },
  selectedCol: {
    borderBottom: `${selectedBorderWidth}px solid ${theme.palette.primary.main}`,
  },
  sortable: {
    cursor: 'pointer',
  },
  errorCheck: {
    color: theme.palette.error.main,
  },
  valueCheck: {
    color: theme.palette.success.main,
    backgroundColor: '#e8ffe8',
  },
  noDataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noData: {
    color: '#cacaca',
    borderBottom: 'none',
    backgroundColor: 'transparent!important',
  },
  denseCellWidth: {
    width: denseCellWidth,
    scrollMarginLeft: `${cellWidth - denseCellWidth}px`,
    //scrollMarginRight: `${cellWidth - denseCellWidth}px`,
  },
}));

function Table(props) {
  const classes = useStyles();
  const {
    title = '',
    data = [],
    headers = [],
    footers = [],
    noIndexNumber,
    noMinHeight,
    dense,
    tableMarginBottom = defaultTableMarginBottom,
    tableState = {},
    defaultScrollTo,
    disableSearch,
    isLoading = false,
  } = props;

  const [state, _setState] = useState({ ...defaultState, ...tableState });
  const [tableData, setTableData] = useState(data);
  const [maxHeight, setMaxHeight] = useState(350);
  const tableRef = useRef(null);

  const setState = useCallback(newState => {
    _setState(prevState => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleSort = useCallback(
    orderBy => () => {
      _setState(prevState => {
        if (prevState.orderBy === orderBy) {
          const newOrder = getInvertOrder(prevState.order);
          return {
            ...prevState,
            order: newOrder,
          };
        } else {
          return {
            ...prevState,
            orderBy,
            order: orderOptions[1],
          };
        }
      });
    },
    []
  );

  const handleChange = useCallback(
    type => event => {
      setState({ [type]: event.target.value });
    },
    [setState]
  );

  const handleMaxHeightChange = useCallback(() => {
    const marginBottom = tableMarginBottom;
    const windowHeight = window.innerHeight;
    const bodyOffset = window.document.body.getBoundingClientRect().y;
    const tableOffset = tableRef.current.getBoundingClientRect().y;
    const maxHeight = windowHeight - (tableOffset - bodyOffset) - marginBottom;
    setMaxHeight(maxHeight);
  }, [tableMarginBottom]);

  // handle tabledata changed
  useEffect(() => {
    const keyword = state.keyword;
    const order = state.order;
    const orderBy = state.orderBy;

    let sortedData = [...data];
    // sort data
    if (orderBy && order) {
      const rawData = [...data];
      const header = getObjectByKey(getSubheaders(headers), 'id', orderBy);

      switch (header.type) {
        case 'float':
          sortedData = rawData.sort((a, b) => {
            if (order !== orderOptions[0]) return b[orderBy] - a[orderBy];
            else return a[orderBy] - b[orderBy];
          });
          break;
        case 'int':
          sortedData = rawData.sort((a, b) => {
            if (order !== orderOptions[0]) return b[orderBy] - a[orderBy];
            else return a[orderBy] - b[orderBy];
          });
          break;
        case 'date':
          sortedData = rawData.sort((a, b) => {
            if (order !== orderOptions[0])
              return new Date(b[orderBy]) - new Date(a[orderBy]);
            else return new Date(a[orderBy]) - new Date(b[orderBy]);
          });
          break;
        case 'string':
          sortedData = rawData.sort((a, b) => {
            if (order === orderOptions[0]) {
              if (a[orderBy] < b[orderBy]) return -1;
              if (a[orderBy] > b[orderBy]) return 1;
              return 0;
            } else {
              if (a[orderBy] < b[orderBy]) return 1;
              if (a[orderBy] > b[orderBy]) return -1;
              return 0;
            }
          });
          break;
        default:
          sortedData = rawData;
      }
    }

    // handle filter
    if (keyword) {
      sortedData = sortedData.filter(e => {
        for (let key in e) {
          let value = e[key];
          if (typeof value === 'object') continue;

          value = String(value).toUpperCase();
          if (value.includes(String(keyword).toUpperCase())) return true;
        }
        return false;
      });
    }

    setTableData(sortedData);
  }, [state.keyword, state.orderBy, state.order, data, headers]);

  // onload
  useEffect(() => {
    handleMaxHeightChange();
  }, [data.length, handleMaxHeightChange]);

  // set table maxHeight
  useEffect(() => {
    window.addEventListener('resize', handleMaxHeightChange);
    return () => window.removeEventListener('resize', handleMaxHeightChange);
  });

  // set scroll to
  useEffect(() => {
    if (data.length && defaultScrollTo && !isLoading && tableRef.current) {
      const subheaders = getSubheaders(headers);
      const subheaderIDs = subheaders.map(e => e.id);
      const index = subheaderIDs.indexOf(defaultScrollTo);

      if (index === -1) return;

      const offset = noIndexNumber
        ? dense
          ? cellWidth + denseCellWidth * (index - 2)
          : cellWidth * (index - 1)
        : dense
        ? cellWidth + denseCellWidth * (index - 2)
        : cellWidth * (index - 1) + indexNumberWidth;

      // workaround for scrolling
      setTimeout(() => {
        if (tableRef.current) tableRef.current.scrollTo(offset, 0);
      }, 50);
    }
  }, [dense, noIndexNumber, data.length, headers, isLoading, defaultScrollTo]);

  // before rendering
  const subheaders = getSubheaders(headers);
  const stickyIndex = subheaders.map(e => e.sticky).indexOf(true);
  const tableWidth = noIndexNumber
    ? dense
      ? cellWidth + denseCellWidth * (subheaders.length - 1)
      : cellWidth * subheaders.length
    : dense
    ? cellWidth + denseCellWidth * (subheaders.length - 1) + indexNumberWidth
    : cellWidth * subheaders.length + indexNumberWidth;
  const isHeaderSingleRow = headers.length === subheaders.length;
  const headerHeight = cellHeight * (isHeaderSingleRow ? 1 : 2);
  const bodyScrollMarginTop = `${headerHeight}px`;

  const isSortable = generateSortable(subheaders); // this is a function
  const formattedData = reformatData(subheaders, tableData);
  const formattedFooter = reformatData(subheaders, footers);

  return (
    <div
      className={classes.root}
      style={{ maxWidth: tableWidth + 2 + 2 * rootPadding }}
    >
      <div className={classes.keywordWrapper}>
        <div className={classes.titleWrapper}>
          {isLoading && <CircularProgress size={20} />}
          <Typography className={classes.title} variant="h6" noWrap>
            {title}
          </Typography>
        </div>
        {!disableSearch && (
          <TextField
            value={state.keyword}
            placeholder="Search something here..."
            onChange={handleChange('keyword')}
          />
        )}
      </div>
      <div
        className={clsx(
          classes.tableRoot,
          !formattedData.length && classes.noDataContainer,
          Boolean(noMinHeight) && classes.removeMinHeight
        )}
        ref={tableRef}
        style={{ maxHeight }}
      >
        {Boolean(formattedData.length) && (
          <div className={classes.tableWrapper}>
            {/* Header */}
            <div
              className={classes.headerWrapper}
              style={{
                height: headerHeight,
                width: tableWidth,
              }}
            >
              <div className={classes.headerRow}>
                {!noIndexNumber && (
                  <div
                    className={classes.headerCol}
                    style={{ width: indexNumberWidth }}
                  />
                )}

                {headers.map((e, i) => {
                  const isColSortable = isSortable(e.id);
                  return (
                    <div
                      key={i}
                      className={classes.headerCol}
                      style={{
                        width: e.sticky
                          ? cellWidth
                          : (e.subheaders ? e.subheaders.length : 1) *
                            (dense ? denseCellWidth : cellWidth),
                      }}
                    >
                      <div className={classes.headerColItemWrapper}>
                        <div
                          className={clsx(
                            classes.headerColItem,
                            state.orderBy === e.id && classes.selectedCol,
                            !e.subheaders && isColSortable && classes.sortable
                          )}
                          onClick={
                            !e.subheaders && isColSortable
                              ? handleSort(e.id)
                              : emptyFunc
                          }
                        >
                          {e.label}
                        </div>
                      </div>
                      {e.subheaders && e.subheaders.length && (
                        <div
                          className={clsx(
                            classes.headerColItemWrapper,
                            classes.headerColItemWrapperDivider
                          )}
                        >
                          {e.subheaders.map((el, j) => {
                            const isColSortable = isSortable(el.id);
                            return (
                              <div
                                key={j}
                                className={clsx(
                                  classes.headerColItem,
                                  state.orderBy === el.id &&
                                    classes.selectedCol,
                                  isColSortable && classes.sortable
                                )}
                                onClick={
                                  isColSortable ? handleSort(el.id) : emptyFunc
                                }
                              >
                                {el.label}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Body */}
            <div className={classes.bodyWrapper} style={{ width: tableWidth }}>
              {formattedData.map((e, i) => (
                <div key={i} className={classes.bodyRow}>
                  {!noIndexNumber && (
                    <div
                      className={clsx(
                        classes.bodyCol,
                        Boolean(dense) && classes.denseCellWidth,
                        classes.bodyColIndex
                      )}
                      style={{
                        textAlign: 'right',
                        width: indexNumberWidth,
                      }}
                    >
                      {i + 1}
                    </div>
                  )}

                  {e.map((el, j) => (
                    <div
                      key={j}
                      className={clsx(
                        classes.bodyCol,
                        Boolean(dense) && classes.denseCellWidth,
                        stickyIndex === j && classes.bodyStickLeft,
                        subheaders[j].valueCheck &&
                          parseFloat(el) &&
                          classes.valueCheck,
                        subheaders[j].errorCheck &&
                          !parseFloat(el) &&
                          classes.errorCheck
                      )}
                      style={{ scrollMarginTop: bodyScrollMarginTop }}
                    >
                      {el}
                    </div>
                  ))}
                </div>
              ))}

              {/* No data found */}
              {!formattedData.length && (
                <div className={classes.bodyRow} style={{ height: 50 }}>
                  <div
                    className={clsx(
                      classes.bodyCol,
                      Boolean(dense) && classes.denseCellWidth,
                      classes.bodyColIndex,
                      classes.noData
                    )}
                  >
                    No data found
                  </div>
                </div>
              )}
            </div>

            {/* Footer */}
            <div
              className={classes.footerWrapper}
              style={{ width: tableWidth }}
            >
              {formattedFooter.map((e, i) => (
                <div key={i} className={classes.footerRow}>
                  {!noIndexNumber && (
                    <div
                      className={clsx(
                        classes.footerCol,
                        Boolean(dense) && classes.denseCellWidth,
                        classes.footerColIndex
                      )}
                      style={{
                        textAlign: 'right',
                        width: indexNumberWidth,
                      }}
                    />
                  )}

                  {e.map((el, j) => (
                    <div
                      key={j}
                      className={clsx(
                        classes.footerCol,
                        Boolean(dense) && classes.denseCellWidth,
                        stickyIndex === j && classes.footerStickLeft
                      )}
                    >
                      {el}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        )}

        {Boolean(!formattedData.length) && (
          <div className={classes.noData}>
            {isLoading ? 'Loading' : 'No data found'}
          </div>
        )}
      </div>
    </div>
  );
}

export default Table;
