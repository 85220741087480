import React, { useEffect, useState } from 'react';
import { Grid, SvgIcon, Typography, makeStyles } from '@material-ui/core';
import Layout from '../../components/Layout';
import companyEventApi from '../../api/companyEvents';
import { ReactComponent as EventIcon } from '../../assets/icons/icon_event.svg';

import EventCard from './EventCard';
import EventDialog from './EventDialog';

const useStyles = makeStyles(theme => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  title: {
    marginLeft: 10,
    fontWeight: 'bold',
    fontSize: `1.25rem`,
  },
  spacer: { width: `100%`, height: theme.spacing(3) },
  section: {
    borderRadius: 10,
    padding: theme.spacing(1),
    backgroundColor: `#ffffff`,
    border: `1px solid ${theme.palette.divider}`,
  },
  innerScroll: {
    flex: 1,
    maxHeight: `300px`,
    overflow: 'auto',
  },
}));

export default function CompanyUpdatePage() {
  const classes = useStyles();
  /** `null` means data not being loaded yet */
  const [companyEvents, setCompanyEvents] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedEventID, setSelectedEventID] = useState('');

  useEffect(() => {
    async function getCompanyEvents() {
      const result = await companyEventApi.getCompanyEvents();
      if (result.success) {
        setCompanyEvents(result.data.events);
      }
    }

    getCompanyEvents();
  }, []);

  useEffect(() => {
    let didCancel = false;

    const selectedEvent = companyEvents
      ? companyEvents.find(event => event.eventID === selectedEventID)
      : undefined;

    if (selectedEvent) {
      setSelectedEvent(selectedEvent);
    } else {
      setTimeout(() => {
        if (didCancel) return;
        setSelectedEvent(null);
      }, 1000);
    }

    return () => {
      didCancel = true;
    };
  }, [companyEvents, selectedEventID]);

  return (
    <Layout
      title={
        <div className={classes.titleWrapper}>
          <SvgIcon color="primary" component={EventIcon} viewBox="0 0 48 48" />
          <Typography className={classes.title} variant="subtitle1">
            Event
          </Typography>
        </div>
      }
    >
      <div>
        {companyEvents && (
          <>
            {companyEvents.length === 0 ? (
              <Typography variant="caption">No company events</Typography>
            ) : (
              <Grid container spacing={1}>
                {companyEvents.map(event => (
                  <Grid key={event.eventID} xs={12} sm={6} md={4} lg={3} item>
                    <EventCard
                      event={event}
                      onClick={() => setSelectedEventID(event.eventID)}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </>
        )}
      </div>

      <div className={classes.spacer} />

      <EventDialog
        event={selectedEvent}
        open={Boolean(selectedEventID)}
        onClose={() => setSelectedEventID('')}
      />
    </Layout>
  );
}
