import React, { useState, useEffect } from 'react';

import Table from '../../../components/Table';
import useFetch from '../../../hooks/useFetch';
import goals from '../../../api/goals';

const headers = [
	{ 
		id: 'name',
		label: 'Type', 
		type: 'string',
		sticky: true,
	},
	{
		id: 'ANP',
		label: 'ANP',
		type: 'string',
		subheaders: [
			{
				id: 'ANPTarget', 
				label: 'Goal',
				type: 'float',
			},
			{
				id: 'ANPValue', 
				label: 'To Date',
				type: 'float',
			},
			{
				id: 'ANPBalance', 
				label: 'Balance',
				type: 'float',
			},
		]
	},
	{
		id: 'SANP',
		label: 'SANP',
		type: 'string',
		subheaders: [
			{
				id: 'SANPTarget',
				label: 'Goal',
				type: 'float',
			},
			{
				id: 'SANPValue', 
				label: 'To Date',
				type: 'float',
			},
			{
				id: 'SANPBalance', 
				label: 'Balance',
				type: 'float',
			},
		]
	},
	{
		id: 'QFYLP',
		label: 'QFYLP',
		type: 'string',
		subheaders: [
			{
				id: 'QFYLPTarget', 
				label: 'Goal',
				type: 'float',
			},
			{
				id: 'QFYLPValue', 
				label: 'To Date',
				type: 'float',
			},
			{
				id: 'QFYLPBalance', 
				label: 'Balance',
				type: 'float',
			},
		]
	},
	{
		id: 'recruitment',
		label: 'RECRUITMENT',
		type: 'string',
		subheaders: [
			{
				id: 'recruitmentTarget', 
				label: 'Goal',
				type: 'int',
			},
			{
				id: 'recruitmentValue', 
				label: 'To Date',
				type: 'int',
			},
			{
				id: 'recruitmentBalance', 
				label: 'Balance',
				type: 'int',
			},
		]
	},
];

function DirectAgents(props) {
	const { onUpdateProgress } = props;
	const [data, setData] = useState([]);
	const [res, status, handleFetch] = useFetch(goals.getDGAgentResult);

	useEffect(() => {
		if(props.year) handleFetch({ year: props.year });
	}, [handleFetch, props.year]);

	useEffect(() => {
		if(status.loaded) {
			if(!status.error) {
				const formattedData = res.goal;
				setData(formattedData);

				const ANPTarget = res.goal.reduce((a, v) => a + v.ANPTarget, 0);
				const ANPValue = res.goal.reduce((a, v) => a + v.ANPValue, 0);

				const SANPTarget = res.goal.reduce((a, v) => a + v.SANPTarget, 0);
				const SANPValue = res.goal.reduce((a, v) => a + v.SANPValue, 0);

				const QFYLPTarget = res.goal.reduce((a, v) => a + v.QFYLPTarget, 0);
				const QFYLPValue = res.goal.reduce((a, v) => a + v.QFYLPValue, 0);

				const recruitmentTarget = res.goal.reduce((a, v) => a + v.recruitmentTarget, 0);
				const recruitmentValue = res.goal.reduce((a, v) => a + v.recruitmentValue, 0);

				const progress = [
					{ 
						label: 'ANP',
						target: ANPTarget,
						current: ANPValue,
						float: true,
					},
					{ 
						label: 'SANP',
						target: SANPTarget,
						current: SANPValue,
						float: true,
					},
					{ 
						label: 'QFYLP',
						target: QFYLPTarget,
						current: QFYLPValue,
						float: true,
					},
					{ 
						label: 'RECRUITMENT',
						target: recruitmentTarget,
						current: recruitmentValue,
						float: false,
					},
				];

				onUpdateProgress(progress);
			} else {
				setData([]);
			}
		}
	}, [res, status.loaded, status.error, props.year, onUpdateProgress]);

	return (
		<Table
			headers={headers}
			data={data}
			isLoading={status.loading}
			disableSearch
		/>
	);
}

export default DirectAgents;
