import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';

import useSnackbarText from '../../../hooks/useSnackbarText';
import useQueryParams from '../../../hooks/useQueryParams';

import group from '../../../api/group';
import { getYearList, getSitemapData } from '../../../utils';
import { generateNumber } from '../../../components/Table/utils';

const sitemapValue = 'GROUP_ACTIVERATIO';
const { label } = getSitemapData(sitemapValue);

const generateFooters = (_submissions, _monthlyActiveAgents) => {
  const months = moment.monthsShort().map(el => el.toUpperCase());
  const submissions = _submissions.map(e => e.submissions);

  let totalNOC = { name: 'Total' };
  let totalActiveAgent = { name: 'Active Agents' };
  let totalManpower = { name: 'Manpowers' };
  let activeRatio = { name: 'Active Ratio', disableParseData: true };
  let productivityRatio = {
    name: 'Productivity Ratio',
    disableParseData: true,
  };
  let averageCaseSize = { name: 'Average Case Size', disableParseData: true };

  let WYTotal = 0;
  let WYANPTotal = 0;
  let WYActiveAgent = 0;

  months.forEach((e, i) => {
    const _totalANP = submissions.reduce((a, v) => a + v[i].ANP, 0);
    const _totalNOC = submissions.reduce((a, v) => a + v[i].NOC, 0);
    const _totalActiveAgent = submissions.filter(e => e[i].NOC).length;
    const _totalManpower = _monthlyActiveAgents[i];
    const _activeRatio = (_totalActiveAgent / _totalManpower) * 100;
    const _productivityRatio = _totalNOC / _totalActiveAgent;

    WYTotal += _totalNOC;
    WYANPTotal += _totalANP;
    WYActiveAgent += _totalActiveAgent;

    totalNOC[e] = _totalNOC;
    totalActiveAgent[e] = _totalActiveAgent;
    totalManpower[e] = _totalManpower;
    activeRatio[e] = generateNumber(_activeRatio) + ' %';
    productivityRatio[e] = generateNumber(_productivityRatio, 2);
    averageCaseSize[e] = generateNumber(_totalANP / (_totalNOC || 1), 2);
  });

  totalNOC.WY = WYTotal;
  totalActiveAgent.WY = WYActiveAgent;
  averageCaseSize.WY = generateNumber(WYANPTotal / (WYTotal || 1), 2);

  let footers = [
    totalNOC,
    totalActiveAgent,
    totalManpower,
    activeRatio,
    productivityRatio,
    averageCaseSize,
  ];

  return footers;
};

const generateHeaders = valueCheck => [
  {
    id: 'name',
    label: 'Name',
    type: 'string',
    sticky: true,
  },
  ...moment.monthsShort().map(e => ({
    id: e.toUpperCase(),
    label: e.toUpperCase(),
    type: 'int',
    valueCheck: valueCheck,
  })),
  {
    id: 'WY',
    label: 'Whole Year',
    type: 'int',
    valueCheck: valueCheck,
  },
];

const firstHeaders = generateHeaders(true);
const secondHeaders = generateHeaders(false);

const useStyles = makeStyles(theme => ({
  select: {
    marginRight: 10,
  },
}));

function ActiveRatio() {
  const classes = useStyles();
  const handleSnackbarChange = useSnackbarText();
  const [queryParams, setQueryParams] = useQueryParams();
  const [data, setData] = useState([]);
  const [footers, setFooters] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleChange = type => event => {
    setQueryParams({ [type]: event.target.value });
  };

  useEffect(() => {
    let tmp = {};
    if (!queryParams.year) tmp.year = moment().year();
    setQueryParams(tmp);
  }, [queryParams.year, setQueryParams]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      let { data, success } = await group.getActiveRatio({
        year: queryParams.year,
      });
      setLoading(false);

      if (success) {
        const formattedData = data.submissions.map(e => {
          let tmp = {
            agentID: e.agentID,
            name: e.name,
          };
          let months = moment.monthsShort().map(el => el.toUpperCase());

          // set monthly submissions
          e.submissions.forEach((el, j) => {
            tmp[months[j]] = el.NOC;
          });
          tmp.WY = e.submissions.reduce((a, v) => a + v.NOC, 0);

          return tmp;
        });

        const formattedFooters = generateFooters(
          data.submissions,
          data.monthlyActiveAgents
        );
        setData(formattedData);
        setFooters(formattedFooters);
      } else {
        setData([]);
        setFooters([]);
        return handleSnackbarChange({ text: data.message, variant: 'error' });
      }
    };

    if (queryParams.year) getData();
  }, [queryParams.year, handleSnackbarChange]);

  return (
    <Layout title={label}>
      <Select
        className={classes.select}
        maxWidth={150}
        label="Year"
        options={getYearList()}
        value={queryParams.year}
        onChange={handleChange('year')}
      />

      <Table headers={firstHeaders} data={data} isLoading={loading} />

      <Table
        headers={secondHeaders}
        data={footers}
        isLoading={loading}
        noIndexNumber
      />
    </Layout>
  );
}

export default ActiveRatio;
