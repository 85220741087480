import React, { useEffect, useState } from 'react';
import { SvgIcon, Typography, makeStyles } from '@material-ui/core';
import Layout from '../../components/Layout';
import fileApi from '../../api/files';
import { ReactComponent as OtherIcon } from '../../assets/icons/icon_others.svg';
import FileList from './FileList';

const useStyles = makeStyles(theme => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  title: {
    marginLeft: 10,
    fontWeight: 'bold',
    fontSize: `1.25rem`,
  },
  spacer: { width: `100%`, height: theme.spacing(3) },
  section: {
    borderRadius: 10,
    padding: theme.spacing(1),
    backgroundColor: `#ffffff`,
    border: `1px solid ${theme.palette.divider}`,
  },
  innerScroll: {
    flex: 1,
    maxHeight: `300px`,
    overflow: 'auto',
  },
}));

export default function ActivityPage() {
  const classes = useStyles();
  const [activities, setActivities] = useState(null);

  useEffect(() => {
    async function getOtherActivities() {
      const result = await fileApi.getFiles({ taxonomy: 'other_activities' });
      if (result.success) {
        setActivities(result.data.files);
      }
    }

    getOtherActivities();
  }, []);

  return (
    <Layout
      title={
        <div className={classes.titleWrapper}>
          <SvgIcon color="primary" component={OtherIcon} viewBox="0 0 48 48" />
          <Typography className={classes.title} variant="subtitle1">
            Other Activities
          </Typography>
        </div>
      }
    >
      {activities && (
        <>
          {activities.length === 0 ? (
            <Typography variant="caption">No Other Activities</Typography>
          ) : (
            <div className={classes.section}>
              <FileList files={activities} />
            </div>
          )}
        </>
      )}
    </Layout>
  );
}
