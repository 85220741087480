import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Radio from '../../../components/Radio';
import TextField from '../../../components/TextField';
import DatePicker from '../../../components/DatePicker';

import useSnackbarText from '../../../hooks/useSnackbarText';

import { 
	tryIsNewClientOptions, 
	tryRecruitmentOptions 
} from '../../../configs/tries';
import { getSitemapData } from '../../../utils';
import tries from '../../../api/tries';
import { maxWidth } from '../../../components/configs';

const dateFormat = 'YYYY-MM-DD';
const sitemapValue = 'PERSONAL_TRACKING';
const { label, fullPathname } = getSitemapData(sitemapValue);

const useStyles = makeStyles(theme => ({
	content: {
		backgroundColor: '#fff',
		padding: 10,
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column'
	},
	submitButton: {
		maxWidth,
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: 20,
	}
}));

function Create() {
	const classes = useStyles();
	const handleSnackbarChange = useSnackbarText();
	const routerHistory = useHistory();
	const [state, _setState] = useState({
		name: '',
		isNewClient: tryIsNewClientOptions[0].value,
		tryDate: moment().format(dateFormat),
		followUpDate1: null,
		followUpDate2: null,
		followUpDate3: null,
		followUpDate4: null,
		followUpDate5: null,
		followUpDate6: null,
		followUpDate7: null,
		ANP: '',
		remark: '',
		recruitment: tryRecruitmentOptions[0].value,
	});
	const [error, setError] = useState({});
	const [disabled, setDisable] = useState(false);

	const setState = useCallback((newState = {}) => {
		_setState((prevState) => ({
			...prevState,
			...newState,
		}));
	}, []);

	const handleValueCheck = () => {
		let noError = true;
		let error = {};
		if(!state.name) {
			noError = false;
			error.name = 'Invalid value.';
		}
		setError(error);
		return noError;
	};

	const handleChange = (type) => (event, value) => {
		if(value !== undefined) setState({ [type]: value });
		else setState({ [type]: event.target.value });
	};

	const handleSubmit = async () => {
		if(!handleValueCheck()) {
			handleSnackbarChange({ text: 'Invalid inputs', variant: 'error' });
			return window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		};

		setDisable(true);
		let { data, success } = await tries.createTry(state);
		setDisable(false);
		if(success) {
			handleSnackbarChange({ text: 'New prospect tracking created', variant: 'success' });
		} else {
			return handleSnackbarChange({ text: data.message, variant: 'error' });
		}
		routerHistory.push(fullPathname);
	};

	return (
		<Layout title={label}>
			<div className={classes.content}>

				<TextField 
					label="Client Name*"
					value={state.name}
					onChange={handleChange('name')}
					error={Boolean(error.name)}
					helperText={error.name}
				/>

				<Radio 
					label="Client"
					options={tryIsNewClientOptions}
					value={state.isNewClient}
					onChange={handleChange('isNewClient')}
					row
				/>

				<DatePicker
					label="Date Opened"
					value={state.tryDate}
					onChange={handleChange('tryDate')}
				/>

				<DatePicker
					label="Follow Up Date 1st"
					value={state.followUpDate1}
					onChange={handleChange('followUpDate1')}
					clearable
				/>

				<DatePicker
					label="Follow Up Date 2nd"
					value={state.followUpDate2}
					onChange={handleChange('followUpDate2')}
					clearable
				/>

				<DatePicker
					label="Follow Up Date 3rd"
					value={state.followUpDate3}
					onChange={handleChange('followUpDate3')}
					clearable
				/>

				<DatePicker
					label="Follow Up Date 4th"
					value={state.followUpDate4}
					onChange={handleChange('followUpDate4')}
					clearable
				/>

				<DatePicker
					label="Follow Up Date 5th"
					value={state.followUpDate5}
					onChange={handleChange('followUpDate5')}
					clearable
				/>

				<DatePicker
					label="Follow Up Date 6th"
					value={state.followUpDate6}
					onChange={handleChange('followUpDate6')}
					clearable
				/>

				<DatePicker
					label="Follow Up Date 7th"
					value={state.followUpDate7}
					onChange={handleChange('followUpDate7')}
					clearable
				/>

				<TextField 
					label="ANP"
					value={state.ANP}
					onChange={handleChange('ANP')}
					type="number"
				/>

				<TextField 
					label="Remarks"
					value={state.remark}
					onChange={handleChange('remark')}
				/>

				<Select 
					label="Recruitment"
					options={tryRecruitmentOptions}
					value={state.recruitment}
					onChange={handleChange('recruitment')}
				/>

				<div className={classes.submitButton}>
					<Button
						disabled={disabled}
						variant="contained"
						color="primary"
						onClick={handleSubmit}
					>
						Submit
					</Button>
				</div>

			</div>
		</Layout>
	);
}

export default Create;
