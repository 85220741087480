import React from 'react';
import { Redirect } from 'react-router-dom';

import configs from '../configs';

function Authentication() {
	let redirect = '/';

	if(!window.localStorage.at || !window.localStorage.rt) {
		redirect = configs.loginPath;
	} else {
		redirect = configs.loginRedirectPath;
	}

	return <Redirect to={redirect} />
}

export default Authentication;
