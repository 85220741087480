const submissionStatusOptions = [
	{ label: 'Underwriting', value: '0' },
	{ label: 'Query', value: '1' },
	{ label: 'Conditional Accept', value: '2' },
	{ label: 'Accept', value: '3' },
	{ label: 'Inforce', value: '4' },
	{ label: 'Postpone', value: '5' },
	{ label: 'Decline', value: '6' },
	{ label: 'Withdraw', value: '7' },
	{ label: 'Lapse', value: '8' },
];

const submissionModeOptions = [
	{ label: 'Monthly', value: '0' },
	{ label: 'Quarterly', value: '1' },
	{ label: 'Half Yearly', value: '2' },
	{ label: 'Yearly', value: '3' },
];

const submissionIsExistingClientOptions = [
	{ label: 'New', value: '0' },
	{ label: 'Existing', value: '1' },
];

const submissionHasNomineeOptions = [
	{ label: 'No', value: '0' },
	{ label: 'Yes', value: '1' },
];

const submissionCarAssistantOptions = [
	{ label: 'No', value: '0' },
	{ label: 'Yes', value: '1' },
	{ label: 'Not Available', value: '2' },
];

const submissionMethodOptions = [
	{ label: 'CC / DC', value: '0' },
	{ label: 'Cheque', value: '1' },
	{ label: 'Cash', value: '2' },
];

const isInforced = (statusID) => {
	return ['4', '8'].includes(String(statusID));
};

export {
	isInforced,
	submissionStatusOptions,
	submissionModeOptions,
	submissionIsExistingClientOptions,
	submissionHasNomineeOptions,
	submissionCarAssistantOptions,
	submissionMethodOptions,
}
