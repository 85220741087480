import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import {
	AppBar,
	Drawer as MuiDrawer,
	Hidden,
	IconButton,
	Toolbar,
	Typography,
	Button
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/core/styles';

import useUserInfo from '../../hooks/useUserInfo';

import agents from '../../api/agents';

import Drawer from './Drawer';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	drawer: {
		[theme.breakpoints.up('md')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	appBar: {
		[theme.breakpoints.up('md')]: {
			width: `calc(100% - ${drawerWidth}px)`,
			marginLeft: drawerWidth,
		},
	},
	menuButton: {
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	// necessary for content to be below app bar
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth,
		paddingBottom: 'max(10px, env(safe-area-inset-bottom))!important',
	},
	content: {
		flexGrow: 1,
		width: `calc(100% - ${drawerWidth}px)`,
		padding: 0,
	},
	container: {
		minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
		backgroundColor: '#fbfbfb'
	},
	nameWrapper: {
		marginLeft: 'auto',
		marginRight: 0,
		display: 'flex',
		alignItems: 'center'
	},
	title: {
		fontSize: 18,
	},
	name: {
		fontSize: 14,
		fontWeight: 200,
		marginRight: 15,
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	backIcon: {
		color: '#fff',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	cancelViewAsButton: {
		backgroundColor: 'rgba(255, 255, 255, 0.3)',
		marginLeft: 10,
		color: 'white',
	}
}));

function Navbar(props) {
	const { container } = props;
	const classes = useStyles();
	const userInfo = useUserInfo();
	const { pathname } = useLocation();
	const routerHistory = useHistory();
	const [title, setTitle] = useState('');
	const [mobileOpen, setMobileOpen] = useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	// update title
	useEffect(() => {
		const pathType = pathname.split('/')[1];
		switch (pathType) {
			case 'personal':
				setTitle('Personal');
				break;
			case 'group':
				setTitle('Whole Group');
				break;
			case 'admin':
				setTitle('Admin');
				break;
			default:
				setTitle('');
				break;
		}
	}, [pathname]);

	return (
		<div className={classes.root}>
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar>
					<Typography className={classes.title} variant="h6" noWrap>
						<IconButton className={classes.backIcon} onClick={routerHistory.goBack}>
							<ChevronLeftIcon />
						</IconButton>
						{title}
					</Typography>
					<div className={classes.nameWrapper}>
						<Typography className={classes.name}>
							{`${userInfo.isViewAs ? 'View As:' : 'Hi,'} ${userInfo.name}`}
							{userInfo.isViewAs && (
								<Button 
									className={classes.cancelViewAsButton}
									size="small"
									onClick={agents.handleCancelViewAs}
								>
									Cancel
								</Button>
							)}
						</Typography>
						<IconButton
							className={classes.menuButton}
							color="inherit"
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
						>
							<MenuIcon />
						</IconButton>
					</div>
				</Toolbar>
			</AppBar>

			<nav className={classes.drawer} aria-label="mailbox folders">
				<Hidden mdUp implementation="css">
					<MuiDrawer
						container={container}
						variant="temporary"
						anchor="right"
						open={mobileOpen}
						onClose={handleDrawerToggle}
						classes={{ paper: classes.drawerPaper }}
						ModalProps={{ keepMounted: true }}
					>
						<Drawer onClose={setMobileOpen} />
					</MuiDrawer>
				</Hidden>
				<Hidden smDown implementation="css">
					<MuiDrawer
						classes={{ paper: classes.drawerPaper }}
						variant="permanent"
						open
					>
						<Drawer />
					</MuiDrawer>
				</Hidden>
			</nav>

			<main className={classes.content}>
				<div className={classes.toolbar} />
				<div className={classes.container}>
					{props.children}
				</div>
			</main>
		</div>
	);
}

Navbar.propTypes = {
	container: PropTypes.any,
};

export default Navbar;
