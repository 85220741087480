import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Checkbox from '../../../components/Checkbox';
import TextField from '../../../components/TextField';
import DatePicker from '../../../components/DatePicker';

import useAgentList from '../../../hooks/useAgentList';
import useSnackbarText from '../../../hooks/useSnackbarText';

import { getSitemapData, isEmail } from '../../../utils';
import { agentRankingOptions } from '../../../configs/agents';
import admin from '../../../api/admin';
import { maxWidth } from '../../../components/configs';

const dateFormat = 'YYYY-MM-DD';
const sitemapValue = 'ADMIN_AGENT';
const { label, fullPathname } = getSitemapData(sitemapValue);

const useStyles = makeStyles(theme => ({
  content: {
    backgroundColor: '#fff',
    padding: 10,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  submitButton: {
    maxWidth,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
}));

function Create() {
  const classes = useStyles();
  const handleSnackbarChange = useSnackbarText();
  const agentList = useAgentList(null, { isIntroducerList: true });
  const routerHistory = useHistory();
  const [state, _setState] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    introducerID: '1',
    joinGEDate: moment().format(dateFormat),
    isActive: true,
    accountNumber: '',
    ranking: agentRankingOptions[5].value,
  });
  const [error, setError] = useState({});
  const [disabled, setDisable] = useState(false);

  const setState = useCallback((newState = {}) => {
    _setState(prevState => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleValueCheck = () => {
    let noError = true;
    let error = {};

    if (!state.name) {
      noError = false;
      error.name = 'Invalid value.';
    }

    if (state.email && !isEmail(state.email)) {
      noError = false;
      error.email = 'Invalid email.';
    }

    if (!state.accountNumber) {
      noError = false;
      error.accountNumber = 'Invalid value.';
    }

    if (!state.introducerID) {
      noError = false;
      error.introducerID = 'Invalid value.';
    }

    setError(error);
    return noError;
  };

  const handleChange = type => (event, value) => {
    if (value !== undefined) setState({ [type]: value });
    else setState({ [type]: event.target.value });
  };

  const handleSubmit = async () => {
    if (!handleValueCheck()) {
      handleSnackbarChange({ text: 'Invalid inputs', variant: 'error' });
      return window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    setDisable(true);
    let { data, success } = await admin.createAgent(state);
    setDisable(false);
    if (success) {
      handleSnackbarChange({ text: 'New agent created', variant: 'success' });
    } else {
      return handleSnackbarChange({ text: data.message, variant: 'error' });
    }
    routerHistory.push(fullPathname);
  };

  return (
    <Layout title={label}>
      <div className={classes.content}>
        <TextField
          label="Agent Name"
          value={state.name}
          onChange={handleChange('name')}
          error={Boolean(error.name)}
          helperText={error.name}
        />

        <TextField
          label="Email"
          value={state.email}
          onChange={handleChange('email')}
          error={Boolean(error.email)}
          helperText={error.email}
        />

        <TextField
          label="Phone Number"
          value={state.phoneNumber}
          onChange={handleChange('phoneNumber')}
        />

        <TextField
          label="Account Number"
          value={state.accountNumber}
          onChange={handleChange('accountNumber')}
          error={Boolean(error.accountNumber)}
          helperText={error.accountNumber}
        />

        <Select
          label="Introducer"
          options={agentList}
          value={state.introducerID}
          onChange={handleChange('introducerID')}
          error={Boolean(error.introducerID)}
          helperText={error.introducerID}
          group
        />

        <DatePicker
          label="Join Date"
          value={state.joinGEDate}
          onChange={handleChange('joinGEDate')}
        />

        <Select
          label="Ranking"
          options={agentRankingOptions}
          value={state.ranking}
          onChange={handleChange('ranking')}
        />

        <Checkbox
          label="Active Agent"
          value={state.isActive}
          onChange={handleChange('isActive')}
        />

        <div className={classes.submitButton}>
          <Button
            disabled={disabled}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Create
          </Button>
        </div>
      </div>
    </Layout>
  );
}

export default Create;
