import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, CircularProgress } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import goal from '../../../api/goals';
import { generateNumber } from '../../../utils';

const useStyles = makeStyles(theme => ({
    card: {
        padding: 15,
        paddingTop: 20,
        paddingBottom: 20,
        borderRadius: 10,
        backgroundColor: `#ffffff`,
        boxShadow: `5px 5px 10px rgb(47 47 47 / 5%)`,
    },
    title: {
        fontWeight: `900`,
    },
    value: {
        fontSize: `2.5rem`,
        fontWeight: `900`
    },
    down: {
        fontSize: `2.5rem`,
        color: theme.palette.error.main,
    },
    up: {
        fontSize: `2.5rem`,
        color: theme.palette.success.main,
    },
    wrapper: {
        marginTop: 10,
        display: `flex`,
        alignItems: 'center',
        justifyContent: `center`,
    },
    percentage: {
        marginTop: 10,
        marginLeft: 5,
    },
}));

const ComparisonCard = (props) => {
    const { 
        label, 
        statusID, 
        startDate, 
        endDate,
        float = false,
    } = props;
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({
        previousValue: 0,
        currentValue: 0,
    });

    useEffect(() => {
        let didCancel = false;

        const main = async () => {
            setIsLoading(true);
            const result = await goal.getActivityComparison({ 
                statusID,
                startDate, 
                endDate,
            });

            if(didCancel) return;

            if(result.success) {
                setData({ ...result.data });
                setIsLoading(false);
            }
        };

        main();

        return () => { didCancel = true };
    }, [statusID, startDate, endDate]);

    
    const diff = Math.abs(data.currentValue - data.previousValue);
    const isSame = data.currentValue === data.previousValue;
    const isPositive = data.currentValue > data.previousValue;
    // const isNegative = data.currentValue < data.previousValue;
    // const percentage = (data.currentValue - data.previousValue) * 100 / data.previousValue;

    return (
        <div className={classes.card}>
            <Typography variant="subtitle1" align="center">
                {label}
            </Typography>

            <div className={classes.wrapper}>
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <React.Fragment>
                        {isSame ? null : (
                            isPositive 
                                ? <ArrowDropUpIcon className={classes.up} />
                                : <ArrowDropDownIcon className={classes.down} />
                        )}
                        <Typography variant="h4" className={classes.value}>
                            {data.currentValue}
                        </Typography>
                        {!isSame && (
                            <Typography variant="caption" className={classes.percentage}>
                                {`(${isPositive ? '+' : '-'}${generateNumber(diff, float ? 2 : 0)})`}
                            </Typography>
                        )}
                    </React.Fragment>
                )}
                
            </div>
        </div>
    );
};

export default ComparisonCard;
