import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { 
	FilledInput,
	InputLabel,
	MenuItem,
	FormControl,
	ListItemText,
	Select,
	ListSubheader,
	Checkbox
} from '@material-ui/core';

import { maxWidth } from '../configs';
import { getObjectFromArray } from '../../utils';

const generateGroupOptions = (groupOptions = []) => {
	let tmp = [];
	for(let i = 0; i < groupOptions.length; i++) {
		const currentOption = groupOptions[i];
		tmp.push({ label: currentOption.label, isSubheader: true });
		for(let j = 0; j < currentOption.options.length; j++) {
			tmp.push({ ...currentOption.options[j], isSubheader: false });
		}
	}
	return tmp;
};

const useStyles = makeStyles((theme) => ({
	formControl: {
		width: '100%',
		maxWidth,
	},
	menuItem: {
		paddingTop: 0,
		paddingBottom: 0,
	},
	noLabel: {
		paddingTop: 10,
		paddingBottom: 10,
	},
	menuPaper: {
		//maxHeight: 300,
		border: '1px solid #efefef',
	},
	subheader: {
		backgroundColor: '#fff',
		borderTop: '1px solid #efefef',
		color: theme.palette.primary.main,
	}
}));

function MultiSelect(props) {
	const classes = useStyles();

	const {
		label,
		value,
		options = [],
		onChange,
		helperText,
		group,
		maxWidth: propMaxWidth,
		...others
	} = props;

	let groupOptions = [];
	if(Boolean(group)) 
		groupOptions = generateGroupOptions(options);

	const renderValue = (selected) => {
		let selectOptions;
		if(Boolean(group)) {
			selectOptions = groupOptions;
		} else {
			selectOptions = options;
		}

		return selected.map(e => getObjectFromArray(selectOptions, e).label).join(', ');
	};

	return (
		<FormControl 
			{...others}
			style={propMaxWidth && { maxWidth: propMaxWidth }}
			className={clsx(classes.formControl, props.className)}
		>
			<InputLabel id={`multi-select-${label}`}>{label}</InputLabel>
			<Select
				labelId={`multi-select-${label}`}
				multiple
				value={value}
				onChange={onChange}
				input={
					<FilledInput 
						name={`label-${label}`} 
						id={`label-${label}`} 
						classes={{ input: clsx(!label && classes.noLabel) }}
					/>
				}
				renderValue={renderValue}
			>
				{label && (
					<MenuItem value="" disabled dense>
						<em>{label}</em>
					</MenuItem>
				)}

				{Boolean(group) && groupOptions.map(option => (
					option.isSubheader ? (
						<ListSubheader 
							className={classes.subheader} 
							key={option.label}
						>
							{option.label}
						</ListSubheader>
					) : (
						<MenuItem 
							key={option.value} 
							className={classes.menuItem}
							value={option.value}
							disabled={Boolean(option.disabled)}
							dense
						>
							<Checkbox checked={value.indexOf(option.value) > -1} size="small" />
							<ListItemText primary={option.label} />
						</MenuItem>
					)
				))}

				{!Boolean(group) && options.map(option => (
					<MenuItem 
						key={option.value} 
						value={option.value}
						disabled={Boolean(option.disabled)}
						dense
					>
						<Checkbox checked={value.indexOf(option.value) > -1} size="small" />
						<ListItemText primary={option.label} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}

export default MultiSelect