import request from './request';

function getMonthlySubmission(data) {
  return request({
    url: '/group/monthly-submission',
    method: 'GET',
    params: data,
  });
}

function getPerformance(data) {
  return request({
    url: '/group/performance',
    method: 'GET',
    params: data,
  });
}

function getPerformanceSummary(data) {
  return request({
    url: '/group/performance/summary',
    method: 'GET',
    params: data,
  });
}

function getContests(data) {
  return request({
    url: '/group/contests',
    method: 'GET',
    params: data,
  });
}

function getContestResult(data) {
  return request({
    url: `/group/contests/${data.contestID}`,
    method: 'GET',
  });
}

function getCustomContestResult(data) {
  return request({
    url: `/group/custom-contests`,
    method: 'GET',
    params: data,
  });
}

function getActiveRatio(data) {
  return request({
    url: '/group/active-ratio',
    method: 'GET',
    params: data,
  });
}

function getMonthlyProspect(data) {
  return request({
    url: '/group/monthly-prospect',
    method: 'GET',
    params: data,
  });
}

function getCasesStatus(data) {
  return request({
    url: '/group/cases-status',
    method: 'GET',
    params: data,
  });
}

function getInforcedPerformance(data) {
  return request({
    url: '/group/inforced-performance',
    method: 'GET',
    params: data,
  });
}

function getInforcedPerformanceSummary(data) {
  return request({
    url: '/group/inforced-performance/summary',
    method: 'GET',
    params: data,
  });
}

function getMSA(data) {
  return request({
    url: '/group/msa',
    method: 'GET',
    params: data,
  });
}

function getMSASummary(data) {
  return request({
    url: '/group/msa/summary',
    method: 'GET',
    params: data,
  });
}

function getSSA(data) {
  return request({
    url: '/group/ssa',
    method: 'GET',
    params: data,
  });
}

function getTries(data) {
  return request({
    url: '/group/tries',
    method: 'GET',
    params: data,
  });
}

function getProspects(data) {
  return request({
    url: '/group/prospects',
    method: 'GET',
    params: data,
  });
}

function getProspectSummary(data) {
  return request({
    url: '/group/prospects/summary',
    method: 'GET',
    params: data,
  });
}

function getPlanPerformance(data) {
  return request({
    url: '/group/plan-performance',
    method: 'GET',
    params: data,
  });
}

function getWeeklySubmission(data) {
  return request({
    url: '/group/weekly-submission',
    method: 'GET',
    params: data,
  });
}

function getQFYLP(data) {
  return request({
    url: '/group/qfylp',
    method: 'GET',
    params: data,
  });
}

function getKPI(data) {
  return request({
    url: '/group/kpi',
    method: 'GET',
    params: data,
  });
}

function getDiaryPerformance(data) {
  return request({
    url: '/group/diary-performance',
    method: 'GET',
    params: data,
  });
}

function getDiaryPerformanceSummary(data) {
  return request({
    url: '/group/diary-performance/summary',
    method: 'GET',
    params: data,
  });
}

export default {
  getMonthlySubmission,
  getPerformance,
  getContests,
  getContestResult,
  getCustomContestResult,
  getActiveRatio,
  getPerformanceSummary,
  getMonthlyProspect,
  getCasesStatus,
  getInforcedPerformance,
  getInforcedPerformanceSummary,
  getMSA,
  getMSASummary,
  getSSA,
  getTries,
  getProspects,
  getProspectSummary,
  getPlanPerformance,
  getWeeklySubmission,
  getQFYLP,
  getKPI,
  getDiaryPerformance,
  getDiaryPerformanceSummary,
};
