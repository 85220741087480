import request from './request';

function createAgent(data) {
  return request({
    url: '/agents',
    method: 'POST',
    data,
  });
}

function getAgents(data) {
  return request({
    url: '/agents',
    method: 'GET',
    params: data,
  });
}

function getAgent(data) {
  return request({
    url: `/agents/${data.agentID}`,
    method: 'GET',
  });
}

function updateSortOrders(data) {
  return request({
    url: '/agents/sort-orders',
    method: 'PUT',
    data,
  });
}

function updateAgent(data) {
  const { agentID, ...others } = data;
  return request({
    url: `/agents/${agentID}`,
    method: 'PUT',
    data: others,
  });
}

function getAgentStates(data) {
  return request({
    url: `/agents/${data.agentID}/agent-states`,
    method: 'GET',
  });
}

function updateAgentStates(data) {
  const { agentID, ...others } = data;
  return request({
    url: `/agents/${agentID}/agent-states`,
    method: 'PUT',
    data: others,
  });
}

function updatePasswordByAdmin(data) {
  const { agentID, ...others } = data;
  return request({
    url: `/agents/${agentID}/password`,
    method: 'PUT',
    data: others,
  });
}

function getDirectGroupLeaders(data) {
  return request({
    url: `/agents/direct-group-leaders`,
    method: 'GET',
    params: data,
  });
}

function getViewAsToken(data) {
  return request({
    url: `/agents/${data.agentID}/view-as-token`,
    method: 'GET',
  });
}

export default {
  createAgent,
  getAgents,
  getAgent,
  updateSortOrders,
  updateAgent,
  getAgentStates,
  updateAgentStates,
  updatePasswordByAdmin,
  getDirectGroupLeaders,
  getViewAsToken,
};
