import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';

const generateDatesOfMonth = (_year, _month, startingDayOffset = 0) => {
	// init
	const year = _year === undefined ? moment().year() : _year;
	const month = _month === undefined ? moment().month() : _month;

	// create start blank area
	const firstDateOfMonth = moment(`${year}-01-01`).month(month);
	const firstDayOfMonth = parseInt(firstDateOfMonth.format('d')) - startingDayOffset;
	const startBlanks = Array(firstDayOfMonth < 0 ? 7 + firstDayOfMonth : firstDayOfMonth).fill('');

	// create end blank area
	const endDateOfMonth = moment(firstDateOfMonth).endOf('month');
	const endDayOfMonth = parseInt(endDateOfMonth.format('d')) - startingDayOffset;;
	const endBlanks = Array(7 - (endDayOfMonth < 0 ? 7 + endDayOfMonth : endDayOfMonth)).fill('');

	const totalDays = firstDateOfMonth.daysInMonth();
	const dates = Array(totalDays)
		.fill('')
		.map((_, i) => moment(firstDateOfMonth).date(i + 1).format(dateFormat));

	let rows = [], cells = [];
	const totalSlots = [...startBlanks, ...dates, ...endBlanks];
	totalSlots.forEach((row, i) => {
		if(i % 7 !== 0) {
			cells.push(row);
		} else {
			rows.push(cells);
			cells = [row];
		}
	});
	return rows;
}

export default {
	generateDatesOfMonth,
}
