import React, { useCallback } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { TextField as MuiTextField } from '@material-ui/core';

import { maxWidth } from '../configs';

const emptyFunction = () => {};

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 10,
    width: '100%',
    maxWidth,
  },
  noLabel: {
    '& input': {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
}));

function TextField(props) {
  const classes = useStyles();
  const { onEnterPress, className, maxWidth, ...others } = props;

  const handleKeyPress = useCallback(
    ({ key }) => {
      if (onEnterPress && key === 'Enter') onEnterPress();
    },
    [onEnterPress]
  );

  return (
    <MuiTextField
      className={clsx(classes.root, !props.label && classes.noLabel, className)}
      margin="dense"
      variant="filled"
      onKeyPress={handleKeyPress || emptyFunction}
      style={{ maxWidth }}
      {...others}
    />
  );
}

export default TextField;
