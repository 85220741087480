import React from 'react';
import { context } from '../configs';

const initialState = {
	name: '',
	avatar: '',
	userID: '',
	email: '',
	isAuthorized: false,
	isViewAs: false,
	isAdmin: false,
};

let defaultUserContext;
try {
	defaultUserContext = JSON.parse(window.localStorage.getItem(context.user));
} catch(err) {
	console.log('No user info found');
	defaultUserContext = initialState;
}

const userContext = React.createContext(defaultUserContext);

export {
	defaultUserContext,
	userContext,
	initialState,
};
