import request from './request';

function getSubmission(data) {
	return request({
		url: `/submissions/${data.submissionID}`,
		method: 'GET',
	});
}

function createSubmission(data) {
	return request({
		url: '/submissions',
		method: 'POST',
		data,
	});
}

function updateSubmission(data) {
	const { submissionID, ...others } = data;
	return request({
		url: `/submissions/${data.submissionID}`,
		method: 'PUT',
		data: others,
	});
}

function deleteSubmission(data) {
	return request({
		url: `/submissions/${data.submissionID}`,
		method: 'DELETE',
	});
}

function getSubmissions(data) {
	return request({
		url: '/submissions',
		method: 'GET',
		params: data,
	});
}

function getPerformance(data) {
	return request({
		url: '/submissions/performance',
		method: 'GET',
		params: data,
	});
}

function getQFYLPSummary(data) {
	return request({
		url: '/submissions/qfylp/summary',
		method: 'GET',
		params: data,
	});
}

function getSubmissionQFYLP(data) {
	return request({
		url: `/submissions/qfylp/${data.submissionID}`,
		method: 'GET',
	});
}

function updateSubmissionQFYLP(data) {
	const { submissionID, ...others } = data;
	return request({
		url: `/submissions/qfylp/${data.submissionID}`,
		method: 'PUT',
		data: others,
	});
}

export default {
	getSubmission,
	createSubmission,
	updateSubmission,
	deleteSubmission,
	getSubmissions,
	getPerformance,
	getQFYLPSummary,
	getSubmissionQFYLP,
	updateSubmissionQFYLP
};
