import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { generateNumber } from '../../../utils';

// type Props = {
//     label: string;
//     target: number;
//     current: number;
//     float?: boolean;
// };

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
    },
    progressRoot: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
    },
    progressContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    progressWrapper: {
        position: 'relative',
        flex: '1 1 100%',
        height: 15,
        width: '100%',
        borderRadius: 7,
        backgroundColor: '#e4e4e4',
    },
    progressBar: {
        height: '100%',
        borderRadius: 7,
        transition: 'all 0.5s',
        backgroundColor: theme.palette.success.main,
    },
    progressValueText: {
        position: 'absolute',
        right: 0,
        bottom: '100%',
        marginBottom: -2,
    },
    progressPercentageTextWrapper: {
        paddingLeft: theme.spacing(2),
        flex: '0 0 70px',
    },
    progressPercentageText: {
        fontWeight: 'bold',
        color: theme.palette.success.main,
    },
}));

function GoalProgressBar(props) {
    const {
        label = '',
        target = 0,
        current = 0,
        float = false,
    } = props;
    const classes = useStyles();


    let percentage = current / target * 100;
    if(percentage > 100) {
        percentage = 100;
    } else if(current === 0) {
        percentage = 0;
    } else if(target === 0) {
        percentage = 100;
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} sm={4} md={3} lg={2}>
                <Typography variant="subtitle2">
                    {label}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={9} lg={10} className={classes.progressRoot}>
                <div className={classes.progressContainer}>
                    <div className={classes.progressWrapper}>
                        <div className={classes.progressBar} style={{ width: `${percentage}%` }} />
                        <div className={classes.progressValueText}>
                            <Typography variant="caption">
                                {`${generateNumber(current, float ? 2 : 0)} / ${generateNumber(target, float ? 2 : 0)}`}
                            </Typography>
                        </div>
                    </div>

                    <div className={classes.progressPercentageTextWrapper}>
                        <Typography variant="subtitle2" className={classes.progressPercentageText}>
                            {`${generateNumber(percentage, float ? 2 : 0)}%`}
                        </Typography>
                    </div>
                </div>
            </Grid>

        </Grid>
    )
}

export default GoalProgressBar;
