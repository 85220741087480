import React, { useState, useCallback, Fragment } from 'react';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '../../../components/TextField';

import useQueryParams from '../../../hooks/useQueryParams';
import useSnackbarText from '../../../hooks/useSnackbarText';

import admin from '../../../api/admin';
import { maxWidth } from '../../../components/configs';

const useStyles = makeStyles(theme => ({
  submitButton: {
    maxWidth,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 20,
  },
  loading: {
    marginTop: 50,
    marginBottom: 50,
    color: '#c7c7c7',
  },
  title: {
    fontSize: 18,
    color: theme.palette.primary.main,
  },
}));

function PasswordSection() {
  const classes = useStyles();
  const handleSnackbarChange = useSnackbarText();
  const [queryParams] = useQueryParams();
  const [state, _setState] = useState({
    password: '',
  });
  const [error, setError] = useState({});
  const [disabled, setDisable] = useState(false);

  const setState = useCallback((newState = {}) => {
    _setState(prevState => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleValueCheck = () => {
    let noError = true;
    let error = {};
    if (!state.password) {
      noError = false;
      error.password = 'Invalid value.';
    }
    setError(error);
    return noError;
  };

  const handleChange = type => (event, value) => {
    if (value !== undefined) setState({ [type]: value });
    else setState({ [type]: event.target.value });
  };

  const handleSubmit = async () => {
    if (!handleValueCheck()) {
      handleSnackbarChange({ text: 'Invalid inputs', variant: 'error' });
      return window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    setDisable(true);
    let { data, success } = await admin.updatePasswordByAdmin({
      ...state,
      agentID: queryParams.agentID,
    });
    setDisable(false);
    if (success) {
      handleSnackbarChange({ text: 'Agent updated', variant: 'success' });
    } else {
      return handleSnackbarChange({ text: data.message, variant: 'error' });
    }
  };

  return (
    <Fragment>
      <Typography className={classes.title} variant="h5">
        Change Password
      </Typography>
      <TextField
        label="New password"
        value={state.password}
        onChange={handleChange('password')}
        error={Boolean(error.password)}
        helperText={error.password}
      />

      <div className={classes.submitButton}>
        <Button
          disabled={disabled}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Update
        </Button>
      </div>
    </Fragment>
  );
}

export default PasswordSection;
