import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { IconButton, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';
import Dialog from '../../../components/Dialog';

import Summary from './Summary';

import useSnackbarText from '../../../hooks/useSnackbarText';
import useQueryParams from '../../../hooks/useQueryParams';

import prospects from '../../../api/prospects';
import { 
	getSitemapData, 
	getYearList, 
	getObjectFromArray,
} from '../../../utils';
import { 
	prospectResourceOptions,
	prospectOutcomeOptions,
} from '../../../configs/prospects';

const yearToDate = 'YEAR_TO_DATE';
const dateFormat = 'YYYY-MM-DD';
const sitemapValue = 'PERSONAL_PROSPECT';
const { fullPathname, label } = getSitemapData(sitemapValue);

let monthOptions = moment.monthsShort();
monthOptions = [
	{ label: 'Year To Date', value: yearToDate },
	...monthOptions.map((e, i) => ({ label: e, value: i + 1 })),
];

const headers = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
	},
	{
		id: 'prospectDate',
		label: 'Date',
		type: 'date',
	},
	{ 
		id: 'phone',
		label: 'Handphone', 
		type: 'string',
	},
	{ 
		id: 'otherContact',
		label: 'Other Contact', 
		type: 'string',
	},
	{ 
		id: 'resource',
		label: 'Resource', 
		type: 'string',
	},
	{ 
		id: 'outcome',
		label: 'Outcome', 
		type: 'string',
	},
	{ 
		id: 'remark',
		label: 'Remark', 
		type: 'string',
	},
	{ 
		id: 'introducer',
		label: 'Introducer', 
		type: 'string',
	},
	{ 
		id: 'edit',
		label: 'Edit', 
		type: 'dom',
	},
	{ 
		id: 'remove',
		label: 'Delete', 
		type: 'dom',
	},
];

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	},
	summaryButton: {
		marginTop: 14,
		marginLeft: 5,
	},
	dialogContentWrapper: {
		display: 'flex',
		justifyContent: 'space-evenly',
		marginTop: 10,
		marginBottom: 10,
	}
}));

function Main() {
	// eslint-disable-next-line
	const classes = useStyles();
	const handleSnackbarChange = useSnackbarText();
	const [queryParams, setQueryParams] = useQueryParams();
	const [data, setData] = useState([]);
	const [timestamp, setTimestamp] = useState(moment().unix())
	const [year, setYear] = useState(
		(queryParams.startDate && moment(queryParams.startDate).year())
		|| moment().year()
	);
	const [month, setMonth] = useState(() => {
		if(queryParams.startDate && queryParams.endDate) {
			const { startDate, endDate } = queryParams;
			if(moment(startDate).month() === moment(endDate).month()) 
				return moment(startDate).month() + 1;
			else return yearToDate;
		}
		return moment().month() + 1;
	});

	const handleYearChange = (event) => {
		setYear(event.target.value);
	};

	const handleMonthChange = (event) => {
		setMonth(event.target.value);
	};

	const handleShowSummary = () => {
		setQueryParams({ summary: 'true' });
	};

	const handleDelete = useCallback((prospectID) => async () => {
		let { data, success } = await prospects.deleteProspect({ prospectID });
		if(!success) {
			return handleSnackbarChange({ text: data.message, variant: 'error' });
		} else {
			handleSnackbarChange({ text: 'Prospect deleted', variant: 'success' });
			setTimestamp(moment().unix());
		}
		setQueryParams({ deleteID: null });
	}, [handleSnackbarChange, setQueryParams]);

	const handleSetDeleteID = useCallback((prospectID) => () => {
		setQueryParams({ deleteID: prospectID });
	}, [setQueryParams]);

	useEffect(() => {
		let startDate, endDate;
		if(month === yearToDate) {
			startDate = moment(`${year}-01-01`).format(dateFormat);
			endDate = moment(`${year}-12-31`).format(dateFormat);
		} else {
			startDate = moment(`${year}-01-01`).month(parseInt(month) - 1).startOf('month').format(dateFormat);
			endDate = moment(`${year}-01-01`).month(parseInt(month) - 1).endOf('month').format(dateFormat);
		}
		setQueryParams({ startDate, endDate });
	}, [year, month, setQueryParams]);

	useEffect(() => {
		const getData = async () => {
			const { success, data } = await prospects.getProspects({ 
				startDate: queryParams.startDate,
				endDate: queryParams.endDate,
			});
			if(!success) {
				return handleSnackbarChange({ text: data.message, variant: 'error' });
			} else {
				console.log(data.prospects);
				setData(data.prospects.map(e => ({
					...e,
					resource: getObjectFromArray(prospectResourceOptions, e.resource).label,
					outcome: getObjectFromArray(prospectOutcomeOptions, e.outcome).label,
					edit: 
						<IconButton
							component={Link}
							to={`${fullPathname}/update?prospectID=${e.prospectID}`}
						>
							<EditIcon />
						</IconButton>,
					remove: 
						<IconButton  onClick={handleSetDeleteID(e.prospectID)}>
							<DeleteIcon />
						</IconButton>,
				})));
			}
		}
		if(queryParams.startDate && queryParams.endDate) getData();
	}, [queryParams.startDate, queryParams.endDate, handleSnackbarChange, timestamp, handleSetDeleteID]);

	return (
		<Layout title={label} createPathname={fullPathname + '/create'}>
			<Select 
				className={classes.select}
				maxWidth={150}
				label="Year"
				options={getYearList()}
				value={String(year)}
				onChange={handleYearChange}
			/>

			<Select
				maxWidth={150}
				label="Month"
				value={String(month)}
				options={monthOptions}
				onChange={handleMonthChange}
			/>

			<Button 
				className={classes.summaryButton} 
				onClick={handleShowSummary} 
				color="primary"
				startIcon={<DescriptionIcon />}
				variant="outlined"
			>
				Summary
			</Button>

			<Table
				headers={headers}
				data={data}
			/>

			<Dialog queryParamKey="summary" title="Summary" maxWidth="100%">
				<Summary />
			</Dialog>

			<Dialog title="Delete this prospect list?" queryParamKey="deleteID">
				<div className={classes.dialogContentWrapper}>
					<Button variant="contained" color="primary" onClick={handleDelete(queryParams.deleteID)}>Delete</Button>
					<Button onClick={() => setQueryParams({ deleteID: null })}>Cancel</Button>
				</div>
			</Dialog>
		</Layout>
	);
}

export default Main;
