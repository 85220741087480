import { useEffect, useState } from 'react';

import useSnackbarText from './useSnackbarText';
import plans from '../api/plans';

function usePlanList() {
	const [planList, setPlanList] = useState([]);
	const handleSnackbarChange = useSnackbarText();
	
	// fetch plans
	useEffect(() => {
		const getPlans = async () => {
			let { data, success } = await plans.getPlans();
			if(success) {
				const currentPlans = data.plans.filter(e => Boolean(e.status));
				const oldPlans = data.plans.filter(e => !Boolean(e.status));

				const planList = [
					{
						label: 'Active Plans',
						options: currentPlans.map(e => ({
							label: e.name + (e.remark ? ` (${e.remark})` : ''),
							value: String(e.planID),
						})),
					},
					{
						label: 'Old Plans',
						options: oldPlans.map(e => ({
							label: e.name + (e.remark ? ` (${e.remark})` : ''),
							value: String(e.planID),
						})),
					}
				];

				setPlanList(planList);
			} else {
				return handleSnackbarChange({ text: data.message, variant: 'error' });
			}
		}
		getPlans();
	}, [handleSnackbarChange]);
	return planList;
}

export default usePlanList;
