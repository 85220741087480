import request from './request';

function createCompanyEvent(data) {
  const formData = new FormData();

  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }

  return request({
    url: '/company-events',
    method: 'POST',
    data: formData,
    headers: { 'content-type': 'multipart/form-data' },
  });
}

function updateCompanyEvent({ eventID, ...data }) {
  const formData = new FormData();

  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }

  return request({
    url: `/company-events/${eventID}`,
    method: 'PUT',
    data: formData,
    headers: { 'content-type': 'multipart/form-data' },
  });
}

function getCompanyEvents() {
  return request({
    url: '/company-events',
    method: 'GET',
  });
}

function getCompanyEvent(eventID) {
  return request({
    url: `/company-events/${eventID}`,
    method: 'GET',
  });
}

function deleteCompanyEvent(eventID) {
  return request({
    url: `/company-events/${eventID}`,
    method: 'DELETE',
  });
}

export default {
  createCompanyEvent,
  updateCompanyEvent,
  getCompanyEvents,
  getCompanyEvent,
  deleteCompanyEvent,
};
