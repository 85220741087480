import request from './request';

function getMSA(data) {
	return request({
		url: '/msa',
		method: 'GET',
		params: data,
	});
}


function updateMSA(data) {
	return request({
		url: '/msa',
		method: 'PUT',
		data,
	});
}

export default {
	getMSA,
	updateMSA,
};
