import React, { useState, useEffect } from 'react';

import Table from '../../../components/Table';

import useQueryParams from '../../../hooks/useQueryParams';
import useFetch from '../../../hooks/useFetch';

import prospects from '../../../api/prospects';
import { getFooterTotal } from '../../../utils';

const headers = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
		disableSort: true,
	},
	{
		id: 'count',
		label: 'Count',
		type: 'int',
	},
];

function Summary() {
	const [queryParams] = useQueryParams();
	const [data, setData] = useState([]);
	const [footers, setFooters] = useState([]);
	const [res, status, handleFetch] = useFetch(prospects.getProspectIntroducers);

	useEffect(() => {
		if(queryParams.startDate && queryParams.endDate) {
			handleFetch({
				startDate: queryParams.startDate,
				endDate: queryParams.endDate,
			});
		}
	}, [handleFetch, queryParams.startDate, queryParams.endDate]);

	useEffect(() => {
		if(status.loaded) {
			if(!status.error) {
				const formattedData = res.introducers.map(e => ({
					name: e.label,
					count: e.value,
				}));
				setData(formattedData);
				setFooters([{
					name: 'Total',
					...getFooterTotal(headers, formattedData),
				}]);
			} else {
				setData([]);
				setFooters([]);
			}
		}
	}, [res, status.loaded, status.error]);

	return (
		<Table
			headers={headers}
			data={data}
			footers={footers}
			isLoading={status.loading}
		/>
	);
}

export default Summary;
