import React, { useEffect, useState } from 'react';
import { SvgIcon, Typography, makeStyles } from '@material-ui/core';
import Layout from '../../components/Layout';
import fileApi from '../../api/files';
import { ReactComponent as SalesReportIcon } from '../../assets/icons/icon_report.svg';

import FileList from './FileList';

const useStyles = makeStyles(theme => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  title: {
    marginLeft: 10,
    fontWeight: 'bold',
    fontSize: `1.25rem`,
  },
  spacer: { width: `100%`, height: theme.spacing(3) },
  section: {
    borderRadius: 10,
    padding: theme.spacing(1),
    backgroundColor: `#ffffff`,
    border: `1px solid ${theme.palette.divider}`,
  },
  innerScroll: {
    flex: 1,
    maxHeight: `300px`,
    overflow: 'auto',
  },
}));

export default function SalesReportPage() {
  const classes = useStyles();
  const [reports, setReports] = useState(null);

  useEffect(() => {
    async function getReports() {
      const result = await fileApi.getFiles({ taxonomy: 'sales_report' });
      if (result.success) {
        setReports(result.data.files);
      }
    }

    getReports();
  }, []);

  return (
    <Layout
      title={
        <div className={classes.titleWrapper}>
          <SvgIcon
            color="primary"
            component={SalesReportIcon}
            viewBox="0 0 48 48"
          />
          <Typography className={classes.title} variant="subtitle1">
            Sales Report
          </Typography>
        </div>
      }
    >
      {reports && (
        <>
          {reports.length === 0 ? (
            <Typography variant="caption">No Sales Report</Typography>
          ) : (
            <div className={classes.section}>
              <FileList files={reports} />
            </div>
          )}
        </>
      )}
    </Layout>
  );
}
