import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography } from '@material-ui/core';

import Layout from '../../../components/Layout';
import DatePicker from '../../../components/DatePicker';
import useSnackbarText from '../../../hooks/useSnackbarText';
import useQueryParams from '../../../hooks/useQueryParams';
import { getSitemapData } from '../../../utils';
import diary from '../../../api/diary';

const dateFormat = 'YYYY-MM-DD';
const dateColumnWidth = 150;
const statusColumnWidth = 250;

const configs = {
    sales: [
        {
            label: ['Services', 'Meal Appointment'],
            value: ['8', '5'],
        },
        {
            label: ['Fact Finding', 'Policy Review', 'Follow Up'],
            value: ['3', '4', '7'],
        },
        {
            label: ['Open'],
            value: ['1'],
        },
        {
            label: ['Closing'],
            value: ['2'],
        },
    ],
    recruitment: [
        {
            label: ['Appointment'],
            value: ['6'],
        },
        {
            label: ['Interview'],
            value: ['9'],
        },
        {
            label: ['Follow Up'],
            value: ['10'],
        },
        {
            label: ['Closing'],
            value: ['11'],
        },
    ],
};

const inColumn = (columnStatus = []) => (status) => {
    const allStatus = status.split(',');
    return allStatus.filter(s => columnStatus.includes(s)).length > 0;
};

const getEventData = (type = 'sales', diaryData = []) => {
    const columns = configs[type];

    let eventData = [];

    diaryData.forEach(data => {
        // loop thru every day
        const date = data.date;

        data.events.forEach(event => {
            const shouldExist = columns.filter(c => inColumn(c.value)(event.status)).length > 0;

            if(!shouldExist) return;

            const columnData = columns.map(c => {
                if(!inColumn(c.value)(event.status)) {
                    return null;
                }

                return {
                    name: event.name,
                    remarks: event.remarks,
                };
            })

            const currentData = { 
                date: moment(date).toDate(),
                columns: columnData,
            };

            eventData = [...eventData, currentData]
        });
    });

    return eventData;
};

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	},
    container: {
        backgroundColor: `#ffffff`,
    },
    tabs: {},
	tab: {},
	selected: {
		color: theme.palette.primary.main,
		fontWeight: 600,
	},
    tabContainer: {
        overflow: `auto`,
		scrollSnapType: 'both mandatory',
		WebkitOverflowScrolling: 'touch',
        borderTop: `1px solid ${theme.palette.divider}`,
    },
    row: {
        display: `flex`,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    headerDateItem: {
        padding: 10,
        paddingTop: 5,
        paddingBottom: 5,
        flex: `0 0 ${dateColumnWidth}px`,
        position: `sticky`,
        left: 0,
        backgroundColor: `#ffffff`,
    },
    headerItem: {
        padding: 10,
        paddingTop: 5,
        paddingBottom: 5,
        flex: `1 0 ${statusColumnWidth}px`,
		scrollMargin: 0,
        scrollMarginLeft: `${dateColumnWidth}px`,
		scrollSnapStop: 'normal',
		scrollSnapAlign: 'start',
        display: `flex`,
        flexDirection: `column`,
    },
    dataDateItem: {
        padding: 10,
        paddingTop: 5,
        paddingBottom: 5,
        borderRight: `1px solid #ebebeb`,
        flex: `0 0 ${dateColumnWidth}px`,
        position: `sticky`,
        left: 0,
        backgroundColor: `#ffffff`,
    },
    dataItem: {
        padding: 10,
        paddingTop: 5,
        paddingBottom: 5,
        flex: `1 0 ${statusColumnWidth}px`,
        scrollMarginLeft: `${dateColumnWidth}px`,
        scrollSnapStop: 'normal',
        scrollSnapAlign: 'start',
        '&:nth-child(even)': {
            backgroundColor: `#ebebeb`,
        }
    },
    noFoundText: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

const sitemapValue = 'PERSONAL_OPPORTUNITY';
const { label } = getSitemapData(sitemapValue);

const Opportunity = () => {
	const classes = useStyles();
	const handleSnackbarChange = useSnackbarText();
    const [diaryData, setDiaryData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState('sales');
	const [queryParams, setQueryParams] = useQueryParams();

    const handleChange = (type) => (event, value) => {
		setQueryParams({ [type]: value });
	};

    const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

    // set up initial payload
	useEffect(() => {
		let tmp = {};
		if(!queryParams.startDate) tmp.startDate = moment().startOf('month').format(dateFormat);
		if(!queryParams.endDate) tmp.endDate = moment().endOf('month').format(dateFormat);
		setQueryParams(tmp);
	}, [queryParams.startDate, queryParams.endDate, setQueryParams]);

    useEffect(() => {
        if(!queryParams.startDate) return;
        if(!queryParams.endDate) return;
        
        let didCancel = false;

        const main = async () => {
            setIsLoading(true);
            const { success, data } = await diary.getDiary({
                startDate: queryParams.startDate,
                endDate: queryParams.endDate,
            });

            if(didCancel) return;

            setIsLoading(false);

            if(!success) {
                return handleSnackbarChange({ text: data.message, variant: 'error' });
            }

            setDiaryData(data.diary);
        };

        main();

        return () => { didCancel = true };
    }, [queryParams.startDate, queryParams.endDate, handleSnackbarChange]);

    const numberOfColumns = configs[selectedTab].length;
    const data = getEventData(selectedTab, diaryData);

    return (
        <Layout title={label} ActionComponent={
            <div>
                <DatePicker 
                    className={classes.select}
                    label="Start Date"
                    value={queryParams.startDate}
                    onChange={handleChange('startDate')}
                    autoApply
                />

                <DatePicker 
                    className={classes.select}
                    label="End Date"
                    value={queryParams.endDate}
                    onChange={handleChange('endDate')}
                    autoApply
                />
            </div>
        }>
            <div className={classes.container}>
                <Tabs 
					className={classes.tabs}
					value={selectedTab} 
					onChange={handleTabChange} 
					indicatorColor="primary" 
					variant="scrollable"
				>
                    <Tab 
                        label="Sales" 
                        value="sales" 
                        classes={{ 
                            root: classes.tab,
                            selected: classes.selected 
                        }}
                    />
                    <Tab 
                        label="Recruitment" 
                        value="recruitment"
                        classes={{ 
                            root: classes.tab,
                            selected: classes.selected 
                        }}
                    />
				</Tabs>

                <div className={classes.tabContainer}>
                    <div className={classes.row} style={{
                        width: dateColumnWidth + numberOfColumns * statusColumnWidth,
                    }}>
                        <div className={classes.headerDateItem}>
                            <Typography variant="caption">Date</Typography>
                        </div>

                        {configs[selectedTab].map((c, i) => (
                            <div key={i} className={classes.headerItem}>
                                {c.label.map(l => (
                                    <Typography variant="caption" key={l}>{l}</Typography>    
                                ))}
                            </div>
                        ))}
                    </div>

                    {!isLoading && data.length === 0 && (
                        <Typography align="center" variant="subtitle1" className={classes.noFoundText}>
                            No data found
                        </Typography>
                    )}

                    {data.map((d, i) => (
                        <div className={classes.row} key={i} style={{
                            width: dateColumnWidth + numberOfColumns * statusColumnWidth,
                        }}>
                            <div className={classes.dataDateItem}>{moment(d.date).format('DD MMM YYYY')}</div>
                            {d.columns.map((item, j) => (
                                <div className={classes.dataItem} key={j}>
                                    {item && (
                                        <React.Fragment>
                                            <Typography>{item.name}</Typography>
                                            <Typography variant="caption">{item.remarks}</Typography>
                                        </React.Fragment>
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    );
}

export default Opportunity;
