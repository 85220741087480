import React, { Fragment } from 'react';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '../TextField';

import { maxWidth } from '../configs';

const useStyles = makeStyles(theme => ({
	root: {
		borderRadius: 10,
		width: '100%',
		maxWidth,
	},
}));

function Autocomplete(props) {
	const classes = useStyles();
	const { onChange, value, options, label, loading } = props;

	const handleChange = (event, value) => {
		// workaround prevent initial reset
		if(event) onChange(event, value);
	};

	return (
		<MuiAutocomplete
			className={classes.root}
			getOptionLabel={option => option.label || ''}
			freeSolo
			options={options || []}
			onInputChange={handleChange}
			inputValue={value}
			renderInput={params => (
				<TextField
					{...params}
					label={label}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<Fragment>
								{loading && <CircularProgress color="inherit" size={20} />}
								{params.InputProps.endAdornment}
							</Fragment>
						),
					}}
				/>
			)}
		/>
	);
}

export default Autocomplete;
