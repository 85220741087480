import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

import TextField from '../components/TextField';
import Select from '../components/Select';
import DatePicker from '../components/DatePicker';
import Checkbox from '../components/Checkbox';
import Radio from '../components/Radio';
import MultiSelect from '../components/MultiSelect';

const useStyles = makeStyles(theme => ({
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	component: {
		margin: 10,
		border: '1px solid red',
	}
}));

const defaultState = {
	text: '',
	select: '',
	date: null,
	checkbox: false,
	radio: 'M',
};

function Components() {
	const classes = useStyles();
	const [state, _setState] = useState(defaultState);

	const setState = useCallback((newState) => {
		_setState(prevState => ({
			...prevState,
			...newState,
		}));
	}, []);

	const handleReset = () => {
		setState(defaultState);
	};

	const handleDateChange = (event, value) => {
		setState({ date: value });
	};

	const handleCheckboxChange = (event, value) => {
		setState({ checkbox: value });
	};

	const handleChange = (type) => (event) => {
		setState({ [type]: event.target.value });
	};

	return (
		<div className={classes.container}>
			<Container>
				<button onClick={handleReset}>RESET</button>
				<pre>
					{JSON.stringify(state, null, 4)}
				</pre>
				<div className={classes.component}>
					<TextField 
						label="TextField"
						placeholder="Type something here..." 
						helperText={state.text.length > 5 && 'Too long'}
						value={state.text}
						onChange={handleChange('text')}
						error={state.text.length > 5}
					/>
				</div>
				<div className={classes.component}>
					<Select
						label="Select"
						options={[
							{ label: 'Kuala Lumpur', value: 'KL' },
							{ label: 'Johor Bahru', value: 'JB' },
						]}
						value={state.select}
						onChange={handleChange('select')}
					/>
				</div>

				<div className={classes.component}>
					<Select
						group
						label="GroupSelect"
						options={[
							{ 
								label: 'Place', 
								options: [
									{ label: 'Kuala Lumpur', value: 'KL' },
									{ label: 'Johor Bahru', value: 'JB' },
								] 
							},
							{ 
								label: 'Food', 
								options: [
									{ label: 'Apple', value: 'APL' },
									{ label: 'Orange', value: 'ORG' },
								] 
							},
						]}
						value={state.select}
						onChange={handleChange('select')}
					/>
				</div>

				<div className={classes.component}>
					<DatePicker
						label="DatePicker"
						hideDefaultValue
						value={state.date}
						onChange={handleDateChange}
						clearable
					/>
				</div>

				<div className={classes.component}>
					<DatePicker
						label="DatePicker"
						hideDefaultValue
						value={state.date}
						onChange={handleDateChange}
					/>
				</div>

				<div className={classes.component}>
					<Checkbox
						label="Checkbox"
						value={state.checkbox}
						onChange={handleCheckboxChange}
					/>
				</div>

				<div className={classes.component}>
					<Radio 
						label="Radio"
						options={[
							{ label: 'Male', value: 'M' },
							{ label: 'Female', value: 'F' },
						]}
						value={state.radio}
						onChange={handleChange('radio')}
						row
					/>
				</div>

				<MultiSelect />
			</Container>
		</div>
	);
}

export default Components;
