import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { IconButton, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';
import Dialog from '../../../components/Dialog';

import MEAA from './MEAA';

import useSnackbarText from '../../../hooks/useSnackbarText';
import useQueryParams from '../../../hooks/useQueryParams';

import tries from '../../../api/tries';
import { 
	getSitemapData, 
	getYearList, 
	getObjectFromArray,
} from '../../../utils';
import { 
	tryIsNewClientOptions, 
	tryRecruitmentOptions 
} from '../../../configs/tries';

const dateFormat = 'YYYY-MM-DD';
const sitemapValue = 'PERSONAL_TRACKING';
const { fullPathname, label } = getSitemapData(sitemapValue);

const headers = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
	},
	{
		id: 'isNewClient',
		label: 'Client',
		type: 'string',
	},
	{ 
		id: 'tryDate',
		label: 'Date Opened', 
		type: 'date',
	},
	{ 
		id: 'followUpDates',
		label: 'Follow Up Dates', 
		type: 'string',
		subheaders: [
			{
				id: 'followUpDate1',
				label: '1st',
				type: 'date',
			},
			{
				id: 'followUpDate2',
				label: '2nd',
				type: 'date',
			},
			{
				id: 'followUpDate3',
				label: '3rd',
				type: 'date',
			},
			{
				id: 'followUpDate4',
				label: '4th',
				type: 'date',
			},
			{
				id: 'followUpDate5',
				label: '5th',
				type: 'date',
			},
			{
				id: 'followUpDate6',
				label: '6th',
				type: 'date',
			},
			{
				id: 'followUpDate7',
				label: '7th',
				type: 'date',
			},
		]
	},
	{ 
		id: 'ANP',
		label: 'ANP', 
		type: 'float',
	},
	{ 
		id: 'remark',
		label: 'Remark', 
		type: 'string',
	},
	{ 
		id: 'recruitment',
		label: 'Recruitment', 
		type: 'string',
	},
	{ 
		id: 'edit',
		label: 'Edit', 
		type: 'dom',
	},
	{ 
		id: 'remove',
		label: 'Delete', 
		type: 'dom',
	},
];

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	},
	dialogContentWrapper: {
		display: 'flex',
		justifyContent: 'space-evenly',
		marginTop: 10,
		marginBottom: 10,
	}
}));

function Main() {
	const classes = useStyles();
	const handleSnackbarChange = useSnackbarText();
	const [queryParams, setQueryParams] = useQueryParams();
	const [data, setData] = useState([]);
	const [timestamp, setTimestamp] = useState(moment().unix())
	const [year, setYear] = useState(
		(queryParams.startDate && moment(queryParams.startDate).year())
		|| moment().year()
	);

	const handleYearChange = (event) => {
		setYear(event.target.value);
	};

	const handleDelete = useCallback((tryID) => async () => {
		let { data, success } = await tries.deleteTry({ tryID });
		if(!success) {
			return handleSnackbarChange({ text: data.message, variant: 'error' });
		} else {
			handleSnackbarChange({ text: 'Prospect tracking deleted', variant: 'success' });
			setTimestamp(moment().unix());
		}
		setQueryParams({ deleteID: null });
	}, [handleSnackbarChange, setQueryParams]);

	const handleSetDeleteID = useCallback((tryID) => () => {
		setQueryParams({ deleteID: tryID });
	}, [setQueryParams]);

	useEffect(() => {
		let startDate, endDate;
		startDate = moment(`${year}-01-01`).format(dateFormat);
		endDate = moment(`${year}-12-31`).format(dateFormat);
		setQueryParams({ startDate, endDate });
	}, [year, setQueryParams]);

	useEffect(() => {
		const getData = async () => {
			const { success, data } = await tries.getTries({ 
				startDate: queryParams.startDate,
				endDate: queryParams.endDate,
			});
			if(!success) {
				return handleSnackbarChange({ text: data.message, variant: 'error' });
			} else {
				setData(data.tries.map(e => ({
					...e,
					isNewClient: getObjectFromArray(tryIsNewClientOptions, e.isNewClient).label,
					recruitment: getObjectFromArray(tryRecruitmentOptions, e.recruitment).label,
					edit: 
						<IconButton
							component={Link}
							to={`${fullPathname}/update?tryID=${e.tryID}`}
						>
							<EditIcon />
						</IconButton>,
					remove: 
						<IconButton  onClick={handleSetDeleteID(e.tryID)}>
							<DeleteIcon />
						</IconButton>,
				})));
			}
		}
		if(queryParams.startDate && queryParams.endDate) getData();
	}, [queryParams.startDate, queryParams.endDate, handleSnackbarChange, timestamp, handleSetDeleteID]);

	const activity = data.length;
	const effective = data.filter(e => Boolean(e.ANP)).length / data.length * 100;
	const ANP = data.reduce((a, v) => a + (Number(v.ANP) || 0), 0);
	const market = ANP * 100 / (activity * effective);

	return (
		<Layout title={label} createPathname={fullPathname + '/create'}>
			<Select 
				className={classes.select}
				maxWidth={150}
				label="Year"
				options={getYearList()}
				value={String(year)}
				onChange={handleYearChange}
			/>

			<MEAA 
				market={market}
				effective={effective}
				activity={activity}
				ANP={ANP}
			/>

			<Table
				headers={headers}
				data={data}
			/>

			<Dialog title="Delete this tracking?" queryParamKey="deleteID">
				<div className={classes.dialogContentWrapper}>
					<Button variant="contained" color="primary" onClick={handleDelete(queryParams.deleteID)}>Delete</Button>
					<Button onClick={() => setQueryParams({ deleteID: null })}>Cancel</Button>
				</div>
			</Dialog>
		</Layout>
	);
}

export default Main;
