import request from './request';

function getTry(data) {
	return request({
		url: `/tries/${data.tryID}`,
		method: 'GET',
	});
}

function createTry(data) {
	return request({
		url: '/tries',
		method: 'POST',
		data,
	});
}

function updateTry(data) {
	const { tryID, ...others } = data;
	return request({
		url: `/tries/${data.tryID}`,
		method: 'PUT',
		data: others,
	});
}

function deleteTry(data) {
	return request({
		url: `/tries/${data.tryID}`,
		method: 'DELETE',
	});
}

function getTries(data) {
	return request({
		url: '/tries',
		method: 'GET',
		params: data,
	});
}

export default {
	getTry,
	getTries,
	createTry,
	updateTry,
	deleteTry,
};
