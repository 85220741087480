import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import { getYearList } from '../../../utils';
import ComparisonCard from './ComparisonCard';

const dateFormat = 'YYYY-MM-DD';
const firstDayOfWeek = 1;

const generateWeekOptions = (year) => {
	return Array(53).fill('').map((e, i) => ({
		label: `Week ${i + 1} (${
			moment(`${year}-01-01`).week(i + 1).startOf('week').add(firstDayOfWeek, 'day').format(dateFormat)
		} - ${
			moment(`${year}-01-01`).week(i + 1).endOf('week').add(firstDayOfWeek, 'day').format(dateFormat)
		})`,
		value: String(i + 1),
	}));
};

const useStyles = makeStyles(theme => ({
    select: {
		marginRight: 10,
	},
    gridItem: {
        padding: 15,
    },
}));

const ActivityPanel = () => {
    const classes = useStyles();
    const [state, _setState] = useState({
		week: String(moment().week()),
		year: String(moment().year()),
	});

    const setState = useCallback((newState) => {
		_setState(prevState => ({
			...prevState,
			...newState,
		}));
	}, []);

    const handleChange = (type) => (event) => {
		setState({ [type]: event.target.value });
	};

    const weekOptions = generateWeekOptions(state.year);

    const startDate = moment(`${state.year}-01-01`).week(state.week).startOf('week').add(firstDayOfWeek, 'day').format(dateFormat);
    const endDate = moment(`${state.year}-01-01`).week(state.week).endOf('week').add(firstDayOfWeek, 'day').format(dateFormat);

    return (
        <Layout title="My Activities">
            <Select 
				className={classes.select}
				maxWidth={110}
				label="Year"
				options={getYearList()}
				value={state.year}
				onChange={handleChange('year')}
			/>
            <Select 
                className={classes.select}
                maxWidth={150}
                label="Week"
                options={weekOptions}
                value={state.week}
                onChange={handleChange('week')}
            />
            <div>
                <Grid container>
                    <Grid className={classes.gridItem} item xs={12} sm={6} lg={4}>
                        <ComparisonCard
                            label="Sales - Meal Appointment"
                            startDate={startDate}
                            endDate={endDate}
                            statusID="5"
                        />
                    </Grid>
                    <Grid className={classes.gridItem} item xs={12} sm={6} lg={4}>
                        <ComparisonCard
                            label="Sales - Open"
                            startDate={startDate}
                            endDate={endDate}
                            statusID="1"
                        />
                    </Grid>
                    <Grid className={classes.gridItem} item xs={12} sm={6} lg={4}>
                        <ComparisonCard
                            label="Sales - Closing"
                            startDate={startDate}
                            endDate={endDate}
                            statusID="2"
                        />
                    </Grid>
                    <Grid className={classes.gridItem} item xs={12} sm={6} lg={4}>
                        <ComparisonCard
                            label="Sales - Fact Finding"
                            startDate={startDate}
                            endDate={endDate}
                            statusID="3"
                        />
                    </Grid>
                    <Grid className={classes.gridItem} item xs={12} sm={6} lg={4}>
                        <ComparisonCard
                            label="Sales - Policy Review"
                            startDate={startDate}
                            endDate={endDate}
                            statusID="4"
                        />
                    </Grid>
                    <Grid className={classes.gridItem} item xs={12} sm={6} lg={4}>
                        <ComparisonCard
                            label="Recruitment"
                            startDate={startDate}
                            endDate={endDate}
                            statusID="recruitment"
                        />
                    </Grid>

                </Grid>
            </div>
        </Layout>
    )
};

export default ActivityPanel;
