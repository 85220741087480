import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';

import useQueryParams from '../../../hooks/useQueryParams';
import useFetch from '../../../hooks/useFetch';

import group from '../../../api/group';
import { 
	getYearList, 
	getSitemapData, 
	getFooterTotal,
} from '../../../utils';

const sitemapValue = 'GROUP_MONTHLYPROSPECTLIST';
const { label } = getSitemapData(sitemapValue);

const headers = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
	},
	...moment.monthsShort().map(e => ({
		id: e.toUpperCase(),
		label: e,
		type: 'int',
	})),
	{
		id: 'WY',
		label: 'Total',
		type: 'int',
	},
];

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	}
}));

function MonthlyProspect() {
	const classes = useStyles();
	const [queryParams, setQueryParams] = useQueryParams();
	const [data, setData] = useState([]);
	const [footers, setFooters] = useState([]);
	const [res, status, handleFetch] = useFetch(group.getMonthlyProspect);

	const handleChange = (type) => (event) => {
		setQueryParams({ [type]: event.target.value });
	};

	useEffect(() => {
		let tmp = {};
		if(!queryParams.year) tmp.year = moment().year();
		setQueryParams(tmp);
	}, [queryParams.year, setQueryParams]);

	useEffect(() => {
		if(queryParams.year) {
			handleFetch({ year: queryParams.year });
		}
	}, [handleFetch, queryParams.year]);

	useEffect(() => {
		if(status.loaded) {
			if(!status.error) {
				const formattedData = res.prospects.map(e => {
					let tmp = {
						agentID: e.agentID,
						name: e.name,
					};
					let months = moment.monthsShort().map(el => el.toUpperCase());

					// set monthly prospects
					e.prospects.forEach((el, j) => {
						tmp[months[j]] = el.value; 
					});

					// set whole year submissions
					tmp.WY = e.prospects.reduce((a, v) => a + v.value, 0);

					return tmp;
				});
				setData(formattedData);
				setFooters([{
					name: 'Total',
					...getFooterTotal(headers, formattedData),
				}]);
			} else {
				setData([]);
				setFooters([]);
			}
		}
	}, [res, status.loaded, status.error]);

	return (
		<Layout title={label}>
			<Select 
				className={classes.select}
				maxWidth={150}
				label="Year"
				options={getYearList()}
				value={queryParams.year}
				onChange={handleChange('year')}
			/>
			
			<Table
				headers={headers}
				data={data}
				footers={footers}
				isLoading={status.loading}
			/>
		</Layout>
	);
}

export default MonthlyProspect;
