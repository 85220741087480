import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';

import useQueryParams from '../../../hooks/useQueryParams';
import useFetch from '../../../hooks/useFetch';

import submissions from '../../../api/submissions';
import { submissionModeOptions } from '../../../configs/submissions';
import { 
	getYearList, 
	getSitemapData, 
	getFooterTotal,
	getObjectFromArray
} from '../../../utils';

const sitemapValue = 'PERSONAL_QFYLP';
const { label } = getSitemapData(sitemapValue);

const startHeaders = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
	},
	{
		id: 'plan',
		label: 'Plan',
		type: 'string',
	},
	{
		id: 'mode',
		label: 'Mode',
		type: 'string',
	},
	{
		id: 'issuedDate',
		label: 'Issued Date',
		type: 'date',
	},
	...moment.monthsShort().map(e => ({
		id: e.toUpperCase(),
		label: e,
		type: 'float',
	})),
];

const footerHeaders = [
	{
		id: 'FYC',
		label: 'FYC',
		type: 'float',
	},
];

const generateTabOptions = (rawYear) => {
	const year = Number(rawYear);
	return [
		{ label: `${year - 1} Total Renewal`, value: 'RENEWAL' },
		{ label: `${year} New Business`, value: 'NEW_BUSINESS' },
		{ label: `${year - 1} & ${year} Total QFYLP`, value: 'TOTAL' },
	];
};

const generateHeaders = (tabValue, rawYear = moment().year()) => {
	const year = parseInt(rawYear);
	let headers = [...startHeaders];
	switch(tabValue) {
		case 'RENEWAL': 
			headers = [
				...headers,
				{
					id: 'total',
					label: `Total ${year - 1}`,
					type: 'float',
				},
				...footerHeaders,
			];
			break;
		case 'NEW_BUSINESS': 
			headers = [
				...headers,
				{
					id: 'total',
					label: `Total ${year}`,
					type: 'float',
				},
				{
					id: 'CF',
					label: `C/F ${year + 1}`,
					type: 'float',
				},
				...footerHeaders,
			];
			break;
		case 'TOTAL': 
			headers = [
				...headers,
				{
					id: 'total',
					label: `Total ${year}`,
					type: 'float',
				},
				{
					id: 'CF',
					label: `C/F ${year + 1}`,
					type: 'float',
				},
				...footerHeaders,
			];
			break;
		default:
			break;
	}
	return headers;
};

const generateData = (rawData, tabValue) => {
	if(tabValue === 'RENEWAL') return rawData.filter(e => !e.isNewBusiness);
	if(tabValue === 'NEW_BUSINESS') return rawData.filter(e => e.isNewBusiness);
	return rawData;
};

const generateToDateFooter = (rawFooter, tabValue) => {
	let footer = {};
	const months = moment.monthsShort().map(e => e.toUpperCase());

	let tmp = 0;
	for(let i = 0; i < 12; i++) {
		const key = months[i];
		const currentValue = rawFooter[key];
		tmp += currentValue;
		footer[key] = tmp;
	}
	return footer;
};

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	},
	summaryButton: {
		marginTop: 14,
		marginLeft: 5,
	},
	wrapper: {
		borderRadius: theme.shape.borderRadius,
		backgroundColor: '#fff'
	},
	tabs: {
		//backgroundColor: '#fff',
		marginBottom: -10,
	},
	tab: {
	},
	selected: {
		color: theme.palette.primary.main,
		fontWeight: 600,
	},
}));

function QFYLP() {
	const classes = useStyles();
	const [queryParams, setQueryParams] = useQueryParams();
	const [data, setData] = useState([]);
	const [res, status, handleFetch] = useFetch(submissions.getQFYLPSummary);
	const tabOptions = generateTabOptions(queryParams.year || moment().year());
	const [tab, setTab] = useState(tabOptions[1].value);

	const handleChange = (type) => (event) => {
		setQueryParams({ [type]: event.target.value });
	};

	const handleTabChange = (event, newValue) => {
		setTab(newValue);
	};

	useEffect(() => {
		let tmp = {};
		if(!queryParams.year) tmp.year = moment().year();
		setQueryParams(tmp);
	}, [queryParams.year, setQueryParams]);

	useEffect(() => {
		if(queryParams.year) {
			handleFetch({ year: queryParams.year });
		}
	}, [handleFetch, queryParams.year]);

	useEffect(() => {
		if(status.loaded) {
			if(!status.error) {
				const formattedData = res.qfylp.map(e => {
					let tmp = {
						name: e.name,
						plan: e.plan,
						total: e.total,
						mode: getObjectFromArray(submissionModeOptions, e.mode).label,
						issuedDate: e.issuedDate,
						isNewBusiness: e.isNewBusiness,
						FYC: e.FYC,
						CF: e.CF,
					};

					moment.monthsShort().forEach((el, j) => {
						tmp[el.toUpperCase()] = e.QFYLP[j].value;
					});

					return tmp;
				});

				setData(formattedData);
			} else {
				setData([]);
			}
		}
	}, [res, status.loaded, status.error, tab]);

	const formattedData = generateData(data, tab);
	const headers = generateHeaders(tab, queryParams.year);
	const footer = getFooterTotal(headers, formattedData);
	const formattedFooters = [
		{
			name: 'Total',
			...footer,
		}, 
		{
			name: 'Year To Date',
			...generateToDateFooter(footer, tab),
		}
	];

	return (
		<Layout title={label}>
			<Select 
				className={classes.select}
				maxWidth={110}
				label="Year"
				options={getYearList()}
				value={queryParams.year}
				onChange={handleChange('year')}
			/>

			<div className={classes.wrapper}>
				<Tabs 
					className={classes.tabs}
					value={tab} 
					onChange={handleTabChange} 
					indicatorColor="primary" 
					variant="scrollable"
				>
					{tabOptions.map(e => (
						<Tab key={e.value} classes={{ 
							root: classes.tab,
							selected: classes.selected 
						}} label={e.label} value={e.value} />
					))}
				</Tabs>

				<Table
					headers={headers}
					data={formattedData}
					footers={formattedFooters}
					isLoading={status.loading}
				/>
			</div>

		</Layout>
	);
}

export default QFYLP;
