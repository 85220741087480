import request from './request';

function createFile(data) {
  const formData = new FormData();

  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }

  return request({
    url: '/files',
    method: 'POST',
    data: formData,
    headers: { 'content-type': 'multipart/form-data' },
  });
}

function getFiles(data) {
  return request({
    url: '/files',
    method: 'GET',
    params: { taxonomy: data.taxonomy },
  });
}

function deleteFile(fileID) {
  return request({
    url: `/files/${fileID}`,
    method: 'DELETE',
  });
}

export default { createFile, getFiles, deleteFile };
