import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';

import useFetch from '../../../hooks/useFetch';
import useSnackbarText from '../../../hooks/useSnackbarText';

import contests from '../../../api/contests';
import admin from '../../../api/admin';
import { getYearList, getSitemapData } from '../../../utils';
import {
  Button,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
} from '@material-ui/core';

const sitemapValue = 'ADMIN_CONTEST_GROUPS';
const { label } = getSitemapData(sitemapValue);

const quarterOptions = [
  { label: 'Quarter 1', value: '1' },
  { label: 'Quarter 2', value: '2' },
  { label: 'Quarter 3', value: '3' },
  { label: 'Quarter 4', value: '4' },
];

const useStyles = makeStyles(theme => ({
  select: { marginRight: 10 },
  agentSelect: { '& div': { fontSize: `12px` } },
  actionWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  updateButton: {
    color: '#fff',
    borderRadius: 6,
    fontWeight: 600,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  container: { padding: 10 },
  paper: {
    padding: 10,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
  },
}));

function ContestGroups() {
  const classes = useStyles();
  const [disabled, setDisable] = useState(false);
  // number[][]
  const [groups, setGroups] = useState([]);
  const [agents, setAgents] = useState([]);
  const handleSnackbarChange = useSnackbarText();
  const [res, status, handleFetch] = useFetch(contests.getContestGroups);
  const [state, _setState] = useState({
    year: String(moment().year()),
    quarter: String(moment().quarter()),
  });

  const setState = useCallback(newState => {
    _setState(prevState => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleChange = type => event => {
    setState({ [type]: event.target.value });
  };

  const handleSave = async () => {
    setDisable(true);
    const result = await contests.updateContestGroups({
      year: Number(state.year),
      quarter: Number(state.quarter),
      groups: [
        { agentIDs: groups[0].filter(e => Boolean(e)) },
        { agentIDs: groups[1].filter(e => Boolean(e)) },
        { agentIDs: groups[2].filter(e => Boolean(e)) },
        { agentIDs: groups[3].filter(e => Boolean(e)) },
      ],
    });
    setDisable(false);

    if (result.success) {
      handleSnackbarChange({
        text: 'Group has been updated',
        variant: 'success',
      });
    } else {
      handleSnackbarChange({
        text: result.data.message,
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    handleFetch({
      year: state.year,
      quarter: state.quarter,
    });
  }, [handleFetch, state.year, state.quarter]);

  useEffect(() => {
    if (status.loaded) {
      if (!status.error) {
        const group1 = res.groups.filter(group => group.groupID === 1);
        const group2 = res.groups.filter(group => group.groupID === 2);
        const group3 = res.groups.filter(group => group.groupID === 3);
        const group4 = res.groups.filter(group => group.groupID === 4);

        const groups = [
          group1.map(group => group.agentID),
          group2.map(group => group.agentID),
          group3.map(group => group.agentID),
          group4.map(group => group.agentID),
        ];

        setGroups(groups);
      } else {
        setGroups([]);
      }
    }
  }, [res, status.loaded, status.error]);

  useEffect(() => {
    async function main() {
      const result = await admin.getAgents();
      if (!result.success) return;

      setAgents(result.data.agents);
    }

    main();
  }, []);

  return (
    <Layout title={label}>
      <div className={classes.actionWrapper}>
        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
          <Select
            className={classes.select}
            maxWidth={110}
            label="Year"
            options={getYearList()}
            value={state.year}
            onChange={handleChange('year')}
          />

          <Select
            className={classes.select}
            maxWidth={150}
            label="Quarter"
            options={quarterOptions}
            value={state.quarter}
            onChange={handleChange('quarter')}
          />
          {!status.loaded && <CircularProgress color="inherit" size={20} />}
        </div>

        <Button
          className={classes.updateButton}
          disabled={disabled}
          color="primary"
          onClick={handleSave}
        >
          Save
        </Button>
      </div>

      {/* <pre>{JSON.stringify(groups, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(agents, null, 2)}</pre> */}

      <Grid container>
        {groups.map((group, index) => {
          const handleAdd = () => {
            setGroups(prev => {
              const newGroups = [...prev];
              newGroups[index].push(0);
              return newGroups;
            });
          };

          const handleChange = (currentIndex, newAgentID) => {
            setGroups(prev => {
              const newGroups = [...prev];
              newGroups[index][currentIndex] = Number(newAgentID);
              return newGroups;
            });
          };

          const handleDelete = currentIndex => () => {
            setGroups(prev => {
              const newGroups = [...prev];
              newGroups[index] = newGroups[index].filter(
                (_, i) => i !== currentIndex
              );
              return newGroups;
            });
          };

          return (
            <Grid
              key={index}
              item
              xs={12}
              sm={6}
              md={3}
              className={classes.container}
            >
              <div className={classes.paper}>
                <Typography>{`Group ${index + 1}`}</Typography>

                {group.map((g, i) => (
                  <div
                    key={i}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <IconButton onClick={handleDelete(i)} size="small">
                      <DeleteIcon />
                    </IconButton>

                    <Select
                      className={classes.agentSelect}
                      label="Agent"
                      options={agents.map(agent => ({
                        label: agent.name,
                        value: agent.agentID,
                      }))}
                      value={g ? String(g) : ''}
                      onChange={event => handleChange(i, event.target.value)}
                    />
                  </div>
                ))}

                <Button variant="contained" onClick={handleAdd}>
                  Add
                </Button>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </Layout>
  );
}

export default ContestGroups;
