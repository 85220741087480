import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import DatePicker from '../../../components/DatePicker';
import Table from '../../../components/Table';

import useQueryParams from '../../../hooks/useQueryParams';
import useFetch from '../../../hooks/useFetch';

import group from '../../../api/group';
import { 
	getSitemapData, 
	getFooterTotal,
	getAgentRankingLabel,
} from '../../../utils';
import { contestTypeOptions } from '../../../configs/contests';

const dateFormat = 'YYYY-MM-DD';
const sitemapValue = 'GROUP_CUSTOMCONTEST';
const { label } = getSitemapData(sitemapValue);

const headers = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
	},
	{
		id: 'ranking',
		label: 'Ranking',
		type: 'string',
	},
	{
		id: 'solidANP',
		label: 'Solid ANP',
		type: 'float',
	},
	/*
	{
		id: 'NOCWithMinTarget',
		label: 'NOC*',
		type: 'int',
	},
	*/
	{
		id: 'NOC',
		label: 'NOC',
		type: 'int',
	},
	{
		id: 'BSA',
		label: 'BSA',
		type: 'float',
	},
	{
		id: 'existingClient',
		label: 'Existing Client',
		type: 'int',
	},
	{
		id: 'newClient',
		label: 'New Client',
		type: 'int',
	},
];

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	},
}));

function CustomContest() {
	const classes = useStyles();
	const [queryParams, setQueryParams] = useQueryParams();
	const [data, setData] = useState([]);
	const [footers, setFooters] = useState([]);
	const [res, status, handleFetch] = useFetch(group.getCustomContestResult);

	const handleChange = (type) => (event, value) => {
		setQueryParams({ [type]: value });
	};

	// set up initial payload
	useEffect(() => {
		let tmp = {};
		if(!queryParams.startDate) tmp.startDate = moment().startOf('month').format(dateFormat);
		if(!queryParams.endDate) tmp.endDate = moment().endOf('month').format(dateFormat);
		if(!queryParams.type) tmp.type = contestTypeOptions[0].value;
		setQueryParams(tmp);
	}, [queryParams.startDate, queryParams.endDate, queryParams.type, setQueryParams]);

	// set up fetch conditions
	useEffect(() => {
		if(queryParams.startDate && queryParams.endDate && queryParams.type) {
			handleFetch({
				startDate: queryParams.startDate,
				endDate: queryParams.endDate,
				contestType: queryParams.type,
			});
		}
	}, [handleFetch, queryParams.startDate, queryParams.endDate, queryParams.type]);

	// on fetch finished
	useEffect(() => {
		if(status.loaded) {
			if(!status.error) {
				const formattedData = res.contests.map(e => ({
					...e,
					ranking: getAgentRankingLabel(e.ranking),
				}));
				setData(formattedData);
				setFooters([{
					name: 'Total',
					...getFooterTotal(headers, formattedData),
				}]);
			} else {
				setData([]);
				setFooters([]);
			}
		}
	}, [res, status.loaded, status.error]);

	return (
		<Layout title={label}>
			<DatePicker 
				className={classes.select}
				label="Start Date"
				value={queryParams.startDate}
				onChange={handleChange('startDate')}
				autoApply
			/>

			<DatePicker 
				className={classes.select}
				label="End Date"
				value={queryParams.endDate}
				onChange={handleChange('endDate')}
				autoApply
			/>

			<Select 
				className={classes.select}
				maxWidth={200}
				label="Type"
				options={contestTypeOptions}
				value={queryParams.type}
				onChange={handleChange('type')}
			/>
			
			<Table
				headers={headers}
				data={data}
				footers={footers}
				isLoading={status.loading}
			/>

		</Layout>
	);
}

export default CustomContest;
