import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ListItem, ListItemText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactSortable } from 'react-sortablejs';

import Layout from '../../../components/Layout';

import useQueryParams from '../../../hooks/useQueryParams';
import useSnackbarText from '../../../hooks/useSnackbarText';

import { getSitemapData } from '../../../utils';
import admin from '../../../api/admin';
import { maxWidth } from '../../../components/configs';

const sitemapValue = 'ADMIN_AGENT';
const { label, fullPathname } = getSitemapData(sitemapValue);

const useStyles = makeStyles(theme => ({
  content: {
    backgroundColor: '#fff',
    padding: 10,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  sortableWrapper: {
    width: '100%',
  },
  listItem: {
    backgroundColor: '#f9f9f9',
    userSelect: 'none',
    border: '1px solid #ececec',
    margin: 'auto',
    marginBottom: 10,
    maxWidth: maxWidth,
    cursor: 'grab',
  },
  listItemTextPrimary: {
    fontSize: 13,
  },
  submitButton: {
    maxWidth,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  instruction: {
    marginBottom: 20,
    fontWeight: 200,
  },
}));

function Sort() {
  const classes = useStyles();
  const handleSnackbarChange = useSnackbarText();
  const routerHistory = useHistory();
  const [queryParams] = useQueryParams();
  const [agentList, setAgentList] = useState([]);
  const [disabled, setDisable] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const handleSubmit = async () => {
    setDisable(true);
    let { data, success } = await admin.updateSortOrders({
      sortOrders: agentList.map((e, i) => ({
        agentID: e.agentID,
        sortOrder: i,
      })),
    });
    setDisable(false);
    if (success) {
      handleSnackbarChange({ text: 'Agent order updated', variant: 'success' });
    } else {
      return handleSnackbarChange({ text: data.message, variant: 'error' });
    }
    routerHistory.push(fullPathname);
  };

  useEffect(() => {
    const getData = async () => {
      let { data, success } = await admin.getAgents();
      if (success) {
        setAgentList(data.agents);
        setLoaded(true);
      } else {
        return handleSnackbarChange({ text: data.message, variant: 'error' });
      }
    };
    getData();
  }, [queryParams.agentID, handleSnackbarChange, setLoaded]);

  return (
    <Layout title={label}>
      <div className={classes.content}>
        <Typography className={classes.instruction}>
          Drag and adjust the order of agents
        </Typography>
        {!loaded && <div className={classes.loading}>loading</div>}
        {loaded && (
          <Fragment>
            <ReactSortable
              className={classes.sortableWrapper}
              list={agentList}
              setList={setAgentList}
              animation={150}
              delay={300}
              delayOnTouchOnly
            >
              {agentList.map((e, i) => (
                <ListItem key={e.agentID} className={classes.listItem} dense>
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={e.name}
                  />
                </ListItem>
              ))}
            </ReactSortable>
            <div className={classes.submitButton}>
              <Button
                disabled={disabled}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Update
              </Button>
            </div>
          </Fragment>
        )}
      </div>
    </Layout>
  );
}

export default Sort;
