import React, { useState, useCallback ,Fragment } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { 
	makeStyles,
	Button,
	Popover,
	Grid,
	IconButton,
	Typography,
	FormControl,
	InputLabel,
	ButtonGroup,
	FormHelperText
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Calendar from './Calendar';
import { maxWidth } from '../configs';

const periodFormat = 'YYYY-MM';
const dateFormat = 'YYYY-MM-DD';
const defaultButtonDisplayFormat = 'D MMM YYYY';
const defaultPeriodDisplayFormat = 'MMM YYYY';

const emptyFunction = () => {};

const useStyles = makeStyles(theme => ({
	InputLabel: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		width: 'calc(100% - 14px)',
	},
	InputLabelShrink: {
		width: '107%'
	},
	baseMargin: {
		width: '100%',
		maxWidth,
		marginTop: 8,
		marginBottom: 8,
	},
	baseFullWidth: {
		width: 'calc(100% - 10px)',
	},
	button: {
		fontSize: '1rem',
		paddingTop: 8,
		paddingBottom: 8,
		border: `1px solid ${theme.palette.border.primary}`,
		backgroundColor: theme.palette.grey.primary,
		minWidth: 108,
	},
	paper: {
		width: 'auto',
		boxShadow: 'none',
		border: '1px solid #e6e6e6',
	},
	wrapper: {
		padding: 5,
	},
	container: {
		outline: 'none',
	},
	actionWrapper: {
		display: 'flex',
		justifyContent: 'flex'
	},
	withLabel: {
		paddingLeft: 12,
		paddingRight: 12,
		paddingTop: 16,
		paddingBottom: 0,
		minHeight: 48,
	},
	buttonLabel: {
		justifyContent: 'flex-start'
	},
	grow: {
		flex: 1
	},
	clearButton: {
		fontSize: '1rem',
		paddingTop: 8,
		paddingBottom: 8,
		border: `1px solid ${theme.palette.border.primary}`,
		borderLeft: 'none',
		backgroundColor: theme.palette.grey.primary,
		borderRadius: theme.shape.borderRadius,
	}
}));

function DatePicker(props) {
	// props
	const { 
		label, 
		onChange,
		PopoverProps = {},
		ButtonProps = {},
		buttonDisplayFormat = defaultButtonDisplayFormat,
		periodDisplayFormat = defaultPeriodDisplayFormat,
		dateDisplayFormat = defaultButtonDisplayFormat,
		isDayBlocked = emptyFunction,
		isDayHighlighted = emptyFunction,
		onClose = emptyFunction,
		onNextMonthClick = emptyFunction,
		onPrevMonthClick = emptyFunction,
		hideSelectedDateLabel,
		hideDefaultValue,
		blockPassedDay,
		autoApply,
		highlightClassName,
		rootClassName,
		clearable,
		helperText,
		fullWidth,
		...others
	} = props;

	const value = props.value || moment().format(dateFormat);

	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const [date, setDate] = useState(moment(value).format(dateFormat));
	const [period, setPeriod] = useState(moment(value).format(periodFormat));

	// popover controller
	const handleOpen = useCallback(e => setAnchorEl(e.currentTarget), []);
	const handleClose = useCallback(() => {
		onClose({ date: props.value });
		setAnchorEl(null);
	}, [props.value, onClose]);

	// navigate month
	const handlePrevMonthClick = useCallback(() => {
		setPeriod((prevPeriod) => {
			const newPeriod = moment(prevPeriod, periodFormat).add(-1, 'month');
			onPrevMonthClick(newPeriod.format(dateFormat));
			return newPeriod.format(periodFormat);
		});
	}, [onPrevMonthClick]);

	const handleNextMonthClick = useCallback(() => {
		setPeriod((prevPeriod) => {
			const newPeriod = moment(prevPeriod, periodFormat).add(1, 'month');
			onNextMonthClick(newPeriod.format(dateFormat));
			return newPeriod.format(periodFormat);
		});
	}, [onNextMonthClick]);

	// handle apply
	const handleApply = useCallback((event) => {
		onClose({ date });
		onChange(event, date);
		setAnchorEl(null);
	}, [date, onClose, onChange]);

	const handleDateClick = useCallback((selectedDate) => {
		if(!selectedDate) return;
		setDate(moment(selectedDate).format(dateFormat));
		if(autoApply) {
			onChange(null, selectedDate);
			setAnchorEl(null);
		}
	}, [autoApply, onChange]);

	const handleSetToday = useCallback(() => {
		setPeriod(moment().format(periodFormat));
		setDate(moment().format(dateFormat));
	}, []);

	const handleOnEntering = useCallback(() => {
		// reset data on entering
		setDate(value);
		setPeriod(moment(value).format(periodFormat));
	}, [value]);

	const handleClear = useCallback((event) => {
		onChange(event, null);
	}, [onChange]);

	const renderButtonText = useCallback((value) => {
		const dateMoment = moment(value);
		const isValidDate = dateMoment.isValid();
		if(!value && hideDefaultValue) return '';
		if(!isValidDate) return '';
		else return dateMoment.format(buttonDisplayFormat);
	}, [buttonDisplayFormat, hideDefaultValue]);

	return (
		<Fragment>
			{Boolean(label) && (
				<FormControl className={clsx(
					classes.baseMargin,
					fullWidth && classes.baseFullWidth,
				)} {...others}>
					<InputLabel
					 classes={{
					 	root: classes.InputLabel,
					 	shrink: classes.InputLabelShrink
					 }}
					 shrink={Boolean(props.value || anchorEl)}
					 focused={Boolean(anchorEl)}
					>
						{label}
					</InputLabel>
					<ButtonGroup aria-label="split button">
						<Button
							className={clsx(
								classes.grow,
								classes.button, 
								classes.withLabel, 
								rootClassName, 
								ButtonProps.className
							)}
							classes={{ label: classes.buttonLabel }}
							aria-controls="date-menu" 
							aria-haspopup="true" 
							{...ButtonProps}
							 onClick={handleOpen}
						>
							{renderButtonText(props.value)}
						</Button>
						{clearable && (
							<IconButton className={classes.clearButton} onClick={handleClear} aria-label="clear">
								<ClearIcon />
							</IconButton>
						)}
					</ButtonGroup>
					<FormHelperText>{helperText}</FormHelperText>
				</FormControl>
			)}
			{!Boolean(label) && (
				<Button
					className={clsx(
						classes.button, 
						classes.baseMargin, 
						fullWidth && classes.baseFullWidth,
						rootClassName, 
						ButtonProps.className
					)}
					classes={{ label: classes.buttonLabel }}
					aria-controls="date-menu" 
					aria-haspopup="true" 
					fullWidth={fullWidth}
					{...ButtonProps}
					onClick={handleOpen}
				>
					{renderButtonText(props.value)}
				</Button>
			)}
			<Popover
				classes={{ paper: classes.paper }}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClose}
				onEntering={handleOnEntering}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				{...PopoverProps}
			>
				<Grid className={classes.wrapper} container direction="column" >
					{!hideSelectedDateLabel && (
						<Grid item xs={12}>
							<Typography align="center">
								{moment(date).format(dateDisplayFormat)}
							</Typography>
						</Grid>
					)}
					<Grid className={classes.container} item xs={12}>
						<Grid container justify="space-between" alignItems="center">
							<IconButton size="small" onClick={handlePrevMonthClick}>
								<KeyboardArrowLeft />
							</IconButton>
							<Typography>{moment(period).format(periodDisplayFormat)}</Typography>
							<IconButton size="small" onClick={handleNextMonthClick}>
								<KeyboardArrowRight />
							</IconButton>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Calendar 
							date={date}
							period={period}
							onDateClick={handleDateClick}
							isDayBlocked={isDayBlocked}
							isDayHighlighted={isDayHighlighted}
							blockPassedDay={blockPassedDay}
							highlightClassName={highlightClassName}
						/>
					</Grid>
					{!autoApply && (
						<Grid item xs={12}>
							<Grid container justify="space-between">
								<div>
									<Button color="primary" onClick={handleSetToday}>Today</Button>
								</div>
								<div>
									<Button color="primary" onClick={handleClose}>Cancel</Button>
									<Button color="primary" onClick={handleApply}>Apply</Button>
								</div>
							</Grid>
						</Grid>
					)}
				</Grid>
			</Popover>
		</Fragment>
	);
}

export default DatePicker;
