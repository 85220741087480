import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';

import Layout from '../../../components/Layout';
import Table from '../../../components/Table';

import useSnackbarText from '../../../hooks/useSnackbarText';

import plans from '../../../api/plans';
import { 
	getSitemapData, 
	getObjectFromArray,
} from '../../../utils';
import { 
	planTypeOptions,
	planStatusOptions,
} from '../../../configs/plans';

const sitemapValue = 'ADMIN_PLAN';
const { fullPathname, label } = getSitemapData(sitemapValue);

const headers = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
	},
	{
		id: 'type',
		label: 'Type',
		type: 'string',
	},
	{ 
		id: 'sANP',
		label: 'S-ANP', 
		type: 'float',
	},
	{ 
		id: 'solidANP',
		label: 'Solid ANP', 
		type: 'float',
	},
	{ 
		id: 'FYC',
		label: 'FYC(%)', 
		type: 'float',
	},
	{ 
		id: 'status',
		label: 'Status', 
		type: 'string',
	},
	{ 
		id: 'remark',
		label: 'Remark', 
		type: 'string',
	},
	{ 
		id: 'edit',
		label: 'Edit', 
		type: 'dom',
	},
];

const useStyles = makeStyles(theme => ({
}));

function Main() {
	// eslint-disable-next-line
	const classes = useStyles();
	const handleSnackbarChange = useSnackbarText();
	const [data, setData] = useState([]);

	useEffect(() => {
		const getData = async () => {
			const { success, data } = await plans.getPlans();
			if(!success) {
				return handleSnackbarChange({ text: data.message, variant: 'error' });
			} else {
				setData(data.plans.map(e => ({
					...e,
					type: getObjectFromArray(planTypeOptions, e.type).label,
					status: getObjectFromArray(planStatusOptions, e.status).label,
					edit: 
						<IconButton
							component={Link}
							to={`${fullPathname}/update?planID=${e.planID}`}
						>
							<EditIcon />
						</IconButton>,
				})));
			}
		}
		getData();
	}, [handleSnackbarChange]);

	return (
		<Layout title={label} createPathname={fullPathname + '/create'}>
			<Table
				headers={headers}
				data={data}
				tableState={{ orderBy: 'status' }}
			/>
		</Layout>
	);
}

export default Main;
