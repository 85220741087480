import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import SimpleTable from '../../../components/SimpleTable';

import { generateNumber } from '../../../utils';
import { isInforced } from '../../../configs/submissions';

const filter = (array = [], key = 'value', value) => {
	try {
		if(typeof value !== 'object') 
			return array.filter(e => e[key] === value);
		else // array
			return array.filter(e => value.includes(e[key]));
	} catch(err) {

		return [];
	}
};

const generateANP = (array = [], digit) => {
	return array.reduce((a, v) => a + v.ANP, 0);
};

const generatePecentage = (numerator, denominator) => {
	return `${generateNumber(numerator * 100 / denominator)} %`;
};

const useStyles = makeStyles(theme => ({
	root: {

	}
}));

function Summary(props) {
	const classes = useStyles();

	const data = props.data;
	const totalANP = generateANP(data);

	// inforced
	const inforcedData = data.filter(e => isInforced(e.status));
	const inforcedInvestmentData = filter(inforcedData, 'planType', 0);
	const inforcedTraditionalData = filter(inforcedData, 'planType', 1);
	const inforcedANP = generateANP(inforcedData);
	const inforcedInvestmentANP = generateANP(inforcedInvestmentData);
	const inforcedTraditionalANP = generateANP(inforcedTraditionalData);

	// other status
	const underwritingData = filter(data, 'status', 0);
	const queryData = filter(data, 'status', 1);
	const acceptData = filter(data, 'status', 3);
	const cAcceptData = filter(data, 'status', 2);
	const underwritingANP = generateANP(underwritingData);
	const queryANP = generateANP(queryData);
	const acceptANP = generateANP(acceptData);
	const cAcceptANP = generateANP(cAcceptData);

	// not taken
	const withdrawnData = filter(data, 'status', 7);
	const postponeData = filter(data, 'status', 5);
	const declinedData = filter(data, 'status', 6);
	const withdrawnANP = generateANP(withdrawnData);
	const postponeANP = generateANP(postponeData);
	const declinedANP = generateANP(declinedData);

	return (
		<div className={classes.root}>
			<SimpleTable
				title="Inforce Client"
				headers={[
					{ label: '', value: 'name' },
					{ label: 'Total', value: 'total' },
				]}
				data={[
					{ name: 'Existing', total: filter(inforcedData, 'isExistingClient', 1).length },
					{ name: 'New', total: filter(inforcedData, 'isExistingClient', 0).length },
				]}
				footers={[
					{ name: 'Total', total: inforcedData.length },
				]}
			/>

			<SimpleTable
				title="Inforce Payment Mode"
				headers={[
					{ label: '', value: 'name' },
					{ label: 'Total', value: 'total' },
				]}
				data={[
					{ name: 'Yearly', total: filter(inforcedData, 'mode', 3).length },
					{ name: 'Half Yearly', total: filter(inforcedData, 'mode', 2).length },
					{ name: 'Quarterly', total: filter(inforcedData, 'mode', 1).length },
					{ name: 'Monthly', total: filter(inforcedData, 'mode', 0).length },
				]}
				footers={[
					{ name: 'Total', total: inforcedData.length },
				]}
			/>

			<SimpleTable
				title="Overall"
				headers={[
					{ label: '', value: 'name' },
					{ label: 'NOC', value: 'NOC' },
					{ label: 'ANP', value: 'ANP' },
				]}
				data={[
					{ name: 'Inforce', NOC: inforcedData.length, ANP: generateNumber(inforcedANP) },
					{ name: 'Grand Total', NOC: data.length, ANP: generateNumber(totalANP) },
					{ name: '% of Inforce', NOC: generatePecentage(inforcedData.length, data.length), ANP: generatePecentage(inforcedANP, totalANP) },
				]}
			/>

			<SimpleTable
				title="Inforce"
				headers={[
					{ label: '', value: 'name' },
					{ label: 'NOC', value: 'NOC' },
					{ label: 'ANP', value: 'ANP' },
				]}
				data={[
					{ name: 'Investment', NOC: inforcedInvestmentData.length, ANP: generateNumber(inforcedInvestmentANP) },
					{ name: 'Traditional', NOC: inforcedTraditionalData.length, ANP: generateNumber(inforcedTraditionalANP) },
				]}
				footers={[
					{ name: 'Total', NOC: inforcedData.length, ANP: generateNumber(inforcedANP) },
				]}
			/>

			<SimpleTable
				title="Status"
				headers={[
					{ label: '', value: 'name' },
					{ label: 'NOC', value: 'NOC' },
					{ label: 'ANP', value: 'ANP' },
				]}
				data={[
					{ name: 'Underwriting', NOC: underwritingData.length , ANP: generateNumber(underwritingANP) },
					{ name: 'Query', NOC: queryData.length, ANP: generateNumber(queryANP) },
					{ name: 'Accept', NOC: acceptData.length, ANP: generateNumber(acceptANP) },
					{ name: 'C. Accept', NOC: cAcceptData.length, ANP: generateNumber(cAcceptANP) },
				]}
				footers={[
					{ name: 'Total', NOC: underwritingData.length + queryData.length + acceptData.length + cAcceptData.length, ANP: generateNumber(underwritingANP + queryANP + acceptANP + cAcceptANP) },
				]}
			/>

			<SimpleTable
				title="Not Taken"
				headers={[
					{ label: '', value: 'name' },
					{ label: 'NOC', value: 'NOC' },
					{ label: 'ANP', value: 'ANP' },
				]}
				data={[
					{ name: 'Withdrawn', NOC: withdrawnData.length, ANP: generateNumber(withdrawnANP) },
					{ name: 'Postpone', NOC: postponeData.length, ANP: generateNumber(postponeANP) },
					{ name: 'Declined', NOC: declinedData.length, ANP: generateNumber(declinedANP) },
				]}
				footers={[
					{ name: 'Total', NOC: withdrawnData.length + postponeData.length + declinedData.length, ANP: generateNumber(withdrawnANP + postponeANP + declinedANP) },
				]}
			/>

		</div>
	);
}

export default Summary;
