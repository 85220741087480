import request from './request';

function getDiaryPerformanceBlacklist(data) {
	return request({
		url: `/table-blacklist/diary-performance`,
		method: 'GET',
	});
}

function updateDiaryPerformanceBlacklist(data) {
	return request({
		url: '/table-blacklist/diary-performance',
		method: 'PUT',
		data,
	});
}

export default {
	getDiaryPerformanceBlacklist,
	updateDiaryPerformanceBlacklist,
}
