import { getSitemapData } from '../../../utils';
import { ReactComponent as EventIcon } from '../../../assets/icons/icon_event.svg';
import { ReactComponent as SalesReportIcon } from '../../../assets/icons/icon_report.svg';
import { ReactComponent as OtherIcon } from '../../../assets/icons/icon_others.svg';
import { ReactComponent as TrainingIcon } from '../../../assets/icons/icon_training.svg';
import { ReactComponent as CultureBookIcon } from '../../../assets/icons/icon_culturebook.svg';
import EventPage from './EventPage';
import TrainingPage from './TrainingPage';
import CultureBookPage from './CultureBookPage';
import SalesReportPage from './SalesReportPage';
import OtherActivityPage from './OtherActivityPage';

const sitemapValue = 'ADMIN_COMPANY_UPDATE';
const { fullPathname } = getSitemapData(sitemapValue);

const routes = [
  {
    label: 'Sales Report',
    Icon: SalesReportIcon,
    Page: SalesReportPage,
    path: fullPathname + '/sales-report',
  },
  {
    label: 'Event',
    Icon: EventIcon,
    Page: EventPage,
    path: fullPathname + '/events',
  },
  {
    label: 'Culture Book',
    Icon: CultureBookIcon,
    Page: CultureBookPage,
    path: fullPathname + '/culture-book',
  },
  {
    label: 'Training & Development',
    Icon: TrainingIcon,
    Page: TrainingPage,
    path: fullPathname + '/training',
  },
  {
    label: 'Other Activities',
    Icon: OtherIcon,
    Page: OtherActivityPage,
    path: fullPathname + '/other-activities',
  },
];

export { fullPathname };

export default routes;
