import { useEffect, useState } from 'react';

import useSnackbarText from './useSnackbarText';
import admin from '../api/admin';
import { capitalizeFirstLetter, getAgentRankingLabel } from '../utils';

function useAgentList(agentID, options = {}) {
	const [agentList, setAgentList] = useState([]);
	const handleSnackbarChange = useSnackbarText();
	const { isIntroducerList } = options;
	
	// fetch agents
	useEffect(() => {
		const getAgents = async () => {
			let { data, success } = await admin.getAgents();
			if(success) {
				const currentAgents = data.agents.filter(e => Boolean(e.isActive));
				const oldAgents = data.agents.filter(e => !Boolean(e.isActive));

				let agentList;
				if(isIntroducerList) {
					agentList = [
						{
							label: 'Active Agents',
							options: currentAgents.map(e => ({
								label: capitalizeFirstLetter(e.name) + (e.ranking ? ` (${getAgentRankingLabel(e.ranking)})` : ''),
								value: e.agentID,
								disabled: String(e.agentID) === String(agentID),
							})),
						},
						{
							label: 'Old Agents',
							options: oldAgents.map(e => ({
								label: capitalizeFirstLetter(e.name) + (e.ranking ? ` (${getAgentRankingLabel(e.ranking)})` : ''),
								value: e.agentID,
								disabled: String(e.agentID) === String(agentID),
							})),
						}
					];
				} else {
					agentList = [
						{
							label: 'Active Agents',
							options: currentAgents.map(e => ({
								...e,
								label: capitalizeFirstLetter(e.name) + (e.ranking ? ` (${getAgentRankingLabel(e.ranking)})` : ''),
								value: e.agentID,
							})),
						},
						{
							label: 'Old Agents',
							options: oldAgents.map(e => ({
								...e,
								label: capitalizeFirstLetter(e.name) + (e.ranking ? ` (${getAgentRankingLabel(e.ranking)})` : ''),
								value: e.agentID,
							})),
						}
					];
				}

				setAgentList(agentList);
			} else {
				return handleSnackbarChange({ text: data.message, variant: 'error' });
			}
		}
		getAgents();
	}, [handleSnackbarChange, agentID, isIntroducerList]);
	return agentList;
}

export default useAgentList;
