import React, { useState, useEffect, useCallback, useRef } from 'react';
import { CssBaseline } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';

import './App.css';
// import logo from './assets/images/logo.png';

// default configs
import { colorPalette, context, fullName } from './configs';

// context
import { mainContext, defaultMainContext } from './contexts/mainContext';
import {
  userContext,
  defaultUserContext,
  initialState,
} from './contexts/userContext';
import {
  snackbarContext,
  defaultSnackbarContext,
} from './contexts/snackbarContext';

// components
import Router from './Router';
import Snackbar from './components/Snackbar';
//import ViewAsBar from './components/ViewAsBar';
import ErrorBoundary from './components/ErrorBoundary';

const borderRadius = 10;

const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        WebkitOverflowScrolling: 'touch',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius,
      },
      contained: {
        boxShadow: 'none',
      },
      containedPrimary: {
        color: '#fff',
      },
      containedSecondary: {
        color: '#fff',
      },
      outlined: {
        border: `1px solid ${colorPalette.border}`,
      },
    },
    MuiFilledInput: {
      root: {
        borderRadius,
      },
      /*
			multiline: {
				'&$marginDense': {
					paddingTop: 6,
				}
			},
			inputMarginDense: {
				paddingTop: 20,
				paddingBottom: 3,
			}
			*/
    },
    MuiFormControl: {
      marginDense: {
        marginTop: 8,
        marginBottom: 8,
      },
    },
    MuiFormHelperText: {
      contained: {
        marginTop: 0,
      },
    },
    MuiSelect: {
      select: {
        textAlign: 'left',
        transition: 'all 0.5s',
        '&:focus': {
          borderRadius,
        },
      },
    },
    MuiFormLabel: {
      root: {
        /*
				'&$focused': {
					color: '#0096c0',
				}
				*/
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
      },
    },
    MuiInputLabel: {
      filled: {
        '&$marginDense': {
          transform: 'translate(14px, 15px) scale(1)',
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: 10,
      },
    },
  },
  props: {
    MuiInputBase: {
      margin: 'dense',
    },
    MuiFormControl: {
      margin: 'dense',
      variant: 'filled',
    },
    MuiFilledInput: {
      disableUnderline: true,
    },
    MuiButtonBase: {
      //disableRipple: true,
    },
  },
  shape: {
    borderRadius,
  },
  mixins: {
    toolbar: {
      minHeight: 48,
      '@media (min-width:600px)': {
        minHeight: 48,
      },
    },
  },
  shadows: Array(25).fill('5px 5px 10px rgba(47, 47, 47, 0.1)'),
  palette: {
    primary: {
      main: colorPalette.primary,
    },
    secondary: {
      main: colorPalette.secondary,
    },
    success: {
      main: colorPalette.success,
    },
    error: {
      main: colorPalette.error,
    },
    background: {
      //default: '#fff',
      default: colorPalette.background,
    },
    text: {
      primary: colorPalette.text,
    },
    icon: {
      primary: colorPalette.icon,
    },
    grey: {
      primary: colorPalette.grey,
    },
    border: {
      primary: colorPalette.border,
    },
    divider: `#D5D5D5`,
  },
});

function App() {
  const snackbarStateRef = useRef({ queue: [], open: false });
  const [mainContextState, setMainContext] = useState(defaultMainContext);
  const [userContextState, setUserContext] = useState(defaultUserContext);
  const [snackbarContextState, setSnackbarContext] = useState(
    defaultSnackbarContext
  );

  // main context
  const handleMainContextChange = useCallback(newState => {
    setMainContext(prevState => {
      const newUserState = {
        ...prevState,
        ...newState,
      };
      // save data to localstorage
      window.localStorage.setItem(context.main, JSON.stringify(newUserState));
      return newUserState;
    });
  }, []);

  // user context
  const handleUserContextChange = useCallback(newState => {
    if (!newState) {
      setUserContext(initialState);
      window.localStorage.removeItem(context.user);
    } else {
      setUserContext(prevState => {
        const newUserState = {
          ...prevState,
          ...newState,
        };
        // save data to localstorage
        window.localStorage.setItem(context.user, JSON.stringify(newUserState));
        return newUserState;
      });
    }
  }, []);

  // process snackbar queue
  const processQueue = useCallback(() => {
    if (snackbarStateRef.current.queue.length > 0) {
      // show snackbar
      snackbarStateRef.current.open = true;
      setSnackbarContext(prevState => ({
        ...prevState,
        ...snackbarStateRef.current.queue.shift(),
      }));
    } else {
      // restore snackbar default value
      snackbarStateRef.current.open = false;
      setSnackbarContext(defaultSnackbarContext);
    }
  }, []);

  // close snackbar
  const handleSnackbarClose = useCallback(() => {
    setSnackbarContext(prevState => ({
      ...prevState,
      text: '',
    }));
  }, []);

  // snackbar context
  const handleSnackbarContextChange = useCallback(
    newState => {
      snackbarStateRef.current.queue.push({
        ...defaultSnackbarContext,
        ...newState,
      });

      if (snackbarStateRef.current.open) {
        // Dismiss current message
        // next message will show up on current message exited
        handleSnackbarClose();
      } else {
        processQueue();
      }
    },
    [processQueue, handleSnackbarClose]
  );

  // load configuration
  useEffect(() => {
    console.log('Init');
    window.__setSnackbar = handleSnackbarContextChange;
    window.__setUserInfo = handleUserContextChange;
    window.__userInfo = userContextState;
  }, [handleSnackbarContextChange, handleUserContextChange, userContextState]);

  return (
    <ErrorBoundary>
      <Helmet>
        {/*
				<link rel="shortcut icon" href={logo} />
				<link rel="apple-touch-icon" sizes="128x128" href={logo} />
				*/}
        <title>{fullName}</title>
      </Helmet>
      <mainContext.Provider
        value={{
          ...mainContextState,
          handleChange: handleMainContextChange,
        }}
      >
        <userContext.Provider
          value={{
            ...userContextState,
            handleChange: handleUserContextChange,
          }}
        >
          <snackbarContext.Provider
            value={{
              ...snackbarContextState,
              handleChange: handleSnackbarContextChange,
              handleExited: processQueue,
              handleClose: handleSnackbarClose,
            }}
          >
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              <Router />
              <Snackbar />
              {/*  <ViewAsBar /> */}
            </MuiThemeProvider>
          </snackbarContext.Provider>
        </userContext.Provider>
      </mainContext.Provider>
    </ErrorBoundary>
  );
}

export default App;
