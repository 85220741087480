import React, { useState, useEffect } from 'react';

import Table from '../../../components/Table';

import useFetch from '../../../hooks/useFetch';

import goals from '../../../api/goals';
import { generateNumber } from '../../../utils';

const headers = [
	{ 
		id: 'name',
		label: 'Type', 
		type: 'string',
		sticky: true,
	},
	{
		id: 'target',
		label: 'Goal',
		type: 'string',
		disableSort: true,
	},
	{
		id: 'value',
		label: 'To Date',
		type: 'string',
		disableSort: true,
	},
	{
		id: 'balance',
		label: 'Balance',
		type: 'string',
		disableSort: true,
	},
];

function WholeGroup(props) {
	const { onUpdateProgress } = props;
	const [data, setData] = useState([]);
	const [res, status, handleFetch] = useFetch(goals.getWGResult);

	useEffect(() => {
		if(props.year) handleFetch({ year: props.year });
	}, [handleFetch, props.year]);

	useEffect(() => {
		if(status.loaded) {
			if(!status.error) {
				const formattedData = res.goal.map(e => {
					const digit = e.name === 'RECRUITMENT' ? 0 : 2;
					return {
						...e,
						target: generateNumber(e.target, digit),
						value: generateNumber(e.value, digit),
						balance: generateNumber(e.balance, digit),
					}
				});
				setData(formattedData);

				const progress = res.goal.map(e => ({
					label: e.name,
					target: e.target,
					current: e.value,
					float: e.name !== 'RECRUITMENT',
				}));

				onUpdateProgress(progress);
			} else {
				setData([]);
			}
		}
	}, [res, status.loaded, status.error, props.year, onUpdateProgress]);

	return (
		<Table
			headers={headers}
			data={data}
			isLoading={status.loading}
			disableSearch
		/>
	);
}

export default WholeGroup;
