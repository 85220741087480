import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { 
	Button, 
	Typography,
	ExpansionPanel, 
	ExpansionPanelSummary, 
	ExpansionPanelDetails 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Checkbox from '../../../components/Checkbox';
import Checkboxes from '../../../components/Checkboxes';
import TextField from '../../../components/TextField';
import DatePicker from '../../../components/DatePicker';

import usePlanList from '../../../hooks/usePlanList';
import useAgentList from '../../../hooks/useAgentList';
import useSnackbarText from '../../../hooks/useSnackbarText';

import { getSitemapData } from '../../../utils';
import contests from '../../../api/contests';
import { maxWidth } from '../../../components/configs';
import { contestTypeOptions } from '../../../configs/contests';

const dateFormat = 'YYYY-MM-DD';
const sitemapValue = 'ADMIN_CONTEST';
const { label, fullPathname } = getSitemapData(sitemapValue);

const useStyles = makeStyles(theme => ({
	content: {
		backgroundColor: '#fff',
		padding: 10,
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column'
	},
	submitButton: {
		maxWidth,
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: 20,
	},
	loading: {
		marginTop: 50,
		marginBottom: 50,
		color: '#c7c7c7'
	},
	expansionRoot: {
		maxWidth,
		width: '100%',
		marginTop: 8,
		marginBottom: 8,
		boxShadow: 'none',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: '#f5f5f5',
		'&:before': {
			backgroundColor: '#fff',
		}
	},
	expansionPanelDetails: {
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		}
	}
}));

function Create() {
	const classes = useStyles();
	const planList = usePlanList();
	const agentList = useAgentList();
	const handleSnackbarChange = useSnackbarText();
	const routerHistory = useHistory();
	const [state, _setState] = useState({
		name: '',
		startDate: moment().format(dateFormat),
		endDate: moment().add(1, 'month').format(dateFormat),
		NOCTarget: 0,
		type: contestTypeOptions[0].value,
		remark: '',
		isAllPlans: true,
		selectedPlans: [],
		selectedAgents: [],
	});
	const [error, setError] = useState({});
	const [disabled, setDisable] = useState(false);

	const setState = useCallback((newState = {}) => {
		_setState((prevState) => ({
			...prevState,
			...newState,
		}));
	}, []);

	const handleValueCheck = () => {
		let noError = true;
		let error = {};
		if(!state.name) {
			noError = false;
			error.name = 'Invalid value.';
		}
		setError(error);
		return noError;
	};

	const handleChange = (type) => (event, value) => {
		if(value !== undefined) setState({ [type]: value });
		else setState({ [type]: event.target.value });
	};

	const handleSubmit = async () => {
		if(!handleValueCheck()) {
			handleSnackbarChange({ text: 'Invalid inputs', variant: 'error' });
			return window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		};

		setDisable(true);
		let { data, success } = await contests.createContest({
			...state,
			selectedPlans: state.isAllPlans ? [] : state.selectedPlans,
		});
		setDisable(false);
		if(success) {
			handleSnackbarChange({ text: 'Contest created', variant: 'success' });
		} else {
			return handleSnackbarChange({ text: data.message, variant: 'error' });
		}
		routerHistory.push(fullPathname);
	};

	useEffect(() => {
		if(agentList.length) {
			const [ary1, ary2] = agentList;
			setState({ 
				selectedAgents: [
					...ary1.options.map(e => e.value),
					...ary2.options.map(e => e.value),
				]
			});
		}
	}, [agentList, setState]);

		// set default active plans
	useEffect(() => {
		if(planList.length && !state.isAllPlans && !state.selectedPlans.length) {
			const [ary1] = planList; // active plan
			setState({ selectedPlans: ary1.options.map(e => e.value) });
		}
	}, [planList, state.isAllPlans, state.selectedPlans.length, setState]);


	return (
		<Layout title={label}>
			<div className={classes.content}>
				<TextField 
					label="Name"
					value={state.name}
					onChange={handleChange('name')}
					error={Boolean(error.name)}
					helperText={error.name}
				/>

				<DatePicker
					label="Start Date"
					value={state.startDate}
					onChange={handleChange('startDate')}
				/>

				<DatePicker
					label="End Date"
					value={state.endDate}
					onChange={handleChange('endDate')}
				/>

				<TextField 
					label="Minimum ANP"
					value={state.NOCTarget}
					onChange={handleChange('NOCTarget')}
					error={Boolean(error.NOCTarget)}
					helperText={error.NOCTarget}
					type="number"
				/>

				<TextField 
					label="Remarks"
					value={state.remark}
					onChange={handleChange('remark')}
				/>

				<Select 
					label="Type"
					options={contestTypeOptions}
					value={state.type}
					onChange={handleChange('type')}
				/>

				<ExpansionPanel className={classes.expansionRoot}>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="selected-plans"
						id="selected-plans"
					>
						<Typography className={classes.heading}>Show Participants</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails className={classes.expansionPanelDetails}>
						{agentList.map(agent => (
							<Checkboxes 
								key={agent.label}
								title={agent.label}
								value={state.selectedAgents}
								options={agent.options}
								onChange={handleChange('selectedAgents')}
							/>
						))}
					</ExpansionPanelDetails>
				</ExpansionPanel>

				<Checkbox 
					label="Include all plans"
					value={state.isAllPlans}
					onChange={handleChange('isAllPlans')}
				/>

				{!state.isAllPlans && (
					<ExpansionPanel className={classes.expansionRoot}>
						<ExpansionPanelSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="selected-plans"
							id="selected-plans"
						>
							<Typography className={classes.heading}>Show selected plans</Typography>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails className={classes.expansionPanelDetails}>
							{planList.map(plans => (
								<Checkboxes 
									key={plans.label}
									title={plans.label}
									value={state.selectedPlans}
									options={plans.options}
									onChange={handleChange('selectedPlans')}
								/>
							))}
						</ExpansionPanelDetails>
					</ExpansionPanel>
				)}

				<div className={classes.submitButton}>
					<Button
						disabled={disabled}
						variant="contained"
						color="primary"
						onClick={handleSubmit}
					>
						Create
					</Button>
				</div>
			</div>
		</Layout>
	);
}

export default Create;
