import moment from 'moment';
import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {
  Grid,
  Button,
  Typography,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import TextField from '../../../components/TextField';
import DatePicker from '../../../components/DatePicker';
import companyEventApi from '../../../api/companyEvents';
import useSnackbarText from '../../../hooks/useSnackbarText';

const DATE_FORMAT = 'YYYY-MM-DD';

const maxUploadSize = 100 * 1024 * 1024;

const useStyles = makeStyles(theme => ({
  section: {
    borderRadius: 10,
    backgroundColor: `#ffffff`,
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2.5),
  },
  header: { fontWeight: 'bold' },
  fileInput: { display: 'none' },
  row: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  createButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  createButton: {
    color: '#ffffff',
    fontWeight: 600,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  deleteIconButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.error.main,
  },
}));

export default function EventCreatePage() {
  const classes = useStyles();
  const routerHistory = useHistory();
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [inputKey, setInputKey] = useState(Math.random());
  const [info, _setInfo] = useState({
    name: '',
    startAt: moment().startOf('D').format(DATE_FORMAT),
    endAt: moment().endOf('D').format(DATE_FORMAT),
    description: '',
  });

  const handleSnackbarChange = useSnackbarText();

  const handleFileChange = event => {
    const file = event.target.files[0];

    if (file.size > maxUploadSize) {
      handleSnackbarChange({
        text: `File size is too large (Max 100MB)`,
        variant: 'error',
      });
      return;
    }
    setFile(file);
  };

  const handleCreate = async () => {
    if (!info.name.trim()) {
      handleSnackbarChange({
        text: `Event name cannot be empty`,
        variant: 'error',
      });
      return;
    }

    setIsUploading(true);

    const payload = {
      name: info.name,
      startDate: info.startAt,
      endDate: info.endAt,
      description: info.description,
    };

    if (file) {
      payload.file = file;
    }

    const result = await companyEventApi.createCompanyEvent(payload);

    if (result.success) {
      handleSnackbarChange({
        text: `Event has been created`,
        variant: 'success',
      });
      // NOTE: Workaround
      setTimeout(() => {
        routerHistory.push('/admin/company-info/events');
      }, 1000);
      return;
    } else {
      handleSnackbarChange({ text: result.data.message, variant: 'error' });
      setIsUploading(false);
    }
  };

  const handleClearFileInput = () => {
    setFile(null);
    setInputKey(Math.random());
  };

  const setInfo = newInfo => {
    _setInfo(prev => ({ ...prev, ...newInfo }));
  };

  const handleChange = key => event => {
    const value = event.target.value;
    setInfo({ [key]: value });
  };

  const handleDateChange = (key, newValue) => {
    if (key === 'startAt') {
      const value = moment(newValue).startOf('D').format(DATE_FORMAT);
      setInfo({ startAt: value });

      if (value > info.endAt) {
        setInfo({ endAt: value });
      }
    }

    if (key === 'endAt') {
      const value = moment(newValue).endOf('D').format(DATE_FORMAT);
      setInfo({ endAt: value });

      if (value < info.startAt) {
        setInfo({ startAt: value });
      }
    }
  };

  return (
    <div>
      <div className={classes.section}>
        <Button
          component={NavLink}
          to="/admin/company-info/events"
          style={{ fontWeight: 'bold', marginBottom: `10px` }}
        >
          <ChevronLeftIcon />
          Back
        </Button>

        <Typography className={classes.header} variant="h6">
          Create Event
        </Typography>

        <TextField
          label="Event Name"
          value={info.name}
          onChange={handleChange('name')}
          maxWidth="100%"
          required
        />

        <Grid container spacing={2}>
          <Grid xs={12} sm={6} item>
            <DatePicker
              label="Start Date"
              value={info.startAt}
              onChange={(event, value) => handleDateChange('startAt', value)}
              isDateBlocked={date => new Date()}
              autoApply
              required
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <DatePicker
              label="End Date"
              value={info.endAt}
              onChange={(event, value) => handleDateChange('endAt', value)}
              autoApply
              required
            />
          </Grid>
        </Grid>

        <Typography
          variant="h6"
          style={{ marginTop: `10px`, fontWeight: 'bold', fontSize: `1rem` }}
        >
          Description
        </Typography>
        <TextField
          InputProps={{ style: { paddingTop: `6px` } }}
          value={info.description}
          onChange={handleChange('description')}
          maxWidth="100%"
          multiline
          minRows={5}
        />

        <div className={classes.row}>
          <div style={{ flex: 1 }}>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              Image (Please upload 1 image)
            </Typography>
          </div>

          <div style={{ flex: 0 }}>
            <input
              key={inputKey}
              className={classes.fileInput}
              onChange={handleFileChange}
              accept="image/*"
              id="contained-button-file"
              type="file"
            />

            {file ? (
              <div className={classes.row}>
                <Typography color="primary" variant="body2">
                  {file.name}
                </Typography>
                <IconButton
                  className={classes.deleteIconButton}
                  onClick={handleClearFileInput}
                  size="small"
                >
                  <CloseIcon />
                </IconButton>
              </div>
            ) : (
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  style={{ fontWeight: 'bold' }}
                >
                  Upload
                </Button>
              </label>
            )}
          </div>
        </div>
      </div>
      <div className={classes.createButtonWrapper}>
        <Button
          className={classes.createButton}
          onClick={handleCreate}
          variant="contained"
          disabled={isUploading}
        >
          Create
        </Button>
      </div>
    </div>
  );
}
