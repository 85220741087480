import React, { useState, useCallback, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  Divider,
  Collapse,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { makeStyles } from '@material-ui/core/styles';

import useUserInfo from '../../hooks/useUserInfo';

import agents from '../../api/agents';
import configs from '../../configs';
import sitemaps from '../../configs/sitemaps';

const filteredSitemaps = {
  personal: sitemaps.personal.sitemaps.filter(e => !e.hidden),
  company_update: sitemaps.company_update.sitemaps.filter(e => !e.hidden),
  group: sitemaps.group.sitemaps.filter(e => !e.hidden),
  admin: sitemaps.admin.sitemaps.filter(e => !e.hidden),
};

const useStyles = makeStyles(theme => ({
  agency: {
    color: theme.palette.primary.main,
    margin: 0,
    fontWeight: 600,
  },
  toolbar: {
    ...theme.mixins.toolbar,
    padding: 10,
    paddingLeft: 16,
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
  },
  innerList: {
    paddingLeft: 30,
    transition: 'all 0.3s',
  },
  active: {
    color: theme.palette.primary.main,
    borderRight: `3px solid ${theme.palette.primary.light}`,
  },
  mobileActive: {
    borderLeft: `3px solid ${theme.palette.primary.light}`,
    borderRight: 'none',
  },
  viewAsName: {
    fontSize: 10,
  },
}));

function Drawer({ onClose }) {
  const classes = useStyles();
  const userInfo = useUserInfo();
  const [open, setOpen] = useState(
    window.location.pathname.split('/')[1] || 'personal'
  );

  const handleOpen = useCallback(
    type => () => {
      setOpen(prevOpen => {
        if (prevOpen === type) return null;
        else return type;
      });
    },
    []
  );

  const handleCancelViewAs = () => {
    agents.handleCancelViewAs();
    if (onClose) onClose(false);
  };

  const handleListClick = useCallback(() => {
    if (onClose) onClose(false);
  }, [onClose]);

  return (
    <Fragment>
      <div className={classes.toolbar}>
        <p className={classes.agency}>{configs.name}</p>
      </div>
      <Divider />
      <List>
        <ListItem onClick={handleOpen('personal')} dense button>
          <ListItemText
            primary={sitemaps.personal.label}
            classes={{ primary: classes.title }}
          />
          {open === 'personal' ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open === 'personal'} timeout="auto">
          <List>
            {filteredSitemaps.personal.map(e => (
              <ListItem
                onClick={handleListClick}
                className={classes.innerList}
                activeClassName={clsx(
                  classes.active,
                  onClose && classes.mobileActive
                )}
                key={e.pathname}
                component={NavLink}
                to={sitemaps.personal.pathname + e.pathname}
                dense
                button
              >
                <ListItemText primary={e.label} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>
      <Divider />

      <List>
        <ListItem onClick={handleOpen('group')} dense button>
          <ListItemText
            primary={sitemaps.group.label}
            classes={{ primary: classes.title }}
          />
          {open === 'group' ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open === 'group'} timeout="auto">
          <List>
            {filteredSitemaps.group.map(e => (
              <ListItem
                onClick={handleListClick}
                className={classes.innerList}
                activeClassName={clsx(
                  classes.active,
                  onClose && classes.mobileActive
                )}
                key={e.pathname}
                component={NavLink}
                to={sitemaps.group.pathname + e.pathname}
                dense
                button
              >
                <ListItemText primary={e.label} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>
      <Divider />

      <List>
        <ListItem onClick={handleOpen('company_update')} dense button>
          <ListItemText
            primary={sitemaps.company_update.label}
            classes={{ primary: classes.title }}
          />
          {open === 'company_update' ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open === 'company_update'} timeout="auto">
          <List>
            {filteredSitemaps.company_update.map(e => (
              <ListItem
                onClick={handleListClick}
                className={classes.innerList}
                activeClassName={clsx(
                  classes.active,
                  onClose && classes.mobileActive
                )}
                key={e.pathname}
                component={NavLink}
                to={sitemaps.company_update.pathname + e.pathname}
                dense
                button
              >
                <ListItemText primary={e.label} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>
      <Divider />

      {userInfo.isAdmin && (
        <Fragment>
          <List>
            <ListItem onClick={handleOpen('admin')} dense button>
              <ListItemText
                primary={sitemaps.admin.label}
                classes={{ primary: classes.title }}
              />
              {open === 'admin' ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open === 'admin'} timeout="auto">
              <List>
                {filteredSitemaps.admin.map(e => (
                  <ListItem
                    onClick={handleListClick}
                    className={classes.innerList}
                    activeClassName={clsx(
                      classes.active,
                      onClose && classes.mobileActive
                    )}
                    key={e.pathname}
                    component={NavLink}
                    to={sitemaps.admin.pathname + e.pathname}
                    dense
                    button
                  >
                    <ListItemText primary={e.label} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </List>
        </Fragment>
      )}
      <div style={{ flex: 1 }} />
      <Divider />
      <List>
        {userInfo.isViewAs && (
          <ListItem
            onClick={handleCancelViewAs}
            className={classes.innerList}
            dense
            button
          >
            <ListItemText>
              Cancel View As: <br />
              <span className={classes.viewAsName}>{userInfo.name}</span>
            </ListItemText>
          </ListItem>
        )}
        {/*
				<ListItem
					onClick={handleListClick}
					className={classes.innerList} 
					activeClassName={clsx(
						classes.active,
						onClose && classes.mobileActive,
					)}
					component={NavLink} 
					to="/components"
					dense 
					button
				>
					<ListItemText primary="Components" />
				</ListItem>
				*/}
        <ListItem
          onClick={agents.signOut}
          className={classes.innerList}
          dense
          button
        >
          <ListItemText primary="Sign Out" />
        </ListItem>
      </List>
    </Fragment>
  );
}

export default Drawer;
