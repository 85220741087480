import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import TextField from '../../../components/TextField';
import DatePicker from '../../../components/DatePicker';
import Autocomplete from '../../../components/Autocomplete';

import useSnackbarText from '../../../hooks/useSnackbarText';

import { 
	prospectResourceOptions,
	prospectOutcomeOptions,
} from '../../../configs/prospects';
import { getSitemapData } from '../../../utils';
import prospects from '../../../api/prospects';
import { maxWidth } from '../../../components/configs';

const dateFormat = 'YYYY-MM-DD';
const sitemapValue = 'PERSONAL_PROSPECT';
const { label, fullPathname } = getSitemapData(sitemapValue);

const useStyles = makeStyles(theme => ({
	content: {
		backgroundColor: '#fff',
		padding: 10,
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column'
	},
	submitButton: {
		maxWidth,
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: 20,
	}
}));

function Create() {
	const classes = useStyles();
	const handleSnackbarChange = useSnackbarText();
	const routerHistory = useHistory();
	const [state, _setState] = useState({
		prospectDate: moment().format(dateFormat),
		name: '',
		phone: '',
		otherContact: '',
		resource: prospectResourceOptions[0].value,
		outcome: prospectOutcomeOptions[0].value,
		remark: '',
		introducer: ''
	});
	const [error, setError] = useState({});
	const [disabled, setDisable] = useState(false);
	const [introducers, setIntroducers] = useState(null);

	const setState = useCallback((newState = {}) => {
		_setState((prevState) => ({
			...prevState,
			...newState,
		}));
	}, []);

	const handleValueCheck = () => {
		let noError = true;
		let error = {};
		if(!state.name) {
			noError = false;
			error.name = 'Invalid value.';
		}
		if(!state.phone) {
			noError = false;
			error.phone = 'Invalid value.';
		}
		setError(error);
		return noError;
	};

	const handleChange = (type) => (event, value) => {
		if(value !== undefined) setState({ [type]: value });
		else setState({ [type]: event.target.value });
	};

	const handleSubmit = async () => {
		if(!handleValueCheck()) {
			handleSnackbarChange({ text: 'Invalid inputs', variant: 'error' });
			return window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		};

		setDisable(true);
		let { data, success } = await prospects.createProspect(state);
		setDisable(false);
		if(success) {
			handleSnackbarChange({ text: 'New prospect created', variant: 'success' });
		} else {
			return handleSnackbarChange({ text: data.message, variant: 'error' });
		}
		routerHistory.push(fullPathname);
	};

	useEffect(() => {
		(async () => {
			let { data, success } = await prospects.getProspectIntroducers();
			if(success) {
				setIntroducers(data.introducers);
			} else {
				setIntroducers([]);
			}
		})();
	}, []);

	return (
		<Layout title={label}>
			<div className={classes.content}>

				<DatePicker
					label="Date"
					value={state.prospectDate}
					onChange={handleChange('prospectDate')}
				/>

				<TextField 
					label="Name*"
					value={state.name}
					onChange={handleChange('name')}
					error={Boolean(error.name)}
					helperText={error.name}
				/>

				<TextField 
					label="Phone*"
					value={state.phone}
					onChange={handleChange('phone')}
					error={Boolean(error.phone)}
					helperText={error.phone}
					type="tel"
				/>

				<TextField 
					label="Other Contact"
					value={state.otherContact}
					onChange={handleChange('otherContact')}
				/>

				<Select 
					label="Resource"
					options={prospectResourceOptions}
					value={state.resource}
					onChange={handleChange('resource')}
				/>

				<Select 
					label="Outcome"
					options={prospectOutcomeOptions}
					value={state.outcome}
					onChange={handleChange('outcome')}
				/>

				<TextField 
					label="Remarks"
					value={state.remark}
					onChange={handleChange('remark')}
				/>

				<Autocomplete 
					label="Introducer"
					value={state.introducer}
					onChange={handleChange('introducer')}
					loading={introducers === null}
					options={introducers || []}
				/>

				<div className={classes.submitButton}>
					<Button
						disabled={disabled}
						variant="contained"
						color="primary"
						onClick={handleSubmit}
					>
						Submit
					</Button>
				</div>

			</div>
		</Layout>
	);
}

export default Create;
