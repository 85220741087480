import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';

import useQueryParams from '../../../hooks/useQueryParams';
import useFetch from '../../../hooks/useFetch';

import group from '../../../api/group';
import { 
	getYearList, 
	getSitemapData, 
	getFooterTotal,
	getAgentRankingLabel,
} from '../../../utils';

const sitemapValue = 'GROUP_WEEKLYSUBMISSION';
const { label } = getSitemapData(sitemapValue);
const dateDisplayFormat = 'DD/MM';

const startHeaders = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
	},
	{
		id: 'ranking',
		label: 'Ranking',
		type: 'string',
	}
];

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	}
}));

function WeeklySubmission() {
	const classes = useStyles();
	const [queryParams, setQueryParams] = useQueryParams();
	const [scrollTo, setScrollTo] = useState(
		queryParams.year === String(moment().year())
		? `WEEK_${moment().week()}_ANP` : ''
	);
	const [data, setData] = useState([]);
	const [headers, setHeaders] = useState(startHeaders);
	const [footers, setFooters] = useState([]);
	const [res, status, handleFetch] = useFetch(group.getWeeklySubmission);

	const handleChange = (type) => (event) => {
		setQueryParams({ [type]: event.target.value });
	};

	useEffect(() => {
		let tmp = {};
		if(!queryParams.year) 
			tmp.year = moment().year();
		if(queryParams.year === String(moment().year())) {
			setScrollTo(`WEEK_${moment().week()}_ANP`);
		} else {
			setScrollTo('');
		}
		setQueryParams(tmp);
	}, [queryParams.year, setQueryParams]);

	useEffect(() => {
		if(queryParams.year) {
			handleFetch({ year: queryParams.year });
		}
	}, [handleFetch, queryParams.year]);

	useEffect(() => {
		if(status.loaded) {
			if(!status.error) {
				const headers = res.weeks.map(e => ({
					id: `WEEK_${e.week + 1}`,
					label: `Week ${e.week + 1} (${moment(e.startDate).format(dateDisplayFormat)} ~ ${moment(e.endDate).format(dateDisplayFormat)})`,
					type: 'string',
					subheaders: [
						{
							id: `WEEK_${e.week + 1}_ANP`,
							label: 'ANP',
							type: 'float',
							errorCheck: true,
						},
						{
							id: `WEEK_${e.week + 1}_NOC`,
							label: 'NOC',
							type: 'int',
							errorCheck: true,
						},
					]
				}));
				setHeaders([...startHeaders, ...headers]);

				const formattedData = res.submissions.map(e => {
					let { submissions, ranking, ...others } = e;
					let tmp = { 
						ranking: getAgentRankingLabel(ranking),
						...others 
					};

					submissions.forEach((el, j) => {
						tmp[`WEEK_${el.week.week + 1}_ANP`] = el.ANP;
						tmp[`WEEK_${el.week.week + 1}_NOC`] = el.NOC;
					});

					return tmp;
				});
				setData(formattedData);

				setFooters([
					{
						name: 'Agent Total',
						...getFooterTotal(headers, formattedData.filter(e => e.ranking !== 'ROOKIE')),
					},
					{
						name: 'ROOKIE Total',
						...getFooterTotal(headers, formattedData.filter(e => e.ranking === 'ROOKIE')),
					},
					{
						name: 'Grand Total',
						...getFooterTotal(headers, formattedData),
					},
				]);
			} else {
				setData([]);
				setFooters([]);
			}
		}
	}, [res, status.loaded, status.error]);

	return (
		<Layout title={label}>
			<Select 
				className={classes.select}
				maxWidth={150}
				label="Year"
				options={getYearList()}
				value={queryParams.year}
				onChange={handleChange('year')}
			/>
			
			<Table
				headers={headers}
				data={data}
				footers={footers}
				isLoading={status.loading}
				defaultScrollTo={scrollTo}
				disableSearch
				dense
			/>

		</Layout>
	);
}

export default WeeklySubmission;
