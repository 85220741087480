import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
	FormControl,
	FormControlLabel,
	Checkbox as MuiCheckbox
} from '@material-ui/core';

import { maxWidth } from '../configs';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		maxWidth,
	},
}));

function Checkbox(props) {
	const classes = useStyles();

	const {
		label,
		value,
		onChange,
		...others
	} = props;

	const handleChange = useCallback((event) => {
		onChange(event, event.target.checked);
	}, [onChange]);

	return (
		<FormControl className={classes.root} component="fieldset" {...others}>
			<FormControlLabel
				control={<MuiCheckbox color="primary" checked={Boolean(value)} onChange={handleChange} value={label} />}
				label={label}
			/>
		</FormControl>
	);
}

export default Checkbox;
