import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { IconButton, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';
import Dialog from '../../../components/Dialog';

import Summary from './Summary';

import useSnackbarText from '../../../hooks/useSnackbarText';
import useQueryParams from '../../../hooks/useQueryParams';
import useUserInfo from '../../../hooks/useUserInfo';

import submissions from '../../../api/submissions';
import {
  getSitemapData,
  getYearList,
  getObjectFromArray,
} from '../../../utils';
import {
  isInforced,
  submissionStatusOptions,
  submissionModeOptions,
  submissionIsExistingClientOptions,
  submissionHasNomineeOptions,
  submissionCarAssistantOptions,
  submissionMethodOptions,
} from '../../../configs/submissions';

const adminID = 1;
const dateFormat = 'YYYY-MM-DD';
const sitemapValue = 'PERSONAL_SUBMISSION';
const { fullPathname, label } = getSitemapData(sitemapValue);

const headers = [
  {
    id: 'lifeAssured',
    label: 'Life Assured',
    type: 'string',
    sticky: true,
  },
  {
    id: 'submissionDate',
    label: 'Submission Date',
    type: 'date',
  },
  {
    id: 'policyNumber',
    label: 'Policy Number',
    type: 'string',
  },
  {
    id: 'plan',
    label: 'Plan',
    type: 'string',
  },
  {
    id: 'BSA',
    label: 'BSA',
    type: 'float',
  },
  {
    id: 'status',
    label: 'Status',
    type: 'string',
  },
  {
    id: 'issuedDate',
    label: 'Issued Date',
    type: 'date',
  },
  {
    id: 'mode',
    label: 'Mode',
    type: 'string',
  },
  {
    id: 'initial',
    label: 'Initial',
    type: 'float',
  },
  {
    id: 'ANP',
    label: 'ANP',
    type: 'float',
  },
  {
    id: 'solidANP',
    label: 'Solid ANP',
    type: 'float',
  },
  {
    id: 'sANP',
    label: 'S-ANP',
    type: 'float',
  },
  {
    id: 'isExistingClient',
    label: 'Client',
    type: 'string',
  },
  {
    id: 'hasNominee',
    label: 'Nominee',
    type: 'string',
  },
  {
    id: 'carAssistant',
    label: 'Car Assistant',
    type: 'string',
  },
  {
    id: 'method',
    label: 'Method',
    type: 'string',
  },
  {
    id: 'lastEditBy',
    label: 'Edited By',
    type: 'string',
  },
  {
    id: 'editInfo',
    label: 'Edit Info',
    type: 'dom',
  },
  {
    id: 'editQFYLP',
    label: 'Edit QFYLP',
    type: 'dom',
  },
  {
    id: 'remove',
    label: 'Delete',
    type: 'dom',
  },
];

const useStyles = makeStyles(theme => ({
  summaryButton: {
    marginTop: 14,
    marginLeft: 5,
  },
  dialogContentWrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 10,
    marginBottom: 10,
  },
}));

function Main() {
  const classes = useStyles();
  const handleSnackbarChange = useSnackbarText();
  const [queryParams, setQueryParams] = useQueryParams();
  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [timestamp, setTimestamp] = useState(moment().unix());
  const [year, setYear] = useState(
    (queryParams.startDate && moment(queryParams.startDate).year()) ||
      moment().year()
  );

  const userInfo = useUserInfo();
  const isAdminAccount = userInfo.agentID === adminID || userInfo.isViewAs;

  const handleChange = event => {
    setYear(event.target.value);
  };

  const handleDelete = useCallback(
    submissionID => async () => {
      let { data, success } = await submissions.deleteSubmission({
        submissionID,
      });
      if (!success) {
        return handleSnackbarChange({ text: data.message, variant: 'error' });
      } else {
        handleSnackbarChange({
          text: 'Submission deleted',
          variant: 'success',
        });
        setTimestamp(moment().unix());
      }
      setQueryParams({ deleteID: null });
    },
    [handleSnackbarChange, setQueryParams]
  );

  const handleSetDeleteID = useCallback(
    submissionID => () => {
      setQueryParams({ deleteID: submissionID });
    },
    [setQueryParams]
  );

  const handleShowSummary = () => {
    setQueryParams({ summary: 'true' });
  };

  useEffect(() => {
    let startDate, endDate;
    startDate = moment(`${year}-01-01`).format(dateFormat);
    endDate = moment(`${year}-12-31`).format(dateFormat);
    setQueryParams({ startDate, endDate });
  }, [year, setQueryParams]);

  useEffect(() => {
    const getData = async () => {
      const { success, data } = await submissions.getSubmissions({
        startDate: queryParams.startDate,
        endDate: queryParams.endDate,
      });
      if (!success) {
        return handleSnackbarChange({ text: data.message, variant: 'error' });
      } else {
        setRawData(data.submissions);
        setData(
          data.submissions.map(e => ({
            ...e,
            status: getObjectFromArray(submissionStatusOptions, e.status).label,
            mode: getObjectFromArray(submissionModeOptions, e.mode).label,
            carAssistant: getObjectFromArray(
              submissionCarAssistantOptions,
              e.carAssistant
            ).label,
            method: getObjectFromArray(submissionMethodOptions, e.method).label,
            isExistingClient: getObjectFromArray(
              submissionIsExistingClientOptions,
              e.isExistingClient
            ).label,
            hasNominee: getObjectFromArray(
              submissionHasNomineeOptions,
              e.hasNominee
            ).label,
            lastEditBy: e.lastEditBy || '-',
            editInfo: (
              <IconButton
                disabled={!isAdminAccount && e.lastEditorID === adminID}
                component={Link}
                to={`${fullPathname}/update?submissionID=${e.submissionID}`}
              >
                <EditIcon />
              </IconButton>
            ),
            editQFYLP: (
              <IconButton
                disabled={
                  !isInforced(e.status) ||
                  (!isAdminAccount && e.lastEditorID === adminID)
                }
                component={Link}
                to={`${fullPathname}/qfylp-update?submissionID=${e.submissionID}`}
              >
                <EditIcon />
              </IconButton>
            ),
            remove: (
              <IconButton
                disabled={!isAdminAccount && e.lastEditorID === adminID}
                onClick={handleSetDeleteID(e.submissionID)}
              >
                <DeleteIcon />
              </IconButton>
            ),
          }))
        );
      }
    };
    if (queryParams.startDate && queryParams.endDate) getData();
  }, [
    queryParams.startDate,
    queryParams.endDate,
    handleSnackbarChange,
    timestamp,
    handleSetDeleteID,
  ]);

  return (
    <Layout title={label} createPathname={fullPathname + '/create'}>
      <Select
        maxWidth={150}
        label="Year"
        options={getYearList()}
        value={year}
        onChange={handleChange}
      />

      <Button
        className={classes.summaryButton}
        onClick={handleShowSummary}
        color="primary"
        startIcon={<DescriptionIcon />}
        variant="outlined"
      >
        Summary
      </Button>

      <Table headers={headers} data={data} />

      <Dialog title="Delete this submission?" queryParamKey="deleteID">
        <div className={classes.dialogContentWrapper}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDelete(queryParams.deleteID)}
          >
            Delete
          </Button>
          <Button onClick={() => setQueryParams({ deleteID: null })}>
            Cancel
          </Button>
        </div>
      </Dialog>

      <Dialog
        queryParamKey="summary"
        title="Summary"
        DialogProps={{ scroll: 'body' }}
      >
        <Summary data={rawData} />
      </Dialog>
    </Layout>
  );
}

export default Main;
