import React from 'react';
import clsx from 'clsx';
import {
  Divider,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  ListItemIcon,
  SvgIcon,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import Layout from '../../../components/Layout';
import { getSitemapData } from '../../../utils';
import routes from './routes';

const sitemapValue = 'ADMIN_COMPANY_UPDATE';
const { label } = getSitemapData(sitemapValue);

const useStyles = makeStyles(theme => ({
  gridContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  sidebarContainer: {
    borderRadius: 10,
    backgroundColor: `#ffffff`,
    border: `1px solid ${theme.palette.divider}`,
  },
  active: {
    color: theme.palette.primary.main,
    borderRight: `3px solid ${theme.palette.primary.light}`,
  },
  sticky: {
    [theme.breakpoints.up('sm')]: {
      alignSelf: 'start',
      position: 'sticky',
      top: `100px`,
    },
  },
}));

export default function CultureBookPage(props) {
  const classes = useStyles();

  return (
    <Layout title={label}>
      <Grid className={classes.gridContainer} spacing={2} container>
        <Grid className={classes.sticky} xs={12} sm={4} md={3} item>
          <div className={classes.sidebarContainer}>
            <List>
              {routes.map(route => (
                <ListItem
                  key={route.path}
                  activeClassName={classes.active}
                  component={NavLink}
                  to={route.path}
                  button
                >
                  <ListItemIcon>
                    <SvgIcon
                      color="primary"
                      component={route.Icon}
                      viewBox="0 0 48 48"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ variant: 'body2' }}
                    primary={route.label}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
        <Grid xs={12} sm={8} md={9} item>
          {props.children}
        </Grid>
      </Grid>
    </Layout>
  );
}
