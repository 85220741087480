import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { Button, Typography, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import StateItem from './StateItem';

import useQueryParams from '../../../hooks/useQueryParams';
import useSnackbarText from '../../../hooks/useSnackbarText';

import admin from '../../../api/admin';
import { maxWidth } from '../../../components/configs';

// interface State {
//   year: number;
//   ranking: 'EA';
//   isActive: 0 | 1;
//   activeMonths: number[];
// }

const useStyles = makeStyles(theme => ({
  submitButton: {
    maxWidth,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 20,
  },
  loading: {
    marginTop: 50,
    marginBottom: 50,
    color: '#c7c7c7',
  },
  title: {
    fontSize: 18,
    color: theme.palette.primary.main,
  },
  list: {
    maxWidth,
    width: '100%',
  },
}));

const getAllMonths = () =>
  Array(12)
    .fill('')
    .map((_, i) => i + 1);

function StateSection() {
  const classes = useStyles();
  const handleSnackbarChange = useSnackbarText();
  const [queryParams] = useQueryParams();
  const [state, setState] = useState([]);
  const [disabled, setDisable] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const handleChange = useCallback(
    index => type => (event, value) => {
      setState(prevState => {
        let newState = [...prevState];
        newState[index][type] = value;
        return newState;
      });
    },
    []
  );

  const handleSubmit = async () => {
    setDisable(true);
    let { data, success } = await admin.updateAgentStates({
      agentStates: state,
      agentID: queryParams.agentID,
    });
    setDisable(false);
    if (success) {
      handleSnackbarChange({
        text: 'History states updated',
        variant: 'success',
      });
    } else {
      return handleSnackbarChange({ text: data.message, variant: 'error' });
    }
  };

  const handleToggle = useCallback(
    index => newIsActive => {
      setState(prevState => {
        let newState = [...prevState];
        const isActive =
          newIsActive === undefined ? !newState[index].isActive : newIsActive;
        newState[index].isActive = isActive;

        if (isActive) {
          newState[index].activeMonths = getAllMonths();
        } else {
          newState[index].activeMonths = [];
        }

        return newState;
      });
    },
    []
  );

  const handleToggleMonth = (year, month, isActive) => {
    const newState = [...state];
    const currentState = newState.find(state => state.year === year);
    if (!currentState) return;

    const isIncluded = currentState.activeMonths.includes(month);
    if (isActive && !isIncluded) {
      currentState.activeMonths.push(month);
    }

    if (!isActive && isIncluded) {
      currentState.activeMonths = currentState.activeMonths.filter(
        e => e !== month
      );
      if (currentState.activeMonths.length === 0) {
        currentState.isActive = false;
      }
    }

    if (isActive && !currentState.isActive) {
      currentState.isActive = true;
    }

    setState(newState);
  };

  // get agent states
  useEffect(() => {
    const agentID = queryParams.agentID;
    const getData = async () => {
      let { data, success } = await admin.getAgentStates({ agentID });
      if (success) {
        data.agentStates = data.agentStates.sort((a, b) => {
          if (a.year > b.year) return -1;
          else if (a.year < b.year) return 1;
          else return 0;
        });

        setState(
          data.agentStates.map(e => ({
            ranking: e.ranking,
            year: String(e.year),
            isActive: e.isActive === 1,
            activeMonths: e.activeMonths || [],
          }))
        );
        setLoaded(true);
      } else {
        return handleSnackbarChange({ text: data.message, variant: 'error' });
      }
    };

    if (agentID) getData();
  }, [queryParams.agentID, handleSnackbarChange, setState]);

  return (
    <Fragment>
      {!loaded && <div className={classes.loading}>loading</div>}
      {loaded && (
        <Fragment>
          <Typography className={classes.title} variant="h5">
            History states
          </Typography>
          <List className={classes.list}>
            {state.map((e, i) => (
              <StateItem
                key={i}
                data={e}
                onToggle={handleToggle(i)}
                onChange={handleChange(i)}
                onMonthToggle={handleToggleMonth}
              />
            ))}
          </List>

          <div className={classes.submitButton}>
            <Button
              disabled={disabled}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Update
            </Button>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}

export default StateSection;
