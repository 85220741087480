import React, { useCallback } from 'react';
import clsx from 'clsx';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {
	Typography,
	IconButton,
	Dialog as MuiDialog,
	DialogContent,
	DialogTitle as MuiDialogTitle,
} from '@material-ui/core';

import useQueryParams from '../../hooks/useQueryParams';

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingBottom: 0,
		paddingLeft: 22,
	},
	closeButton: {
		alignSelf: 'flex-start',
		//color: theme.palette.secondary.main,
	},
});

const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, title, titleNotice, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography color="primary" variant="h6">
				{title}
				{titleNotice}
			</Typography>
			{!props.disableClose && (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			)}
		</MuiDialogTitle>
	);
});

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	paper: {
		//width: '100%',
		transition: 'all 0.5s',
		[theme.breakpoints.down('xs')]: {
			margin: 10
		}
	},
	largePaper: {
		minWidth: 800,
	},
	mediumPaper: {
		//minWidth: 600,
	}
}));

/*
	@params title
	@params children
	@params queryParamKey
	@params disableClose (optional)
*/

export default function Dialog(props) {
	const classes = useStyles();
	const { title, titleNotice, children, queryParamKey, disableClose, size, maxWidth, DialogProps = {}, ...others } = props;

	const [queryParams, setQueryParams] = useQueryParams();
	const queryParamValue = queryParams[queryParamKey];
	const sizeClasses = (size === 'large') ? classes.largePaper : classes.mediumPaper;

	const handleClose = useCallback(() => {
		if(!disableClose) {
			setQueryParams({ [queryParamKey]: null });
		}
	}, [setQueryParams, disableClose, queryParamKey]);

	return (
		<div>
			<MuiDialog
				onClose={handleClose}
				aria-labelledby="dialog-title"
				open={Boolean(queryParamValue)}
				classes={{ paper: clsx(classes.paper, sizeClasses) }}
				PaperProps={{ style: { maxWidth } }}
				{...DialogProps}
			>
				<DialogTitle id="dialog-title" onClose={handleClose} title={title} titleNotice={titleNotice} />
				<DialogContent {...others}>
					{children}
				</DialogContent>
			</MuiDialog>
		</div>
	);
}
