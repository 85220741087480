import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';

import useQueryParams from '../../../hooks/useQueryParams';
import useFetch from '../../../hooks/useFetch';

import group from '../../../api/group';
import { 
	getYearList, 
	getSitemapData, 
	generateNumber
} from '../../../utils';

const sitemapValue = 'GROUP_KPI';
const { label } = getSitemapData(sitemapValue);

const assignGrade = (_score) => {
	const score = Number(_score);
	let grade;
	if(score >= 100) grade = 'A+';
	else if(score >= 90) grade = 'A';
	else if(score >= 80) grade = 'B';
	else if(score >= 70) grade = 'C';
	else grade = 'F';

	return grade;
};

const startHeaders = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
	},
	{ 
		id: 'ranking',
		label: 'Ranking', 
		type: 'string',
	},
];

const endHeaders = [
	{ 
		id: 'KPIAchieved',
		label: 'KPI Achieve (%)', 
		type: 'float',
	},
	{
		id: 'grade',
		label: 'Grade',
		type: 'string',
		disableSort: true,
	}
];

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	},
	summaryButton: {
		marginTop: 14,
		marginLeft: 5,
	},
	wrapper: {
		borderRadius: theme.shape.borderRadius,
		backgroundColor: '#fff'
	},
	tabs: {
		//backgroundColor: '#fff',
		marginBottom: -10,
	},
	tab: {
	},
	selected: {
		color: theme.palette.primary.main,
		fontWeight: 600,
	},
}));

function KPI() {
	const classes = useStyles();
	const [queryParams, setQueryParams] = useQueryParams();
	const [data, setData] = useState([]);
	const [headers, setHeaders] = useState([...startHeaders, ...endHeaders]);
	const [res, status, handleFetch] = useFetch(group.getKPI);
	const [tab, setTab] = useState(null);
	const [tabOptions, setTabOptions] = useState([]);

	const handleChange = (type) => (event) => {
		setQueryParams({ [type]: event.target.value });
	};

	const handleTabChange = (event, newValue) => {
		setTab(newValue);
	};

	useEffect(() => {
		let tmp = {};
		if(!queryParams.year) tmp.year = moment().year();
		setQueryParams(tmp);
	}, [queryParams.year, setQueryParams]);

	useEffect(() => {
		if(queryParams.year) {
			setTab(0);
			handleFetch({ year: queryParams.year });
		}
	}, [handleFetch, queryParams.year]);

	useEffect(() => {
		if(status.loaded) {
			if(!status.error) {
				const tabOptions = res.KPIStandard.standards.map((e, i) => ({
					...e,
					value: i,
				}));

				setTabOptions(tabOptions);
				if(tabOptions.length && tab === null) setTab(0);

				setHeaders([
					...startHeaders,
					{
						id: 'solidANPLabel',
						label: 'Solid ANP Inforced',
						type: 'string',
						subheaders: [
							{
								id: 'solidANP',
								label: generateNumber(tabOptions[tab].solidANP),
								type: 'float',
							},
							{
								id: 'solidANPValue',
								label: `${generateNumber(tabOptions[tab].solidANPRatio)} %`,
								type: 'float',
							},
						]
					},
					{
						id: 'NOCLabel',
						label: 'NOC Inforced',
						type: 'string',
						subheaders: [
							{
								id: 'NOC',
								label: generateNumber(tabOptions[tab].NOC, 0),
								type: 'int',
							},
							{
								id: 'NOCValue',
								label: `${generateNumber(tabOptions[tab].NOCRatio)} %`,
								type: 'float',
							},
						]
					},
					{
						id: 'rookieLabel',
						label: 'Rookie',
						type: 'string',
						subheaders: [
							{
								id: 'rookie',
								label: generateNumber(tabOptions[tab].rookie, 0),
								type: 'int',
							},
							{
								id: 'rookieValue',
								label: `${generateNumber(tabOptions[tab].rookieRatio)} %`,
								type: 'float',
							},
						]
					},
					...endHeaders,
				]);

				const formattedData = res.KPI.filter(e => e.division === tab).map(e => ({
					...e,
					KPIAchieved: e.solidANPValue + e.NOCValue + e.rookieValue,
					grade: assignGrade(e.solidANPValue + e.NOCValue + e.rookieValue),
				}));

				setData(formattedData);
			} else {
				setData([]);
			}
		}
	}, [res, status.loaded, status.error, tab]);

	return (
		<Layout title={label}>
			<Select 
				className={classes.select}
				maxWidth={110}
				label="Year"
				options={getYearList()}
				value={queryParams.year}
				onChange={handleChange('year')}
			/>

			<div className={classes.wrapper}>
				<Tabs 
					className={classes.tabs}
					value={tab} 
					onChange={handleTabChange} 
					indicatorColor="primary" 
					variant="scrollable"
				>
					{tabOptions.map(e => (
						<Tab key={e.value} classes={{ 
							root: classes.tab,
							selected: classes.selected 
						}} label={e.label} value={e.value} />
					))}
				</Tabs>

				<Table
					headers={headers}
					data={data}
					isLoading={status.loading}
				/>
			</div>

		</Layout>
	);
}

export default KPI;
