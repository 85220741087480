import React from 'react';
import { Switch, Route } from 'react-router-dom';

import EventListPage from './EventListPage';
import EventEditPage from './EventEditPage';
import EventCreatePage from './EventCreatePage';
import { fullPathname } from './routes';

export default function EventPage() {
  return (
    <Switch>
      <Route path={fullPathname + '/events'} exact>
        <EventListPage />
      </Route>

      <Route path={fullPathname + '/events/create'}>
        <EventCreatePage />
      </Route>

      <Route path={fullPathname + '/events/:eventID/edit'}>
        <EventEditPage />
      </Route>
    </Switch>
  );
}
