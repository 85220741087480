import React, { useState, useEffect } from 'react';

import Table from '../../../components/Table';

import useQueryParams from '../../../hooks/useQueryParams';
import useFetch from '../../../hooks/useFetch';

import group from '../../../api/group';
import { getFooterTotal } from '../../../utils';

const adminAgentID = 1;

const headers = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
		disableSort: true,
	},
	{
		id: 'ranking',
		label: 'Ranking',
		type: 'string',
	},
	{
		id: 'target',
		label: 'Target',
		type: 'int',
	},
	{
		id: 'm1',
		label: 'M1',
		type: 'int',
	},
	{
		id: 'm2',
		label: 'M2',
		type: 'int',
	},
	{
		id: 'm3',
		label: 'M3',
		type: 'int',
	},
	{
		id: 'balance',
		label: 'Balance',
		type: 'int',
	},
	{
		id: 'achieveNumber',
		label: 'Achieved',
		type: 'int',
	},
];

const generateHeaders = (quarter) => {
	let _headers = JSON.parse(JSON.stringify(headers));
	switch(String(quarter)) {
		case '1':
			_headers[3].label = 'Jan';
			_headers[4].label = 'Feb';
			_headers[5].label = 'Mar';
			break;
		case '2':
			_headers[3].label = 'Apr';
			_headers[4].label = 'May';
			_headers[5].label = 'Jun';
			break;
		case '3':
			_headers[3].label = 'Jul';
			_headers[4].label = 'Aug';
			_headers[5].label = 'Sep';
			break;
		case '4':
			_headers[3].label = 'Oct';
			_headers[4].label = 'Nov';
			_headers[5].label = 'Dec';
			break;
		default:
			_headers[3].label = '';
			_headers[4].label = '';
			_headers[5].label = '';
	}
	return _headers;
}

function Summary() {
	const [queryParams] = useQueryParams();
	const [data, setData] = useState([]);
	const [footers, setFooters] = useState([]);
	const [res, status, handleFetch] = useFetch(group.getMSASummary);

	useEffect(() => {
		if(queryParams.year && queryParams.quarter) {
			handleFetch({
				year: queryParams.year,
				quarter: queryParams.quarter,
			});
		}
	}, [handleFetch, queryParams.year, queryParams.quarter]);

	useEffect(() => {
		if(status.loaded) {
			if(!status.error) {
				const formattedData = res.summary;
				setData(formattedData);
				setFooters([
					{
						name: 'Direct Total',
						...getFooterTotal(headers, formattedData.filter(e => e.agentID === adminAgentID)),
					},
					{
						name: 'Indirect Total',
						...getFooterTotal(headers, formattedData.filter(e => e.agentID !== adminAgentID)),
					},
					{
						name: 'Grand Total',
						...getFooterTotal(headers, formattedData),
					}
				]);
			} else {
				setData([]);
				setFooters([]);
			}
		}
	}, [res, status.loaded, status.error]);

	return (
		<Table
			headers={generateHeaders(queryParams.quarter)}
			data={data}
			footers={footers}
			isLoading={status.loading}
		/>
	);
}

export default Summary;
