import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    paddingBottom: 30,
  },
  title: {
    fontWeight: 600,
  },
  createButton: {
    color: '#fff',
    borderRadius: 6,
    fontWeight: 600,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  titleWrapper: {
    paddingTop: 5,
    paddingBottom: 5,
    position: 'sticky',
    zIndex: 2,
    top: theme.mixins.toolbar.minHeight,
    backgroundColor: '#fbfbfb',
  },
}));

function Layout(props) {
  const classes = useStyles();
  const { createPathname, onButtonClick, title, ActionComponent } = props;

  return (
    <div className={classes.root}>
      <Container>
        <Grid
          className={classes.titleWrapper}
          container
          justify="space-between"
          alignItems="center"
        >
          {title &&
            (typeof title === 'string' ? (
              <Typography className={classes.title} variant="h6">
                {title}
              </Typography>
            ) : (
              title
            ))}
          {Boolean(createPathname) && (
            <Button
              className={classes.createButton}
              variant="contained"
              component={Link}
              startIcon={<AddIcon />}
              to={createPathname}
            >
              Create
            </Button>
          )}
          {Boolean(onButtonClick) && (
            <Button
              className={classes.createButton}
              onClick={onButtonClick}
              variant="contained"
              startIcon={<AddIcon />}
            >
              Create
            </Button>
          )}
          {ActionComponent || ''}
        </Grid>
        {props.children}
      </Container>
    </div>
  );
}

export default Layout;
