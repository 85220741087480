import axios from 'axios';

import configs from '../configs';
import { updateAccessToken } from './tokens';

const baseURL = configs.apiURL;

const request = async (config, preventRedirect = false) => {
  let requestResult;
  try {
    requestResult = await axios({
      baseURL,
      ...config,
      headers: {
        'content-type': 'application/json',
        authorization: 'Bearer ' + window.localStorage.at,
        ...config.headers,
      },
    });
    return { ...requestResult, success: true };
  } catch (err) {
    console.error(err);
    if (err.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (err.response.status === 401 && !preventRedirect) {
        if (window.localStorage.rt) {
          try {
            const { data } = await updateAccessToken();
            const { at } = data;
            window.localStorage.setItem('at', at);
            if (data.rt) {
              console.log('updating refresh token');
              // depends on if is viewing as
              if (window.localStorage.tt) {
                window.localStorage.setItem('tt', data.rt);
              } else {
                window.localStorage.setItem('rt', data.rt);
              }
            }

            requestResult = await axios({
              baseURL,
              ...config,
              headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + at,
                ...config.headers,
              },
            });

            return { ...requestResult, success: true };
          } catch (err) {
            console.error('Refresh access token failed', err);
          }
        }

        // redirect user to login page
        window.localStorage.removeItem('user');
        const redirectURL = window.location.pathname + window.location.search;
        const loginURL = `${
          configs.loginPath
        }?redirect=${window.encodeURIComponent(redirectURL)}`;

        // clean up
        window.localStorage.removeItem('at');
        window.localStorage.removeItem('rt');
        window.localStorage.removeItem('tt');
        window.localStorage.removeItem(configs.context.main);
        window.localStorage.removeItem(configs.context.user);
        window.localStorage.removeItem('originUserInfo');
        if (window.__history) {
          window.__history.push(loginURL);
        } else {
          window.location.href = window.location.origin + loginURL;
        }
        return {
          success: false,
          data: { message: 'Session expired, please sign in.' },
        };
      }
      return {
        ...err.response,
        success: false,
        data: {
          message:
            err.response.data.message || JSON.stringify(err.response.data),
        },
      };
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error', err.message);
      return { success: false, data: err.message };
    }
  }
};

export default request;
