import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import TextField from '../../../components/TextField';

import CreateDialog from './CreateDialog';
import UpdateDialog from './UpdateDialog';

import useSnackbarText from '../../../hooks/useSnackbarText';
import useQueryParams from '../../../hooks/useQueryParams';

import { maxWidth } from '../../../components/configs';
import { msaRankingOptions } from '../../../configs/msa';
import msa from '../../../api/msa';
import { 
	getYearList, 
	getSitemapData, 
	getObjectFromArray,
	flattenArray,
} from '../../../utils';

const sitemapValue = 'ADMIN_MSA';
const { label } = getSitemapData(sitemapValue);

const quarterOptions = [
	{ label: 'Quarter 1', value: '1' },
	{ label: 'Quarter 2', value: '2' },
	{ label: 'Quarter 3', value: '3' },
	{ label: 'Quarter 4', value: '4' },
];

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	},
	textField: {
		margin: 8,
		'& input': {
			textAlign: 'right'
		}
	},
	container: {
		backgroundColor: '#fff',
		maxWidth
	},
	actionPanel: {
		display: 'flex',
		//alignItems: 'flex-end',
		justifyContent: 'flex-start'
	},
	submitButton: {
		marginTop: 8,
		marginBottom: 8,
		marginLeft: 10
	}
}));

function MSA() {
	const classes = useStyles();
	const [queryParams, setQueryParams] = useQueryParams();
	const handleSnackbarChange = useSnackbarText();
	const [standards, setStandards] = useState([]);
	const [disabled, setDisable] = useState(false);
	const [NOCTarget, setNOCTarget] = useState('');

	const handleYearChange = (event) => {
		setQueryParams({ year: event.target.value });
	};

	const handleQuarterChange = (event) => {
		setQueryParams({ quarter: event.target.value });
	};

	const handleSubmit = async () => {
		let allRankings = flattenArray(...standards.map(e => e.rankings));
		if(allRankings.length !== msaRankingOptions.length) {
			let tmp = msaRankingOptions.map(e => e.value);
			allRankings.forEach(e => {
				const index = tmp.indexOf(e);
				if(index !== -1) tmp.splice(index, 1);
			});

			tmp = tmp.map(e => getObjectFromArray(msaRankingOptions, e).label);

			return handleSnackbarChange({ 
				text: `Some rankings (${tmp.join(', ')}) are missing`, 
				variant: 'error' 
			});
		}
		setDisable(true);

		const { success, data } = await msa.updateMSA({
			NOCTarget: NOCTarget,
			standards: standards,
			year: queryParams.year,
			quarter: queryParams.quarter,
		});

		setDisable(false);
		if(success) {
			handleSnackbarChange({ text: 'MSA updated', variant: 'success' });
		} else {
			handleSnackbarChange({ text: data.message, variant: 'error' });
		}
	};

	const handleToggleDialog = () => {
		setQueryParams({ 
			createDialog: (queryParams.createDialog !== 'true') || null,
		});
	};

	const handleCreateStandard = (newStandard) => {
		setStandards(preStandard => ([...preStandard, newStandard]));
	};

	const handleSetIndex = (index) => () => {
		setQueryParams({ updateDialog: index });
	};

	// get msa awards
	useEffect(() => {
		const getData = async () => {
			let { data, success } = await msa.getMSA({
				year: queryParams.year,
				quarter: queryParams.quarter,
			});

			if(success) {
				setStandards(data.standards);
				setNOCTarget(data.NOCTarget || '');
			} else {
				return handleSnackbarChange({ text: data.message, variant: 'error' });
			}
		};

		if(queryParams.year && queryParams.quarter) getData();
	}, [queryParams.year, queryParams.quarter, handleSnackbarChange]);
	
	// set default params
	useEffect(() => {
		if(queryParams.year && queryParams.quarter) return;

		let newQueryParams = {
			year: queryParams.year,
			quarter: queryParams.quarter,
		};

		if(!queryParams.year) newQueryParams.year = moment().year();
		if(!queryParams.quarter) newQueryParams.quarter = moment().quarter();
		setQueryParams(newQueryParams);
	}, [queryParams.year, queryParams.quarter, setQueryParams]);

	return (
		<Layout title={label} onButtonClick={handleToggleDialog}>
			<Select 
				className={classes.select}
				maxWidth={110}
				label="Year"
				options={getYearList()}
				value={queryParams.year}
				onChange={handleYearChange}
			/>

			<Select 
				className={classes.select}
				maxWidth={150}
				label="Quarter"
				options={quarterOptions}
				value={queryParams.quarter}
				onChange={handleQuarterChange}
			/>

			{Boolean(standards.length) && (
				<List className={classes.container} dense>
					{standards.map((e, i) => (
						<ListItem key={i} onClick={handleSetIndex(i)} button>
							<ListItemText
								primary={e.label}
								secondary={e.target}
							/>
						</ListItem>
					))}
				</List>
			)}

			<div className={classes.actionPanel}>
				<TextField
					label="Minimum ANP"
					value={NOCTarget}
					onChange={event => setNOCTarget(event.target.value)}
					maxWidth={150}
				/>
				<Button 
					disabled={disabled}
					className={classes.submitButton}
					color="primary" 
					variant="contained" 
					onClick={handleSubmit}
				>
					Update
				</Button>
			</div>

			<CreateDialog standards={standards} onCreateStandard={handleCreateStandard} />
			<UpdateDialog standards={standards} onSetStandards={setStandards} />
		</Layout>
	);
}

export default MSA;
