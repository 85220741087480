import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Button, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Layout from '../../../components/Layout';
import Table from '../../../components/Table';

import useSnackbarText from '../../../hooks/useSnackbarText';

import agents from '../../../api/agents';
import admin from '../../../api/admin';
import tableBlacklist from '../../../api/tableBlacklist';
import { 
	getSitemapData, 
	getObjectFromArray,
	capitalizeFirstLetter,
	getAgentRankingLabel,
} from '../../../utils';

const sitemapValue = 'ADMIN_AGENT';
const { fullPathname, label } = getSitemapData(sitemapValue);

const headers = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
	},
	{
		id: 'introducer',
		label: 'Introducer',
		type: 'string',
	},
	{ 
		id: 'joinGEDate',
		label: 'Join Date', 
		type: 'date',
	},
	{ 
		id: 'ranking',
		label: 'Ranking', 
		type: 'string',
	},
	/*
	{ 
		id: 'direct',
		label: 'Direct', 
		type: 'string',
	},
	*/
	{ 
		id: 'accountNumber',
		label: 'Account Number', 
		type: 'string',
	},
	{ 
		id: 'edit',
		label: 'Edit', 
		type: 'dom',
	},
	{ 
		id: 'viewAs',
		label: 'View As', 
		type: 'dom',
	},
	{ 
		id: 'isHidden',
		label: 'Visible In Table', 
		type: 'dom',
	},
];

const useStyles = makeStyles(theme => ({
}));

function Main() {
	// eslint-disable-next-line
	const classes = useStyles();
	const [isUpdating, setIsUpdating] = useState(false);
	const handleSnackbarChange = useSnackbarText();
	const [data, setData] = useState([]);
	const [blacklistIDs, setBlacklistIDs] = useState([]);

	const handleViewAs = useCallback((agentID) => () => {
		agents.handleSetViewAs(agentID);
	}, []);

	const handleToggleSwitch = (agentID) => async () => {
		setIsUpdating(true);
		const isExist = blacklistIDs.includes(agentID);

		const { success, data } = await tableBlacklist.updateDiaryPerformanceBlacklist({
			agentID,
			isBlacklisted: !isExist,
		});

		if(!success) {
			handleSnackbarChange({ text: data.message, variant: 'error' });
		} else {
			if(isExist) {
				// remove it
				setBlacklistIDs(prev => prev.filter(e => e !== agentID));
			} else {
				// add it
				setBlacklistIDs(prev => [...prev, agentID]);
			}
		}

		setIsUpdating(false);
	};

	useEffect(() => {
		const getData = async () => {
			const { success, data } = await admin.getAgents();
			if(!success) {
				return handleSnackbarChange({ text: data.message, variant: 'error' });
			} else {
				setData(data.agents.map(e => ({
					...e,
					name: capitalizeFirstLetter(e.name),
					ranking: getAgentRankingLabel(e.ranking),
					introducer: capitalizeFirstLetter(getObjectFromArray(
							data.agents, 
							e.introducerID, 
							'agentID',
							false,
						).name),
				})));
			}
		};

		const getDiaryPerformanceBlacklist = async () => {
			setIsUpdating(true);
			const { success, data } = await tableBlacklist.getDiaryPerformanceBlacklist();
			if(!success) {
				handleSnackbarChange({ text: data.message, variant: 'error' });
			} else {
				setBlacklistIDs(data.agents.map(e => e.agentID));
			}
			setIsUpdating(false);
		};

		getData();
		getDiaryPerformanceBlacklist();
	}, [handleSnackbarChange, handleViewAs]);

	const reformattedData = data.map(e => ({
		...e,
		edit: 
			<IconButton
				component={Link}
				to={`${fullPathname}/update?agentID=${e.agentID}`}
			>
				<EditIcon />
			</IconButton>,
		viewAs: 
			<IconButton onClick={handleViewAs(e.agentID)}>
				<VisibilityIcon />
			</IconButton>,
		isHidden:
			<Switch
				onChange={handleToggleSwitch(e.agentID)}
				checked={!blacklistIDs.includes(e.agentID)} 
				disabled={isUpdating}
			/>
	}));

	return (
		<Layout title={label} createPathname={fullPathname + '/create'}>
			<Button 
				color="primary"
				component={Link} 
				to={fullPathname + '/sort'}
			>
				Modify agents order
			</Button>
			<Table
				headers={headers}
				data={reformattedData}
				tableState={{ orderBy: 'status' }}
			/>
		</Layout>
	);
}

export default Main;
