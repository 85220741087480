import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { 
	Grid,
	Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { cellSize } from './configs';

const emptyFunction = () => {};

const useStyles = makeStyles(theme => ({
	root: {
		marginTop: 4,
	},
	date: {
		fontSize: 13,
		width: cellSize,
		height: cellSize,
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '50%',
		transition: 'all 0.3s',
		cursor: 'pointer',
		userSelect: 'none',
	},
	hover: {
		'&:hover': {
			backgroundColor: '#e4e7e7',
		}
	},
	cellWrapper: {
		width: cellSize,
		height: cellSize,
		display: 'flex',
		alignItems: 'center',
	},
	isSelected: {
		borderRadius: '50%',
		color: 'white',
		backgroundColor: theme.palette.primary.main,
		'& $hover:hover': {
			backgroundColor: theme.palette.primary.dark,
		}
	},
	isBlocked: {
		cursor: 'default',
		backgroundColor: 'transparent',
		opacity: 0.5,
		'&$hover:hover': {
			backgroundColor: 'transparent',
		}
	}
}));

function DateItem({ date, onClick, isSelected, isBlocked, isHighlighted, highlightClassName }) {
	const classes = useStyles();

	return (
		<div className={classes.cellWrapper}>
			<Grid 
				className={clsx(
					classes.cell,
					isSelected && classes.isSelected,
				)}
				container 
				direction="column" 
			>
				<Typography 
					className={clsx(
						classes.date,
						date && classes.hover,
						isHighlighted && highlightClassName,
						isBlocked && classes.isBlocked,
					)}
					onClick={isBlocked ? emptyFunction : onClick}
				>
					{date && moment(date).format('D')}
				</Typography>
			</Grid>
		</div>
	);
}

export default DateItem;
