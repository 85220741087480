import React, { useState, useEffect, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Checkboxes from '../../../components/Checkboxes';
import TextField from '../../../components/TextField';
import Dialog from '../../../components/Dialog';

import useSnackbarText from '../../../hooks/useSnackbarText';
import useQueryParams from '../../../hooks/useQueryParams';

import { maxWidth } from '../../../components/configs';
import { msaRankingOptions } from '../../../configs/msa';
import { 
	getObjectFromArray,
	flattenArray,
} from '../../../utils';

const defaultState = {
	label: '',
	target: '',
	rankings: []
};

const useStyles = makeStyles(theme => ({
	submitButton: {
		maxWidth,
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: 20,
		marginBottom: 20,
	},
}));

function UpdateDialog(props) {
	const classes = useStyles();
	const [queryParams, setQueryParams] = useQueryParams();
	const handleSnackbarChange = useSnackbarText();
	const [state, _setState] = useState(defaultState);
	const [error, setError] = useState({});

	const handleChange = (type) => (event, value) => {
		if(value !== undefined) setState({ [type]: value });
		else setState({ [type]: event.target.value });
	};

	const setState = useCallback((newState) => {
		_setState((prevState) => ({
			...prevState,
			...newState,
		}));
	}, []);

	const handleValueCheck = () => {
		let noError = true;
		let error = {};
		if(!state.label) {
			noError = false;
			error.label = 'Invalid value.';
		}
		if(!state.target) {
			noError = false;
			error.target = 'Invalid value.';
		} else {
			let validFormat = true;
			try {
				const total = state.target.split('/');
				if(total.length !== 2) throw new Error('Invalid format');
				if(total[0].split('*').length !== 3) throw new Error('Invalid month value');
				console.log(total)
			} catch(err) {
				validFormat = false;
			}
			if(!validFormat) error.target = 'Invalid format.'
		}

		setError(error);
		return noError;
	};

	const handleUpdate = () => {
		if(!handleValueCheck()) {
			return handleSnackbarChange({ text: 'Invalid inputs', variant: 'error' });
		};

		let allRankings = [...props.standards.map(e => e.rankings)];
		allRankings.splice(queryParams.updateDialog, 1);
		allRankings = flattenArray(...allRankings);
		let isConflict = null;

		for(let i = 0; i < state.rankings.length; i++) {
			const index = allRankings.indexOf(state.rankings[i]);
			if(index !== -1) {
				isConflict = state.rankings[i];
				break;
			}
		}

		if(isConflict === null) {
			props.onSetStandards(prevState => {
				let newState = [...prevState];
				newState[parseInt(queryParams.updateDialog)] = state;
				return newState;
			});
			setQueryParams({ updateDialog: null });
		} else {
			handleSnackbarChange({ 
				text: `Ranking "${getObjectFromArray(msaRankingOptions, isConflict).label}" has duplicated, please remove it`,
				variant: 'error'
			});
		}
	};

	useEffect(() => {
		if(!queryParams.updateDialog) _setState(defaultState);
		else if(props.standards && props.standards.length) {
			const newState = props.standards[parseInt(queryParams.updateDialog)];
			if(newState) _setState(newState);
		}
	}, [props.standards, queryParams.updateDialog]);

	return (
		<Dialog title="Update Award" queryParamKey="updateDialog" maxWidth={maxWidth}>
			<TextField
				label="Name"
				value={state.label}
				onChange={handleChange('label')}
				placeholder="Active 4"
				error={Boolean(error.label)}
				helperText={error.label}
			/>

			<TextField
				label="Target"
				value={state.target}
				onChange={handleChange('target')}
				placeholder="4*4*4/16"
				error={Boolean(error.target)}
				helperText={error.target}
			/>

			<Checkboxes 
				title="Rankings"
				value={state.rankings}
				options={msaRankingOptions}
				onChange={handleChange('rankings')}
			/>

			<div className={classes.submitButton}>
				<Button
					variant="contained"
					color="primary"
					onClick={handleUpdate}
				>
					Update
				</Button>
			</div>
		</Dialog>
	);
}

export default UpdateDialog;
