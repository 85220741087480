import React from 'react';
import moment from 'moment';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import PieChart from '../../../components/PieChart';
import Dialog from '../../../components/Dialog';

import useQueryParams from '../../../hooks/useQueryParams';
import { generateNumber } from '../../../utils';

const BOX_SIZE = 20;
const DATE_FORMAT = 'D MMM YYYY';
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#44c734', '#bd60e4', '#e46061'];

const ColorBox = ({ color }) => {
	return (
		<div style={{
			background: color,
			height: BOX_SIZE,
			width: BOX_SIZE,
		}} />
	);
}

const useStyles = makeStyles(theme => ({
	container: {
		minWidth: 300,
		width: `calc(100vw - 100px)`
	},
	information: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
	name: {
		fontWeight: 600
	},
	gridTable: {
		margin: 'auto',
		maxWidth: 500,
		display: 'flex',
		flexDirection: 'column',
	},
	gridRow: {
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
		display: 'flex',
	},
	gridColorCol: {
		flex: 0.5,
		justifyContent: 'center',
		display: 'flex',
		alignItems: 'center',
	},
	gridCol: {
		flex: 1,
		fontSize: 12,
		display: 'flex',
		alignItems: 'center',
	},
	point: {
		color: `#003563`,
		fontSize: `6rem`,
		fontWeight: 600,
	},
}));

const DiaryPieChart = (props) => {
	const classes = useStyles();
	const { diaryData } = props;
	const [queryParams] = useQueryParams();

	let pieData = [];
	const agentID = queryParams.pieChartID;
	const agentDiaryData = diaryData.find(e => e.agentID === Number(agentID));

	if(agentDiaryData) {
		pieData = [{
			name: 'Open',
			value: agentDiaryData.statusOpen,
		}, {
			name: 'Close / Submissions',
			value: Math.max(agentDiaryData.statusClose, (agentDiaryData.submissions || 0)),
		}, {
			name: 'Fact Finding',
			value: agentDiaryData.statusFactFinding,
		}, {
			name: 'Policy Review',
			value: agentDiaryData.statusPolicyReview,
		}, {
			name: 'Meal Appointment',
			value: agentDiaryData.statusMealAppointment,
		}, {
			name: 'Recruitment',
			value: agentDiaryData.statusRecruitment,
		}, {
			name: 'Prospects',
			value: agentDiaryData.prospects,
		}];
	}

	return (
		<Dialog queryParamKey="pieChartID" title="Pie Chart" maxWidth="100%">
			{!(agentDiaryData && agentDiaryData.total) 
				? <Typography>No performance points</Typography>
				: <div className={classes.container}>
					<Grid container>
						<Grid xs={12} md={6} item>
							<PieChart pieData={pieData} />

							<div className={classes.gridTable}>
								<div className={classes.gridRow}>
									<div className={classes.gridColorCol} />
									<div className={classes.gridCol}>Title</div>
									<div className={classes.gridCol}>Points</div>
									<div className={classes.gridCol}>Percentage</div>
								</div>

								{pieData.map((e, i) => (
									<div key={e.name} className={classes.gridRow}>
										<div className={classes.gridColorCol}><ColorBox color={COLORS[i]} /></div>
										<div className={classes.gridCol}>{e.name}</div>
										<div className={classes.gridCol}>{e.value}</div>
										<div className={classes.gridCol}>{generateNumber(e.value * 100 / agentDiaryData.total)} %</div>
									</div>
								))}

							</div>
						</Grid>
						<Grid className={classes.information} xs={12} md={6} item>
							<Typography className={classes.point} variant="h5">
								{agentDiaryData.total}
								<Typography variant="caption">Points</Typography>
							</Typography>
							<Typography className={classes.name} variant="h4">{agentDiaryData.name}</Typography>
							<Typography variant="subtitle1">
								{`${moment(queryParams.startDate).format(DATE_FORMAT)} - ${moment(queryParams.endDate).format(DATE_FORMAT)}`}
							</Typography>
							{/* <Typography variant="h5">Total Points: {agentDiaryData.total}</Typography> */}
						</Grid>
					</Grid>
				</div>
			}
		</Dialog>
	);
};

export default DiaryPieChart;
