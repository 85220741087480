import React from 'react';

import Navbar from './Navbar';
/*
import Footer from './Footer';
*/

function WithNavigation(props) {
	return (
		<Navbar>
			{props.children}
		</Navbar>
	);
}

export default WithNavigation;
