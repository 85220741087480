import React from 'react';
import { Switch, Route } from 'react-router-dom';

import EventPage from './EventPage';
import TrainingPage from './TrainingPage';
import CultureBookPage from './CultureBookPage';
import ActivityPage from './ActivityPage';
import SalesReportPage from './SalesReportPage';

import sitemaps from '../../configs/sitemaps';

const companyMap = {
  COMPANYUPDATE_SALES_REPORT: SalesReportPage,
  COMPANYUPDATE_EVENT: EventPage,
  COMPANYUPDATE_TRAINING: TrainingPage,
  COMPANYUPDATE_CULTURE: CultureBookPage,
  COMPANYUPDATE_ACTIVITY: ActivityPage,
};

const companySitemaps = sitemaps.company_update.sitemaps
  .filter(e => !e.hidden)
  .map(e => ({
    fullPathname: sitemaps.company_update.pathname + e.pathname,
    Component: companyMap[e.value],
  }));

export default function CompanyUpdatePage() {
  return (
    <Switch>
      {companySitemaps.map(sitemap => (
        <Route key={sitemap.fullPathname} path={sitemap.fullPathname}>
          <sitemap.Component />
        </Route>
      ))}

      <Route>
        <div> 404 Page Not found</div>
      </Route>
    </Switch>
  );
}
