import { useEffect, useState } from 'react';
import moment from 'moment';

import useSnackbarText from './useSnackbarText';
import group from '../api/group';

function useContestList(year) {
	const [contests, setContests] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const handleSnackbarChange = useSnackbarText();
	
	useEffect(() => {
		const getContests = async () => {
			setContests([]);
			let { data, success } = await group.getContests({
				year: year || moment().year()
			});
			if(success) {
				const formattedData = data.contests.map(e => ({
					...e,
					value: String(e.contestID),
					label: e.name,
				}));

				setContests(formattedData);
			} else {
				return handleSnackbarChange({ text: data.message, variant: 'error' });
			}
		}
		getContests();
	}, [year, handleSnackbarChange]);

	useEffect(() => {
		setFilteredData(contests.filter(e => 
			(moment(e.startDate).year() <= parseInt(year))
			&& (moment(e.startDate).year() >= parseInt(year))
		));
	}, [year, contests]);

	return filteredData;
}

export default useContestList;
