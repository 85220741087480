import React, { useState, useEffect, useCallback, Fragment } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Button, Grid, List, ListItem, ListItemText, Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import TextField from '../../../components/TextField';

import useQueryParams from '../../../hooks/useQueryParams';
import useSnackbarText from '../../../hooks/useSnackbarText';

import { 
	submissionStatusOptions,
	submissionModeOptions,
	submissionIsExistingClientOptions,
	submissionHasNomineeOptions,
	submissionCarAssistantOptions,
	submissionMethodOptions, 
} from '../../../configs/submissions';
import { getYearList, getSitemapData, generateNumber, getObjectFromArray } from '../../../utils';
import submissions from '../../../api/submissions';
import { maxWidth } from '../../../components/configs';

const dateFormat = 'YYYY-MM-DD';
const dateDisplayFormat = 'DD/MM/YYYY';
const sitemapValue = 'PERSONAL_SUBMISSION';
const { label, fullPathname } = getSitemapData(sitemapValue);

const generateOffset = (rawData) => {
	const data = String(rawData);
	return data.length === 2 ? data : `0${data}`;
};

const monthOptions = moment.monthsShort().map((e, i) => ({
	label: e,
	value: String(i + 1),
}));

const yearOptions = [
	...getYearList(),
	{ label: String(moment().year() + 1), value: String(moment().year() + 1) },
];

const useStyles = makeStyles(theme => ({
	content: {
		backgroundColor: '#fff',
		padding: 10,
	},
	titleWrapper: {
		maxWidth,
		display: 'flex',
		justifyContent: 'space-between',
	},
	submitButton: {
		maxWidth,
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: 20,
		marginRight: 5,
	},
	loading: {
		marginTop: 50,
		marginBottom: 50,
		color: '#c7c7c7'
	},
	QFYLPList: {
		display: 'flex',
		justifyContent: 'center',
		maxWidth,
	},
	QFYLPItem: {
		marginLeft: 5,
		marginRight: 5,
	},
	deleteButtonWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	createButton: {
		color: '#fff',
		borderRadius: 6,
		fontWeight: 600,
		backgroundColor: theme.palette.success.main,
		'&:hover': {
			backgroundColor: theme.palette.success.dark,
		}
	},
}));

function QFYLPUpdate() {
	const classes = useStyles();
	const handleSnackbarChange = useSnackbarText();
	const routerHistory = useHistory();
	const [queryParams] = useQueryParams();
	const [state, _setState] = useState({
		submissionDate: moment().format(dateFormat),
		lifeAssured: '',
		policyNumber: '',
		planID: '',
		BSA: '',
		status: submissionStatusOptions[0].value,
		issuedDate: null,
		mode: submissionModeOptions[0].value,
		initial: '',
		isExistingClient: submissionIsExistingClientOptions[0].value,
		hasNominee: submissionHasNomineeOptions[0].value,
		carAssistant: submissionCarAssistantOptions[0].value,
		method: submissionMethodOptions[0].value,
	});
	const [QFYLP, setQFYLP] = useState([]);
	const [disabled, setDisable] = useState(false);
	const [loaded, setLoaded] = useState(false);

	const setState = useCallback((newState = {}) => {
		_setState((prevState) => ({
			...prevState,
			...newState,
		}));
	}, []);

	const handleChange = (index, type) => (event) => {
		const value = event.target.value;
		setQFYLP(prevState => {
			let tmp = [...prevState];
			tmp[index][type] = value;
			return tmp;
		});
	};

	const handleDelete = (index) => () => {
		setQFYLP(prevState => {
			let tmp = [...prevState];
			tmp.splice(index, 1);
			return tmp;
		});
	};

	const handleSubmit = async () => {
		setDisable(true);
		const formattedQFYLP = QFYLP
			.filter(e => Boolean(e.year && e.month && e.value))
			.map(e => ({
				...e,
				isCarryForward: parseInt(e.year) > moment(state.issuedDate).year()
			}));
		let { data, success } = await submissions.updateSubmissionQFYLP({
			qfylp: formattedQFYLP,
			submissionID: queryParams.submissionID,
		});
		setDisable(false);
		if(success) {
			handleSnackbarChange({ text: 'QFYLP updated', variant: 'success' });
		} else {
			return handleSnackbarChange({ text: data.message, variant: 'error' });
		}
		routerHistory.push(fullPathname);
	};

	const handleCreate = () => {
		setQFYLP(prevState => {
			let tmp = [
				...prevState,
				{ year: '', month: '', value: '' }
			];
			return tmp;
		});
	};

	// set details
	useEffect(() => {
		const submissionID = queryParams.submissionID;
		const getData = async () => {
			let { data, success } = await submissions.getSubmission({ submissionID });
			if(success) {
				setState({
					...data,
					planID: String(data.planID),
					status: String(data.status),
					mode: String(data.mode),
					isExistingClient: String(data.isExistingClient),
					hasNominee: String(data.hasNominee),
					carAssistant: String(data.carAssistant),
					method: String(data.method),
				});
			} else {
				return handleSnackbarChange({ text: data.message, variant: 'error' });
			}
		}

		if(submissionID) getData();
	}, [queryParams.submissionID, handleSnackbarChange, setState])

	// set QFYLP
	useEffect(() => {
		const submissionID = queryParams.submissionID;
		const getData = async () => {
			let { data, success } = await submissions.getSubmissionQFYLP({ submissionID });
			if(success) {
				let qfylp = data.qfylp.map(e => ({
					...e,
					year: String(e.year),
					month: String(e.month),
					value: String(e.value),
				}));

				setQFYLP(qfylp.sort((a, b) => {
					const dateA = `${a.year}-${generateOffset(a.month)}`;
					const dateB = `${b.year}-${generateOffset(b.month)}`;
					if(dateA > dateB) return 1;
					if(dateA < dateB) return -1;
					return 0;
				}));
				setLoaded(true);
			} else {
				return handleSnackbarChange({ text: data.message, variant: 'error' });
			}
		}

		if(submissionID) getData();
	}, [queryParams.submissionID, handleSnackbarChange, setLoaded]);

	return (
		<Layout title={label}>
			<div className={classes.content}>
				<Grid container>
					<Grid item xs={12} md={6}>
						<List>
							<ListItem dense>
								<ListItemText primary="Life Assured" secondary={state.lifeAssured} />
							</ListItem>
							<ListItem dense>
								<ListItemText primary="Policy Number" secondary={state.policyNumber} />
							</ListItem>
							<ListItem dense>
								<ListItemText primary="Initial" secondary={generateNumber(state.initial)} />
							</ListItem>
							<ListItem dense>
								<ListItemText primary="ANP" secondary={generateNumber(state.ANP)} />
							</ListItem>
							<ListItem dense>
								<ListItemText primary="Mode" secondary={getObjectFromArray(submissionModeOptions, state.mode).label} />
							</ListItem>
							<ListItem dense>
								<ListItemText primary="Issued Date" secondary={moment(state.issuedDate).format(dateDisplayFormat)} />
							</ListItem>
						</List>
					</Grid>
					<Grid item xs={12} md={6}>
						<div className={classes.titleWrapper}>
							<Typography color="primary" variant="h6">QFYLP</Typography>
							<Button 
								className={classes.createButton} 
								onClick={handleCreate}
								startIcon={<AddIcon />}
							>
								Create
							</Button>
						</div>
						{loaded && !QFYLP.length && 'No QFYLP list'}
						{QFYLP.map((e, i) => (
							<div key={i} className={classes.QFYLPList}>
								<Select 
									className={classes.QFYLPItem}
									label="Year"
									maxWidth={110}
									options={yearOptions}
									value={e.year}
									onChange={handleChange(i, 'year')}
								/>

								<Select 
									className={classes.QFYLPItem}
									label="Month"
									maxWidth={110}
									options={monthOptions}
									value={e.month}
									onChange={handleChange(i, 'month')}
								/>

								<TextField 
									className={classes.QFYLPItem}
									label="Value"
									maxWidth={150}
									value={e.value}
									onChange={handleChange(i, 'value')}
									type="number"
								/>
								<div className={classes.deleteButtonWrapper}>
									<IconButton onClick={handleDelete(i)}>
										<DeleteIcon />
									</IconButton>
								</div>
							</div>
						))}

						{!loaded && <div className={classes.loading}>loading</div>}
						{loaded && (
							<Fragment>
								<div className={classes.submitButton}>
									<Button
										disabled={disabled}
										variant="contained"
										color="primary"
										onClick={handleSubmit}
									>
										Update
									</Button>
								</div>
							</Fragment>
						)}
					</Grid>
				</Grid>

			</div>
		</Layout>
	);
}

export default QFYLPUpdate;
