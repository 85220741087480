import React, { useState, useEffect, useCallback, Fragment } from 'react';
import moment from 'moment';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Select from '../../../components/Select';
import Checkbox from '../../../components/Checkbox';
import TextField from '../../../components/TextField';
import DatePicker from '../../../components/DatePicker';

import useAgentList from '../../../hooks/useAgentList';
import useQueryParams from '../../../hooks/useQueryParams';
import useSnackbarText from '../../../hooks/useSnackbarText';

import admin from '../../../api/admin';
import { maxWidth } from '../../../components/configs';
import { isEmail } from '../../../utils';

const adminAgentID = 1;
const dateFormat = 'YYYY-MM-DD';

const useStyles = makeStyles(theme => ({
  submitButton: {
    maxWidth,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 20,
  },
  loading: {
    marginTop: 50,
    marginBottom: 50,
    color: '#c7c7c7',
  },
  title: {
    fontSize: 18,
    color: theme.palette.primary.main,
  },
}));

function ProfileSection() {
  const classes = useStyles();
  const handleSnackbarChange = useSnackbarText();
  const [queryParams] = useQueryParams();
  const agentList = useAgentList(queryParams.agentID, {
    isIntroducerList: true,
  });
  const [state, _setState] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    introducerID: '1',
    joinGEDate: '',
    isActive: true,
    accountNumber: '',
  });
  const [error, setError] = useState({});
  const [disabled, setDisable] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const setState = useCallback((newState = {}) => {
    _setState(prevState => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleValueCheck = () => {
    let noError = true;
    let error = {};

    if (!state.name) {
      noError = false;
      error.name = 'Invalid value.';
    }

    if (state.email && !isEmail(state.email)) {
      noError = false;
      error.email = 'Invalid email.';
    }

    if (!state.accountNumber) {
      noError = false;
      error.accountNumber = 'Invalid value.';
    }

    if (!state.introducerID) {
      noError = false;
      error.introducerID = 'Invalid value.';
    }

    setError(error);
    return noError;
  };

  const handleChange = type => (event, value) => {
    if (value !== undefined) setState({ [type]: value });
    else setState({ [type]: event.target.value });
  };

  const handleSubmit = async () => {
    if (!handleValueCheck()) {
      handleSnackbarChange({ text: 'Invalid inputs', variant: 'error' });
      return window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    setDisable(true);
    let { data, success } = await admin.updateAgent({
      ...state,
      agentID: queryParams.agentID,
    });
    setDisable(false);
    if (success) {
      handleSnackbarChange({ text: 'Agent updated', variant: 'success' });
    } else {
      return handleSnackbarChange({ text: data.message, variant: 'error' });
    }
  };

  // get agent info
  useEffect(() => {
    const agentID = queryParams.agentID;
    const getData = async () => {
      let { data, success } = await admin.getAgent({ agentID });
      if (success) {
        setState({
          ...data,
          email: data.email || '',
          phoneNumber: data.phoneNumber || '',
          introducerID: String(data.introducerID),
          joinGEDate: moment(data.joinGEDate).format(dateFormat),
        });
        setLoaded(true);
      } else {
        return handleSnackbarChange({ text: data.message, variant: 'error' });
      }
    };

    if (agentID) getData();
  }, [queryParams.agentID, handleSnackbarChange, setState, setLoaded]);

  return (
    <Fragment>
      {!loaded && <div className={classes.loading}>loading</div>}
      {loaded && (
        <Fragment>
          <Typography className={classes.title} variant="h5">
            Profile
          </Typography>
          <TextField
            label="Agent Name"
            value={state.name}
            onChange={handleChange('name')}
            error={Boolean(error.name)}
            helperText={error.name}
          />

          <TextField
            label="Email"
            value={state.email}
            onChange={handleChange('email')}
            error={Boolean(error.email)}
            helperText={error.email}
          />

          <TextField
            label="Phone Number"
            value={state.phoneNumber}
            onChange={handleChange('phoneNumber')}
          />

          <TextField
            label="Account Number"
            value={state.accountNumber}
            onChange={handleChange('accountNumber')}
            error={Boolean(error.accountNumber)}
            helperText={error.accountNumber}
          />

          <Select
            label="Introducer"
            options={agentList}
            value={state.introducerID}
            onChange={handleChange('introducerID')}
            disabled={queryParams.agentID === String(adminAgentID)}
            group
          />

          <DatePicker
            label="Join Date"
            value={state.joinGEDate}
            onChange={handleChange('joinGEDate')}
          />

          <Checkbox
            label="Enable Login"
            value={state.isActive}
            onChange={handleChange('isActive')}
          />

          <div className={classes.submitButton}>
            <Button
              disabled={disabled}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Update
            </Button>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}

export default ProfileSection;
