import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Table from '../../../components/Table';

import useSnackbarText from '../../../hooks/useSnackbarText';
import useQueryParams from '../../../hooks/useQueryParams';
import useDirectGroupLeaders from '../../../hooks/useDirectGroupLeaders';

import group from '../../../api/group';
import { getFooterTotal } from '../../../utils';

const adminAgentID = 1;

const headers = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
		disableSort: true,
	},
	{
		id: 'BSA',
		label: 'BSA',
		type: 'string',
		subheaders: [
			{
				id: 'BSAUW',
				label: 'UW',
				type: 'float',
			},
			{
				id: 'BSAInforced',
				label: 'Inforced',
				type: 'float',
			},
			{
				id: 'BSATotal',
				label: 'Total',
				type: 'float',
			},
		],
	},
	{
		id: 'ANP',
		label: 'ANP',
		type: 'string',
		subheaders: [
			{
				id: 'ANPUW',
				label: 'UW',
				type: 'float',
			},
			{
				id: 'ANPInforced',
				label: 'Inforced',
				type: 'float',
			},
			{
				id: 'ANPTotal',
				label: 'Total',
				type: 'float',
			},
		],
	},
	{
		id: 'solidANP',
		label: 'Solid ANP',
		type: 'string',
		subheaders: [
			{
				id: 'solidANPUW',
				label: 'UW',
				type: 'float',
			},
			{
				id: 'solidANPInforced',
				label: 'Inforced',
				type: 'float',
			},
			{
				id: 'solidANPTotal',
				label: 'Total',
				type: 'float',
			},
		],
	},
	{
		id: 'NOC',
		label: 'NOC',
		type: 'string',
		subheaders: [
			{
				id: 'NOCUW',
				label: 'UW',
				type: 'int',
			},
			{
				id: 'NOCInforced',
				label: 'Inforced',
				type: 'int',
			},
			{
				id: 'NOCTotal',
				label: 'Total',
				type: 'int',
			},
		],
	},
];

function PerformanceSummary() {
	const handleSnackbarChange = useSnackbarText();
	const [queryParams, setQueryParams] = useQueryParams();
	const leaders = useDirectGroupLeaders(queryParams.year);
	const [data, setData] = useState([]);
	const [footers, setFooters] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		let tmp = {};
		if(!queryParams.year) tmp.year = moment().year();
		if(!queryParams.leaderID && leaders.length) {
			tmp.leaderID = leaders[0].value;
		}
		setQueryParams(tmp);
	}, [queryParams.year, queryParams.leaderID, leaders, setQueryParams]);

	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			let { data, success } = await group.getPerformanceSummary({ 
				year: queryParams.year,
			});
			setLoading(false);

			if(success) {
				let summary = data.summary.map(e => ({
					...e,
					BSATotal: (e.BSAUW + e.BSAInforced) || 0,
					ANPTotal: (e.ANPUW + e.ANPInforced) || 0,
					solidANPTotal: (e.solidANPUW + e.solidANPInforced) || 0,
					NOCTotal: (e.NOCUW + e.NOCInforced) || 0,
				}))

				setData(summary);
				setFooters([
					{
						name: 'Direct Total',
						...getFooterTotal(headers, summary.filter(e => e.agentID === adminAgentID)),
					},
					{
						name: 'Indirect Total',
						...getFooterTotal(headers, summary.filter(e => e.agentID !== adminAgentID)),
					},
					{
						name: 'Grand Total',
						...getFooterTotal(headers, summary),
					}
				]);

			} else {
				return handleSnackbarChange({ text: data.message, variant: 'error' });
			}
		};

		if(queryParams.year && queryParams.leaderID) getData();
	}, [queryParams.year, queryParams.leaderID, handleSnackbarChange]);

	return (
		<Table
			headers={headers}
			data={data}
			footers={footers}
			isLoading={loading}
		/>
	);
}

export default PerformanceSummary;
