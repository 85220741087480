import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';

import useSnackbarText from '../../../hooks/useSnackbarText';
import useQueryParams from '../../../hooks/useQueryParams';

import group from '../../../api/group';
import contests from '../../../api/contests';
import {
  getObjectFromArray,
  getYearList,
  getSitemapData,
  getFooterTotal,
} from '../../../utils';

const sitemapValue = 'GROUP_MONTHLYSUBMISSION';
const { label } = getSitemapData(sitemapValue);

const quarterHeaders = [
  {
    id: 'name',
    label: 'Name',
    type: 'string',
    sticky: true,
    disableSort: true,
  },
  {
    id: 'Q1',
    label: 'Quarter 1',
    type: 'string',
    subheaders: [
      {
        id: 'Q1_ANP',
        label: 'ANP',
        type: 'float',
      },
      {
        id: 'Q1_NOC',
        label: 'NOC',
        type: 'int',
      },
    ],
  },
  {
    id: 'Q2',
    label: 'Quarter 2',
    type: 'string',
    subheaders: [
      {
        id: 'Q2_ANP',
        label: 'ANP',
        type: 'float',
      },
      {
        id: 'Q2_NOC',
        label: 'NOC',
        type: 'int',
      },
    ],
  },
  {
    id: 'Q3',
    label: 'Quarter 3',
    type: 'string',
    subheaders: [
      {
        id: 'Q3_ANP',
        label: 'ANP',
        type: 'float',
      },
      {
        id: 'Q3_NOC',
        label: 'NOC',
        type: 'int',
      },
    ],
  },
  {
    id: 'Q4',
    label: 'Quarter 4',
    type: 'string',
    subheaders: [
      {
        id: 'Q4_ANP',
        label: 'ANP',
        type: 'float',
      },
      {
        id: 'Q4_NOC',
        label: 'NOC',
        type: 'int',
      },
    ],
  },
  // {
  //   id: 'WY',
  //   label: 'Whole Year',
  //   type: 'string',
  //   subheaders: [
  //     {
  //       id: 'WY_ANP',
  //       label: 'ANP',
  //       type: 'float',
  //     },
  //     {
  //       id: 'WY_NOC',
  //       label: 'NOC',
  //       type: 'int',
  //     },
  //   ],
  // },
];

function getQuarterHeaders(quarter) {
  const quarterHeaders = [
    {
      id: 'name',
      label: 'Name',
      type: 'string',
      sticky: true,
      disableSort: true,
    },
    // {
    //   id: 'Q1',
    //   label: 'Quarter 1',
    //   type: 'string',
    //   subheaders: [
    //     {
    //       id: 'Q1_ANP',
    //       label: 'ANP',
    //       type: 'float',
    //     },
    //     {
    //       id: 'Q1_NOC',
    //       label: 'NOC',
    //       type: 'int',
    //     },
    //   ],
    // },
    // {
    //   id: 'Q2',
    //   label: 'Quarter 2',
    //   type: 'string',
    //   subheaders: [
    //     {
    //       id: 'Q2_ANP',
    //       label: 'ANP',
    //       type: 'float',
    //     },
    //     {
    //       id: 'Q2_NOC',
    //       label: 'NOC',
    //       type: 'int',
    //     },
    //   ],
    // },
    // {
    //   id: 'Q3',
    //   label: 'Quarter 3',
    //   type: 'string',
    //   subheaders: [
    //     {
    //       id: 'Q3_ANP',
    //       label: 'ANP',
    //       type: 'float',
    //     },
    //     {
    //       id: 'Q3_NOC',
    //       label: 'NOC',
    //       type: 'int',
    //     },
    //   ],
    // },
    // {
    //   id: 'Q4',
    //   label: 'Quarter 4',
    //   type: 'string',
    //   subheaders: [
    //     {
    //       id: 'Q4_ANP',
    //       label: 'ANP',
    //       type: 'float',
    //     },
    //     {
    //       id: 'Q4_NOC',
    //       label: 'NOC',
    //       type: 'int',
    //     },
    //   ],
    // },
    // {
    //   id: 'WY',
    //   label: 'Whole Year',
    //   type: 'string',
    //   subheaders: [
    //     {
    //       id: 'WY_ANP',
    //       label: 'ANP',
    //       type: 'float',
    //     },
    //     {
    //       id: 'WY_NOC',
    //       label: 'NOC',
    //       type: 'int',
    //     },
    //   ],
    // },
  ];

  quarterHeaders.push({
    id: `Q${quarter}`,
    label: `Quarter ${quarter}`,
    type: 'string',
    subheaders: [
      {
        id: `Q${quarter}_ANP`,
        label: 'ANP',
        type: 'float',
      },
      {
        id: `Q${quarter}_NOC`,
        label: 'NOC',
        type: 'int',
      },
    ],
  });

  return quarterHeaders;
}

const quarterOptions = [
  { label: 'Quarter 1', value: '1' },
  { label: 'Quarter 2', value: '2' },
  { label: 'Quarter 3', value: '3' },
  { label: 'Quarter 4', value: '4' },
];

const useStyles = makeStyles(theme => ({
  select: {
    marginRight: 10,
  },
}));

function GroupContest() {
  const classes = useStyles();
  const handleSnackbarChange = useSnackbarText();
  // number[][]
  const [groups, setGroups] = useState([[], [], [], []]);
  const [groupID, setGroupID] = useState(1);
  const [period, setPeriod] = useState({
    year: moment().year(),
    quarter: moment().quarter(),
  });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleChange = type => event => {
    const value = Number(event.target.value);
    setPeriod(prev => ({ ...prev, [type]: value }));
  };

  useEffect(() => {
    const getData = async () => {
      setData([]);
      setLoading(true);
      let { data, success } = await group.getMonthlySubmission({
        year: period.year,
      });
      setLoading(false);

      if (success) {
        setData(data.submissions);
      } else {
        handleSnackbarChange({ text: data.message, variant: 'error' });
      }
    };

    if (period.year) getData();
  }, [period.year, handleSnackbarChange]);

  useEffect(() => {
    async function main() {
      setGroups([[], [], [], []]);
      const result = await contests.getContestGroups({
        year: period.year,
        quarter: period.quarter,
      });

      if (result.success) {
        const res = result.data;
        const group1 = res.groups.filter(group => group.groupID === 1);
        const group2 = res.groups.filter(group => group.groupID === 2);
        const group3 = res.groups.filter(group => group.groupID === 3);
        const group4 = res.groups.filter(group => group.groupID === 4);

        const groups = [
          group1.map(group => group.agentID),
          group2.map(group => group.agentID),
          group3.map(group => group.agentID),
          group4.map(group => group.agentID),
        ];
        setGroups(groups);
      }
    }

    main();
  }, [period.year, period.quarter]);

  function getFormattedData() {
    const agentIDs = groups[groupID - 1];

    const _data = data.filter(d => {
      return agentIDs.includes(d.agentID);
    });

    const formattedData = _data.map(e => {
      let tmp = { agentID: e.agentID, name: e.name };
      let months = moment.monthsShort().map(el => el.toUpperCase());

      // set monthly submissions
      e.submissions.forEach((el, j) => {
        tmp[`${months[j]}_ANP`] = el.ANP;
        tmp[`${months[j]}_NOC`] = el.NOC;
      });

      // set quarterly submissions
      tmp.Q1_ANP = e.submissions.slice(0, 3).reduce((a, v) => a + v.ANP, 0);
      tmp.Q1_NOC = e.submissions.slice(0, 3).reduce((a, v) => a + v.NOC, 0);
      tmp.Q2_ANP = e.submissions.slice(3, 6).reduce((a, v) => a + v.ANP, 0);
      tmp.Q2_NOC = e.submissions.slice(3, 6).reduce((a, v) => a + v.NOC, 0);
      tmp.Q3_ANP = e.submissions.slice(6, 9).reduce((a, v) => a + v.ANP, 0);
      tmp.Q3_NOC = e.submissions.slice(6, 9).reduce((a, v) => a + v.NOC, 0);
      tmp.Q4_ANP = e.submissions.slice(9, 12).reduce((a, v) => a + v.ANP, 0);
      tmp.Q4_NOC = e.submissions.slice(9, 12).reduce((a, v) => a + v.NOC, 0);

      // set half yearly submissions
      tmp.HY1_ANP = e.submissions.slice(0, 6).reduce((a, v) => a + v.ANP, 0);
      tmp.HY1_NOC = e.submissions.slice(0, 6).reduce((a, v) => a + v.NOC, 0);
      tmp.HY2_ANP = e.submissions.slice(6, 12).reduce((a, v) => a + v.ANP, 0);
      tmp.HY2_NOC = e.submissions.slice(6, 12).reduce((a, v) => a + v.NOC, 0);

      // set whole year submissions
      tmp.WY_ANP = e.submissions.reduce((a, v) => a + v.ANP, 0);
      tmp.WY_NOC = e.submissions.reduce((a, v) => a + v.NOC, 0);

      return tmp;
    });

    const footers = [
      {
        name: 'Total',
        ...getFooterTotal(
          [...getQuarterHeaders(period.quarter)],
          formattedData
        ),
      },
    ];

    return { formattedData, footers };
  }

  const { formattedData, footers } = getFormattedData();

  return (
    <Layout title={label}>
      <Select
        className={classes.select}
        maxWidth={150}
        label="Year"
        options={getYearList()}
        value={String(period.year)}
        onChange={handleChange('year')}
      />

      <Select
        className={classes.select}
        maxWidth={150}
        label="Period"
        options={quarterOptions}
        value={String(period.quarter)}
        onChange={handleChange('quarter')}
      />

      <Select
        className={classes.select}
        maxWidth={150}
        label="Group"
        options={groups.map((group, index) => ({
          label: `Group ${index + 1}`,
          value: String(index + 1),
        }))}
        value={String(groupID)}
        onChange={event => {
          const value = Number(event.target.value);
          setGroupID(value);
        }}
      />

      <Table
        headers={getQuarterHeaders(period.quarter)}
        data={formattedData}
        footers={footers}
        isLoading={loading}
      />
    </Layout>
  );
}

export default GroupContest;
