import React, { useEffect, useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import api from '../../../api/agents';
import { isEmail } from '../../../utils';
import useSnackbarText from '../../../hooks/useSnackbarText';
import TextField from '../../../components/TextField';
import { maxWidth } from '../../../components/configs';

const useStyles = makeStyles(theme => ({
  submitButton: {
    maxWidth,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
}));

export default function EditProfile() {
  const classes = useStyles();
  const [profile, setProfile] = useState({
    name: '',
    email: '',
    phoneNumber: '',
  });
  const [error, setError] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [disabled, setDisable] = useState(false);
  const handleSnackbarChange = useSnackbarText();

  const handleChange = key => event => {
    const value = event.target.value;
    setProfile(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleValueCheck = () => {
    let noError = true;
    let error = {};

    if (profile.name.length < 2) {
      noError = false;
      error.name = 'Invalid value.';
    }

    if (profile.email && !isEmail(profile.email)) {
      noError = false;
      error.email = 'Invalid value.';
    }
    setError(error);
    return noError;
  };

  const handleSubmit = async () => {
    if (!handleValueCheck()) {
      handleSnackbarChange({ text: 'Invalid inputs', variant: 'error' });
      return window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    setDisable(true);
    const { data, success } = await api.updateProfile(profile);
    setDisable(false);
    if (success) {
      handleSnackbarChange({ text: 'Profile updated', variant: 'success' });
    } else {
      return handleSnackbarChange({ text: data.message, variant: 'error' });
    }
  };

  useEffect(() => {
    const main = async () => {
      setLoaded(false);
      const { data, success } = await api.getProfile();

      if (success) {
        setProfile({
          name: data.name,
          email: data.email || '',
          phoneNumber: data.phoneNumber || '',
        });
        setLoaded(true);
      } else {
        return handleSnackbarChange({ text: data.message, variant: 'error' });
      }
    };

    main();
  }, []);

  if (!loaded)
    return (
      <div style={{ padding: 20 }}>
        <CircularProgress />
      </div>
    );

  return (
    <React.Fragment>
      <TextField
        label="Name"
        value={profile.name}
        onChange={handleChange('name')}
        error={Boolean(error.name)}
        helperText={error.name}
      />

      <TextField
        label="Email"
        value={profile.email}
        onChange={handleChange('email')}
        error={Boolean(error.email)}
        helperText={error.email}
        type="email"
      />

      <TextField
        label="Phone Number"
        value={profile.phoneNumber}
        onChange={handleChange('phoneNumber')}
        error={Boolean(error.phoneNumber)}
        helperText={error.phoneNumber}
        type="tel"
      />

      <div className={classes.submitButton}>
        <Button
          disabled={disabled}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </React.Fragment>
  );
}
