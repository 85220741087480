import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
	FormLabel,
	FormControl,
	FormGroup,
	FormControlLabel,
	FormHelperText,
	Checkbox as MuiCheckbox
} from '@material-ui/core';

import { maxWidth } from '../configs';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		maxWidth,
	},
}));

function Checkboxes(props) {
	const classes = useStyles();

	const {
		title,
		label,
		value = [],
		options = [],
		onChange,
		helperText,
		...others
	} = props;

	const handleChange = (inputValue) => (event) => {
		let newValue = [...value];
		const index = value.indexOf(inputValue);
		if(index === -1) {
			newValue.push(inputValue);
		} else {
			newValue.splice(index, 1);
		}
		onChange(event, newValue);
	};

	return (
		<FormControl className={classes.root} component="fieldset" {...others}>
			<FormLabel component="legend">{title}</FormLabel>
			<FormGroup>
				{options.map(option => (
					<FormControlLabel
						key={option.value}
						control={
							<MuiCheckbox 
								color="primary"
								checked={value.includes(option.value)} 
								onChange={handleChange(option.value)} 
								value={option.value} 
							/>
						}
						label={option.label}
					/>
				))}
			</FormGroup>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
}

export default Checkboxes;
