import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import {
  Grid,
  Button,
  Typography,
  IconButton,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import TextField from '../../../components/TextField';
import DatePicker from '../../../components/DatePicker';
import companyEventApi from '../../../api/companyEvents';
import useSnackbarText from '../../../hooks/useSnackbarText';

const DATE_FORMAT = 'YYYY-MM-DD';

const maxUploadSize = 20 * 1024 * 1024;

const useStyles = makeStyles(theme => ({
  section: {
    borderRadius: 10,
    backgroundColor: `#ffffff`,
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2.5),
  },
  header: { fontWeight: 'bold' },
  fileInput: { display: 'none' },
  row: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  updateButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  updateButton: {
    color: '#ffffff',
    fontWeight: 600,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  deleteIconButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.error.main,
  },
  imagePreviewContainer: {
    position: 'relative',
    maxWidth: 300,
  },
  imagePreviewDeleteButton: {
    position: 'absolute',
    transform: 'translate(30%, -30%)',
    top: 0,
    right: 0,
    color: '#ffffff',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  deleteButton: {
    color: '#ffffff',
    fontWeight: 600,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  deleteEventButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.error.main,
  },
}));

export default function EventEditPage() {
  const classes = useStyles();
  const { eventID } = useParams();
  const routerHistory = useHistory();
  const [file, setFile] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [shouldRemoveImage, setShouldRemoveImage] = useState(false);
  const [timestamp, setTimestamp] = useState(new Date().valueOf());
  const [inputKey, setInputKey] = useState(Math.random());
  const [info, _setInfo] = useState({
    name: '',
    startAt: moment().startOf('D').format(DATE_FORMAT),
    endAt: moment().endOf('D').format(DATE_FORMAT),
    description: '',
    featureImageUrl: '',
  });

  const handleSnackbarChange = useSnackbarText();

  const handleFileChange = event => {
    const file = event.target.files[0];

    if (file.size > maxUploadSize) {
      handleSnackbarChange({
        text: `File size is too large (Max 100MB)`,
        variant: 'error',
      });
      return;
    }
    setFile(file);
  };

  const handleUpdate = async () => {
    if (!info.name.trim()) {
      handleSnackbarChange({
        text: `Event name cannot be empty`,
        variant: 'error',
      });
      return;
    }

    const payload = {
      name: info.name,
      startDate: info.startAt,
      endDate: info.endAt,
      description: info.description,
    };

    if (file) {
      payload.file = file;
    } else if (shouldRemoveImage) {
      payload.action = 'remove_feature_image';
    }

    setIsUploading(true);
    const result = await companyEventApi.updateCompanyEvent({
      eventID,
      ...payload,
    });
    setIsUploading(false);

    if (result.success) {
      handleSnackbarChange({
        text: `Event has been updated`,
        variant: 'success',
      });
      setFile(null);
      setInputKey(Math.random());
      setTimestamp(new Date().valueOf());
      return;
    } else {
      handleSnackbarChange({ text: result.data.message, variant: 'error' });
      setIsUploading(false);
    }
  };

  const handleClearFileInput = () => {
    setFile(null);
    setInputKey(Math.random());
  };

  const setInfo = newInfo => {
    _setInfo(prev => ({ ...prev, ...newInfo }));
  };

  const handleChange = key => event => {
    const value = event.target.value;
    setInfo({ [key]: value });
  };

  const handleDateChange = (key, newValue) => {
    if (key === 'startAt') {
      const value = moment(newValue).startOf('D').format(DATE_FORMAT);
      setInfo({ startAt: value });

      if (value > info.endAt) {
        setInfo({ endAt: value });
      }
    }

    if (key === 'endAt') {
      const value = moment(newValue).endOf('D').format(DATE_FORMAT);
      setInfo({ endAt: value });

      if (value < info.startAt) {
        setInfo({ startAt: value });
      }
    }
  };

  const handleDeleteImage = () => {
    setShouldRemoveImage(true);
  };

  const handleDelete = async () => {
    if (!eventID) return;

    setIsDeleting(true);
    const result = await companyEventApi.deleteCompanyEvent(eventID);
    setIsDeleting(false);
    if (result.success) {
      routerHistory.push('/admin/company-info/events');
      handleSnackbarChange({
        text: `Event has been deleted`,
        variant: 'success',
      });
    } else {
      handleSnackbarChange({ text: result.data.message, variant: 'error' });
    }
  };

  useEffect(() => {
    if (!eventID) return;

    async function main() {
      const result = await companyEventApi.getCompanyEvent(eventID);
      if (!result.success) {
        handleSnackbarChange({ text: result.data.message, variant: 'error' });
        return;
      } else {
        const data = result.data;
        setShouldRemoveImage(false);
        setInfo({
          name: data.name,
          startAt: moment(data.startAt).format(DATE_FORMAT),
          endAt: moment(data.endAt).format(DATE_FORMAT),
          description: data.description,
          featureImageUrl: data.featureImageUrl,
        });
      }
    }

    main();
  }, [eventID, timestamp]);

  const shouldShowImage = info.featureImageUrl && !shouldRemoveImage;

  return (
    <div>
      <div className={classes.section}>
        <Button
          component={NavLink}
          to="/admin/company-info/events"
          style={{ fontWeight: 'bold', marginBottom: `10px` }}
        >
          <ChevronLeftIcon />
          Back
        </Button>

        <Typography className={classes.header} variant="h6">
          Edit Event
        </Typography>

        <TextField
          label="Event Name"
          value={info.name}
          onChange={handleChange('name')}
          maxWidth="100%"
          required
        />

        <Grid container spacing={2}>
          <Grid xs={12} sm={6} item>
            <DatePicker
              label="Start Date"
              value={info.startAt}
              onChange={(event, value) => handleDateChange('startAt', value)}
              isDateBlocked={date => new Date()}
              autoApply
              required
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <DatePicker
              label="End Date"
              value={info.endAt}
              onChange={(event, value) => handleDateChange('endAt', value)}
              autoApply
              required
            />
          </Grid>
        </Grid>

        <Typography
          variant="h6"
          style={{ marginTop: `10px`, fontWeight: 'bold', fontSize: `1rem` }}
        >
          Description
        </Typography>
        <TextField
          InputProps={{ style: { paddingTop: `6px` } }}
          value={info.description}
          onChange={handleChange('description')}
          maxWidth="100%"
          multiline
          minRows={5}
        />

        <div className={classes.row}>
          <div style={{ flex: 1 }}>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              Image (Please upload 1 image)
            </Typography>
          </div>

          <div style={{ flex: 0 }}>
            {!shouldShowImage && (
              <>
                <input
                  key={inputKey}
                  className={classes.fileInput}
                  onChange={handleFileChange}
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                />

                {file ? (
                  <div className={classes.row}>
                    <Typography color="primary" variant="body2">
                      {file.name}
                    </Typography>
                    <IconButton
                      className={classes.deleteIconButton}
                      onClick={handleClearFileInput}
                      size="small"
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                ) : (
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      style={{ fontWeight: 'bold' }}
                    >
                      Upload
                    </Button>
                  </label>
                )}
              </>
            )}
          </div>
        </div>

        {shouldShowImage && (
          <div className={classes.imagePreviewContainer}>
            <img
              src={info.featureImageUrl}
              style={{ borderRadius: 10, width: `100%` }}
              alt=""
            />

            <IconButton
              className={classes.imagePreviewDeleteButton}
              onClick={handleDeleteImage}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </div>
        )}
      </div>
      <div className={classes.updateButtonWrapper}>
        <Button
          className={classes.deleteEventButton}
          onClick={() => setShowDeleteDialog(true)}
        >
          Delete Event
        </Button>

        <Button
          className={classes.updateButton}
          onClick={handleUpdate}
          variant="contained"
          disabled={isUploading}
        >
          Update
        </Button>
      </div>

      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>{`Delete "${info.name}"`}</DialogTitle>
        <DialogContent>
          <DialogContentText>It cannot be undone</DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button onClose={() => setShowDeleteDialog(false)}>Cancel</Button>
          <Button
            className={classes.deleteButton}
            onClick={handleDelete}
            disabled={isDeleting}
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
