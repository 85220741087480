import React from 'react';
import { Switch, Route } from 'react-router-dom';

import MonthlySubmission from './monthly-submission';
import Performance from './performance';
import Contest from './contest';
import GroupContest from './group-contest';
import CustomContest from './custom-contest';
import ActiveRatio from './active-ratio';
import MonthlyProspect from './monthly-prospect';
import CasesStatus from './cases-status';
import InforcedPerformance from './inforced-performance';
import MSA from './msa';
import SSA from './ssa';
import Try from './try';
import Prospect from './prospect';
import PlanPerformance from './plan-performance';
import WeeklySubmission from './weekly-submission';
import QFYLP from './qfylp';
import KPI from './kpi';
import DiaryPerformance from './diary-performance';

import sitemaps from '../../configs/sitemaps';

const groupMap = {
  GROUP_WEEKLYSUBMISSION: WeeklySubmission,
  GROUP_MONTHLYSUBMISSION: MonthlySubmission,
  GROUP_PERFORMANCE: Performance,
  GROUP_MSA: MSA,
  GROUP_SSA: SSA,
  GROUP_CONTEST: Contest,
  GROUP_GROUP_CONTEST: GroupContest,
  GROUP_CUSTOMCONTEST: CustomContest,
  GROUP_ACTIVERATIO: ActiveRatio,
  GROUP_TRACKING: Try,
  GROUP_PROSPECT: Prospect,
  GROUP_MONTHLYPROSPECTLIST: MonthlyProspect,
  GROUP_QFYLP: QFYLP,
  GROUP_KPI: KPI,
  GROUP_INFORCEPERFORMANCE: InforcedPerformance,
  GROUP_PLANPERFORMANCE: PlanPerformance,
  GROUP_CASESSTATUS: CasesStatus,
  GROUP_ACTIVITYPOINT: DiaryPerformance,
};

const groupSitemaps = sitemaps.group.sitemaps
  .filter(e => !e.hidden)
  .map(e => ({
    fullPathname: sitemaps.group.pathname + e.pathname,
    Component: groupMap[e.value],
  }));

function Group() {
  return (
    <Switch>
      {groupSitemaps.map(sitemap => (
        <Route key={sitemap.fullPathname} path={sitemap.fullPathname}>
          <sitemap.Component />
        </Route>
      ))}

      {/* Error handling */}
      <Route>
        <div> 404 Page Not found</div>
      </Route>
    </Switch>
  );
}

export default Group;
