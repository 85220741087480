import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Main from './Main';
import Create from './Create';
import Update from './Update';
import QFYLPUpdate from './QFYLPUpdate';

import { getSitemapData } from '../../../utils';

const sitemapValue = 'PERSONAL_SUBMISSION';
const { fullPathname } = getSitemapData(sitemapValue);

function Submission() {
	return (
		<Switch>
			<Route path={fullPathname} exact>
				<Main />
			</Route>

			<Route path={fullPathname + '/create'}>
				<Create />
			</Route>

			<Route path={fullPathname + '/update'}>
				<Update />
			</Route>

			<Route path={fullPathname + '/qfylp-update'}>
				<QFYLPUpdate />
			</Route>

			{/* Error handling */}
			<Route>
				<div> 404 Not found</div>
			</Route>
		</Switch>
	);
}

export default Submission;
