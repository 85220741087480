import React, { useState, useEffect } from 'react';

import Table from '../../../components/Table';

import useQueryParams from '../../../hooks/useQueryParams';
import useFetch from '../../../hooks/useFetch';

import group from '../../../api/group';
import { getFooterTotal } from '../../../utils';

const adminAgentID = 1;

const headers = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
		disableSort: true,
	},
	{
		id: 'BSA',
		label: 'BSA',
		type: 'float',
	},
	{
		id: 'ANP',
		label: 'ANP',
		type: 'float',
	},
	{
		id: 'solidANP',
		label: 'Solid ANP',
		type: 'float',
	},
	{
		id: 'NOC',
		label: 'NOC',
		type: 'int',
	},
	{
		id: 'client',
		label: 'Client',
		type: 'string',
		subheaders: [
			{
				id: 'newClient',
				label: 'New',
				type: 'int',
			},
			{
				id: 'existingClient',
				label: 'Existing',
				type: 'int',
			},
		]
	},
	{
		id: 'nominee',
		label: 'Nominee',
		type: 'string',
		subheaders: [
			{
				id: 'withNominee',
				label: 'Yes',
				type: 'int',
			},
			{
				id: 'withoutNominee',
				label: 'No',
				type: 'int',
			},
		]
	},
];

function Summary() {
	const [queryParams] = useQueryParams();
	const [data, setData] = useState([]);
	const [footers, setFooters] = useState([]);
	const [res, status, handleFetch] = useFetch(group.getInforcedPerformanceSummary);

	useEffect(() => {
		if(queryParams.startDate && queryParams.endDate) {
			handleFetch({
				startDate: queryParams.startDate,
				endDate: queryParams.endDate,
			});
		}
	}, [handleFetch, queryParams.startDate, queryParams.endDate]);

	useEffect(() => {
		if(status.loaded) {
			if(!status.error) {
				const formattedData = res.summary;
				setData(formattedData);
				setFooters([
					{
						name: 'Direct Total',
						...getFooterTotal(headers, formattedData.filter(e => e.agentID === adminAgentID)),
					},
					{
						name: 'Indirect Total',
						...getFooterTotal(headers, formattedData.filter(e => e.agentID !== adminAgentID)),
					},
					{
						name: 'Grand Total',
						...getFooterTotal(headers, formattedData),
					}
				]);
			} else {
				setData([]);
				setFooters([]);
			}
		}
	}, [res, status.loaded, status.error]);

	return (
		<Table
			headers={headers}
			data={data}
			footers={footers}
			isLoading={status.loading}
		/>
	);
}

export default Summary;
