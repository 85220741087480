import React, { useCallback } from 'react';
// import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTheme, useMediaQuery } from '@material-ui/core';
import {
	Pie,
	// Sector,
	Cell,
	Tooltip,
	// Legend,
	ResponsiveContainer,
	PieChart
} from 'recharts';

import { generateNumber } from '../../utils';

const defaultPieData = [];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#44c734', '#bd60e4', '#e46061'];

const CustomPieChart = (props) => {
	const theme = useTheme();
	const { pieData = defaultPieData } = props;

	const isMatch = useMediaQuery(theme.breakpoints.down('xs'));

	const renderCustomizedLabel = useCallback(({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, value }) => {
		const RADIAN = Math.PI / 180;
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  		const x = cx + radius * 2.5 * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * 2.5 * Math.sin(-midAngle * RADIAN);
		  
		const offset = 10;

		return (
			<>
				<text 
					x={x} 
					y={y + offset} 
					fill={theme.palette.common.black}
					textAnchor={x > cx ? 'start' : 'end'} 
				>
					{value !== 0
						? `${pieData[index].name}: ${value} (${generateNumber(percent * 100)}%)`
						: ``
					}
				</text>
			</>
		);
	}, [theme, pieData]);

	return (
		<>
		<ResponsiveContainer width="100%" height={400}>
			<PieChart width={300} height={300}>
				<Pie 
					data={pieData}
					cx="50%"
					cy="50%"
					fill="#8884d8"
					label={isMatch ? '' : renderCustomizedLabel}
					labelLine={!isMatch}
					outerRadius="50%"
				>
					{pieData.map((entry, index) => (
						<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
					))}
				</Pie>
				<Tooltip />
				{/* <Legend layout="vertical" /> */}
			</PieChart>
		</ResponsiveContainer>
		</>
	);
};

export default CustomPieChart;
