import React, { useState, useEffect, useCallback, Fragment } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import TextField from '../../../components/TextField';
import DatePicker from '../../../components/DatePicker';
import Autocomplete from '../../../components/Autocomplete';

import useQueryParams from '../../../hooks/useQueryParams';
import useSnackbarText from '../../../hooks/useSnackbarText';

import { 
	prospectResourceOptions,
	prospectOutcomeOptions,
} from '../../../configs/prospects';
import { getSitemapData } from '../../../utils';
import prospects from '../../../api/prospects';
import { maxWidth } from '../../../components/configs';

const dateFormat = 'YYYY-MM-DD';
const sitemapValue = 'PERSONAL_PROSPECT';
const { label, fullPathname } = getSitemapData(sitemapValue);

const useStyles = makeStyles(theme => ({
	content: {
		backgroundColor: '#fff',
		padding: 10,
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column'
	},
	submitButton: {
		maxWidth,
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: 20,
	},
	loading: {
		marginTop: 50,
		marginBottom: 50,
		color: '#c7c7c7'
	}
}));

function Update() {
	const classes = useStyles();
	const handleSnackbarChange = useSnackbarText();
	const routerHistory = useHistory();
	const [queryParams] = useQueryParams();
	const [state, _setState] = useState({
		prospectDate: moment().format(dateFormat),
		name: '',
		phone: '',
		otherContact: '',
		resource: prospectResourceOptions[0].value,
		outcome: prospectOutcomeOptions[0].value,
		remark: '',
		introducer: ''
	});
	const [error, setError] = useState({});
	const [disabled, setDisable] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [introducers, setIntroducers] = useState(null);

	const setState = useCallback((newState = {}) => {
		_setState((prevState) => ({
			...prevState,
			...newState,
		}));
	}, []);

	const handleValueCheck = () => {
		let noError = true;
		let error = {};
		if(!state.name) {
			noError = false;
			error.name = 'Invalid value.';
		}
		if(!state.phone) {
			noError = false;
			error.phone = 'Invalid value.';
		}
		setError(error);
		return noError;
	};

	const handleChange = (type) => (event, value) => {
		if(value !== undefined) setState({ [type]: value });
		else setState({ [type]: event.target.value });
	};

	const handleSubmit = async () => {
		if(!handleValueCheck()) {
			handleSnackbarChange({ text: 'Invalid inputs', variant: 'error' });
			return window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		};

		setDisable(true);
		let { data, success } = await prospects.updateProspect({
			...state,
			prospectID: queryParams.prospectID,
		});
		setDisable(false);
		if(success) {
			handleSnackbarChange({ text: 'Prospect updated', variant: 'success' });
		} else {
			return handleSnackbarChange({ text: data.message, variant: 'error' });
		}
		routerHistory.push(fullPathname);
	};

	// get prospect data
	useEffect(() => {
		const prospectID = queryParams.prospectID;
		const getData = async () => {
			let { data, success } = await prospects.getProspect({ prospectID });
			if(success) {
				setState({
					...data,
					resource: String(data.resource),
					outcome: String(data.outcome),
				});
				setLoaded(true);
			} else {
				return handleSnackbarChange({ text: data.message, variant: 'error' });
			}
		}

		if(prospectID) getData();
	}, [queryParams.prospectID, handleSnackbarChange, setState, setLoaded]);

	// get introducers
	useEffect(() => {
		(async () => {
			let { data, success } = await prospects.getProspectIntroducers();
			setIntroducers(success ? data.introducers : []);
		})();
	}, []);

	return (
		<Layout title={label}>
			<div className={classes.content}>
				{!loaded && <div className={classes.loading}>loading</div>}
				{loaded && (
					<Fragment>

						<DatePicker
							label="Date"
							value={state.prospectDate}
							onChange={handleChange('prospectDate')}
						/>

						<TextField 
							label="Name*"
							value={state.name}
							onChange={handleChange('name')}
							error={Boolean(error.name)}
							helperText={error.name}
						/>

						<TextField 
							label="Phone*"
							value={state.phone}
							onChange={handleChange('phone')}
							error={Boolean(error.phone)}
							helperText={error.phone}
							type="tel"
						/>

						<TextField 
							label="Other Contact"
							value={state.otherContact}
							onChange={handleChange('otherContact')}
						/>

						<Select 
							label="Resource"
							options={prospectResourceOptions}
							value={state.resource}
							onChange={handleChange('resource')}
						/>

						<Select 
							label="Outcome"
							options={prospectOutcomeOptions}
							value={state.outcome}
							onChange={handleChange('outcome')}
						/>

						<TextField 
							label="Remarks"
							value={state.remark}
							onChange={handleChange('remark')}
						/>

						<Autocomplete 
							label="Introducer"
							value={state.introducer}
							onChange={handleChange('introducer')}
							loading={introducers === null}
							options={introducers || []}
						/>

						<div className={classes.submitButton}>
							<Button
								disabled={disabled}
								variant="contained"
								color="primary"
								onClick={handleSubmit}
							>
								Submit
							</Button>
						</div>

					</Fragment>
				)}

			</div>
		</Layout>
	);
}

export default Update;
