import request from './request';

function getContests(data) {
  return request({
    url: '/contests',
    method: 'GET',
    params: data,
  });
}

function getContest(data) {
  return request({
    url: `/contests/${data.contestID}`,
    method: 'GET',
  });
}

function getContestGroups(data) {
  return request({
    url: '/contests/groups',
    method: 'GET',
    params: data,
  });
}

function updateContestGroups(data) {
  return request({
    url: '/contests/groups',
    method: 'PUT',
    data: data,
  });
}

function createContest(data) {
  return request({
    url: '/contests',
    method: 'POST',
    data,
  });
}

function updateContest(data) {
  const { contestID, ...others } = data;
  return request({
    url: `/contests/${data.contestID}`,
    method: 'PUT',
    data: others,
  });
}

function deleteContest(data) {
  return request({
    url: `/contests/${data.contestID}`,
    method: 'DELETE',
  });
}

function highlightContest(data) {
  const { contestID, ...others } = data;
  return request({
    url: `/contests/${data.contestID}/highlight`,
    method: 'PUT',
    data: others,
  });
}

export default {
  getContests,
  getContestGroups,
  updateContestGroups,
  getContest,
  createContest,
  updateContest,
  deleteContest,
  highlightContest,
};
