import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';
import Checkbox from '../../../components/Checkbox';

import useQueryParams from '../../../hooks/useQueryParams';
import useFetch from '../../../hooks/useFetch';

import MEAA from './MEAA';

import group from '../../../api/group';
import { 
	getYearList, 
	getSitemapData, 
	getFooterTotal,
} from '../../../utils';

const defaultHideEmpty = true;

const sitemapValue = 'GROUP_TRACKING';
const { label } = getSitemapData(sitemapValue);

const headers = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
	},
	{
		id: 'market',
		label: 'Market',
		type: 'float',
	},
	{
		id: 'effective',
		label: 'Effective (%)',
		type: 'float',
	},
	{
		id: 'activity',
		label: 'Activity',
		type: 'int',
	},
	{
		id: 'ANP',
		label: 'ANP',
		type: 'float',
	}
];

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	},
}));

function Tries() {
	const classes = useStyles();
	const [queryParams, setQueryParams] = useQueryParams();
	const [data, setData] = useState([]);
	const [res, status, handleFetch] = useFetch(group.getTries);
	const [hideEmpty, setHideEmpty] = useState(defaultHideEmpty);

	const handleToggle = () => {
		setHideEmpty(prev => !prev);
	};

	const handleChange = (type) => (event) => {
		if(type === 'year') 
			setQueryParams({ [type]: event.target.value });
	};

	useEffect(() => {
		let tmp = {};
		if(!queryParams.year) tmp.year = moment().year();
		setQueryParams(tmp);
	}, [queryParams.year, setQueryParams]);

	useEffect(() => {
		if(queryParams.year) {
			handleFetch({ year: queryParams.year });
		}
	}, [handleFetch, queryParams.year]);

	useEffect(() => {
		if(status.loaded) {
			if(!status.error) {
				const formattedData = res.tries.map(e => ({
					...e,
					effective: e.effective * 100,
				}));
				setData(formattedData);
			} else {
				setData([]);
			}
		}
	}, [res, status.loaded, status.error]);

	let formattedData = data;
	if(hideEmpty) {
		formattedData = data.filter(e => e.activity);
	} 

	const MEAARawData = getFooterTotal(headers, data);

	const closedClient = data.reduce((a, v) => a + v.effective * v.activity, 0);
	const ANP = MEAARawData.ANP;
	const activity = MEAARawData.activity;
	const market = MEAARawData.ANP * 100 / closedClient;
	const effective = closedClient / MEAARawData.activity;

	return (
		<Layout title={label}>
			<Select 
				className={classes.select}
				maxWidth={110}
				label="Year"
				options={getYearList()}
				value={queryParams.year}
				onChange={handleChange('year')}
			/>

			<MEAA 
				market={market}
				effective={effective}
				activity={activity}
				ANP={ANP}
			/>

			<Table
				title={
					<Checkbox
						label="Hide empty"
						value={hideEmpty}
						onChange={handleToggle}
					/>
				}
				headers={headers}
				data={formattedData}
				isLoading={status.loading}
			/>
		</Layout>
	);
}

export default Tries;
