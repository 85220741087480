import axios from 'axios';

import configs from '../configs';

const baseURL = configs.apiURL;

const updateAccessToken = (_refreshToken, cancelViewAs = false) => {
	let refreshToken = _refreshToken;
	const { isViewAs, agentID } = window.__userInfo;

	if(!refreshToken) {
		if(isViewAs) {
			refreshToken = window.localStorage.getItem('tt');
		} else {
			refreshToken = window.localStorage.getItem('rt');
		}
	}

	let options = {};
	if(isViewAs && !cancelViewAs){
		options = {
			method: 'POST',
			data: { agentID: agentID },
			url: baseURL + '/auth/refresh-view-as',
			headers: {
				'content-type': 'application/json',
				'authorization': 'Bearer ' + refreshToken,
			},
		};
	} else {
		options = {
			method: 'POST',
			url: baseURL + '/auth/refresh',
			headers: {
				'content-type': 'application/json',
				'authorization': 'Bearer ' + refreshToken,
			},
		};
	}
	return axios(options);
}

export {
	updateAccessToken
};