import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';

import useQueryParams from '../../../hooks/useQueryParams';
import useFetch from '../../../hooks/useFetch';

import group from '../../../api/group';
import { SSAAwardOptions } from '../../../configs/ssa';
import { 
	getYearList, 
	getSitemapData, 
	getFooterTotal,
	getObjectFromArray,
	getAgentRankingLabel,
} from '../../../utils';

const sitemapValue = 'GROUP_SSA';
const { label } = getSitemapData(sitemapValue);

const monthOptions = ['none', ...moment.monthsShort()].map((e, i) => ({
	label: e,
	value: String(i),
}));

const headers = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
	},
	{
		id: 'ranking',
		label: 'Ranking',
		type: 'string',
	},
	{
		id: 'award',
		label: 'Award',
		type: 'string',
	},
	{
		id: 'target',
		label: 'Target',
		type: 'string',
	},
	{
		id: 'sANPGoal',
		label: 'Goal S-ANP',
		type: 'float',
	},
	{
		id: 'achieveSANP',
		label: 'Achieved S-ANP',
		type: 'float',
	},
	{
		id: 'balanceSANP',
		label: 'Balance S-ANP',
		type: 'float',
	},
	{
		id: 'NOCWithMinTarget',
		label: 'NOC',
		type: 'int',
	},
	{
		id: 'pr',
		label: 'PR (%)',
		type: 'float',
	},
	{
		id: 'award',
		label: 'Award',
		type: 'string',
	},
	{
		id: 'achieveMonth',
		label: 'Month Achieved',
		type: 'string',
		disableSort: true,
	},
];

const generateHeaders = (NOCTarget) => {
	let _headers = JSON.parse(JSON.stringify(headers));
	_headers[7].label = NOCTarget ? `NOC/${NOCTarget}` : 'NOC';
	return _headers;
};

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	},
}));

function MSA() {
	const classes = useStyles();
	const [queryParams, setQueryParams] = useQueryParams();
	const [NOCTarget, setNOCTarget] = useState('');
	const [data, setData] = useState([]);
	const [footers, setFooters] = useState([]);
	const [res, status, handleFetch] = useFetch(group.getSSA);

	const handleChange = (type) => (event) => {
		setQueryParams({ [type]: event.target.value });
	};

	useEffect(() => {
		let tmp = {};
		if(!queryParams.year) tmp.year = moment().year();
		setQueryParams(tmp);
	}, [queryParams.year, setQueryParams]);

	useEffect(() => {
		if(queryParams.year) {
			handleFetch({ year: queryParams.year });
		}
	}, [handleFetch, queryParams.year]);

	useEffect(() => {
		if(status.loaded) {
			if(!status.error) {
				const formattedData = res.SSA.map(e => ({
					...e,
					ranking: e.isFirstTimer ? '1st Timer' : getAgentRankingLabel(e.ranking),
					target: e.target === null ? '-' : getObjectFromArray(SSAAwardOptions, e.target).label,
					award: e.award === null ? '-' : getObjectFromArray(SSAAwardOptions, e.award).label,
					achieveMonth: e.achieveMonth === null ? '-' : getObjectFromArray(monthOptions, e.achieveMonth).label,
				}));
				if(formattedData.length) setNOCTarget(String(formattedData[0].NOCTarget));
				setData(formattedData);
				setFooters([{
					name: 'Total',
					...getFooterTotal(headers, formattedData),
					pr: '-'
				}]);
			} else {
				setData([]);
				setFooters([]);
			}
		}
	}, [res, status.loaded, status.error]);

	return (
		<Layout title={label}>
			<Select 
				className={classes.select}
				maxWidth={110}
				label="Year"
				options={getYearList()}
				value={queryParams.year}
				onChange={handleChange('year')}
			/>

			<Table
				headers={generateHeaders(NOCTarget)}
				data={data}
				footers={footers}
				isLoading={status.loading}
			/>

		</Layout>
	);
}

export default MSA;
