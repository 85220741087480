import request from './request';

function getPlans(data) {
	return request({
		url: '/plans',
		method: 'GET',
		params: data,
	});
}

function getPlan(data) {
	return request({
		url: `/plans/${data.planID}`,
		method: 'GET',
	});
}

function createPlan(data) {
	return request({
		url: '/plans',
		method: 'POST',
		data,
	});
}

function updatePlan(data) {
	const { planID, ...others } = data;
	return request({
		url: `/plans/${data.planID}`,
		method: 'PUT',
		data: others,
	});
}

function deletePlan(data) {
	return request({
		url: `/plans/${data.planID}`,
		method: 'DELETE',
	});
}

export default {
	getPlans,
	getPlan,
	createPlan,
	updatePlan,
	deletePlan,
};
