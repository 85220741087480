import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Plan from './plan';
import Agent from './agent';
import Contest from './contest';
import Goal from './goal';
import MSA from './msa';
import SSA from './ssa';
import KPI from './kpi';
import CompanyUpdate from './company-update';
import ContestGroups from './contest-groups';

import sitemaps from '../../configs/sitemaps';

const adminMap = {
  ADMIN_AGENT: Agent,
  ADMIN_GOAL: Goal,
  ADMIN_PLAN: Plan,
  ADMIN_MSA: MSA,
  ADMIN_SSA: SSA,
  ADMIN_KPI: KPI,
  ADMIN_CONTEST: Contest,
  ADMIN_CONTEST_GROUPS: ContestGroups,
  ADMIN_COMPANY_UPDATE: CompanyUpdate,
};

const adminSitemaps = sitemaps.admin.sitemaps
  .filter(e => !e.hidden)
  .map(e => ({
    fullPathname: sitemaps.admin.pathname + e.pathname,
    Component: adminMap[e.value],
  }));

function Personal() {
  return (
    <Switch>
      {adminSitemaps.map(sitemap => (
        <Route key={sitemap.fullPathname} path={sitemap.fullPathname}>
          <sitemap.Component />
        </Route>
      ))}

      {/* Error handling */}
      <Route>
        <div> 404 Page Not found</div>
      </Route>
    </Switch>
  );
}

export default Personal;
