import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Layout';
import TextField from '../../../components/TextField';

import useSnackbarText from '../../../hooks/useSnackbarText';

import { getSitemapData } from '../../../utils';
import agents from '../../../api/agents';
import { maxWidth } from '../../../components/configs';
import EditProfile from './EditProfile';

const passwordMinLength = 8;

const sitemapValue = 'PERSONAL_PROFILE';
const { label, fullPathname } = getSitemapData(sitemapValue);

const useStyles = makeStyles(theme => ({
  content: {
    backgroundColor: '#fff',
    padding: 10,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  submitButton: {
    maxWidth,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  loading: {
    marginTop: 50,
    marginBottom: 50,
    color: '#c7c7c7',
  },
  title: {
    fontWeight: 'bold',
  },
}));

function Update() {
  const classes = useStyles();
  const handleSnackbarChange = useSnackbarText();
  const routerHistory = useHistory();
  const [state, _setState] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState({});
  const [disabled, setDisable] = useState(false);

  const setState = useCallback((newState = {}) => {
    _setState(prevState => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleValueCheck = () => {
    let noError = true;
    let error = {};
    if (!state.oldPassword) {
      noError = false;
      error.oldPassword = 'Invalid value.';
    }
    if (!state.password) {
      noError = false;
      error.password = 'Invalid value.';
    }
    if (state.password.length < passwordMinLength) {
      noError = false;
      error.password = `Require at least ${passwordMinLength} charaters.`;
    }
    if (!state.confirmPassword) {
      noError = false;
      error.confirmPassword = 'Invalid password.';
    }
    if (state.password !== state.confirmPassword) {
      noError = false;
      error.confirmPassword = 'Passwords not the same.';
    }
    setError(error);
    return noError;
  };

  const handleChange = type => (event, value) => {
    if (value !== undefined) setState({ [type]: value });
    else setState({ [type]: event.target.value });
  };

  const handleSubmit = async () => {
    if (!handleValueCheck()) {
      handleSnackbarChange({ text: 'Invalid inputs', variant: 'error' });
      return;
    }

    setDisable(true);
    let { data, success } = await agents.updatePassword({
      password: state.password,
    });
    setDisable(false);
    if (success) {
      handleSnackbarChange({ text: 'Password updated', variant: 'success' });
    } else {
      return handleSnackbarChange({ text: data.message, variant: 'error' });
    }
    routerHistory.push(fullPathname);
  };

  return (
    <React.Fragment>
      <Layout title={label}>
        <div className={classes.content}>
          <Typography variant="h6" className={classes.title}>
            Update Profile
          </Typography>

          <EditProfile />
        </div>

        <div style={{ paddingBottom: 30 }} />

        <div className={classes.content}>
          <Typography variant="h6" className={classes.title}>
            Change Password
          </Typography>
          <TextField
            label="Current Password"
            value={state.oldPassword}
            onChange={handleChange('oldPassword')}
            error={Boolean(error.oldPassword)}
            helperText={error.oldPassword}
            type="password"
          />

          <TextField
            label="New Password"
            value={state.password}
            onChange={handleChange('password')}
            error={Boolean(error.password)}
            helperText={error.password}
            type="password"
          />

          <TextField
            label="Confirm Password"
            value={state.confirmPassword}
            onChange={handleChange('confirmPassword')}
            error={Boolean(error.confirmPassword)}
            helperText={error.confirmPassword}
            type="password"
          />

          <div className={classes.submitButton}>
            <Button
              disabled={disabled}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
}

export default Update;
