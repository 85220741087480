import React, { useState } from 'react';
import moment from 'moment';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  Typography,
  Collapse,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Select from '../../../components/Select';

import { getYearList } from '../../../utils';
import { agentRankingOptions } from '../../../configs/agents';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 20,
  },
  listItem: {
    backgroundColor: '#f9f9f9',
    padding: 0,
    paddingLeft: 10,
    borderRadius: theme.shape.borderRadius,
  },
  firstSelect: {
    marginRight: 10,
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  monthContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f9f9f9',
  },
  monthItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 29,
  },
}));

function StateItem(props) {
  const { data, onToggle, onChange, onMonthToggle } = props;
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => setIsExpanded(prev => !prev);

  const handleMonthToggle = (year, month, isActive) => () => {
    onMonthToggle(year, month, isActive);
  };

  return (
    <div className={classes.root}>
      <ListItem className={classes.listItem}>
        <ListItemText>
          <Select
            disabled
            className={classes.firstSelect}
            label="Year"
            options={getYearList()}
            value={data.year}
            onChange={onChange('year')}
            maxWidth={100}
          />
          <Select
            label="Ranking"
            options={agentRankingOptions}
            value={data.ranking}
            onChange={onChange('ranking')}
            maxWidth={120}
          />
        </ListItemText>
        <ListItemSecondaryAction>
          <div className={classes.col}>
            <Typography variant="body2">Active Agent</Typography>
            <Switch
              edge="end"
              onChange={() => onToggle(!data.isActive)}
              checked={data.isActive}
            />
          </div>
        </ListItemSecondaryAction>
      </ListItem>
      <div className={classes.monthContainer}>
        <Collapse in={isExpanded} timeout="auto">
          {Array(12)
            .fill('')
            .map((_, i) => (
              <div key={i} className={classes.monthItem}>
                <Typography>{moment().month(i).format('MMM')}</Typography>
                <Switch
                  edge="end"
                  onChange={handleMonthToggle(
                    data.year,
                    i + 1,
                    !data.activeMonths.includes(i + 1)
                  )}
                  checked={data.activeMonths.includes(i + 1)}
                />
              </div>
            ))}
        </Collapse>
        <Button onClick={handleExpand}>
          {isExpanded ? `Close` : `Expand`}
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </Button>
      </div>
    </div>
  );
}

export default StateItem;
