import React, { useState, useEffect, useCallback, Fragment } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Layout';
import Radio from '../../../components/Radio';
import Select from '../../../components/Select';
import TextField from '../../../components/TextField';
import DatePicker from '../../../components/DatePicker';

import usePlanList from '../../../hooks/usePlanList';
import useQueryParams from '../../../hooks/useQueryParams';
import useSnackbarText from '../../../hooks/useSnackbarText';

import { 
	isInforced,
	submissionStatusOptions,
	submissionModeOptions,
	submissionIsExistingClientOptions,
	submissionHasNomineeOptions,
	submissionCarAssistantOptions,
	submissionMethodOptions, 
} from '../../../configs/submissions';
import { getSitemapData } from '../../../utils';
import submissions from '../../../api/submissions';
import { maxWidth } from '../../../components/configs';

const dateFormat = 'YYYY-MM-DD';
const sitemapValue = 'PERSONAL_SUBMISSION';
const { label, fullPathname } = getSitemapData(sitemapValue);

const useStyles = makeStyles(theme => ({
	content: {
		backgroundColor: '#fff',
		padding: 10,
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column'
	},
	submitButton: {
		maxWidth,
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: 20,
	},
	loading: {
		marginTop: 50,
		marginBottom: 50,
		color: '#c7c7c7'
	}
}));

function Update() {
	const classes = useStyles();
	const planList = usePlanList();
	const handleSnackbarChange = useSnackbarText();
	const routerHistory = useHistory();
	const [queryParams] = useQueryParams();
	const [state, _setState] = useState({
		submissionDate: moment().format(dateFormat),
		lifeAssured: '',
		policyNumber: '',
		planID: '',
		BSA: '',
		status: submissionStatusOptions[0].value,
		issuedDate: null,
		mode: submissionModeOptions[0].value,
		initial: '',
		isExistingClient: submissionIsExistingClientOptions[0].value,
		hasNominee: submissionHasNomineeOptions[0].value,
		carAssistant: submissionCarAssistantOptions[0].value,
		method: submissionMethodOptions[0].value,
	});
	const [error, setError] = useState({});
	const [disabled, setDisable] = useState(false);
	const [loaded, setLoaded] = useState(false);

	const setState = useCallback((newState = {}) => {
		_setState((prevState) => ({
			...prevState,
			...newState,
		}));
	}, []);

	const handleValueCheck = () => {
		let noError = true;
		let error = {};
		if(!state.lifeAssured) {
			noError = false;
			error.lifeAssured = 'Invalid value.';
		}
		// if(!state.policyNumber) {
		// 	noError = false;
		// 	error.policyNumber = 'Invalid value.';
		// }
		if(!state.BSA) {
			noError = false;
			error.BSA = 'Invalid value.';
		}
		if(!state.initial) {
			noError = false;
			error.initial = 'Invalid value.';
		}
		if(isInforced(state.status) && !state.issuedDate) {
			noError = false;
			error.issuedDate = 'Invalid value.';
		}
		setError(error);
		return noError;
	};

	const handleChange = (type) => (event, value) => {
		if(value !== undefined) setState({ [type]: value });
		else setState({ [type]: event.target.value });
	};

	const handleSubmit = async () => {
		if(!handleValueCheck()) {
			handleSnackbarChange({ text: 'Invalid inputs', variant: 'error' });
			return window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		};

		setDisable(true);
		let { data, success } = await submissions.updateSubmission({
			...state,
			submissionID: queryParams.submissionID,
		});
		setDisable(false);
		if(success) {
			handleSnackbarChange({ text: 'Submission updated', variant: 'success' });
		} else {
			return handleSnackbarChange({ text: data.message, variant: 'error' });
		}
		routerHistory.push(fullPathname);
	};

	useEffect(() => {
		const submissionID = queryParams.submissionID;
		const getData = async () => {
			let { data, success } = await submissions.getSubmission({ submissionID });
			if(success) {
				setState({
					...data,
					planID: String(data.planID),
					status: String(data.status),
					mode: String(data.mode),
					isExistingClient: String(data.isExistingClient),
					hasNominee: String(data.hasNominee),
					carAssistant: String(data.carAssistant),
					method: String(data.method),
				});
				setLoaded(true);
			} else {
				return handleSnackbarChange({ text: data.message, variant: 'error' });
			}
		}

		if(submissionID) getData();
	}, [queryParams.submissionID, handleSnackbarChange, setState, setLoaded])

	return (
		<Layout title={label}>
			<div className={classes.content}>
				{!loaded && <div className={classes.loading}>loading</div>}
				{loaded && (
					<Fragment>

						<DatePicker
							label="Submission Date"
							value={state.submissionDate}
							onChange={handleChange('submissionDate')}
						/>

						<TextField 
							label="Life Assured*"
							value={state.lifeAssured}
							onChange={handleChange('lifeAssured')}
							error={Boolean(error.lifeAssured)}
							helperText={error.lifeAssured}
						/>

						<TextField 
							label="Policy Number"
							value={state.policyNumber}
							onChange={handleChange('policyNumber')}
							error={Boolean(error.policyNumber)}
							helperText={error.policyNumber}
						/>

						<Select 
							label="Plan"
							options={planList}
							value={state.planID}
							onChange={handleChange('planID')}
							group
						/>

						<TextField 
							label="BSA*"
							value={state.BSA}
							onChange={handleChange('BSA')}
							type="number"
							error={Boolean(error.BSA)}
							helperText={error.BSA}
						/>

						<Select 
							label="Status"
							options={submissionStatusOptions}
							value={state.status}
							onChange={handleChange('status')}
						/>

						{isInforced(state.status) && (
							<DatePicker
								label="Issued Date"
								value={state.issuedDate}
								onChange={handleChange('issuedDate')}
								error={Boolean(error.issuedDate)}
								helperText={error.issuedDate}
								clearable
							/>
						)}

						<Select 
							label="Mode"
							options={submissionModeOptions}
							value={state.mode}
							onChange={handleChange('mode')}
						/>

						<TextField 
							label="Initial*"
							value={state.initial}
							onChange={handleChange('initial')}
							type="number"
							error={Boolean(error.initial)}
							helperText={error.initial}
						/>

						<Radio 
							label="Client"
							options={submissionIsExistingClientOptions}
							value={state.isExistingClient}
							onChange={handleChange('isExistingClient')}
							row
						/>

						<Radio 
							label="Nominee"
							options={submissionHasNomineeOptions}
							value={state.hasNominee}
							onChange={handleChange('hasNominee')}
							row
						/>

						<Radio 
							label="Car Assistant"
							options={submissionCarAssistantOptions}
							value={state.carAssistant}
							onChange={handleChange('carAssistant')}
						/>

						<Select 
							label="Method"
							options={submissionMethodOptions}
							value={state.method}
							onChange={handleChange('method')}
						/>

						<div className={classes.submitButton}>
							<Button
								disabled={disabled}
								variant="contained"
								color="primary"
								onClick={handleSubmit}
							>
								Update
							</Button>
						</div>

					</Fragment>
				)}

			</div>
		</Layout>
	);
}

export default Update;
