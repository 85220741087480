const planTypeOptions = [
	{ label: 'Traditional', value: '1' },
	{ label: 'Investment', value: '0' },
];

const planStatusOptions = [
	{ label: 'Active', value: '1' },
	{ label: 'Disabled', value: '0' },
];

export {
	planTypeOptions,
	planStatusOptions,
}
