import React, { useEffect, useState } from 'react';
import {
  Link,
  Button,
  Dialog,
  List,
  ListItem,
  ListItemText,
  Collapse,
  makeStyles,
  Typography,
  TextField,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Select from '../../../components/Select';
import fileApi from '../../../api/files';
import useSnackbarText from '../../../hooks/useSnackbarText';
import { trainingCategories } from '../../../configs/companyUpdate';

// 'culture_book' | 'other_activities' | 'training' | 'company_event'
const taxonomy = 'training';

const maxUploadSize = 100 * 1024 * 1024;

const useStyles = makeStyles(theme => ({
  section: {
    borderRadius: 10,
    backgroundColor: `#ffffff`,
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2.5),
  },
  header: { fontWeight: 'bold' },
  fileInput: { display: 'none' },
  row: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  createButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  createButton: {
    color: '#ffffff',
    fontWeight: 600,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  deleteIconButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.error.main,
  },
  deleteButton: {
    color: '#ffffff',
    fontWeight: 600,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  accordionWrapper: {
    marginBottom: theme.spacing(2),
  },
  accordionHeader: {
    borderRadius: 10,
    marginBottom: 4,
    backgroundColor: `#ffeaea!important`,
  },
}));

export default function TrainingPage() {
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState('');
  const [items, setItems] = useState([]);
  const [timestamp, setTimestamp] = useState(new Date().valueOf());
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [deleteFileID, setDeleteFileID] = useState('');
  const [inputKey, setInputKey] = useState(Math.random());
  const [category, setCategory] = useState(trainingCategories[0].value);
  const [expandedSections, setExpandedSections] = useState([]);

  const handleSnackbarChange = useSnackbarText();

  const handleRefresh = () => setTimestamp(new Date().valueOf());

  const handleCategoryChange = event => {
    const value = event.target.value;
    setCategory(value);
  };

  const handleFileChange = event => {
    const file = event.target.files[0];

    if (file.size > maxUploadSize) {
      handleSnackbarChange({
        text: `File size is too large (Max 100MB)`,
        variant: 'error',
      });
      return;
    }
    setFile(file);
  };

  const handleTitleChange = event => {
    const value = event.target.value;
    setTitle(value);
  };

  const handleClearFileInput = () => {
    setFile(null);
    setInputKey(Math.random());
  };

  const handleCreate = async () => {
    if (!title.trim()) {
      handleSnackbarChange({ text: `Title cannot be empty`, variant: 'error' });
      return;
    }

    if (!file) {
      handleSnackbarChange({ text: `File cannot be empty`, variant: 'error' });
      return;
    }

    setIsUploading(true);
    const result = await fileApi.createFile({
      name: title,
      taxonomy,
      file,
      value: category,
    });
    setIsUploading(false);

    if (result.success) {
      handleSnackbarChange({
        text: `File has been uploaded`,
        variant: 'success',
      });
      setTitle('');
      handleRefresh();
      handleClearFileInput();
    } else {
      handleSnackbarChange({ text: result.data.message, variant: 'error' });
    }
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    const result = await fileApi.deleteFile(deleteFileID);
    setIsDeleting(false);

    if (result.success) {
      handleSnackbarChange({
        text: `File has been deleted`,
        variant: 'success',
      });
      setDeleteFileID('');
      handleRefresh();
    } else {
      handleSnackbarChange({ text: result.data.message, variant: 'error' });
    }
  };

  const handleToggleCollapse = value => () => {
    setExpandedSections(prev => {
      return prev.includes(value) ? prev.filter(p => p !== value) : [value];
    });
  };

  useEffect(() => {
    async function main() {
      const result = await fileApi.getFiles({ taxonomy });

      if (result.success) {
        setItems(result.data.files);
      } else {
        handleSnackbarChange({ text: result.data.message, variant: 'error' });
      }
    }

    main();
  }, [timestamp]);

  const fileToBeDeleted = items.find(item => item.fileID === deleteFileID);

  return (
    <div>
      <div className={classes.section}>
        <Typography className={classes.header} variant="h6">
          Training & Development
        </Typography>

        <TextField
          label="Title"
          value={title}
          onChange={handleTitleChange}
          margin="dense"
          variant="filled"
          fullWidth
          required
        />

        <Select
          label="Category"
          options={trainingCategories}
          value={category}
          onChange={handleCategoryChange}
          maxWidth="100%"
          required
        />

        <div style={{ marginTop: `10px` }}>
          <div className={classes.row}>
            <div style={{ flex: 1 }}>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                File (Please upload 1 file)
              </Typography>
            </div>

            <div style={{ flex: 0 }}>
              <input
                key={inputKey}
                className={classes.fileInput}
                onChange={handleFileChange}
                id="contained-button-file"
                type="file"
              />

              {file ? (
                <div className={classes.row}>
                  <Typography color="primary" variant="body2">
                    {file.name}
                  </Typography>
                  <IconButton
                    className={classes.deleteIconButton}
                    onClick={handleClearFileInput}
                    size="small"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              ) : (
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    style={{ fontWeight: 'bold' }}
                  >
                    Upload
                  </Button>
                </label>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={classes.createButtonWrapper}>
        <Button
          className={classes.createButton}
          onClick={handleCreate}
          variant="contained"
          disabled={isUploading}
        >
          Create
        </Button>
      </div>

      <div className={classes.section}>
        <List>
          {trainingCategories.map((category, i) => {
            const filteredItems = items.filter(i => i.value === category.value);

            return (
              <div
                key={category.value}
                className={classes.accordionWrapper}
                style={
                  i === trainingCategories.length - 1
                    ? { marginBottom: 0 }
                    : undefined
                }
              >
                <ListItem
                  className={classes.accordionHeader}
                  onClick={handleToggleCollapse(category.value)}
                  disabled={filteredItems.length === 0}
                  dense
                  button
                >
                  <ListItemText
                    primary={category.label}
                    primaryTypographyProps={{ style: { fontWeight: 'bold' } }}
                  />
                  {expandedSections.includes(category.value) ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItem>

                <Collapse in={expandedSections.includes(category.value)}>
                  {filteredItems.map((item, index) => (
                    <div
                      key={item.fileID}
                      className={classes.row}
                      style={{
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant="body2">
                        {`${index + 1}. ${item.name}`}
                      </Typography>

                      <div className={classes.row}>
                        <Link href={item.url} target="_blank">
                          <span>{item.originalName}</span>
                        </Link>
                        <IconButton
                          className={classes.deleteIconButton}
                          onClick={() => setDeleteFileID(item.fileID)}
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                  ))}
                </Collapse>
              </div>
            );
          })}
        </List>
      </div>

      <Dialog
        open={Boolean(deleteFileID)}
        onClose={() => setDeleteFileID('')}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>{`Delete "${fileToBeDeleted?.name || ''}"`}</DialogTitle>
        <DialogContent>
          <DialogContentText>It cannot be undone</DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button onClick={() => setDeleteFileID('')}>Cancel</Button>
          <Button
            className={classes.deleteButton}
            onClick={handleDelete}
            disabled={isDeleting}
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
