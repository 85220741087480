import { useMemo, useCallback } from 'react';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

window.queryString = queryString;

function useQueryParams() {
	const routerHistory = useHistory();
	const routerLocation = useLocation();
	
	const { search } = routerLocation;
	const currentState = useMemo(() => queryString.parse(search), [search]);

	const setState = useCallback((newState = {}) => {
		const { search, pathname } = window.location;
		const currentState = queryString.parse(search);
		const newParams = queryString.stringify({ 
			...currentState, 
			...newState 
		}, { skipNull: true });
		routerHistory.replace(`${pathname}?${newParams}`);
	}, [routerHistory])

	return [currentState, setState];
}

export default useQueryParams;
