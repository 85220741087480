import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Submission from './submission';
import Prospect from './prospect';
import Try from './try';
import Profile from './profile';
import Performance from './performance';
import QFYLP from './qfylp';
import Goal from './goal';
import Opportunity from './opportunity';

import sitemaps from '../../configs/sitemaps';

const personalMap = {
  PERSONAL_SUBMISSION: Submission,
  PERSONAL_GOAL: Goal,
  PERSONAL_OPPORTUNITY: Opportunity,
  PERSONAL_PERFORMANCE: Performance,
  PERSONAL_QFYLP: QFYLP,
  PERSONAL_TRACKING: Try,
  PERSONAL_PROSPECT: Prospect,
  PERSONAL_PROFILE: Profile,
};

const personalSitemaps = sitemaps.personal.sitemaps
  .filter(e => !e.hidden)
  .map(e => ({
    fullPathname: sitemaps.personal.pathname + e.pathname,
    Component: personalMap[e.value],
  }));

function Personal() {
  return (
    <Switch>
      {personalSitemaps.map(sitemap => (
        <Route key={sitemap.fullPathname} path={sitemap.fullPathname}>
          <sitemap.Component />
        </Route>
      ))}

      {/* Error handling */}
      <Route>
        <div> 404 Page Not found</div>
      </Route>
    </Switch>
  );
}

export default Personal;
