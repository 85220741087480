import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Tabs, Tab } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';
import Checkbox from '../../../components/Checkbox';
import Dialog from '../../../components/Dialog';

import Summary from './Summary';

import useQueryParams from '../../../hooks/useQueryParams';
import useFetch from '../../../hooks/useFetch';

import group from '../../../api/group';
import { 
	getYearList, 
	getSitemapData, 
	getFooterTotal,
} from '../../../utils';

const defaultHideEmpty = true;
const defaultTableType = 'RESOURCE';

const dateFormat = 'YYYY-MM-DD';
const sitemapValue = 'GROUP_PROSPECT';
const { label } = getSitemapData(sitemapValue);

const resourceHeaders = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
	},
	{
		id: 'referral',
		label: 'Referral',
		type: 'int',
	},
	{
		id: 'roadShow',
		label: 'Road Show',
		type: 'int',
	},
	{
		id: 'bankAttack',
		label: 'Bank Attack',
		type: 'int',
	},
	{
		id: 'doorToDoor',
		label: 'Door To Door',
		type: 'int',
	},
	{
		id: 'socialMedia',
		label: 'Social Media',
		type: 'int',
	},
	{
		id: 'others',
		label: 'Others',
		type: 'int',
	},
	{
		id: 'total',
		label: 'Total',
		type: 'int',
	},
];

const outcomeHeaders = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
		disableSort: true,
	},
	{
		id: 'approach',
		label: 'Approach',
		type: 'int',
	},
	{
		id: 'followUp',
		label: 'Follow Up',
		type: 'int',
	},
	{
		id: 'noResponse',
		label: 'No Response',
		type: 'int',
	},
	{
		id: 'reject',
		label: 'Reject',
		type: 'int',
	},
	{
		id: 'closing',
		label: 'Closing',
		type: 'int',
	},
	{
		id: 'pending',
		label: 'Pending',
		type: 'int',
	},
	{
		id: 'total',
		label: 'Total',
		type: 'int',
	},
];

const monthOptions = ['Whole year', ...moment.monthsShort()].map((e, i) => ({
	label: e,
	value: String(i),
}));

const tabOptions = [
	{ label: 'Resource', value: 'RESOURCE' },
	{ label: 'Outcome', value: 'OUTCOME' },
];

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	},
	summaryButton: {
		marginTop: 14,
		marginLeft: 5,
	},
	wrapper: {
		borderRadius: theme.shape.borderRadius,
		backgroundColor: '#fff'
	},
	tabs: {
		//backgroundColor: '#fff',
		marginBottom: -10,
	},
	selected: {
		color: theme.palette.primary.main,
		fontWeight: 600,
	},
}));

function Prospect() {
	const classes = useStyles();
	const [queryParams, setQueryParams] = useQueryParams();
	const [tableType, setTableType] = useState(defaultTableType);
	const [data, setData] = useState([]);
	const [footers, setFooters] = useState([]);
	const [res, status, handleFetch] = useFetch(group.getProspects);
	const [hideEmpty, setHideEmpty] = useState(defaultHideEmpty);

	const [year, setYear] = useState(
		(queryParams.startDate && moment(queryParams.startDate).year())
		|| moment().year()
	);

	const [month, setMonth] = useState(
		(queryParams.startDate && String(moment(queryParams.startDate).month() + 1))
		|| String(moment().month() + 1)
	);

	const handleToggle = () => {
		setHideEmpty(prev => !prev);
	};

	const handleShowSummary = () => {
		setQueryParams({ summary: 'true' });
	};

	const handleTabChange = (event, newValue) => {
		setTableType(newValue);
	};

	const handleChange = (type) => (event) => {
		if(type === 'year') setYear(String(event.target.value));
		if(type === 'month') setMonth(String(event.target.value));
	};

	useEffect(() => {
		if(month === '0') {
			setQueryParams({
				startDate: moment().year(year).startOf('year').format(dateFormat),
				endDate: moment().year(year).endOf('year').format(dateFormat),
			});
		} else {
			setQueryParams({
				startDate: moment(`${year}-01-01`).month(parseInt(month) - 1).startOf('month').format(dateFormat),
				endDate: moment(`${year}-01-01`).month(parseInt(month) - 1).endOf('month').format(dateFormat),
			});
		}
	}, [year, month, setQueryParams]);

	useEffect(() => {
		if(queryParams.startDate && queryParams.endDate) {
			handleFetch({ 
				startDate: queryParams.startDate,
				endDate: queryParams.endDate,
			});
		}
	}, [handleFetch, queryParams.startDate, queryParams.endDate]);

	useEffect(() => {
		if(status.loaded) {
			if(!status.error) {
				const formattedData = res.prospects.map(e => ({
					name: e.name,
					total: e.total,
					...e.resources,
					...e.outcomes
				}));
				setData(formattedData);
			
				const footer = getFooterTotal([
					...resourceHeaders,
					...outcomeHeaders,
				], formattedData);
				setFooters([{
					name: 'Total',
					...footer,
				}]);
			} else {
				setData([]);
				setFooters([]);
			}
		}
	}, [res, status.loaded, status.error]);

	let formattedData = data;
	if(hideEmpty) {
		formattedData = data.filter(e => e.total);
	} 

	return (
		<Layout title={label}>
			<Select 
				className={classes.select}
				maxWidth={110}
				label="Year"
				options={getYearList()}
				value={year}
				onChange={handleChange('year')}
			/>

			<Select 
				className={classes.select}
				maxWidth={130}
				label="Month"
				options={monthOptions}
				value={month}
				onChange={handleChange('month')}
			/>

			<Button 
				className={classes.summaryButton} 
				onClick={handleShowSummary} 
				color="primary"
				startIcon={<DescriptionIcon />}
				variant="outlined"
			>
				Summary
			</Button>

			<div className={classes.wrapper}>
				<Tabs 
					className={classes.tabs}
					value={tableType} 
					onChange={handleTabChange} 
					indicatorColor="primary" 
					variant="scrollable"
				>
					{tabOptions.map(e => (
						<Tab key={e.value} classes={{ 
							root: classes.tab,
							selected: classes.selected 
						}} label={e.label} value={e.value} />
					))}
				</Tabs>

				<Table
					title={
						<Checkbox
							label="Hide empty"
							value={hideEmpty}
							onChange={handleToggle}
						/>
					}
					headers={tableType === 'RESOURCE' ? resourceHeaders : outcomeHeaders}
					data={formattedData}
					footers={footers}
					isLoading={status.loading}
				/>
			</div>

			<Dialog queryParamKey="summary" title="Summary" maxWidth="100%">
				<Summary />
			</Dialog>

		</Layout>
	);
}

export default Prospect;
