import React, { Fragment } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
	FormControl,
	InputLabel,
	Select,
	FilledInput,
	MenuItem,
	ListSubheader,
	FormHelperText
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { maxWidth } from '../configs';

/*
	GroupOptions Example
const groupOptions = [
	{ 
		label: 'hello', 
		options: [
			{ label: 'kl', value: 'kl' },
			{ label: 'jb', value: 'jb' },
		]
	},
];
*/

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		maxWidth,
	},
	select: {
		
	},
	noLabel: {
		paddingTop: 10,
		paddingBottom: 10,
	},
	menuPaper: {
		//maxHeight: 300,
		border: '1px solid #efefef',
	},
	subheader: {
		backgroundColor: '#fff',
		borderTop: '1px solid #efefef',
		color: theme.palette.primary.main,
	}
}));

const generateGroupOptions = (groupOptions = []) => {
	let tmp = [];
	for(let i = 0; i < groupOptions.length; i++) {
		const currentOption = groupOptions[i];
		tmp.push({ label: currentOption.label, isSubheader: true });
		for(let j = 0; j < currentOption.options.length; j++) {
			tmp.push({ ...currentOption.options[j], isSubheader: false });
		}
	}
	return tmp;
};

function CustomSelect(props) {
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));

	const { 
		label, 
		value, 
		options = [], 
		onChange, 
		helperText,
		IconComponent, 
		group,
		maxWidth: propMaxWidth,
		...others 
	} = props;

	const handleChange = (event) => {
		if(event.target.value) 
			onChange(event, event.target.value);
	};

	return (
		<FormControl 
			{...others}
			style={propMaxWidth && { maxWidth: propMaxWidth }}
			className={clsx(classes.root, props.className)} 
		>
			<InputLabel htmlFor={`label-${label}`}>{label}</InputLabel>
			<Select
				className={clsx(classes.select)}
				value={value === undefined ? '' : value}
				onChange={handleChange}
				input={
					<FilledInput 
						name={`label-${label}`} 
						id={`label-${label}`} 
						classes={{ input: clsx(!label && classes.noLabel) }}
					/>
				}
				native={matches}
				MenuProps={{
					classes: {
						paper: classes.menuPaper,
					}
				}}
				IconComponent={IconComponent}
			>

				{!matches && label && (
					<MenuItem value="" disabled dense>
						<em>{label}</em>
					</MenuItem>
				)}

				{!matches && Boolean(group) && generateGroupOptions(options).map(option => (
					option.isSubheader ? (
						<ListSubheader className={classes.subheader} key={option.label}>{option.label}</ListSubheader>
					) : (
						<MenuItem 
							key={option.value} 
							value={option.value}
							disabled={Boolean(option.disabled)}
							dense
						>
							{option.label}
						</MenuItem>
					)
				))}

				{!matches && !Boolean(group) && options.map(option => (
					<MenuItem 
						key={option.value} 
						value={option.value}
						disabled={Boolean(option.disabled)}
						dense
					>
						{option.label}
					</MenuItem>
				))}

				{matches && (
					<Fragment>
						{Boolean(label) && <option value="" disabled></option>}
						{options.map(option => (
							group ? (
								<optgroup label={option.label} key={option.label}>
									{option.options.map(e => (
										<option 
											key={e.value} 
											value={e.value}
											disabled={Boolean(e.disabled)}
										>
											{e.label}
										</option>
									))}
								</optgroup>
							) : (
								<option 
									key={option.value} 
									value={option.value}
									disabled={Boolean(option.disabled)}
								>
									{option.label}
								</option>
							)
						))}
					</Fragment>
				)}

			</Select>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
}

export default CustomSelect;
