import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';

import useQueryParams from '../../../hooks/useQueryParams';
import useDirectGroupLeaders from '../../../hooks/useDirectGroupLeaders';
import useFetch from '../../../hooks/useFetch';

import group from '../../../api/group';
import { 
	getYearList, 
	getSitemapData, 
	getFooterTotal,
} from '../../../utils';

const adminAgentID = 1;
const sitemapValue = 'GROUP_QFYLP';
const { label } = getSitemapData(sitemapValue);

const startHeaders = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
	},
];

const generateTabOptions = (rawYear) => {
	const year = Number(rawYear);
	return [
		{ label: `${year - 1} Total Renewal`, value: 'RENEWAL' },
		{ label: `${year} New Business`, value: 'NEW_BUSINESS' },
		{ label: `${year - 1} & ${year} Total QFYLP`, value: 'TOTAL' },
	];
};

const generateHeaders = (tabValue, rawYear = moment().year()) => {
	const year = parseInt(rawYear);
	let headers = [...startHeaders];
	switch(tabValue) {
		case 'RENEWAL': 
			headers = [
				...headers,
				...moment.monthsShort().map(e => ({
					id: `RENEWAL_${e.toUpperCase()}`,
					label: e,
					type: 'float',
				})),
				{
					id: 'RENEWAL_TOTAL',
					label: `Total ${year - 1}`,
					type: 'float',
				},
				{
					id: 'RENEWAL_FYC',
					label: 'FYC',
					type: 'float',
				},
			];
			break;
		case 'NEW_BUSINESS': 
			headers = [
				...headers,
				...moment.monthsShort().map(e => ({
					id: `NEW_BUSINESS_${e.toUpperCase()}`,
					label: e,
					type: 'float',
				})),
				{
					id: 'NEW_BUSINESS_TOTAL',
					label: `Total ${year}`,
					type: 'float',
				},
				{
					id: 'NEW_BUSINESS_CF',
					label: `C/F ${year + 1}`,
					type: 'float',
				},
				{
					id: 'NEW_BUSINESS_FYC',
					label: 'FYC',
					type: 'float',
				},
			];
			break;
		case 'TOTAL': 
			headers = [
				...headers,
				...moment.monthsShort().map(e => ({
					id: `TOTAL_${e.toUpperCase()}`,
					label: e,
					type: 'float',
				})),
				{
					id: 'TOTAL_TOTAL',
					label: `Total ${year}`,
					type: 'float',
				},
				{
					id: 'NEW_BUSINESS_CF',
					label: `C/F ${year + 1}`,
					type: 'float',
				},
				{
					id: 'TOTAL_FYC',
					label: 'FYC',
					type: 'float',
				},
			];
			break;
		default:
			break;
	}
	return headers;
};

const generateToDateFooter = (rawFooter, tabValue) => {
	let footer = {};
	const months = moment.monthsShort().map(e => e.toUpperCase());

	let tmp = 0;
	for(let i = 0; i < 12; i++) {
		const key = `${tabValue}_${months[i]}`;
		const currentValue = rawFooter[key];
		tmp += currentValue;
		footer[key] = tmp;
	}
	return footer;
};

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	},
	summaryButton: {
		marginTop: 14,
		marginLeft: 5,
	},
	wrapper: {
		borderRadius: theme.shape.borderRadius,
		backgroundColor: '#fff'
	},
	tabs: {
		//backgroundColor: '#fff',
		marginBottom: -10,
	},
	tab: {
	},
	selected: {
		color: theme.palette.primary.main,
		fontWeight: 600,
	},
}));

function QFYLP() {
	const classes = useStyles();
	const [queryParams, setQueryParams] = useQueryParams();
	const leaders = useDirectGroupLeaders(queryParams.year, true);
	const [data, setData] = useState([]);
	const [footers, setFooters] = useState([]);
	const [res, status, handleFetch] = useFetch(group.getQFYLP);
	const tabOptions = generateTabOptions(queryParams.year || moment().year());
	const [tab, setTab] = useState(tabOptions[1].value);

	const handleChange = (type) => (event) => {
		if(type === 'year') {
			setQueryParams({ 
				[type]: event.target.value,
				leaderID: adminAgentID,
			});
		} else 
			setQueryParams({ [type]: event.target.value });
	};

	const handleTabChange = (event, newValue) => {
		setTab(newValue);
	};

	useEffect(() => {
		let tmp = {};
		if(!queryParams.year) tmp.year = moment().year();
		if(!queryParams.leaderID && leaders.length) {
			tmp.leaderID = leaders[0].value;
		}
		setQueryParams(tmp);
	}, [queryParams.year, queryParams.leaderID, leaders, setQueryParams]);

	useEffect(() => {
		if(queryParams.year && queryParams.leaderID) {
			handleFetch({
				year: queryParams.year,
				leaderID: queryParams.leaderID,
			});
		}
	}, [handleFetch, queryParams.year, queryParams.leaderID]);

	useEffect(() => {
		if(status.loaded) {
			if(!status.error) {
				const formattedData = res.qfylp.map(e => {
					let tmp = {
						name: e.name,
						RENEWAL_TOTAL: e.lastYearTotal,
						RENEWAL_FYC: e.lastYearFYC,
						NEW_BUSINESS_TOTAL: e.currentYearTotal,
						NEW_BUSINESS_CF: e.currentYearCF,
						NEW_BUSINESS_FYC: e.currentYearFYC,
						TOTAL_TOTAL: e.lastYearTotal + e.currentYearTotal,
						TOTAL_FYC: e.lastYearFYC + e.currentYearFYC,
					}

					moment.monthsShort().forEach((el, j) => {
						const lastYearValue = e.lastYearValue[j].value;
						const currentYearValue = e.currentYearValue[j].value;
						tmp[`RENEWAL_${el.toUpperCase()}`] = lastYearValue;
						tmp[`NEW_BUSINESS_${el.toUpperCase()}`] = currentYearValue;
						tmp[`TOTAL_${el.toUpperCase()}`] = lastYearValue + currentYearValue;
					});

					return tmp;
				});

				setData(formattedData);
				const footer = getFooterTotal(generateHeaders(tab), formattedData);
				setFooters([
					{
						name: 'Total',
						...footer,
					},
					{
						name: 'Year To Date',
						...generateToDateFooter(footer, tab),
					}
				]);
			} else {
				setData([]);
				setFooters([]);
			}
		}
	}, [res, status.loaded, status.error, tab]);

	return (
		<Layout title={label}>
			<Select 
				className={classes.select}
				maxWidth={110}
				label="Year"
				options={getYearList()}
				value={queryParams.year}
				onChange={handleChange('year')}
			/>

			<Select 
				maxWidth={200}
				label="Group Leader"
				options={leaders}
				value={queryParams.leaderID || ''}
				onChange={handleChange('leaderID')}
			/>

			<div className={classes.wrapper}>
				<Tabs 
					className={classes.tabs}
					value={tab} 
					onChange={handleTabChange} 
					indicatorColor="primary" 
					variant="scrollable"
				>
					{tabOptions.map(e => (
						<Tab key={e.value} classes={{ 
							root: classes.tab,
							selected: classes.selected 
						}} label={e.label} value={e.value} />
					))}
				</Tabs>

				<Table
					headers={generateHeaders(tab, queryParams.year)}
					data={data}
					footers={footers}
					isLoading={status.loading}
				/>
			</div>

		</Layout>
	);
}

export default QFYLP;
