import request from './request';

function getSSA(data) {
	return request({
		url: '/ssa',
		method: 'GET',
		params: data,
	});
}

function updateSSA(data) {
	return request({
		url: '/ssa',
		method: 'PUT',
		data,
	});
}

export default {
	getSSA,
	updateSSA,
};
