import React, { useState, useEffect, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Checkboxes from '../../../components/Checkboxes';
import TextField from '../../../components/TextField';
import Dialog from '../../../components/Dialog';

import useSnackbarText from '../../../hooks/useSnackbarText';
import useQueryParams from '../../../hooks/useQueryParams';

import { maxWidth } from '../../../components/configs';
import { kpiRankingOptions } from '../../../configs/kpi';
import { 
	getObjectFromArray,
	flattenArray,
} from '../../../utils';

const defaultState = {
	label: '',
	solidANP: '',
	solidANPRatio: '',
	NOC: '',
	NOCRatio: '',
	rookie: '',
	rookieRatio: '',
	rankings: []
};

const useStyles = makeStyles(theme => ({
	submitButton: {
		maxWidth,
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: 20,
		marginBottom: 20,
	},
}));

function UpdateDialog(props) {
	const classes = useStyles();
	const [queryParams, setQueryParams] = useQueryParams();
	const handleSnackbarChange = useSnackbarText();
	const [state, _setState] = useState(defaultState);
	const [error, setError] = useState({});

	const handleChange = (type) => (event, value) => {
		if(value !== undefined) setState({ [type]: value });
		else setState({ [type]: event.target.value });
	};

	const setState = useCallback((newState) => {
		_setState((prevState) => ({
			...prevState,
			...newState,
		}));
	}, []);

	const handleValueCheck = () => {
		let noError = true;
		let error = {};
		if(!state.label) {
			noError = false;
			error.label = 'Invalid value.';
		}
		if(!state.solidANP) {
			noError = false;
			error.solidANP = 'Invalid value.';
		} 
		if(!state.solidANPRatio) {
			noError = false;
			error.solidANPRatio = 'Invalid value.';
		} 
		if(!state.NOC) {
			noError = false;
			error.NOC = 'Invalid value.';
		} 
		if(!state.NOCRatio) {
			noError = false;
			error.NOCRatio = 'Invalid value.';
		} 
		if(!state.rookie) {
			noError = false;
			error.rookie = 'Invalid value.';
		} 
		if(!state.rookieRatio) {
			noError = false;
			error.rookieRatio = 'Invalid value.';
		} 
		setError(error);
		return noError;
	};

	const handleUpdate = () => {
		if(!handleValueCheck()) {
			return handleSnackbarChange({ text: 'Invalid inputs', variant: 'error' });
		};

		let allRankings = [...props.standards.map(e => e.rankings)];
		allRankings.splice(queryParams.updateDialog, 1);
		allRankings = flattenArray(...allRankings);
		let isConflict = null;

		for(let i = 0; i < state.rankings.length; i++) {
			const index = allRankings.indexOf(state.rankings[i]);
			if(index !== -1) {
				isConflict = state.rankings[i];
				break;
			}
		}

		if(isConflict === null) {
			props.onSetStandards(prevState => {
				let newState = [...prevState];
				newState[parseInt(queryParams.updateDialog)] = {
					label: state.label,
					solidANP: Number(state.solidANP),
					solidANPRatio: Number(state.solidANPRatio),
					NOC: Number(state.NOC),
					NOCRatio: Number(state.NOCRatio),
					rookie: Number(state.rookie),
					rookieRatio: Number(state.rookieRatio),
					rankings: state.rankings,
				};
				console.log(newState);
				return newState;
			});
			setQueryParams({ updateDialog: null });
		} else {
			handleSnackbarChange({ 
				text: `Ranking "${getObjectFromArray(kpiRankingOptions, isConflict).label}" has duplicated, please remove it`,
				variant: 'error'
			});
		}
	};

	useEffect(() => {
		if(!queryParams.updateDialog) _setState(defaultState);
		else if(props.standards && props.standards.length) {
			const newState = props.standards[parseInt(queryParams.updateDialog)];
			if(newState) _setState(newState);
		}
	}, [props.standards, queryParams.updateDialog]);

	return (
		<Dialog title="Update KPI" queryParamKey="updateDialog" maxWidth={maxWidth}>
			<TextField
				label="Name"
				value={state.label}
				onChange={handleChange('label')}
				error={Boolean(error.label)}
				helperText={error.label}
			/>

			<TextField
				label="Solid ANP"
				value={state.solidANP}
				onChange={handleChange('solidANP')}
				type="number"
				error={Boolean(error.solidANP)}
				helperText={error.solidANP}
			/>

			<TextField
				label="Solid ANP %"
				value={state.solidANPRatio}
				onChange={handleChange('solidANPRatio')}
				type="number"
				error={Boolean(error.solidANPRatio)}
				helperText={error.solidANPRatio}
			/>

			<TextField
				label="NOC"
				value={state.NOC}
				onChange={handleChange('NOC')}
				type="number"
				error={Boolean(error.NOC)}
				helperText={error.NOC}
			/>

			<TextField
				label="NOC %"
				value={state.NOCRatio}
				onChange={handleChange('NOCRatio')}
				type="number"
				error={Boolean(error.NOCRatio)}
				helperText={error.NOCRatio}
			/>

			<TextField
				label="Rookie"
				value={state.rookie}
				onChange={handleChange('rookie')}
				type="number"
				error={Boolean(error.rookie)}
				helperText={error.rookie}
			/>

			<TextField
				label="Rookie %"
				value={state.rookieRatio}
				onChange={handleChange('rookieRatio')}
				type="number"
				error={Boolean(error.rookieRatio)}
				helperText={error.rookieRatio}
			/>

			<Checkboxes 
				title="Rankings"
				value={state.rankings}
				options={kpiRankingOptions}
				onChange={handleChange('rankings')}
			/>

			<div className={classes.submitButton}>
				<Button
					variant="contained"
					color="primary"
					onClick={handleUpdate}
				>
					Update
				</Button>
			</div>
		</Dialog>
	);
}

export default UpdateDialog;
