import request from './request';

function getGoals(data) {
	return request({
		url: '/goals',
		method: 'GET',
		params: data,
	});
}

function updateGoals(data) {
	return request({
		url: '/goals',
		method: 'PUT',
		data,
	});
}

function getPSResult(data) {
	return request({
		url: '/goals/personal',
		method: 'GET',
		params: data,
	});
}

function getDGResult(data) {
	return request({
		url: '/goals/direct-group',
		method: 'GET',
		params: data,
	});
}

function getIGResult(data) {
	return request({
		url: '/goals/indirect-group',
		method: 'GET',
		params: data,
	});
}

function getWGResult(data) {
	return request({
		url: '/goals/whole-group',
		method: 'GET',
		params: data,
	});
}

function getDGAgentResult(data) {
	return request({
		url: '/goals/direct-group-agents',
		method: 'GET',
		params: data,
	});
}

function getIGAgentResult(data) {
	return request({
		url: '/goals/indirect-group-agents',
		method: 'GET',
		params: data,
	});
}

function getGoalStates(data) {
	return request({
		url: '/goals/states',
		method: 'GET',
		params: data,
	});
}

function getActivityComparison(data) {
	const { statusID, ...others } = data;
	return request({
		url: `/goals/activity-comparison/${data.statusID}`,
		method: 'GET',
		params: others,
	});
}

export default {
	getGoals,
	updateGoals,
	getPSResult,
	getDGResult,
	getIGResult,
	getWGResult,
	getDGAgentResult,
	getIGAgentResult,
	getGoalStates,
	getActivityComparison
};
