import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';

import useSnackbarText from '../../../hooks/useSnackbarText';
import useQueryParams from '../../../hooks/useQueryParams';
import useContestList from '../../../hooks/useContestList';

import group from '../../../api/group';
import { 
	getYearList, 
	getSitemapData, 
	getObjectFromArray,
	getFooterTotal,
	getAgentRankingLabel,
} from '../../../utils';
import { contestTypeOptions } from '../../../configs/contests';

const dateDisplayFormat = 'DD/MM/YYYY';
const sitemapValue = 'GROUP_CONTEST';
const { label } = getSitemapData(sitemapValue);

const headers = [
	{ 
		id: 'name',
		label: 'Name', 
		type: 'string',
		sticky: true,
	},
	{
		id: 'ranking',
		label: 'Ranking',
		type: 'string',
	},
	{
		id: 'solidANP',
		label: 'Solid ANP',
		type: 'float',
	},
	{
		id: 'NOCWithMinTarget',
		label: 'NOC*',
		type: 'int',
	},
	{
		id: 'NOC',
		label: 'NOC',
		type: 'int',
	},
	{
		id: 'BSA',
		label: 'BSA',
		type: 'float',
	},
	{
		id: 'existingClient',
		label: 'Existing Client',
		type: 'int',
	},
	{
		id: 'newClient',
		label: 'New Client',
		type: 'int',
	},
];

const useStyles = makeStyles(theme => ({
	select: {
		marginRight: 10,
	},
	infoWrapper: {
		marginTop: 8,
		marginBottom: 8,
		display: 'inline-flex',
		flexDirection: 'column',
		minHeight: 38,
		justifyContent: 'center'
	},
	infoText: {
		fontSize: 14,
		fontWeight: 300
	}
}));

function Contest() {
	const classes = useStyles();
	const handleSnackbarChange = useSnackbarText();
	const [queryParams, setQueryParams] = useQueryParams();
	const contests = useContestList(queryParams.year);
	const [data, setData] = useState([]);
	const [footers, setFooters] = useState([]);
	const [loading, setLoading] = useState(true);

	const handleChange = (type) => (event) => {
		if(type === 'year') {
			setQueryParams({ 
				[type]: event.target.value,
				contestID: null,
			});
		} else 
			setQueryParams({ [type]: event.target.value });
	};

	useEffect(() => {
		let tmp = {};
		if(!queryParams.year) tmp.year = moment().year();
		setQueryParams(tmp);
	}, [queryParams.year, queryParams.contestID, contests, setQueryParams]);

	useEffect(() => {
		if(contests.length) setQueryParams({ contestID: contests[0].value });
	}, [contests, setQueryParams]);

	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			let { data, success } = await group.getContestResult({ 
				contestID: queryParams.contestID,
			});
			setLoading(false);

			if(success) {
				const formattedData = data.contests.map(e => ({
					...e,
					ranking: getAgentRankingLabel(e.ranking),
				}));
				setData(formattedData);
				setFooters([{
					name: 'Total',
					...getFooterTotal(headers, formattedData),
				}]);
			} else {
				setData([]);
				setFooters([]);
				return handleSnackbarChange({ text: data.message, variant: 'error' });
			}
		};

		if(queryParams.contestID) getData();
		else setLoading(false);
	}, [queryParams.contestID, handleSnackbarChange]);

	const contestInfo = getObjectFromArray(contests, queryParams.contestID);
	const formattedHeaders = [...headers];
	formattedHeaders[3].label = `NOC / ${contestInfo ? contestInfo.NOCTarget : 0}`

	return (
		<Layout title={label}>
			<Select 
				className={classes.select}
				maxWidth={110}
				label="Year"
				options={getYearList()}
				value={queryParams.year}
				onChange={handleChange('year')}
			/>

			<Select 
				className={classes.select}
				maxWidth={200}
				label="Contest"
				options={contests}
				value={queryParams.contestID || ''}
				onChange={handleChange('contestID')}
			/>

			{contestInfo && (
				<div className={classes.infoWrapper}>
					<Typography className={classes.infoText}>
						Contest type: {getObjectFromArray(contestTypeOptions, contestInfo.type).label}
					</Typography>
					<Typography className={classes.infoText}>
						Date: {moment(contestInfo.startDate).format(dateDisplayFormat)} to {moment(contestInfo.endDate).format(dateDisplayFormat)}
					</Typography>
				</div>
			)}
			
			<Table
				headers={formattedHeaders}
				data={data}
				footers={footers}
				isLoading={loading}
			/>

		</Layout>
	);
}

export default Contest;
