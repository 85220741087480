import React from 'react';
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  makeStyles,
  IconButton,
  DialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const DATE_FORMAT = 'YYYY-MM-DD';

const useStyles = makeStyles(theme => ({
  title: { fontWeight: 'bold' },
  image: {
    marginTop: theme.spacing(1),
    width: `100%`,
    borderRadius: 10,
  },
  closeButtonWrapper: {
    flex: 1,
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

export default function EventDialog(props) {
  const { event, open, onClose } = props;
  const classes = useStyles();

  const isSameDate = event
    ? moment(event.startAt).format(DATE_FORMAT) ===
      moment(event.endAt).format(DATE_FORMAT)
    : false;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {event && (
        <DialogContent>
          <div className={classes.root}>
            <Typography className={classes.title} color="primary">
              {event.name}
            </Typography>

            <Typography variant="body2">
              {isSameDate
                ? moment(event.startAt).format('D MMM YYYY')
                : `${moment(event.startAt).format('D MMM YYYY')} - ${moment(
                    event.endAt
                  ).format('D MMM YYYY')}`}
            </Typography>

            {event.featureImageUrl && (
              <img
                className={classes.image}
                src={event.featureImageUrl}
                alt=""
              />
            )}

            {event.description && (
              <Typography style={{ whiteSpace: 'pre-wrap' }}>
                {event.description}
              </Typography>
            )}
          </div>
        </DialogContent>
      )}
      <DialogActions>
        <div className={classes.closeButtonWrapper}>
          <Button variant="contained" onClick={onClose} color="primary">
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
