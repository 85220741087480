const tryIsNewClientOptions = [
	{ label: 'New', value: '1' },
	{ label: 'Existing', value: '0' },
];

const tryRecruitmentOptions = [
	{ label: 'Not Available', value: '0' },
	{ label: 'Follow Up', value: '1' },
	{ label: 'PCE', value: '2' },
	{ label: 'Contract', value: '3' },
];

export {
	tryIsNewClientOptions,
	tryRecruitmentOptions
}
