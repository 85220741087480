import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  Slide,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import TextField from '../components/TextField';

import useSnackbarText from '../hooks/useSnackbarText';
import useQueryParams from '../hooks/useQueryParams';
import useUserInfo from '../hooks/useUserInfo';

import configs from '../configs';
import agents from '../api/agents';
import auth from '../api/auth';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
    minHeight: '100vh',
  },
  dialogContent: {
    maxWidth: 500,
    padding: '1rem',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '50px!important',
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '2.5rem',
    fontWeight: 600,
    textAlign: 'center',
  },
  subTitle: {
    color: theme.palette.primary.dark,
    textAlign: 'center',
    fontWeight: 300,
    marginBottom: 30,
    fontSize: '1.5rem',
  },
  button: {
    borderRadius: 4,
    minWidth: 120,
    marginTop: 20,
  },
}));

function SignIn() {
  const classes = useStyles();
  const userInfo = useUserInfo();
  const routerHistory = useHistory();
  const [queryParams] = useQueryParams();
  const handleSnackbarChange = useSnackbarText();
  const [state, _setState] = useState({
    username: '',
    password: '',
  });
  const [disabled, setDisable] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const setState = useCallback(newState => {
    _setState(prevState => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const handleChange = useCallback(
    type => (event, value) => {
      if (value !== undefined) setState({ [type]: value });
      else setState({ [type]: event.target.value });
    },
    [setState]
  );

  const handleSubmit = async () => {
    setDisable(true);
    const { data, success } = await agents.signIn({
      username: state.username,
      password: state.password,
    });
    setDisable(false);

    if (!success) {
      handleSnackbarChange({ text: data.message, variant: 'error' });
    } else {
      userInfo.handleChange({
        name: data.name,
        agentID: data.agentID,
        isAdmin: data.isAdmin,
      });
      window.localStorage.at = data.at;
      window.localStorage.rt = data.rt;
      routerHistory.replace(queryParams.redirect || configs.loginRedirectPath);
    }
  };

  useEffect(() => {
    (async () => {
      let { success } = await auth.authCheck();
      if (success) {
        routerHistory.replace(configs.loginRedirectPath);
      }
    })();
  }, [routerHistory]);

  return (
    <div className={classes.root}>
      <Dialog
        open
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="title"
      >
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.title} variant="h4">
            {configs.name}
          </Typography>
          <Typography className={classes.subTitle}>
            {configs.subName}
          </Typography>
          <TextField
            maxWidth
            label="Account Number"
            type="number"
            value={state.username}
            onChange={handleChange('username')}
          />
          <TextField
            label="Password"
            value={state.password}
            onChange={handleChange('password')}
            type={isPasswordVisible ? 'text' : 'password'}
            onEnterPress={handleSubmit}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setIsPasswordVisible(prev => !prev)}
                  >
                    {isPasswordVisible ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            className={classes.button}
            disabled={disabled}
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            Sign In
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SignIn;
