import React from 'react';

import GoalProgressBar from './GoalProgessBar';
import Layout from '../../../components/Layout';

// type Props = {
//     goals: {
//         label: string;
//         target: number;
//         current: number;
//         float?: boolean;
//     }[]
// }

function GoalProgressSection(props) {
    const { goals } = props;

    if(goals.length === 0) return null;

    return (
        <Layout title="Goal Progress Bar">
            <div>
                {goals.map(goal => (
                    <GoalProgressBar key={goal.label} {...goal} />
                ))}
            </div>
        </Layout>
    );
};

export default GoalProgressSection;
